import React, { FC, memo } from 'react'

import { LeaveConfirmation } from 'ui/components'
import { FieldSet } from 'ui/components/FieldSet'
import { Page, PageFace } from 'ui/components/Page'
import { Panel } from 'ui/components/Panel'

import { ManageDocumentPageProps } from './ManageDocumentPage.types'
import { EditDocumentForm } from './components/EditDocumentForm'
import { Footer } from './components/Footer'

import * as SC from './styles'

export const ManageDocumentPage: FC<ManageDocumentPageProps> = memo((props) => {
  const { isNew, form, panelTitle, pageSubtitle, onDocumentDelete, appearanceInfo, presetFields } = props

  return (
    <>
      <LeaveConfirmation preventLeaving={form.useStore((state) => state.isDirty && !state.isSubmitting)}>
        <Page
          face={PageFace.SECONDARY}
          title="Document"
          subTitle={pageSubtitle}
          footer={
            <form.Subscribe
              children={({ isSubmitting, isDirty, isFieldsValid }) => (
                <Footer
                  isSubmitting={isSubmitting}
                  disabled={isDirty && !isFieldsValid}
                  onDelete={onDocumentDelete}
                  onSubmit={form.handleSubmit}
                />
              )}
            />
          }
        >
          <Panel rounded shadowed collapsible={false} title={panelTitle}>
            <SC.PanelBody>
              <FieldSet legend="Client Document Details">
                <EditDocumentForm
                  isNew={isNew}
                  form={form}
                  appearanceInfo={appearanceInfo}
                  presetFields={presetFields}
                />
              </FieldSet>
            </SC.PanelBody>
          </Panel>
        </Page>
      </LeaveConfirmation>
    </>
  )
})
