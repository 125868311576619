/* eslint-disable camelcase */
import { BsaCtrAccountNumbers } from './bsaCTRAccountNumbers'

export type BsaCtrPersonInfo = {
  account_numbers_in?: BsaCtrAccountNumbers[]
  account_numbers_out?: BsaCtrAccountNumbers[]
  address?: string
  alternate_name?: string
  birthdate?: string
  business_type?: string
  cash_in?: number
  cash_out?: number
  city?: string
  country?: string
  email?: string
  entity_name?: string
  first_name?: string
  gender?: string
  id_country?: string
  id_form?: string
  id_form_other?: string
  id_number?: string
  id_state?: string
  is_entity?: boolean
  last_name?: string
  middle_name?: string
  multiple_transactions?: boolean
  naics_code?: string
  phone?: string
  phone_ext?: string
  postal_code?: string
  state?: string
  suffix?: string
  tin?: string
  tin_type?: string
  type?: string
}
