import React, { memo, useMemo } from 'react'

import { TableDataQa } from 'commons/types/enums'
import { DataSourceItem, Link, Table } from 'ui/components'
import { Dialog, DialogFace } from 'ui/components/Dialog'
import { Tag } from '~bank-bsa-reporting/components/Tag'
import { CTRBatch } from '~bank-bsa-reporting/types/CTRBatches/CTRBatch'

interface Props {
  item: CTRBatch | null
  onClose: () => void
}

export const BatchView = memo((props: Props) => {
  const { item, onClose } = props

  const mappedCTRs: DataSourceItem<CTRBatch['itemsInfo'][number]>[] = useMemo(
    () =>
      item.itemsInfo.map((el) => ({
        item: el
      })),
    [item]
  )

  return item ? (
    <Dialog
      id={`batch-${item.id}`}
      onClose={onClose}
      face={DialogFace.PRIMARY}
      title={`Batch ID ${item.id}`}
      subTitle="CTRs in the Batch"
    >
      <Table<CTRBatch['itemsInfo'][number]>
        dataQa={TableDataQa.BP_BATCH_CTRS}
        columns={[
          {
            title: 'Name',
            align: 'left',
            render: (item) => (
              <Link to="/bsa/ctrs/$id" params={{ id: item.id }}>
                {item.name}
              </Link>
            )
          },
          {
            title: 'Status',
            align: 'right',
            render: ({ status }) => <Tag status={status.toLocaleLowerCase() as any} />
          }
        ]}
        dataSource={mappedCTRs}
      />
    </Dialog>
  ) : null
})
