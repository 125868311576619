import React from 'react'

import { formatCurrency } from 'commons/utils'
import { ClampedText } from 'ui/components'
import { DateTime } from 'ui/components/DateTime'
import { Icon, IconName, IconSize } from 'ui/components/Icon'
import { Link } from 'ui/components/Link'
import { TableActions } from 'ui/components/QueryTable'
import { TableColumn } from 'ui/components/Table'
import { CTRItem, CTRTransaction, ChosenItems } from '~bank-bsa-reporting/types'

import { BSA_MODULE_PATH } from '../../../../../constants'

import { s } from './styles'

type Opts = {
  onShowTransactions: React.Dispatch<
    React.SetStateAction<{
      transactions: CTRTransaction[]
      ctrId: number
      ctrName: string
    }>
  >
  onItemSelect: (item?: ChosenItems) => void
  onDelete: (item: CTRItem) => void
  onShowWarnings: (data: string, warningType: string | null) => void
  chosenItems: ChosenItems[]
  isAllItemsChosen: boolean
}

export const columns = (opts: Opts): TableColumn<CTRItem>[] => [
  {
    title: (
      <s.WrappedCheckBox
        checkBoxLabel=""
        value={opts.isAllItemsChosen}
        onChange={() => opts.onItemSelect(null)}
        data-qa="CTRs List table all checkbox"
      />
    ),
    sortable: false,
    width: '50px',
    align: 'center',
    dataQa: (v: string) => `title_${v}`,
    render: (item) => (
      <s.WrappedCheckBox
        checkBoxLabel=""
        value={opts.chosenItems.findIndex((i) => i.id === item.id) !== -1}
        onChange={() => opts.onItemSelect(item)}
        data-qa={`CTRs List table item checkbox ${item.id}`}
      />
    )
  },
  {
    title: 'Date',
    align: 'left',
    render: ({ date }) => <DateTime date={date} />
  },
  {
    title: 'ID',
    align: 'center',
    render: ({ id }) => (
      <Link to="/bsa/ctrs/$id" params={{ id }}>
        {id}
      </Link>
    )
  },
  {
    title: 'Name',
    align: 'left',
    render: (item) => {
      const warningType = item.attention ? 'attention' : item.fincenWarnings ? 'fincen' : null
      return (
        <s.NameRow>
          {(!!item.attention || !!item.fincenWarnings) && (
            <s.IconButton
              type="button"
              tabIndex={0}
              onClick={() => opts.onShowWarnings(item.attention || item.fincenWarnings, warningType)}
            >
              <Icon name={IconName.ERROR_RED} size={IconSize.XS} />
            </s.IconButton>
          )}
          <Link to="/bsa/ctrs/$id" params={{ id: item.id }}>
            <ClampedText>{item.name}</ClampedText>
          </Link>
        </s.NameRow>
      )
    }
  },
  {
    title: 'Cash In',
    align: 'center',
    sortable: false,
    render: ({ cashIn }) => formatCurrency(cashIn)
  },
  {
    title: 'Cash Out',
    align: 'center',
    sortable: false,
    render: ({ cashOut }) => formatCurrency(cashOut)
  },
  {
    title: 'Filing Type',
    align: 'center',
    render: ({ filingType }) => filingType
  },
  {
    title: 'Status',
    align: 'center',
    render: ({ status }) => status
  },
  {
    title: 'Last Modified Date',
    align: 'center',
    render: ({ lastModifiedAt }) => <DateTime date={lastModifiedAt} withTime />
  },
  {
    title: 'Action',
    align: 'center',
    sortable: false,
    render: (item) => (
      <TableActions
        items={(
          [
            {
              key: 1,
              title: 'Transactions',
              behavior: 'button',
              onClick: () =>
                opts.onShowTransactions({ transactions: item.transactions, ctrId: item.id, ctrName: item.name })
            },
            {
              key: 2,
              title: 'Edit',
              url: `${BSA_MODULE_PATH}/ctrs/${item.id}/edit`,
              iconName: IconName.EDIT
            },
            {
              key: 3,
              title: 'Delete',
              behavior: 'button',
              onClick: () => opts.onDelete(item),
              iconName: IconName.DELETE
            }
          ] as any
        ).filter(Boolean)}
      />
    )
  }
]
