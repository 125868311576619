export enum DocumentFrequencyValues {
  every5Years = 'every_5_years',
  every2Years = 'every_2_years',
  every18Months = 'every_18_months',
  annual = 'annual',
  semiAnnual = 'semi-annual',
  quarterly = 'quarterly',
  monthly = 'monthly',
  oneTime = 'one-time'
}

export enum DocumentFrequencyLabels {
  every5Years = 'Every 5 years',
  every2Years = 'Every 2 years',
  every18Months = 'Every 18 Months',
  annual = 'Annual',
  semiAnnual = 'Semi-Annual',
  quarterly = 'Quarterly',
  monthly = 'Monthly',
  oneTime = 'One-Time'
}
