import React, { FC, memo } from 'react'

import { SalesInfo } from 'commons/types/DTO/bank/reportWebPage'
import { formatCurrencyCent } from 'commons/utils'

import * as SmallTable from '../../../../../components/smallTable.styles'
import { SalesSourcesTable } from './SalesSourcesTable'

import * as SC from './styles'

export const SalesReportTable: FC<{
  salesInfo: SalesInfo
  monthlyAnalyticsId: number
  bankAccountId: number
  title: string
}> = memo(({ salesInfo, monthlyAnalyticsId, bankAccountId, title }) => (
  <div>
    <SmallTable.WrappedLayer rounded shadowed>
      <SmallTable.Title>{title}</SmallTable.Title>
      <SmallTable.Table>
        <tbody>
          <tr>
            <SC.Td>Current Month</SC.Td>
            <SC.Td>{formatCurrencyCent(salesInfo.total, false)}</SC.Td>
          </tr>
        </tbody>
      </SmallTable.Table>
    </SmallTable.WrappedLayer>
    {salesInfo.salesSourceItems.map((el, index) => (
      <SalesSourcesTable
        key={index}
        salesSourceItem={el}
        monthlyAnalyticsId={monthlyAnalyticsId}
        bankAccountId={bankAccountId}
      />
    ))}
  </div>
))
