import React, { FC, memo } from 'react'

import { Form } from 'brief-form'
import { usAndTerritories } from 'commons/types/dictionaries'
import { YesNoRadioGroup } from 'commons/types/enums'
import { DatePicker, RadioGroup, Validators } from 'ui/components'
import { TextButton } from 'ui/components/Buttons'
import { InfoSet } from 'ui/components/Info'
import { CurrencyCentInput, PercentInput, SSNInput, TextInput } from 'ui/components/InputV2'
import { Select, SelectListName, getOptionsByListName } from 'ui/components/Select'
import { Col, FormRow } from 'ui/themes/globalStyles'

import { OnboardingFormFieldNames } from '../../enums'
import { ContactAdditionalInformationFormProps } from './ContactAdditionalInformationFormProps'
import { onCopyFromPhysicalAddressToMailingAddress } from './helpers'
import { useContactAdditionalInformationForm } from './useContactAdditionalInformationForm'

export const ContactAdditionalInformationForm: FC<ContactAdditionalInformationFormProps> = memo((props) => {
  const {
    contactIndex,
    companyIndex,
    value,
    state: {
      isDebtholderRole,
      isOwnerRole,
      isAccountSignerRole,
      contactPersonalAddressLabel,
      showOwnerMailingAddress,
      showAccountSignerMailingAddress,
      showOwnerPhysicalAddress,
      isOwnerWithPercentContactRequired,
      showOwnerSsn,
      showOwnerBirthday,
      conditionPercentOwnerSsn
    },
    onChange
  } = props

  const { Field, config } = useContactAdditionalInformationForm({
    companyIndex,
    contactIndex,
    formData: value,
    onChange
  })

  const isMailingAddressEnable = showOwnerMailingAddress || showAccountSignerMailingAddress

  const onCopy = () => onCopyFromPhysicalAddressToMailingAddress(config)

  const showOwnerSsnField =
    isAccountSignerRole ||
    (isOwnerRole && showOwnerSsn) ||
    (isOwnerRole &&
      isOwnerWithPercentContactRequired &&
      +value.formValues.ownerOwnershipPercent >= conditionPercentOwnerSsn)
  const showBirthdateField = isAccountSignerRole || (isOwnerRole && showOwnerBirthday)
  const showPhysicalAddressBlock = isAccountSignerRole || (isOwnerRole && showOwnerPhysicalAddress)

  return (
    <Form config={config}>
      <InfoSet legend="Additional Information">
        {isDebtholderRole && (
          <>
            <FormRow>
              <Col>
                <Field
                  name={OnboardingFormFieldNames.DEBTHOLDER_DEBT_AMOUNT}
                  label="Debt Amount"
                  input={CurrencyCentInput}
                  required
                  validator={Validators.required.field}
                  inputProps={{
                    defaultEmptyValue: null,
                    'data-qa': 'Debt Amount'
                  }}
                />
              </Col>
              <Col>
                <Field
                  name={OnboardingFormFieldNames.DEBTHOLDER_PAYMENT_FREQUENCY}
                  label="Payment Frequency"
                  input={TextInput}
                  required
                  validator={Validators.required.field}
                  inputProps={{
                    'data-qa': 'Payment Frequency'
                  }}
                />
              </Col>
            </FormRow>
            <FormRow>
              <Col>
                <Field
                  name={OnboardingFormFieldNames.DEBTHOLDER_PAYMENT_AMOUNT}
                  label="Payment Amount"
                  input={CurrencyCentInput}
                  required
                  validator={Validators.required.field}
                  inputProps={{
                    defaultEmptyValue: null,
                    'data-qa': 'Payment Amount'
                  }}
                />
              </Col>
              <Col>
                <Field
                  name={OnboardingFormFieldNames.DEBTHOLDER_MATURITY_DATE}
                  label="Maturity Date"
                  input={DatePicker}
                  required
                  validator={Validators.required.field}
                  inputProps={{
                    'data-qa': 'Maturity Date'
                  }}
                />
              </Col>
            </FormRow>
          </>
        )}

        {isOwnerRole && (
          <>
            <FormRow>
              <Col>
                <Field
                  name={OnboardingFormFieldNames.OWNER_INVESTMENT_AMOUNT}
                  label="Investment"
                  input={CurrencyCentInput}
                  required
                  validator={Validators.required.field}
                  inputProps={{
                    defaultEmptyValue: null,
                    'data-qa': 'Investment'
                  }}
                />
              </Col>
              <Col>
                <Field
                  name={OnboardingFormFieldNames.OWNER_OWNERSHIP_PERCENT}
                  label="Ownership"
                  input={PercentInput}
                  required
                  validator={Validators.required.field}
                  inputProps={{
                    'data-qa': 'Ownership'
                  }}
                />
              </Col>
            </FormRow>
          </>
        )}

        {(showOwnerSsnField || showBirthdateField) && (
          <>
            <FormRow>
              <Col>
                {showBirthdateField && (
                  <Field
                    name={OnboardingFormFieldNames.BIRTHDATE}
                    label="Date of Birth"
                    input={DatePicker}
                    required
                    validator={Validators.required.field}
                    inputProps={{
                      'data-qa': 'Date of Birth'
                    }}
                  />
                )}
              </Col>
              <Col>
                {showOwnerSsnField && (
                  <Field
                    name={OnboardingFormFieldNames.EINSSN}
                    label="Social Security Number / Tax ID Number"
                    input={SSNInput}
                    required
                    validator={Validators.required.field}
                    inputProps={{
                      'data-qa': 'Social Security Number / Tax ID Number'
                    }}
                  />
                )}
              </Col>
            </FormRow>
          </>
        )}
        {showPhysicalAddressBlock && (
          <>
            <InfoSet legend={contactPersonalAddressLabel}>
              <FormRow>
                <Col>
                  <Field
                    name={OnboardingFormFieldNames.STREET}
                    label="Street"
                    input={TextInput}
                    required
                    validator={Validators.required.field}
                    inputProps={{
                      'data-qa': 'Street',
                      maxLength: 255
                    }}
                  />
                </Col>
                <Col>
                  <Field
                    name={OnboardingFormFieldNames.CITY}
                    label="City"
                    input={TextInput}
                    required
                    validator={Validators.required.field}
                    inputProps={{
                      'data-qa': 'City',
                      maxLength: 40
                    }}
                  />
                </Col>
              </FormRow>
              <FormRow>
                <Col>
                  <Field
                    name={OnboardingFormFieldNames.STATE}
                    label="State"
                    input={Select}
                    required
                    validator={Validators.required.field}
                    inputProps={{
                      'data-qa': 'RI - State',
                      'options-data-qa': 'RI - State',
                      data: getOptionsByListName(SelectListName.STATE_OF_INCORPORATION)
                    }}
                  />
                </Col>
                <Col>
                  <Field
                    required
                    name={OnboardingFormFieldNames.COUNTRY}
                    label="Country"
                    input={Select}
                    validator={Validators.required.field}
                    inputProps={{
                      'data-qa': 'Country',
                      'options-data-qa': 'Country',
                      data: usAndTerritories
                    }}
                  />
                </Col>
              </FormRow>
              <FormRow>
                <Col>
                  <Field
                    name={OnboardingFormFieldNames.POSTAL_CODE}
                    label="Zip/Postal Code"
                    input={TextInput}
                    required
                    validator={Validators.required.field}
                    inputProps={{
                      'data-qa': 'Zip/Postal Code',
                      maxLength: 20
                    }}
                  />
                </Col>
              </FormRow>
            </InfoSet>
            {isMailingAddressEnable && (
              <>
                <div>
                  <TextButton title="Use physical address for mailing address" onClick={onCopy} data-qa="Duplicate">
                    Duplicate to Mailing Address
                  </TextButton>
                </div>
                <InfoSet legend="Mailing Address">
                  <FormRow>
                    <Col>
                      <Field
                        name={OnboardingFormFieldNames.CONTACT_MAILING_STREET}
                        label="Street"
                        input={TextInput}
                        required
                        validator={Validators.required.field}
                        inputProps={{
                          'data-qa': 'Mailing Street',
                          maxLength: 255
                        }}
                      />
                    </Col>
                    <Col>
                      <Field
                        name={OnboardingFormFieldNames.CONTACT_MAILING_CITY}
                        label="City"
                        input={TextInput}
                        required
                        validator={Validators.required.field}
                        inputProps={{
                          'data-qa': 'Mailing City',
                          maxLength: 40
                        }}
                      />
                    </Col>
                  </FormRow>
                  <FormRow>
                    <Col>
                      <Field
                        name={OnboardingFormFieldNames.CONTACT_MAILING_STATE}
                        label="State/Province"
                        input={Select}
                        required
                        validator={Validators.required.field}
                        inputProps={{
                          'data-qa': 'Mailing State/Province',
                          'options-data-qa': 'Mailing State/Province',
                          data: getOptionsByListName(SelectListName.STATE_OF_INCORPORATION)
                        }}
                      />
                    </Col>
                    <Col>
                      <Field
                        name={OnboardingFormFieldNames.CONTACT_MAILING_COUNTRY}
                        label="Country"
                        input={Select}
                        required
                        validator={Validators.required.field}
                        inputProps={{
                          'data-qa': 'Country',
                          'options-data-qa': 'Country',
                          data: usAndTerritories
                        }}
                      />
                    </Col>
                  </FormRow>
                  <FormRow>
                    <Col>
                      <Field
                        name={OnboardingFormFieldNames.CONTACT_MAILING_POSTAL_CODE}
                        label="Postal Code"
                        input={TextInput}
                        required
                        validator={Validators.required.field}
                        inputProps={{
                          'data-qa': 'Postal Code',
                          maxLength: 20
                        }}
                      />
                    </Col>
                  </FormRow>
                </InfoSet>
              </>
            )}
            <Field
              name={OnboardingFormFieldNames.US_CITIZEN}
              label={
                <>
                  Is this individual/entity a <b>US Citizen?</b>
                </>
              }
              input={RadioGroup}
              inputProps={{
                name: `${OnboardingFormFieldNames.US_CITIZEN}${companyIndex}${contactIndex}`,
                'data-qa': 'Is this individual/entity an US Citizen??',
                data: [
                  { value: YesNoRadioGroup.YES, label: 'Yes' },
                  { value: YesNoRadioGroup.NO, label: 'No' }
                ]
              }}
              required
              validator={Validators.required.notNull}
            />
          </>
        )}
      </InfoSet>
    </Form>
  )
})
