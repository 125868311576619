import React, { useEffect } from 'react'

import { FieldApi } from '@tanstack/react-form'
import { BsaSar } from 'commons/types/DTO/bank/bsa'
import { CurrencyInput, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

type CurrentCumulativeRendererProps = {
  field: FieldApi<BsaSar, 'transactions_info.current_cumulative_amount'>
}

const CurrentCumulativeRenderer = ({ field }: CurrentCumulativeRendererProps) => {
  const { formApi } = useSARFormContext()

  useEffect(() => {
    const currentAmount = Number(formApi.state.values.transactions_info.current_amount)
    const previousCumulative = Number(formApi.state.values.transactions_info.total.previous_cumulative)

    formApi.setFieldValue(
      'transactions_info.current_cumulative_amount',
      Math.ceil(currentAmount + previousCumulative),
      {
        dontUpdateMeta: true
      }
    )
  }, [
    formApi,
    formApi.state.values.transactions_info.current_amount,
    formApi.state.values.transactions_info.total.previous_cumulative
  ])

  return <FF field={field} label="31. Cumulative amount" input={(p) => <CurrencyInput {...p} disabled />} />
}

export const CurrentCumulativeAmount = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="transactions_info.current_cumulative_amount"
      children={(field) => <CurrentCumulativeRenderer field={field} />}
    />
  )
}
