import { useMutation, useQueryClient } from 'react-query'

import { handlerServerError } from 'errors'
import { Toast } from 'ui/components/Toast'

import * as Service from '../../service'
import { CTR_LIST_WEBPAGE_KEY } from './useGetCTRList'

export const useDelete = () => {
  const queryClient = useQueryClient()

  return useMutation((ids: number[]) => Promise.all(ids.map((id) => Service.CTR.remove({ id }))), {
    onError: (error) => handlerServerError(error),
    onSuccess: async () => {
      Toast.successSmall('The CTR successfully deleted')
      await queryClient.invalidateQueries([CTR_LIST_WEBPAGE_KEY])
    }
  })
}
