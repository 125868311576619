/* eslint-disable camelcase */
import { BsaCtrInstitutionContactInfo } from './bsaCTRInstitutionContactInfo'
import { BsaSettingFilesParsingConfig } from './bsaSettingFilesParsingConfig'
import { InstitutionBranchInfo } from './institutionBranchInfo'
import { TellerBranchInfo } from './tellerBranchInfo'
import { TransmitterInfo } from './transmitterInfo'

export type BsaSetting = {
  created_at?: string
  deleted_at?: string
  files_parsing_config?: BsaSettingFilesParsingConfig
  id?: number
  institution_info?: BsaCtrInstitutionContactInfo
  organization_id?: number
  rounding_type?: string
  tellers_branch?: TellerBranchInfo[]
  transaction_locations_info?: InstitutionBranchInfo[]
  transmitter_info?: TransmitterInfo
  updated_at?: string
}
