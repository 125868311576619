import { OnboardingWebPageResponse } from 'commons/types/DTO/commons'
import { YesNoRadioGroup } from 'commons/types/enums'

import { CompanyFormValues, OnboardingFormState } from '../../../types/state'
import { getEmptyCompany, parseCompanyFromApplication } from './emptyEntity/getEmptyCompany'

// used in packages/onboarding-form/src/components/OnboardingForm/helpers/index.ts
// like helpers.mapApplicationToState
// in packages/onboarding-form/src/components/OnboardingForm/onboardingFormReducer.ts
export const mapApplicationToState = ({
  currentState,
  responseData
}: {
  currentState: OnboardingFormState
  responseData: OnboardingWebPageResponse
}): OnboardingFormState => {
  const emptyCompanies = [getEmptyCompany()]
  const companiesFormValues: CompanyFormValues[] = responseData.companies.length
    ? responseData.companies.map((_company, companyIndex) =>
        parseCompanyFromApplication({ responseData, companyIndex })
      )
    : emptyCompanies
  const isAccountSignerTrue =
    companiesFormValues.length && companiesFormValues[0].contacts.length
      ? companiesFormValues[0].contacts[0].roleInformation.formValues.accountSigner === YesNoRadioGroup.YES
      : false
  const isDebtholderRoleTrue =
    companiesFormValues.length && companiesFormValues[0].contacts.length
      ? companiesFormValues[0].contacts[0].roleInformation.formValues.debtholder === YesNoRadioGroup.YES
      : false
  const isOwnerRoleTrue =
    companiesFormValues.length && companiesFormValues[0].contacts.length
      ? companiesFormValues[0].contacts[0].roleInformation.formValues.owner === YesNoRadioGroup.YES
      : false

  const questionnaireResponse = responseData.companies[0]?.questionnaire || emptyCompanies[0]?.questionnaire.formValues

  const resultState = {
    ...currentState,
    applicationStatus: responseData.applicationStatus,
    applicationName: responseData.applicationName,
    bankName: responseData.bankName,
    bankNotes: responseData.bankNotes,
    bankPercents: responseData.bankContactOwnershipPercents,
    currentStep: responseData.currentStep,
    step: responseData.currentStep,
    availableSteps: responseData.availableSteps,
    proxyWebpageData: {
      updatedAt: responseData.updatedAt
    },
    isCurrentStepEditEnable: responseData.isPageEditEnable,
    selectedCompany: companiesFormValues[0],
    selectedCompanyIndex: 0,
    formValue: {
      notes: {
        formValues: { clientNotes: responseData.clientNotes },
        formErrors: {}
      },
      companiesFormValues
    },

    formsState: {
      accountDetails: {
        isConsumerAccountAvailable: responseData.isBusinessTypeConsumerAccountOptionEnabled
      },
      licenseDetails: {
        isLicenseMetrcApiKeyEnable: responseData.isLicenseMetrcAPIKeyFieldEnabled
      },
      contactDetails: {
        isAccountSigner: isAccountSignerTrue,
        isMobileEnable: responseData.isContactMobileFieldEnabled,
        isHomePhoneEnable: responseData.isContactHomePhoneFieldEnabled,
        isOtherMjRelatedBusinessEnable: responseData.isContactOtherMjRelatedBusinessFieldEnabled,
        isBioEnable: responseData.isContactBioFieldEnabled,
        allContacts: []
      },
      roleInformation: {
        isConductorRoleEnable: responseData.isContactConductorRoleFieldEnabled
      },
      contactAdditionalInformation: {
        isDebtholderRole: isDebtholderRoleTrue,
        isOwnerRole: isOwnerRoleTrue,
        isAccountSignerRole: isAccountSignerTrue,
        showOwnerSsn: responseData.showOwnerSsn,
        showOwnerBirthday: responseData.showOwnerBirthday,
        showOwnerBirthdayAndSsn: responseData.showOwnerBirthdayAndSsn,
        showOwnerPhysicalAddress: responseData.showOwnerPhysicalAddress,
        showOwnerMailingAddress: responseData.showOwnerMailingAddress,
        showAccountSignerMailingAddress: responseData.showAccountSignerMailingAddress,
        isOwnerWithPercentContactRequired: responseData.isContactOwnerWithPercentFieldRequired,
        contactPersonalAddressLabel: responseData.contactPersonalAddressLabel,
        conditionPercentOwnerSsn: responseData.conditionPercentOwnerSsn
      },
      questionnaire: {
        statesLicensedLabel: questionnaireResponse.statesLicensedLabel,
        isWholesaleProductsEnable: 'aWholesaleProducts' in questionnaireResponse,
        isWholesaleProductsDescEnable: 'aWholesaleProductsDesc' in questionnaireResponse,
        isTypeOfProductsEnable: 'bTypeOfProducts' in questionnaireResponse,
        isTypeOfAncillaryProductsEnable: 'cTypeOfAncillaryProducts' in questionnaireResponse,
        isWhoSuppliesYouEnable: 'whoSuppliesYou' in questionnaireResponse,
        isWhereIsYourSupplierEnable: 'whereIsYourSupplier' in questionnaireResponse,
        isWhoDoYouSupplyGrowerEnable: 'whoDoYourSupplyGrower' in questionnaireResponse,
        isGrowerCustomersLocationEnable: 'growerCustomersLocation' in questionnaireResponse,
        isHowManyLocationsEnable: 'dHowManyLocations' in questionnaireResponse,
        isOnlineOrdersForPickupEnable: 'eOnlineOrdersForPickup' in questionnaireResponse,
        isMinorSellingEnsureEnable: 'fMinorSellingEnsure' in questionnaireResponse,
        isPaymentMethodsEnable: 'gPaymentMethods' in questionnaireResponse,
        isTransferToOtherStatesEnable: 'hTransferToOtherStates' in questionnaireResponse,
        isTransferToOtherStatesDescEnable: 'hTransferToOtherStatesDesc' in questionnaireResponse,
        isAreYouAffiliatedEnable: 'iAreYouAffiliated' in questionnaireResponse,
        isAreYouAffiliatedDescEnable: 'iAreYouAffiliatedDesc' in questionnaireResponse,
        isContactsForeignConnectionsEnable: 'contactsForeignConnections' in questionnaireResponse
      },
      professionalPartnerships: {
        isProPartPrevClosureReasonEnable: 'proPartPrevClosureReason' in questionnaireResponse,
        isProPartCurrentServicesOtherDescEnable:
          responseData.isQuestionnaireProPartCurrentServicesOtherDescFieldEnabled,
        isProPartCurrentServicesOtherDescDisabledEnable:
          responseData.isQuestionnaireProPartCurrentServicesOtherDescConditionsEnabled,
        isProPartCurrentServicesLogisticEnable: 'proPartCurrentServicesLogistic' in questionnaireResponse,
        isProPartCurrentServicesLogisticDescEnable: 'proPartCurrentServicesLogisticDesc' in questionnaireResponse,
        isProPartInternetGamblingEnable: 'proPartInternetGambling' in questionnaireResponse
      },
      atmGeneralInfo: {
        isAdditionalHintEnable: responseData.isQuestionnaireATMAdditionalHintEnabled
      },
      availableServices: {
        isAvailableServicesDescEnable: responseData.isQuestionnaireAvailableServicesDescFieldEnabled,
        isAvailServiceHowManyAccountsIsOpeningEnable: responseData.isAvailServiceHowManyAccountsIsOpeningFieldEnabled,
        isAvailServiceOnlineBankingDescEnable: responseData.isAvailServiceOnlineBankingDescEnable,
        conditions: {
          isAvailServiceCheckingOperatingEnable: 'availServiceCheckingOperating' in questionnaireResponse,
          isAvailServiceCheckingPayrollEnable: 'availServiceCheckingPayroll' in questionnaireResponse,
          isAvailServiceAchOriginationEnable: 'availServiceACHOrigination' in questionnaireResponse,
          isAvailServiceRemoteDepositCaptureEnable: 'availServiceRemoteDepositCapture' in questionnaireResponse,
          isAvailServiceEscrowEnable: 'availServiceEscrow' in questionnaireResponse,
          isAvailServiceCheckingEnable: 'availServiceChecking' in questionnaireResponse,
          isBusinessSavingsEnable: responseData.isQuestionnaireAvailableServicesBusinessSavingsFieldEnabled,
          isAvailServiceMobileDepositEnable: 'availServiceMobileDeposit' in questionnaireResponse,
          isWireTransferEnable: 'availServiceWire' in questionnaireResponse,
          isAvailServiceCreditCardEnable: 'availServiceCreditCard' in questionnaireResponse,
          isAvailServicePersonalCheckingEnable: 'availServicePersonalChecking' in questionnaireResponse,
          isAvailServicePersonalSavingsEnable: 'availServicePersonalSavings' in questionnaireResponse,
          isAvailServiceRollingCoinChangeOrderEnable: 'availServiceRollingCoinChangeOrder' in questionnaireResponse,
          isAnticipatedActivityAtmSettlementEnable: 'availServiceATMSettlement' in questionnaireResponse,
          isAvailServiceWireDomesticEnable: 'availServiceWireDomestic' in questionnaireResponse,
          isAvailServiceWireForeignEnable: 'availServiceWireForeign' in questionnaireResponse,
          isAvailServiceWireDomesticOnlyEnable: 'availServiceWireDomesticOnly' in questionnaireResponse
        }
      },
      anticipatedMonthlyActivity: {
        isAnticipatedActivityWiresInternationalCountriesEnable:
          'anticipatedActivityWiresInternationalCountries' in questionnaireResponse,

        isAdditionalAgreementRequiredEnable:
          responseData.isQuestionnaireAnticipatedActivityAdditionalAgreementTextEnabled,
        conditions: {
          isAnticipatedActivityAchOriginatingRequired:
            responseData.isQuestionnaireAnticipatedActivityAchOriginatingFieldRequired,
          isAnticipatedActivityWiresDomesticOnlyEnable:
            'anticipatedActivityWiresDomesticOnlyFrequency' in questionnaireResponse,
          isAnticipatedActivityWiresDomesticEnable:
            'anticipatedActivityWiresDomesticFrequency' in questionnaireResponse,
          isAnticipatedActivityCheckDepositsEnable:
            'anticipatedActivityCheckDepositsFrequency' in questionnaireResponse,
          isAnticipatedActivityAtmSettlementEnable:
            'anticipatedActivityATMSettlementFrequency' in questionnaireResponse,
          isAnticipatedActivityRemoteDepositsEnable:
            'anticipatedActivityRemoteDepositsFrequency' in questionnaireResponse,
          isAnticipatedActivityWiresInternationalEnable:
            'anticipatedActivityWiresInternationalFrequency' in questionnaireResponse
        }
      },
      anticipatedMonthlyActivityNA: {
        isAnticipatedMonthlyActivityNAFormEnable: responseData.isAnticipatedMonthlyActivityNAFormEnabled
      },
      companyMerchantQuestionnaire: {
        isPrimaryMethodToFundEnable: 'primaryMethodToFund' in questionnaireResponse,
        isCryptocurrencyTransactionsEnable: responseData.isCryptocurrencyTransactionsFieldEnabled,
        isRealTimePaymentServicesEnable: responseData.isRealTimePaymentServicesFieldEnabled
      }
    },
    licenseTypesValues: responseData.licenseTypesValues,
    licenseSubTypesValues: responseData.licenseSubTypesValues,
    licensePOSTypesValues: responseData.licensePOSTypesValues,
    businessTypesValues: responseData.businessTypesValues,
    entityTypesValues: responseData.entityTypesValues,
    isIsolatedApplicationFromEntities: responseData.isIsolatedApplicationFromEntities,
    applicationClientDocumentsUploading: responseData.applicationClientDocumentsUploading,
    documentsUploading: responseData.documentsUploading,
    businessTypesDescription: responseData.businessTypesDescription,
    documentSign: responseData.documentSign,
    shouldCustomerAcknowledgementDialogBeShown: responseData.shouldCustomerAcknowledgementDialogBeShown,
    isSubmitRFIFormEnabled: responseData.isSubmitRFIFormEnabled
  }
  return resultState
}
