import { FieldProps, FormConfig, FormInputProps } from 'brief-form'

import { ContactMetadata } from '../../../../../../types/contact'

export interface ContactMetadataManageFormProps {
  config: FormConfig<ContactMetadata>
  Field: <Input extends React.ComponentType<FormInputProps<any, any>>>(
    props: FieldProps<Input, ContactMetadata>
  ) => JSX.Element
  companyId?: number
  contactId: number
  contactName: string
}
