/* eslint-disable camelcase */
import { BsaCtrBatch } from './bsaCtrBatch'

export type BsaBatch = {
  acknowledgments_key?: string
  created_at?: string
  ctrs_info?: BsaCtrBatch[]
  sars_info?: BsaCtrBatch[]
  ctrx_pdf_key?: string
  sarx_pdf_key?: string
  deleted_at?: string
  end_date?: string
  filing_name?: string
  id?: number
  organization_id?: number
  record_count?: number
  start_date?: string
  status?: string
  tracking_id?: string
  type?: string
  updated_at?: string
  xml_key?: string
  name?: string
}
