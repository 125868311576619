import React, { useContext, useState } from 'react'

import { useRouter } from '@tanstack/react-router'
import { useConfirmation } from 'ui/hooks'
import { useDelete } from '~bank-bsa-reporting/hooks/batch'

import { CTRBatchesChosenItemsContext } from '../context/CTRBatchesChosenItemsContext'

export const useCTRBatchDeleteConfirmation = () => {
  const router = useRouter()

  const { chosenItemsIdList, resetChosenItemsList } = useContext(CTRBatchesChosenItemsContext)

  const [itemToDelete, setItemToDelete] = useState<number | null>(null)

  const deleteMutation = useDelete()

  const { open: openDeleteConfirmation, Confirmation: DeleteConfirmation } = useConfirmation({
    message: (
      <span>
        Are you sure you want to delete{' '}
        <i>{itemToDelete || chosenItemsIdList.length === 1 ? itemToDelete || chosenItemsIdList[0] : 'these'}</i>{' '}
        Batch(es)?
      </span>
    ),
    onConfirm: () =>
      deleteMutation.mutateAsync(itemToDelete ? [itemToDelete] : chosenItemsIdList).then(() => {
        setItemToDelete(null)
        resetChosenItemsList()
        router.invalidate()
      }),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  return {
    DeleteConfirmation,
    openDeleteConfirmation
  }
}
