import { FormInputProps } from 'brief-form'
import { ChoiceValue } from 'ui/types'

import { SingleSelectAutoSuggestOpts } from '../SingleSelectAutoSuggest'

export type ContactSelectOpts = Omit<SingleSelectAutoSuggestOpts, 'listName' | 'options'> & {
  filters?: { field: string; type: string; value: string | number }[]
}

export type ContactSelectProps = FormInputProps<ChoiceValue, ContactSelectOpts>

export type ContactSearchItem = {
  email: string
  firstName: string
  id: number
  lastName: string
}
