import React, { useState } from 'react'

import {
  DateTime,
  IconName,
  Info,
  InfoSet,
  InfoSetDirection,
  Link,
  PageSection,
  PageSectionFace,
  formatDateTime
} from 'ui/components'

import { Tag } from '../../../../../../components/Tag'
import { BSAStatus } from '../../../../../../types'
import { GeneralInformationProps } from './GeneralInformation.types'
import { AcknowledgmentsPdfLink, BatchPdfLink, BatchXmlLink, History } from './components'

import { s } from './styles'

export const GeneralInformation = (props: GeneralInformationProps) => {
  const { details, page, pageSize, onHistoryPageChange } = props

  const {
    id,
    startDate,
    endDate,
    bsaTrackingId,
    status,
    showDownloadBatchXML,
    showUploadAcknowledgmentsPDF,
    showDownloadAcknowledgmentsPDF,
    showUploadBatchPDF,
    showDownloadBatchPDF,
    subjectsCount,
    filingName,
    createdAt,
    updatedAt,
    history,
    historyTotalCount,
    type
  } = details

  const isCTR = type === 'ctr'
  const [isHistoryOpen, setHistoryOpen] = useState(false)
  const ctrsDate = formatDateTime(startDate)

  return (
    <PageSection face={PageSectionFace.SECONDARY} title="General Information" anchor="general">
      <s.HrWithBottomMargin />
      <InfoSet direction={InfoSetDirection.COLUMN}>
        <s.Row>
          <Info label="Batch ID">{id}</Info>
          <Info label={isCTR ? 'CTRs Date' : 'Period'}>
            {isCTR ? (
              <Link to="/bsa/ctrs" search={{ trans_date: ctrsDate }}>
                {ctrsDate}
              </Link>
            ) : (
              <>
                <DateTime date={startDate} /> - <DateTime date={endDate} />
              </>
            )}
          </Info>
        </s.Row>
        <s.Row>
          <Info label="Status">
            <Tag status={status.toLocaleLowerCase() as BSAStatus} />
          </Info>
          <Info label="Batch XML">
            <BatchXmlLink id={id} showDownloadBatchXML={showDownloadBatchXML} />
          </Info>
        </s.Row>
        <s.Row>
          <Info label="Batch PDF">
            <BatchPdfLink
              id={id}
              showDownloadBatchPDF={showDownloadBatchPDF}
              showUploadBatchPDF={showUploadBatchPDF}
              isCTR={isCTR}
            />
          </Info>
          <Info label="Acknowledgments PDF">
            <AcknowledgmentsPdfLink
              id={id}
              showDownloadAcknowledgmentsPDF={showDownloadAcknowledgmentsPDF}
              showUploadAcknowledgmentsPDF={showUploadAcknowledgmentsPDF}
              isCTR={isCTR}
            />
          </Info>
        </s.Row>
        <s.Row>
          <Info label={`Number of ${isCTR ? 'CTRs' : 'SARs'}`}>{subjectsCount}</Info>
          <Info label="Filing Name">{filingName}</Info>
        </s.Row>
        <s.Row>
          <Info label="BSA Tracking ID">{bsaTrackingId}</Info>
        </s.Row>
        <s.Row>
          <Info label="Created Date">
            <DateTime date={createdAt} withTime />
          </Info>
          <Info label="Last Modified Date">
            <DateTime date={updatedAt} withTime />
          </Info>
        </s.Row>
        <s.Row>
          <History
            batchId={id}
            isOpen={isHistoryOpen}
            onClose={() => setHistoryOpen(false)}
            items={history}
            total={historyTotalCount}
            page={page}
            pageSize={pageSize}
            onChange={onHistoryPageChange}
          />
          <s.HistoryButton face="positive" icon={IconName.HISTORY} onClick={() => setHistoryOpen(true)}>
            Show History
          </s.HistoryButton>
        </s.Row>
      </InfoSet>
    </PageSection>
  )
}
