import { ComparisonInfo } from './ComparisonInfo'
import { DepositsInfo } from './DepositsInfo'
import { SalesInfo } from './SalesInfo'

export type BankAccount = {
  bankAccountFormattedNumber: string
  bankAccountId: number
  bankAccountNumber: string
  comparison: ComparisonInfo
  deposits: DepositsInfo
  enableOtherActivity: boolean
  enableSalesRetail: boolean
  enableSalesWholesale: boolean
  salesRetail: SalesInfo
  salesWholesale: SalesInfo
  warnings: string[]
  enableDepositsExport: boolean
}
