import React, { FC, memo, useCallback, useMemo } from 'react'

import { emptyMetadata, useContactMetadataItem, useSaveContactMetadata } from '../../hooks'
import { ContactMetadata } from '../../types/contact'
import { ContactMetadataManageProps } from './ContactMetadataManageProps'
import { ContactMetadataManageDialog } from './components'

export const ContactMetadataManage: FC<ContactMetadataManageProps> = memo((props) => {
  const { companyId, onClose, contactId, contactName, isContactCreateFromRelationships = false, onSuccess } = props
  const { data, isFetching } = useContactMetadataItem({ companyId, contactId, isContactCreateFromRelationships })
  const { saveMetadata, isSuccess } = useSaveContactMetadata(onSuccess)

  const formInitialValue: ContactMetadata | undefined = useMemo(
    () =>
      data?.form
        ? {
            ...emptyMetadata,
            ...data.form,
            permissions: [data.form.isDocuments ? 'documents' : '', data.form.isFinancials ? 'financials' : ''].filter(
              Boolean
            )
          }
        : undefined,
    [data]
  )

  const onSubmit = useCallback(
    (metadata: ContactMetadata, isCreating: boolean) => {
      const data = { ...metadata } as ContactMetadata & { isCreating: boolean }
      data.isDocuments = metadata.permissions.indexOf('documents') !== -1
      data.isFinancials = metadata.permissions.indexOf('financials') !== -1
      data.isCreating = isCreating || isContactCreateFromRelationships

      if (data.debtMaturityDate?.length === 0) {
        data.debtMaturityDate = null
      }

      delete data.permissions

      return saveMetadata(data)
    },
    [isContactCreateFromRelationships, saveMetadata]
  )

  if (!data) {
    return null
  }

  return (
    <ContactMetadataManageDialog
      onClose={onClose}
      onSubmit={onSubmit}
      isOpen={true}
      metadata={formInitialValue}
      companyId={companyId}
      contactId={contactId}
      contactName={contactName ?? data.contactName}
      isSuccess={isSuccess}
      isFetching={isFetching}
    />
  )
})
