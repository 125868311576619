import React, { memo, useCallback } from 'react'

import { SalesRetail } from 'commons/types/DTO/bank/quarterlyReport/bankAccount/SalesRetail'
import { formatCurrencyCent } from 'commons/utils'
import { isString } from 'lodash'
import { Link } from 'ui/components/Link'

import * as SmallTable from '../../../../../components/smallTable.styles'

import * as SC from './styles'

interface Props {
  item: SalesRetail
  title: string
}

export const SalesReportTable = memo(({ item, title }: Props) => {
  const { changesPriorQuarter, currentQuarter, licenses, peerGroupAverage, priorQuarter } = item

  const getPercentValue = useCallback((v: number | string) => {
    if (isString(v)) {
      v = Number(v)
    }

    if (v === -1) {
      return 'N/A'
    }

    return v >= 0 ? <SC.TV>{v.toFixed(2)}%</SC.TV> : <SC.TV $negative>({(-v).toFixed(2)})%</SC.TV>
  }, [])

  return (
    <SmallTable.WrappedLayer rounded shadowed>
      <SmallTable.Title>
        SALES REPORT - {title} (
        {licenses.map((license, i) => (
          <SC.SubTitle key={i}>
            {license.id !== 0 ? (
              <Link to="/licenses/$licenseId" params={{ licenseId: license.id }}>
                {license.licenseNumber}
              </Link>
            ) : (
              <>{license.licenseNumber}</>
            )}
            {i === licenses.length - 1 ? '' : ', '}
          </SC.SubTitle>
        ))}
        )
      </SmallTable.Title>
      <SmallTable.Table>
        <tbody>
          <tr>
            <SmallTable.Td>Current Quarter</SmallTable.Td>
            <SmallTable.Td>{formatCurrencyCent(currentQuarter.total, false)}</SmallTable.Td>
          </tr>
          <tr>
            <SmallTable.Td>Prior Quarter</SmallTable.Td>
            <SmallTable.Td>{formatCurrencyCent(priorQuarter.total, false)}</SmallTable.Td>
          </tr>
          <tr>
            <SmallTable.Td>Peer Group Average</SmallTable.Td>
            <SmallTable.Td>{formatCurrencyCent(peerGroupAverage.total, false)}</SmallTable.Td>
          </tr>
          <tr>
            <SmallTable.Td>
              <b>% Change</b>
            </SmallTable.Td>
            <SmallTable.Td> </SmallTable.Td>
          </tr>
          <tr>
            <SmallTable.Td>Prior Quarter</SmallTable.Td>
            <SmallTable.Td $alerted={changesPriorQuarter.total.alerted}>
              {getPercentValue(changesPriorQuarter.total.percent)}
            </SmallTable.Td>
          </tr>
        </tbody>
      </SmallTable.Table>
    </SmallTable.WrappedLayer>
  )
})
