import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react'

import { QuarterlyAnalytics } from 'commons/types/DTO/bank/companyDetails/quarterlyAnalytics'
import { TableDataQa } from 'commons/types/enums'
import { handlerServerError } from 'errors'
import { DataSourceItem, Layer, Table } from 'ui/components'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { useConfirmation } from 'ui/hooks'
import { QR } from '~bank-quarterly-report/hooks'

import { columns } from './columns'
import { useFilterCompanyQuarterlyAnalyticsWebPage } from './hooks/useFilterCompanyQuarterlyAnalyticsWebPage'

interface Props {
  companyId: number
  initialQuarterlyAnalytics: QuarterlyAnalytics[]
}

export const CompanyQRsTable: FC<Props> = memo(({ companyId, initialQuarterlyAnalytics }) => {
  const [quarterlyAnalytics, setQuarterlyAnalytics] = useState(initialQuarterlyAnalytics)

  useEffect(() => {
    setQuarterlyAnalytics(initialQuarterlyAnalytics)
  }, [initialQuarterlyAnalytics])

  const { routine: filterCompanyQuarterlyAnalyticsWebPage } = useFilterCompanyQuarterlyAnalyticsWebPage({
    onSuccess: async (_, result) => {
      await setQuarterlyAnalytics(result.quarterlyAnalytics)
    },
    onError: (error) => handlerServerError(error)
  })

  const [tbd, setTbd] = useState<any>(null)
  const deleteMutation = QR.useDeleteOnWebpage(() =>
    filterCompanyQuarterlyAnalyticsWebPage({ relationshipId: companyId })
  )

  const deleteQR = useCallback(() => {
    deleteMutation.mutate(tbd.id)
  }, [tbd, deleteMutation])

  const { open, Confirmation } = useConfirmation({
    message: `Are you sure want to remove this report?`,
    onConfirm: deleteQR,
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const startDeleting = useCallback(
    (item: any) => {
      setTbd(item)
      open()
    },
    [open, setTbd]
  )

  const memoizedColumns = useMemo(columns(startDeleting), [quarterlyAnalytics])

  const mappedQuarterlyAnalytics: DataSourceItem<QuarterlyAnalytics>[] = useMemo(
    () =>
      quarterlyAnalytics.map((el) => ({
        item: el
      })),
    [quarterlyAnalytics]
  )

  return (
    <PageSection face={PageSectionFace.PRIMARY} title="Quarterly Analytics" anchor="qr">
      <Confirmation />
      <Layer rounded shadowed stretch>
        <Table<QuarterlyAnalytics>
          dataQa={TableDataQa.BP_COMPANY_QUARTERLY_ANALYTICS}
          columns={memoizedColumns}
          dataSource={mappedQuarterlyAnalytics}
        />
      </Layer>
    </PageSection>
  )
})
