import styled from 'styled-components'

const FormWrapper = styled.div`
  padding: 20px 30px 30px 30px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

const LeftWrapper = styled.div`
  display: grid;
  grid-template-columns: 242px 242px;
  grid-column-gap: 20px;
`

const RightWrapper = styled.div`
  display: grid;
  grid-template-columns: 160px 160px;
  grid-column-gap: 20px;
`

export const s = { FormWrapper, Row, LeftWrapper, RightWrapper }
