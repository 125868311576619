/* eslint-disable camelcase */

export type BsaTransaction = {
  acct_avail?: number
  acct_tin?: string
  amount?: number
  cif_acct?: string
  comment?: string
  created_at?: string
  deleted_at?: string
  description?: string
  description_primary?: string
  from_cd?: string
  id_hash?: string
  organization_id?: number
  post_date?: string
  post_datetime?: string
  source?: string
  status?: string
  teller?: string
  to_cd?: string
  tran_id?: string
  type?: string
  updated_at?: string
}
