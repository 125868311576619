import React, { useCallback, useEffect, useState } from 'react'

import { notFound, useNavigate } from '@tanstack/react-router'
import { useFormData } from 'brief-form'
import { useGetMRWebPage } from 'commons/hooks/bank/mr'
import {
  MRWebPagePayload,
  ReportMonthlyWebPage,
  SalesSourceItem,
  SalesSourceLicense
} from 'commons/types/DTO/bank/reportWebPage'
import { BankSourceCreateFormValue } from 'commons/types/DTO/bank/salesDataSourceWebPage'
import { useConfirmation } from 'ui/hooks'

import { useSaveSourceAndFilesManage } from '../../hooks'

export const useBankSourceCreate = (MRId: number, licenseId: number) => {
  const navigate = useNavigate()
  const [webPageData, setWebPageData] = useState<ReportMonthlyWebPage | undefined>(undefined)
  const { config, Field } = useFormData<BankSourceCreateFormValue>({ initialValue: new BankSourceCreateFormValue() })

  const { onSubmit, isSubmitting, isFileUploading, files, addFiles, deleteFile } = useSaveSourceAndFilesManage(
    undefined,
    MRId,
    config
  )

  const originalChangeHandler = config.onChange

  const { data, isFetched } = useGetMRWebPage(new MRWebPagePayload(MRId))

  useEffect(() => {
    if (isFetched) {
      setWebPageData(data)
    }
  }, [isFetched, data])

  const { open: openSubmitConfirm, Confirmation: SubmitConfirmation } = useConfirmation({
    message: (
      <>
        Are you sure you want to proceed?
        <br />
        Manual Sales Data Override will become the primary Sales Data source.
      </>
    ),
    onConfirm: () => onSubmit(),
    confirmationButtonText: 'Confirm',
    isConfirmationNegative: false
  })

  const onCancel = useCallback(() => {
    navigate({ to: '/mr/$id', params: { id: MRId.toString() } })
  }, [navigate, MRId])

  let retailLicenses: SalesSourceLicense[] = []
  let wholeSaleLicenses: SalesSourceLicense[] = []
  let sourceItem: SalesSourceItem = null

  if (data) {
    for (const bankAccount of data.bankAccounts) {
      if (bankAccount.salesRetail !== undefined) {
        const retailSourceItem = bankAccount.salesRetail.salesSourceItems.find((el) =>
          el.licenses.find((license) => license.licenseId === licenseId)
        )
        if (retailSourceItem) {
          sourceItem = retailSourceItem
          retailLicenses = sourceItem.licenses
          break
        }
      }
      if (bankAccount.salesWholesale !== undefined) {
        const wholeSaleSourceItem = bankAccount.salesWholesale.salesSourceItems.find((el) =>
          el.licenses.find((license) => license.licenseId === licenseId)
        )
        if (wholeSaleSourceItem) {
          sourceItem = wholeSaleSourceItem
          wholeSaleLicenses = sourceItem.licenses
          break
        }
      }
    }
  }

  if (isFetched && !retailLicenses.length && !wholeSaleLicenses.length) {
    throw notFound()
  }

  useEffect(() => {
    if (sourceItem) {
      originalChangeHandler(
        new BankSourceCreateFormValue(MRId, licenseId).fillFromMRSalesSourceItem(sourceItem),
        config.errors || {}
      )
    }
  }, [sourceItem])

  return {
    webPageData,
    licenses: [...retailLicenses, ...wholeSaleLicenses],
    config,
    Field,
    onCancel,
    openSubmitConfirm,
    SubmitConfirmation,
    isSubmitting: isSubmitting || isFileUploading,
    files,
    addFiles,
    deleteFile
  }
}
