import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'
import * as z from 'zod'

const sch = z.object({
  successReturnUrl: z.string().optional(),
  cancelReturnUrl: z.string().optional(),
  returnUrl: z.string().optional()
})

export const createContactRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: `new`,
    validateSearch: (search: Record<string, string>) => sch.parse(search),
    component: lazyRouteComponent(() => import('./ContactManage'))
  })

export const editContactRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: `$contactId/edit`,
    component: lazyRouteComponent(() => import('./ContactManage'))
  })
