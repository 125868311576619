import styled from 'styled-components'

const Inner = styled.div`
  padding: 30px 35px;
`
const VisibilityBox = styled.span<{ $visible: boolean }>`
  display: ${(p) => (p.$visible ? 'inline' : 'none')};
`

export const s = { Inner, VisibilityBox }
