import { useCallback } from 'react'

import { Request } from 'commons/utils/request'
import { handlerServerError } from 'errors'
import { WriteHook, useMutationManager } from 'state-manager'
import { Toast } from 'ui/components/Toast'

const request = (opts: { companyId: number; contactId: number }) =>
  Request.delete(Request.urls.bank.contactCompanyConnection, opts)

const useDetach: WriteHook<any, { companyId: number; contactId: number }> = (opts) =>
  useMutationManager<any, { companyId: number; contactId: number }>(request, opts)

export const useDetachContactAndCompany = (onSuccess: () => void) => {
  const { routine: saveContact } = useDetach({
    onSuccess: async () => {
      onSuccess()
      Toast.successSmall('Contact and company were detached.')
    },
    onError: (error) => handlerServerError(error)
  })

  const detachContactAndCompany = useCallback(
    async (companyId: number, contactId: number) => saveContact({ companyId, contactId }),
    [saveContact]
  )

  return {
    detachContactAndCompany
  }
}
