import { useEffect } from 'react'

import { useRouterState } from '@tanstack/react-router'

import { useScrollTo } from './useScrollTo'

export const useComponentGuts = (items: any[]) => {
  const { scrollTo } = useScrollTo(items)
  const hash = useRouterState({ select: (s) => s.location.hash })

  useEffect(() => {
    if (!hash) {
      scrollTo(items[0].hash)
    } else {
      scrollTo(hash.slice(1))
    }
  }, [scrollTo])

  return {
    scrollTo
  }
}
