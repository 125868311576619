import { Request } from 'commons/utils/request/request'

export const getCompanyDetailsWebPage = (relationshipID: number) =>
  Request.post(Request.urls.bank.companyDetailsWebpage, { relationshipID })

export type FilterCompanyCustomersWebPage = {
  relationshipID?: number | undefined
  customersLimit?: number | undefined
  customersOffset?: number | undefined
  customersName?: string | undefined
}

export const getCompanyCustomersWebPage = (filter: FilterCompanyCustomersWebPage) =>
  Request.post(Request.urls.bank.companyCustomersWebpage, { ...filter })

export type FilterCompanyVendorsWebPage = {
  relationshipID?: number | undefined
  vendorsLimit?: number | undefined
  vendorsOffset?: number | undefined
  vendorsName?: string | undefined
}

export const getCompanyVendorsWebPage = (filter: FilterCompanyVendorsWebPage) =>
  Request.post(Request.urls.bank.companyVendorsWebpage, { ...filter })

export type FilterCompanyContactsWebPage = {
  relationshipID?: number | undefined
  contactsLimit?: number | undefined
  contactsOffset?: number | undefined
  contactsType?: string | undefined
}

export const getCompanyContactsWebPage = (filter: FilterCompanyContactsWebPage) =>
  Request.post(Request.urls.bank.companyContactsWebpage, { ...filter })

export type FilterCompanyHistoryWebPage = {
  relationshipID: number | undefined
  historyLimit: number | undefined
  historyOffset: number | undefined
}

export const getCompanyDetailsHistoryWebPage = (filter: FilterCompanyHistoryWebPage) =>
  Request.post(Request.urls.bank.companyHistoryDetailsWebpage, { ...filter })

export type FilterCompanyAdverseMediaWebPage = {
  relationshipID?: number | undefined
  adverseMediaLimit?: number | undefined
  adverseMediaOffset?: number | undefined
}

export const getCompanyAdverseMediaWebPage = (filter: FilterCompanyAdverseMediaWebPage) =>
  Request.post(Request.urls.bank.companyAdverseMediaWebpage, { ...filter })

export type FilterCompanyDocumentsWebPage = {
  alerts?: string
  bankAccountID?: number
  contactID?: number
  frequency?: string
  isInternal?: boolean | null
  licenseID?: number
  limit?: number
  offset?: number
  relationshipID?: number
  sortBy?: string
  sortDesc?: boolean
  subjectType?: string
}

export const getCompanyDocumentsWebPage = (filter: FilterCompanyDocumentsWebPage) =>
  Request.post(Request.urls.bank.companyDocumentsWebpage, { ...filter })

export type FilterCompanyQuarterlyAnalyticsWebPage = {
  relationshipId: number | undefined
}

export const getCompanyQuarterlyAnalyticsWebPage = (filter: FilterCompanyQuarterlyAnalyticsWebPage) =>
  Request.post(Request.urls.bank.companyQuarterlyAnalyticsWebpage, { ...filter })

export type FilterCompanyAffiliatedCompaniesWebPage = {
  relationshipID: number | undefined
  affCompaniesLimit?: number | undefined
  affCompaniesOffset?: number | undefined
}

export const getCompanyAffiliatedCompaniesWebPage = (filter: FilterCompanyAffiliatedCompaniesWebPage) =>
  Request.post(Request.urls.bank.companyAffiliatedCompaniesWebpage, { ...filter })

export type FilterCompanyLicensesWebPage = {
  relationshipID: number | undefined
  licensesLimit?: number | undefined
  licensesOffset?: number | undefined
}

export const getCompanyLicensesWebPage = (filter: FilterCompanyLicensesWebPage) =>
  Request.post(Request.urls.bank.companyLicensesWebpage, { ...filter })

export type FilterCompanyBankAccountsWebPage = {
  relationshipID: number | undefined
  bankAccountsLimit?: number | undefined
  bankAccountsOffset?: number | undefined
}

export const getCompanyBankAccountsWebPage = (filter: FilterCompanyBankAccountsWebPage) =>
  Request.post(Request.urls.bank.companyBankAccountsWebpage, { ...filter })

export type FilterCompanyAnnualReviewWebPage = {
  relationshipID: number | undefined
  annualReviewsLimit?: number | undefined
  annualReviewsOffset?: number | undefined
}

export const getCompanyAnnualReviewWebPage = (filter: FilterCompanyAnnualReviewWebPage) =>
  Request.post(Request.urls.bank.companyAnnualReviewsWebpage, { ...filter })
