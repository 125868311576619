import styled from 'styled-components'

const Inner = styled.div`
  padding: 30px 35px;
`

const ColumnWide = styled.div`
  width: 100%;
`

const ColumnThin = styled.div`
  width: 160px;
  margin-right: 30px;
  font-size: 16px;
`

const OtherInputWrapper = styled.div`
  width: 320px;
  margin-top: 15px;
`

export const VisibilityBox = styled.span<{ $visible: boolean }>`
  display: ${(p) => (p.$visible ? 'inline' : 'none')};
`

export const s = { Inner, ColumnThin, ColumnWide, OtherInputWrapper, VisibilityBox }
