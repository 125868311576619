import { ContactListWebPageRequest, ContactListWebPageResponse } from 'commons/types/contracts/api/bank/contactList'
import { Request } from 'commons/utils/request'
import { Keys, ReadHook, useStateManager } from 'state-manager'

const request = (opts: ContactListWebPageRequest) => Request.post(Request.urls.bank.contactListWebPage, opts)

const getHandler = (payload: ContactListWebPageRequest) => (): Promise<ContactListWebPageResponse> => request(payload)

export const useGetContactListWebPage: ReadHook<ContactListWebPageResponse, ContactListWebPageRequest> = (opts) => {
  const sm = useStateManager()

  const { data, ...rest } = sm.use(Keys.Contact.List(opts), getHandler(opts), {
    keepPreviousData: true
  })

  return {
    ...rest,
    data
  }
}
