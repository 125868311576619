import { useCallback, useEffect, useLayoutEffect, useState } from 'react'

import { useRouterState } from '@tanstack/react-router'
import { Logger } from 'commons/utils/logger'
import throttle from 'lodash/throttle'

interface OnScreenElement {
  // Name of element (usually an id value)
  name: string
  // Viewable part on the screen in percent
  delta: number
}

export const useSubMenuHighlighter = (elements: string[], active: boolean) => {
  const pathname = useRouterState({ select: (s) => s.location.pathname })

  if (!elements || !elements.length) {
    Logger.getInstance().warning('elements arg are empty')
    return {
      activeSubMenu: ''
    }
  }

  const [activeSubMenu, setActiveSubMenu] = useState(elements[0])
  const [scrollFixTimeout, setScrollFixTimeout] = useState(0)

  useEffect(() => {
    setActiveSubMenu(elements[0])
  }, [pathname])

  const scrollEvent = useCallback(() => {
    if (!active) {
      return
    }

    let checkErrors = false

    if (!scrollFixTimeout) {
      setScrollFixTimeout(Date.now())
    } else if (Date.now() - scrollFixTimeout > 100) {
      checkErrors = true
    }

    const elementsOnScreen: OnScreenElement[] = []

    for (let i = 0; i < elements.length; i += 1) {
      const anchor = document.getElementById(elements[i])
      if (anchor) {
        const bound = anchor.getBoundingClientRect()
        // check if element visible on screen
        if (
          (bound.top <= 0 && bound.bottom >= 0) ||
          (bound.top >= 0 && bound.bottom >= 0 && bound.bottom <= window.innerHeight) ||
          (bound.top >= 0 && bound.top < window.innerHeight)
        ) {
          let elementHeightInViewport = 0
          // find a proper way to calc a visible part of element in view
          if (bound.top < 0) {
            // little above screen
            elementHeightInViewport = bound.bottom
          } else if (bound.bottom > window.innerHeight) {
            // little bellow screen
            elementHeightInViewport = window.innerHeight - bound.top
          } else {
            // fully on screen, or fully out of
            elementHeightInViewport = bound.bottom - bound.top
          }

          const elementOverallHeight = anchor.offsetHeight
          const viewablePartInPercent = Math.round((elementHeightInViewport / elementOverallHeight) * 100)

          const onScreenElement: OnScreenElement = {
            delta: viewablePartInPercent,
            name: elements[i]
          }

          elementsOnScreen.push(onScreenElement)
        }
      } else if (checkErrors) {
        Logger.getInstance().warning("Can't locate anchor `" + elements[i] + '` on current page')
      }
    }

    if (elementsOnScreen.length) {
      // find an element, whose menu item we would select
      let bestMatch = elementsOnScreen[0]
      for (let i = 1; i < elementsOnScreen.length; i += 1) {
        if (elementsOnScreen[i].delta > bestMatch.delta) {
          bestMatch = elementsOnScreen[i]
        }
      }
      setActiveSubMenu(bestMatch.name)
    } else if (checkErrors) {
      Logger.getInstance().warning('No one elements on screen, ' + 'probably are you using not section anchors?')
    }
  }, [JSON.stringify(elements), active, scrollFixTimeout])

  const throttledScroll = useCallback(throttle(scrollEvent, 100), [scrollEvent])

  useLayoutEffect(() => {
    window.addEventListener('scroll', throttledScroll, true)
    return () => {
      window.removeEventListener('scroll', throttledScroll, true)
    }
  }, [throttledScroll])

  return {
    activeSubMenu
  }
}
