import React, { FC, memo } from 'react'

import { mergeArrayToString } from 'commons/utils/mergeArrayToString'
import { Info, InfoSet, InfoSetDirection } from 'ui/components/Info'

import { AddressInformationProps } from './AddressInformationProps'

export const AddressInformation: FC<AddressInformationProps> = memo((props) => {
  const { physicalAddress, mailingAddress } = props

  const pAddress = mergeArrayToString(', ', Object.values(physicalAddress))
  const mAddress = mergeArrayToString(', ', Object.values(mailingAddress))

  return (
    <InfoSet legend="Address Information" direction={InfoSetDirection.COLUMN}>
      <Info label="Physical Address">{pAddress}</Info>
      <Info label="Mailing Address">{mAddress}</Info>
    </InfoSet>
  )
})
