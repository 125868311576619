import React, { FC, memo } from 'react'

import { DatePicker, FieldSet } from 'ui/components'
import { EmailInput, SSNInput, TextInput, TextareaInput, USPhoneInput } from 'ui/components/InputV2'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

import { ContactDetailsProps } from './ContactDetailsProps'

export const ContactDetails: FC<ContactDetailsProps> = memo((props) => {
  const { showBio, Field } = props

  return (
    <FieldSet legend="Contact Details">
      <FormRow>
        <Col>
          <Field
            name="firstName"
            label="First Name"
            input={TextInput}
            inputProps={{
              'data-qa': 'first-name',
              maxLength: 100
            }}
          />
        </Col>
        <Col>
          <Field
            required
            name="lastName"
            label="Last Name"
            input={TextInput}
            inputProps={{
              'data-qa': 'last-name',
              maxLength: 100
            }}
            validator={(v) => (v?.toString().length ? undefined : 'Last Name required')}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <Field name="birthdate" label="Birthdate" input={DatePicker} inputProps={{}} />
        </Col>
        <Col>
          <Field
            name="einSsn"
            label="SSN/EIN"
            input={SSNInput}
            inputProps={{
              'data-qa': 'ein-ssn'
            }}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <Field
            name="email"
            label="Email"
            input={EmailInput}
            inputProps={{
              'data-qa': 'email',
              maxLength: 50
            }}
          />
        </Col>
        <Col>
          <Field
            name="mobilePhone"
            label="Mobile"
            input={USPhoneInput}
            inputProps={{
              'data-qa': 'mobile-phone',
              maxLength: 50
            }}
          />
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <Field
            name="entityName"
            label="Entity Name"
            input={TextInput}
            inputProps={{
              'data-qa': 'entity-name',
              maxLength: 500
            }}
          />
        </Col>
        <Col>
          <Field
            name="phone"
            label="Phone"
            input={USPhoneInput}
            inputProps={{
              'data-qa': 'phone',
              maxLength: 25
            }}
          />
        </Col>
      </FormRow>
      {showBio && (
        <FormRow>
          <Col2x>
            <Field name="bio" label="Bio" input={TextareaInput} inputProps={{ maxLength: 5000 }} />
          </Col2x>
        </FormRow>
      )}
    </FieldSet>
  )
})
