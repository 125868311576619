import React from 'react'

import { Ctrs } from 'commons/types/DTO/bank/alerts/ctrs'
import { formatCurrencyCent } from 'commons/utils'
import { IconButton } from 'ui/components/Buttons'
import { DateTime } from 'ui/components/DateTime'
import { IconName } from 'ui/components/Icon'
import { Link } from 'ui/components/Link'
import { MenuItemBehavior, TableActions } from 'ui/components/QueryTable'
import { TableColumn } from 'ui/components/Table'

import { NameRow } from './styles'

type Opts = {
  onShowTransactions: React.Dispatch<
    React.SetStateAction<{
      // see comment bellow about transaction type (import { CTRTransaction } from 'bank-bsa-reporting/src/types')
      transactions: any[]
      ctrId: number
      ctrName: string
    }>
  >
  onShowWarnings: (data: string, warningsType: string) => void
}

export const columns = (opts: Opts): TableColumn<Ctrs>[] => [
  {
    title: 'Date',
    align: 'left',
    key: 'date'
  },
  {
    title: 'ID',
    align: 'center',
    render: ({ id }) => <Link to={`/bsa/ctrs/${id}`}>{id}</Link>
  },
  {
    title: 'Name',
    align: 'center',
    render: (item) => {
      const warningType = item.attention ? 'attention' : item.finCENWarnings ? 'fincen' : null
      return (
        <NameRow>
          {(!!item.attention || !!item.finCENWarnings) && (
            <IconButton
              icon={IconName.ERROR_RED}
              onClick={() => opts.onShowWarnings(item.attention || item.finCENWarnings, warningType)}
            />
          )}
          <Link to={`/bsa/ctrs/${item.id}`}>{item.name}</Link>
        </NameRow>
      )
    }
  },
  {
    title: 'Cash In',
    align: 'center',
    render: ({ cashIn }) => formatCurrencyCent(cashIn)
  },
  {
    title: 'Cash Out',
    align: 'center',
    render: ({ cashOut }) => formatCurrencyCent(cashOut)
  },
  {
    title: 'Filing Type',
    align: 'center',
    key: 'filingType'
  },
  {
    title: 'Status',
    align: 'center',
    key: 'status'
  },
  {
    title: 'Last Modified Date',
    align: 'center',
    render: ({ lastModifiedDate }) => <DateTime date={lastModifiedDate} withTime />
  },
  {
    title: 'Action',
    align: 'center',
    render: (item) => (
      <TableActions
        items={[
          {
            key: 1,
            title: 'Transactions',
            behavior: MenuItemBehavior.BUTTON,
            onClick: () =>
              opts.onShowTransactions({
                ctrId: item.id,
                ctrName: item.name,
                // TODO: check transaction type, looks this not working
                transactions: item.transactions.map((transaction) => ({
                  acct_avail: transaction.acctAvail,
                  acct_tin: transaction.acctTIN,
                  amount: transaction.amount,
                  cif_acct: transaction.cifACCT,
                  comment: transaction.comment,
                  post_datetime: transaction.dateTime,
                  description: transaction.description,
                  from_cd: transaction.fromCD,
                  tran_id: transaction.id,
                  description_primary: transaction.primaryDescription,
                  status: transaction.status,
                  teller: transaction.teller,
                  to_cd: transaction.toCD,
                  type: transaction.type
                }))
              })
          },
          {
            key: 2,
            title: 'Edit',
            url: `/bsa/ctrs/${item.id}/edit?returnUrl=${encodeURIComponent('/alerts#ctr')}`,
            iconName: IconName.EDIT
          }
        ]}
      />
    )
  }
]
