import React, { FC, KeyboardEvent, memo, useCallback, useEffect, useState } from 'react'

import { ChoiceValue } from 'ui/types'

import { CheckBox } from '../CheckBox'
import { CheckBoxGroupProps } from './CheckBoxGroupProps'

import * as SC from './styles'

/* eslint-disable import/no-unused-modules */
export const CheckBoxGroup: FC<CheckBoxGroupProps> = memo((props) => {
  const {
    direction,
    data,
    value,
    onChange,
    required,

    disabled = false,
    hint,
    readOnly = false,
    error,
    'data-qa': dataQa = undefined
  } = props

  const [selectedValues, setSelectedValues] = useState<unknown[]>(value || [])

  useEffect(() => {
    setSelectedValues(value || [])
  }, [value])

  if (!data) {
    throw new Error('Data property should be provided.')
  }

  // Readonly is always disabled
  const isDisabled = readOnly ? true : disabled

  const handleChange = useCallback(
    (value: ChoiceValue) => {
      let newArr = []
      if (selectedValues.includes(value)) {
        newArr = selectedValues.filter((el) => el !== value)
      } else {
        newArr = [...selectedValues]
        newArr.push(value)
      }
      setSelectedValues(newArr)
      onChange(newArr)
    },
    [selectedValues, onChange]
  )

  return (
    <SC.CheckBoxGroupContainer $direction={direction}>
      {data.map((item, index) => (
        <div
          key={index}
          onClick={() => {
            handleChange(item.value)
          }}
          onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key == 'Enter') {
              handleChange(item.value)
            }
          }}
        >
          <CheckBox
            checkBoxLabel={item.label}
            value={selectedValues.includes(item.value)}
            disabled={isDisabled}
            error={error}
            required={required}
            data-qa={dataQa ? dataQa + ' - ' + item.label : undefined}
          />
        </div>
      ))}
      {hint && (
        <SC.Hint data-testid="hint" disabled={disabled}>
          {hint}
        </SC.Hint>
      )}
    </SC.CheckBoxGroupContainer>
  )
})
