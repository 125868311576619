import React, { useCallback, useEffect } from 'react'

import { Block, useNavigate, useRouterState } from '@tanstack/react-router'
import { LEAVE_CONFIRMATION_MESSAGE } from 'commons/constants/common'

export const useLeaveConfirmation = (when: boolean) => {
  const navigate = useNavigate()
  const appLocation = useRouterState({ select: (s) => s.location })

  const showLeaveConfirmation = useCallback(() => {
    if (when) {
      return confirm(LEAVE_CONFIRMATION_MESSAGE)
    }
    return true
  }, [when])

  useEffect(() => {
    const unloadEventListener = (event: BeforeUnloadEvent) => {
      if (window.confirm(LEAVE_CONFIRMATION_MESSAGE)) {
        window.history.go(-1)
        return null
      } else {
        event.preventDefault()
        window.history.pushState(window.history.state, '')
        navigate({ to: appLocation.href })
      }
    }

    if (when) {
      window.history.pushState(window.history.state, '')
      window.addEventListener('popstate', unloadEventListener)
    }

    return () => {
      if (when) {
        window.removeEventListener('popstate', unloadEventListener)
      }
    }
  }, [when, appLocation.href])

  const LeaveConfirmation = useCallback(
    () => (
      <Block
        condition={when}
        blocker={() => {
          const isLogout = appLocation.pathname === '/auth/login'

          if (isLogout) {
            return true
          }

          return window.confirm(LEAVE_CONFIRMATION_MESSAGE)
        }}
      />
    ),
    [when, appLocation]
  )

  return {
    LeaveConfirmation,
    showLeaveConfirmation
  }
}
