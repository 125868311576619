import React, { FC, memo } from 'react'

import { ReportMonthlyWebPage } from 'commons/types/DTO/bank/reportWebPage'
import { DownloadLink, Tooltip } from 'ui/components'
import { ButtonGroup } from 'ui/components/ButtonGroup'
import { Button } from 'ui/components/Buttons'
import { IconSize } from 'ui/components/Icon'
import { Info } from 'ui/components/Info'
import { Link } from 'ui/components/Link'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { TextMask } from 'ui/components/TextMask'

import { Warnings } from '../Warnings'
import {
  DepositsFromSalesTable,
  OtherBankingActivityTable,
  SalesReportTable,
  SalesToDepositComparisonTable
} from './components'

import * as SC from './styles'

export const BankAccountData: FC<{ webPage: ReportMonthlyWebPage }> = memo(({ webPage }) => {
  const { bankAccounts, monthlyAnalyticsId } = webPage

  return (
    <>
      {bankAccounts.map((bankAccount, index) => {
        const title = (
          <span style={{ display: 'flex', columnGap: '10px' }}>
            <span>Bank Account #{index + 1}</span>
            <TextMask
              isBankAccount
              text={bankAccount.bankAccountNumber || '---'}
              link={bankAccount.bankAccountId ? `/bank-accounts/${bankAccount.bankAccountId}` : undefined}
              iconSize={IconSize.XS}
            />
          </span>
        )
        return (
          <PageSection key={index} face={PageSectionFace.THIRD} title={title} anchor={`bank-account-${index}`}>
            {bankAccount.warnings !== undefined && <Warnings warnings={bankAccount.warnings} />}
            <SC.SalesReportTableWrapper>
              {bankAccount.enableSalesRetail && bankAccount.salesRetail && (
                <SalesReportTable
                  salesInfo={bankAccount.salesRetail}
                  monthlyAnalyticsId={monthlyAnalyticsId}
                  bankAccountId={bankAccount.bankAccountId}
                  title={'Retail Sales'}
                />
              )}
              {bankAccount.enableSalesWholesale && bankAccount.salesWholesale && (
                <SalesReportTable
                  salesInfo={bankAccount.salesWholesale}
                  monthlyAnalyticsId={monthlyAnalyticsId}
                  bankAccountId={bankAccount.bankAccountId}
                  title={'Wholesale Sales'}
                />
              )}
            </SC.SalesReportTableWrapper>
            <PageSection
              title={'Bank Account Deposits'}
              face={PageSectionFace.SECONDARY}
              actions={
                <ButtonGroup margin="small">
                  {bankAccount.enableDepositsExport && (
                    <DownloadLink
                      direct
                      parametersForm={{
                        monthlyAnalyticsId: monthlyAnalyticsId,
                        bankAccountId: bankAccount.bankAccountId
                      }}
                      handleError={() => ({})}
                      baseUrl="/bank/v1/deposits-data/monthly-core-source-download-xlsx"
                      name={'Download Deposit Transactions'}
                    >
                      Download Deposit Transactions
                    </DownloadLink>
                  )}
                  {bankAccount.deposits.enableEdit === false &&
                    bankAccount.deposits.editButtonTooltip !== undefined && (
                      <Tooltip id="edit" type="dark" content={bankAccount.deposits.editButtonTooltip}>
                        <Button face="neutral" disabled>
                          Edit
                        </Button>
                      </Tooltip>
                    )}
                  {bankAccount.deposits.enableEdit === false &&
                    bankAccount.deposits.editButtonTooltip === undefined && <></>}
                  {bankAccount.deposits.enableEdit && (
                    <Link
                      to="/mr/$id/$bankAccountId/deposits-edit"
                      params={{ id: monthlyAnalyticsId, bankAccountId: bankAccount.bankAccountId }}
                    >
                      <Button face="neutral">Edit</Button>
                    </Link>
                  )}
                </ButtonGroup>
              }
            >
              {bankAccount.deposits.depositsFromSales !== undefined && (
                <DepositsFromSalesTable depositsFromSales={bankAccount.deposits.depositsFromSales} />
              )}
              {bankAccount.enableOtherActivity && bankAccount?.deposits.otherActivity && (
                <OtherBankingActivityTable otherActivityInfo={bankAccount.deposits.otherActivity} />
              )}
              {bankAccount.deposits.notes !== undefined && <Info label="Notes">{bankAccount.deposits.notes}</Info>}
            </PageSection>
            {bankAccount.comparison !== undefined && (
              <PageSection title={'Comparison'} face={PageSectionFace.SECONDARY}>
                <SalesToDepositComparisonTable comparisonInfo={bankAccount.comparison} />
              </PageSection>
            )}
          </PageSection>
        )
      })}
    </>
  )
})
