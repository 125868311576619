import { HistoryEntity } from '../historyAudit'

export type BsaBatchDetailsOptions = {
  historyPage: number
  historySize: number
  id: number
}

export type BsaBatchDetailsWebPage = {
  filingName: string
  createdAt: string
  updatedAt: string | null
  bsaTrackingId: string
  enableDelete: boolean
  endDate: string | null
  form: {
    historyPage: number
    historySize: number
    id: number
  }
  history: HistoryEntity[]
  historyTotalCount: number
  id: number
  subjectsCount: number
  subjectsInfo: {
    id: number
    name: string
    status: string
  }[]
  showDownloadAcknowledgmentsPDF: string
  showUploadAcknowledgmentsPDF: boolean
  showDownloadBatchPDF: string
  showDownloadBatchXML: string
  showUploadBatchPDF: boolean
  startDate: string | null
  status: string
  type: 'ctr' | 'sar'
}
