import { SalesSourceItemInfo } from './SalesSourceItemInfo'
import { SalesSourceLicense } from './SalesSourceLicense'

export type SalesSourceItem = {
  enableOverride: boolean
  enableSendTask: boolean
  licenses: SalesSourceLicense[]
  overrideButtonTooltip: string
  sources: SalesSourceItemInfo[]

  showOverrideTotalCannabisSales: number
  showOverrideTotalNonCannabisSales: number
  showOverrideTotalSalesTaxCollected: number
}
