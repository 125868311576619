import { Request } from 'commons/utils/request'
import { ReadHook, useStateManager } from 'state-manager'

import { ContactForm, ContactResponse } from '../types/contact'

export const emptyContact: ContactForm = {
  bio: '',
  birthdate: null,
  city: '',
  country: '',
  einSsn: '',
  email: '',
  entityName: '',
  firstName: '',
  id: 0,
  lastName: '',
  mobilePhone: '',
  phone: '',
  state: '',
  street: '',
  zipCode: ''
}

const request = (id: number) =>
  id
    ? Request.post(Request.urls.bank.contactEditWebPage, { id })
    : Promise.resolve({
        form: {
          bio: '',
          birthdate: null,
          city: '',
          country: '',
          einSsn: '',
          email: '',
          entityName: '',
          firstName: '',
          id: 0,
          lastName: '',
          mobilePhone: '',
          phone: '',
          state: '',
          street: '',
          zipCode: ''
        }
      })

export const useContactItem: ReadHook<ContactResponse, number> = (id) =>
  useStateManager().use(['contact-edit', id], () => request(id), {
    keepPreviousData: false
  })
