import React from 'react'

import { LICENSES_PATH } from 'commons/constants/routes'
import { License } from 'commons/types/DTO/bank/companyDetails/license'
import { TableColumn } from 'ui/components'
import { IconName } from 'ui/components/Icon'
import { LicenseSubTypeLabel } from 'ui/components/Labels'
import { Link } from 'ui/components/Link'
import {
  MenuIconHoverFace,
  MenuItemBehavior,
  MenuItemTextFace,
  TableActions,
  TableActionsItem
} from 'ui/components/QueryTable'
import { DotLabelBadge } from 'ui/temp/DotLabelBadge'

import { MappedLicense } from './MappedLicense'

export const columns = (
  onDelete: (item: MappedLicense) => void,
  isAPIConnectionColumnEnabled: boolean,
  navigateToConnections: () => void
) =>
  [
    {
      align: 'left',
      title: 'Name',
      render: ({ id, name }) => <Link to={`${LICENSES_PATH}/${id}`}>{name}</Link>
    },
    {
      align: 'left',
      title: 'Subtype',
      render: ({ subtype }) => <LicenseSubTypeLabel name={subtype} />
    },
    {
      align: 'left',
      title: 'Number',
      key: 'number'
    },
    isAPIConnectionColumnEnabled
      ? {
          align: 'left',
          title: 'API Connection',
          render: (item: License) => (
            <>
              {item?.APIConnection && (
                <DotLabelBadge dotActive={!item?.addConnectionActionEnabled} label={item?.APIConnection} />
              )}
            </>
          )
        }
      : null,
    {
      align: 'left',
      title: 'City',
      key: 'city'
    },
    {
      align: 'left',
      title: 'State',
      key: 'state'
    },
    {
      align: 'left',
      title: 'Phone',
      key: 'phone'
    },
    {
      title: 'Actions',
      align: 'left',
      width: '50px',
      render: (item: License) => {
        const items = [
          item?.addConnectionActionEnabled
            ? {
                key: 1,
                title: 'Add API Connection',
                textFace: MenuItemTextFace.BLACK,
                behavior: MenuItemBehavior.BUTTON,
                onClick: () => navigateToConnections()
              }
            : null,
          {
            key: 2,
            title: 'Edit',
            iconName: IconName.EDIT,
            iconHoverFace: MenuIconHoverFace.PRIMARY,
            textFace: MenuItemTextFace.GREY,
            url: `${LICENSES_PATH}/${item.id}/edit`
          },
          {
            key: 3,
            title: 'Delete',
            behavior: MenuItemBehavior.BUTTON,
            iconName: IconName.DELETE,
            iconHoverFace: MenuIconHoverFace.DANGER,
            textFace: MenuItemTextFace.GREY,
            onClick: () => onDelete(item)
          }
        ].filter(Boolean) as TableActionsItem[]
        return <TableActions items={items} dataQaAttribute={`Licenses-menu-dots-button-${item.id}`} />
      }
    }
  ].filter(Boolean) as TableColumn<License>[]
