import { Request } from 'commons/utils/request/request'

export type UploadReportFileRequest = {
  file: File
  reportMonthlyId?: number
  reportQuarterlyId?: number
}

export const uploadReportFile = ({ file, reportMonthlyId, reportQuarterlyId }: UploadReportFileRequest) => {
  const form = new FormData()
  form.append('file', file)
  reportMonthlyId && form.append('reportMonthlyId', reportMonthlyId.toString())
  reportQuarterlyId && form.append('reportQuarterlyId', reportQuarterlyId.toString())
  return Request.post(Request.urls.bank.reportFileUpload, form)
}
