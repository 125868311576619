import styled from 'styled-components'
import { Button } from 'ui/components/Buttons'

export const Inner = styled.div`
  width: 500px;
  padding: 27px 42px 27px 40px;
`

export const SaveButton = styled(Button)`
  margin-top: 27px;
`
