/* eslint-disable camelcase */
import { BsaSettingFilesParsingCIFConfigColumnIndexes } from './bsaSettingFilesParsingCIFConfigColumnIndexes'

export type BsaSettingFilesParsingCIFConfig = {
  column_indexes?: BsaSettingFilesParsingCIFConfigColumnIndexes
  common_carriers_tins?: string[]
  // eslint-disable-next-line
  full_alternate_names?: any
  // eslint-disable-next-line
  full_company_names?: any
  // eslint-disable-next-line
  full_occupations?: any
}
