import React from 'react'

import { Validator } from '@tanstack/form-core'
import { DeepKeys, DeepValue } from '@tanstack/react-form'
import { Label } from 'ui/temp/Label'

import { FFProps } from './FF.types'

import { s } from './styles'

export const FF = <
  TParentData,
  TName extends DeepKeys<TParentData>,
  TFieldValidator extends Validator<DeepValue<TParentData, TName>, unknown> | undefined = undefined,
  TFormValidator extends Validator<TParentData, unknown> | undefined = undefined,
  TData extends DeepValue<TParentData, TName> = DeepValue<TParentData, TName>
>(
  props: FFProps<TParentData, TName, TFieldValidator, TFormValidator, TData>
) => {
  const { label, error, errorPosition = 'bottom', required, field, input } = props
  const formErrorForThisField = field.form.state.errorMap[field.name as string]
  const finalError = error || (field.state.meta.errors || []).join(', ') || formErrorForThisField

  const onChange = (value: TData) => {
    const meta = field.form.getFieldMeta(field.name)
    field.form.setFieldMeta(field.name, { ...meta, errors: [], errorMap: {} })

    field.handleChange(value)
  }

  return (
    <s.Container data-qa={`form_${field.name}`}>
      {!!label && <Label $error={!!error}>{label}</Label>}
      <div style={{ display: errorPosition === 'right' ? 'flex' : 'block', alignItems: 'baseline' }}>
        {input({ required, error: finalError || '', value: field.state.value, onChange })}
        {!!finalError && (
          <s.Error data-field-with-error={field.name} $error $position={errorPosition}>
            {finalError}
          </s.Error>
        )}
      </div>
    </s.Container>
  )
}
