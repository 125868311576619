import React, { useMemo } from 'react'

import { BatchView } from 'bank-bsa-reporting/src/components/BatchView'
import { BsaBatches } from 'commons/types/DTO/bank/alerts/bsaBatches'
import { TableDataQa } from 'commons/types/enums'
import { Layer } from 'ui/components/Layer'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { DataSourceItem, Table } from 'ui/components/Table'

import { BSATableProps } from './BSATableProps'
import { columns } from './columns'
import { useComponentsEssence } from './hooks/useComponentsEssence'

export const BSATable = ({ BSA, BSATotalCount, onAlertFilterChange }: BSATableProps) => {
  const { modalItem, onClose, changePagination, loading, tableState, onSetModalItem } =
    useComponentsEssence(onAlertFilterChange)

  const memoizedColumns = useMemo(() => columns({ onShowBatch: onSetModalItem }), [onSetModalItem])

  const mappedBSA: DataSourceItem<BsaBatches>[] = useMemo(
    () =>
      BSA.map((el) => ({
        item: el
      })),
    [BSA]
  )

  return (
    <PageSection face={PageSectionFace.PRIMARY} title="BSA Batches to finalize" anchor="batch">
      <BatchView item={modalItem} onClose={onClose} />
      <Layer rounded shadowed stretch>
        <Table<BsaBatches>
          dataQa={TableDataQa.BP_ALERTS_BSA_BATCHES}
          columns={memoizedColumns}
          dataSource={mappedBSA}
          loading={loading}
          pagination={{
            page: tableState.page,
            pageSize: tableState.size,
            onChange: changePagination,
            total: BSATotalCount
          }}
        />
      </Layer>
    </PageSection>
  )
}
