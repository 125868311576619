import { ClientUserPermissions } from 'commons/types/enums'

import { RoleFilling } from './RoleFilling'
import { permissionsTree } from './permissionsTree'

export class ClientUserPermissionsHelper {
  /**
   * Get level of permissions role filling.
   * @param name {String} - permission role
   * @param permissions {ClientUserPermissions[]} - user permissions
   * @return {RoleFilling} - returns whether user has fully filled permission role, or partially filled
   * or user doesn't have given permission role.
   */
  private static getRoleFilling(name: string, permissions: ClientUserPermissions[]): RoleFilling {
    const node = permissionsTree[name]

    if (!node) {
      throw new Error(`There is no group named "${name}" in permissions set.`)
    }

    const filtered = permissions.filter((i) => i.indexOf(`${name}:`) !== -1)

    if (filtered.length === 0) {
      return 'none'
    }
    if (filtered.length === node.items.length) {
      return 'full'
    }
    return 'partial'
  }

  /**
   * Detect, whether user has all possible permissions (based on frontend defined permissions).
   * @param permissions {ClientUserPermissions[]} - user permissions.
   * @return {boolean} - whether user has all possible permissions.
   */
  public static isHaveFullPermissions(permissions: ClientUserPermissions[]): boolean {
    for (const permission of Object.values(ClientUserPermissions)) {
      if (!this.hasPermission(permission, permissions)) {
        return false
      }
    }
    return true
  }

  /**
   * Get array of all possible user permissions.
   * @return {ClientUserPermissions[]} - full permissions list.
   */
  public static getFullPermissionsList(): ClientUserPermissions[] {
    return Object.values(ClientUserPermissions)
  }

  /**
   * Get friendly label for given permissions list.
   * @param permissions {ClientUserPermissions[]} - permissions list.
   * @return {string} - concatenated available permissions.
   */
  public static getAccessibleRolesLabel(permissions: ClientUserPermissions[]) {
    if (!permissions?.length) {
      return 'No permissions assigned'
    }

    if (this.isHaveFullPermissions(permissions)) {
      return 'Full access'
    }

    const filtered = Object.keys(permissionsTree).filter((key) => this.getRoleFilling(key, permissions) !== 'none')

    if (!filtered.length) {
      return 'No permissions assigned'
    }

    const result = filtered
      .map((key) => permissionsTree[key].label)
      .slice(0, 3)
      .join(', ')

    return filtered.length > 3 ? `${result}...` : result
  }

  /**
   * Check if given permission is enabled.
   * @param permission {ClientUserPermissions} - permission to be checked.
   * @param permissions {ClientUserPermissions[]} - permissions list.
   * @return {boolean} - is given permission enabled.
   */
  public static hasPermission(permission: ClientUserPermissions, permissions: (ClientUserPermissions | string)[]) {
    return !!permissions && permissions.indexOf(permission) !== -1
  }

  /**
   * Check if given permission group is enabled.
   * @param group {string} - group to be checked.
   * @param permissions {ClientUserPermissions[]} - permissions list.
   * @return {boolean} - is given permission group enabled.
   */
  public static hasPermissionGroup(group: string, permissions: (ClientUserPermissions | string)[]) {
    return !!permissions && permissions.some((i) => i.indexOf(group) !== -1)
  }
}
