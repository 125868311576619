import React, { FC, useCallback, useMemo, useState } from 'react'

import { Applications } from 'commons/types/DTO/bank/applications/list/Applications'
import { TableDataQa } from 'commons/types/enums'
import { handlerServerError } from 'errors'
import { Keys, useStateManager } from 'state-manager'
import { Layer } from 'ui/components/Layer'
import { DataSourceItem, Table } from 'ui/components/Table'
import { Toast } from 'ui/components/Toast'
import { useConfirmation } from 'ui/hooks'

import { ApplicationForDelete } from './ApplicationForDelete'
import { ApplicationsListTableProps } from './ApplicationsListTableProps'
import { columns } from './columns'
import { Filter, GetLinkModal } from './components'
import { useDeleteApplication } from './hooks/useDeleteApplication'

export const ApplicationsListTable: FC<ApplicationsListTableProps> = (props) => {
  const {
    applications,
    tableState,
    pagination,
    filters,
    loading,
    showDeleteBtn,
    filterForm,
    onSetWebPageSorting,
    onSetWebPagePagination,
    onClean
  } = props
  const { deleteApplication } = useDeleteApplication()
  const sm = useStateManager()

  const mappedApplicationsList: DataSourceItem<Applications>[] = useMemo(
    () =>
      applications.map((el) => ({
        item: el
      })),
    [applications]
  )

  const [openGetLinkModal, setOpenGetLinkModal] = useState(false)
  const [applicationForDelete, setApplicationForDelete] = useState<ApplicationForDelete | null>(null)
  const [applicationId, setApplicationId] = useState<number | null>(null)

  const { Confirmation: DeleteConfirmation, open: openDeleteConfirmation } = useConfirmation({
    message: <span>{applicationForDelete?.companyName || 'Are you sure want to delete this application?'}</span>,
    onConfirm: () => onDelete(),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const onDelete = useCallback(() => {
    if (applicationForDelete?.id) {
      deleteApplication(applicationForDelete.id).then(
        async () => {
          Toast.success('Application was deleted')
          await sm.invalidate(Keys.BankApplication.List)
        },
        (error) => handlerServerError(error)
      )
    }
  }, [applicationForDelete?.id, deleteApplication, sm])

  const onDeleteApplication = (companyName: string, id: number) => {
    setApplicationForDelete({
      companyName,
      id
    })
    openDeleteConfirmation()
  }

  const showGetLinkModal = (applicationId: number) => {
    setOpenGetLinkModal(true)
    setApplicationId(applicationId)
  }

  return (
    <Layer rounded shadowed stretch>
      <DeleteConfirmation />
      {openGetLinkModal && <GetLinkModal onClose={() => setOpenGetLinkModal(false)} applicationId={applicationId} />}
      <Filter
        form={filterForm}
        expanded={tableState.expanded}
        onToggle={tableState.onFilterToggle}
        statuses={filters.statuses}
        creatorList={filters.creatorList}
        ownerList={filters.ownerList}
        onClean={onClean}
      />
      <Table<Applications>
        dataQa={TableDataQa.BP_APPLICATION_LIST}
        columns={columns(onDeleteApplication, showGetLinkModal, showDeleteBtn)()}
        dataSource={mappedApplicationsList}
        loading={loading}
        sorting={{
          order: tableState.order,
          onChange: (value) => onSetWebPageSorting(value.direction)
        }}
        pagination={{
          total: pagination.totalApplicationsCount,
          page: tableState.page,
          pageSize: tableState.size,
          onChange: (page: number, size: number) => onSetWebPagePagination(page, size)
        }}
      />
    </Layer>
  )
}
