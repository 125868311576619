import React, { FC, useMemo } from 'react'

import { MonthlyAnalytics } from 'commons/types/DTO/bank/alerts/monthlyAnalytics'
import { TableDataQa } from 'commons/types/enums'
import { Layer } from 'ui/components/Layer'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { DataSourceItem, Table } from 'ui/components/Table'

import { MonthlyAnalyticsTableProps } from './MonthlyAnalyticsTableProps'
import { columns } from './columns'
import { Filter } from './components'
import { useComponentsEssence } from './hooks'

export const MonthlyAnalyticsTable: FC<MonthlyAnalyticsTableProps> = ({ webPage, onAlertFilterChange }) => {
  const { changePagination, loading, onClear, tableState } = useComponentsEssence(onAlertFilterChange, webPage)
  const memoizedColumns = useMemo(columns, [])

  const mappedQA: DataSourceItem<MonthlyAnalytics>[] = useMemo(
    () =>
      webPage.monthlyAnalytics.map((el) => ({
        item: el
      })),
    [webPage.monthlyAnalytics]
  )

  return (
    <PageSection face={PageSectionFace.PRIMARY} title="Monthly Analytics" anchor="mr">
      <Layer rounded shadowed stretch>
        <Filter
          isExpanded={tableState.expanded}
          onToggle={tableState.onFilterToggle}
          onClear={onClear}
          form={tableState.filterForm}
          webPage={webPage}
        />
        <Table<MonthlyAnalytics>
          dataQa={TableDataQa.BP_ALERTS_MONTHLY_ANALYTICS}
          columns={memoizedColumns}
          dataSource={mappedQA}
          loading={loading}
          pagination={{
            page: tableState.page,
            pageSize: tableState.size,
            onChange: changePagination,
            total: webPage.monthlyAnalyticsTotalCount
          }}
        />
      </Layer>
    </PageSection>
  )
}
