import { useMutation } from 'react-query'

import { BSACTRStatus } from 'commons/types/enums'
import { Toast } from 'ui/components/Toast'
import { CTREditRequest } from '~bank-bsa-reporting/types'

import * as Service from '../../service'

type ValidateError = {
  message: string
  meta?: {
    string: []
  }
  httpCode?: number
}

export const useValidate = (callbackOnError?: (params: { string: [] }) => void) =>
  useMutation((item: CTREditRequest) => Service.CTR.validate(item), {
    onError: ({ message, meta, httpCode }: ValidateError, vars) => {
      let errorMessage = message
      if (vars.generalInfo.status === BSACTRStatus.APPROVED && httpCode === 400) {
        errorMessage = 'The CTR contains errors and can’t be approved.'
      }
      Toast.errorSmall(errorMessage)
      callbackOnError && meta && callbackOnError(meta)
    }
  })
