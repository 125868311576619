/* eslint-disable camelcase */

export type BatchInfo = {
  acknowledgments_key?: string
  batch_id?: number
  ctrx_pdf_key?: string
  filing_name?: string
  seq_num?: number
  status?: string
  tracking_id?: string
  updated_at?: string
  xml_key?: string
}
