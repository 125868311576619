import React, { FC, memo, useCallback, useEffect, useState } from 'react'

import { useFormData } from 'brief-form'
import { Dialog, DialogFace, DialogFooterActions } from 'ui/components'
import { LeaveConfirmation } from 'ui/components/LeaveConfirmation'
import { Spinner, SpinnerSize } from 'ui/components/Spinner'

import { emptyMetadata } from '../../../../hooks'
import { ContactMetadata } from '../../../../types/contact'
import { ContactMetadataManageDialogProps } from './ContactMetadataManageDialogProps'
import { ContactMetadataManageForm } from './components'

export const ContactMetadataManageDialog: FC<ContactMetadataManageDialogProps> = memo((props) => {
  const { isOpen, onClose, onSubmit, metadata, companyId, contactId, contactName, isSuccess, isFetching } = props
  const [isCreating, setIsCreating] = useState(false)
  const initialValue = metadata ? metadata : { ...emptyMetadata, permissions: [] }

  useEffect(() => {
    if (!companyId) {
      setIsCreating(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { config, Field, validate, isDirty, set } = useFormData<ContactMetadata>({
    initialValue,
    alwaysSyncWithInitialValueAndErrors: true
  })

  const [isSubmitting, setIsSubmitting] = useState(false)

  const close = useCallback(() => {
    set({ value: metadata })
    onClose()
  }, [metadata, onClose, set])

  const submitWrapper = useCallback(async () => {
    if (validate({ updateFields: true }).valid) {
      setIsSubmitting(true)
      onSubmit(config.value, isCreating)
    }
  }, [validate, onSubmit, config.value, isCreating, onClose])

  if (!isOpen) {
    return null
  }

  return (
    <LeaveConfirmation preventLeaving={!isSuccess && !isSubmitting && isDirty} ignoreUrlHashChanging={false}>
      <Dialog
        id="contact-metadata"
        onClose={close}
        face={DialogFace.PRIMARY}
        title="Edit company related Contact Info"
        onSubmit={submitWrapper}
        submitDisabled={isSubmitting}
        footerActions={[DialogFooterActions.SAVE, DialogFooterActions.CANCEL]}
        isDialogAsRoute={true}
      >
        {metadata && !isFetching ? (
          <ContactMetadataManageForm
            config={config}
            Field={Field}
            companyId={companyId}
            contactId={contactId}
            contactName={contactName}
          />
        ) : (
          <Spinner centered size={SpinnerSize.M} />
        )}
      </Dialog>
    </LeaveConfirmation>
  )
})
