import React from 'react'

import { LeaveConfirmation, Tooltip } from 'ui/components'
import { Page, PageFace } from 'ui/components/Page'

import { useGetSAR } from '../../../hooks/sar'
import { Footer, Form } from './components'
import { ManageSARContext } from './context'
import { useDelete, useRouting, useSARForm } from './hooks'

import { s } from './styles'

const title = `OMB No. 1506-0065 (Report); OMB No. 1506-0001, 1506-0006, 1506-0015, 1506-0019, 1506-0029, and
1506-0061 (Regulations) Version Number: 1.2`

const ManageSARPage = () => {
  const { id, comeBackToDetailsOrReturnUrl } = useRouting()
  const { data: sar, isFetching } = useGetSAR(id)
  const { formApi, isFormDirty, isFormValidating, setNotDirty, isSuccess } = useSARForm(sar)
  const { Subscribe } = formApi
  const { startDeletion, DeleteConfirmation } = useDelete(id, setNotDirty)

  const handleSubmit = () => {
    setTimeout(() => {
      const element = document.querySelector(`[data-field-with-error]`)
      element?.scrollIntoView({ block: 'center', behavior: 'smooth' })
    }, 0)

    formApi.handleSubmit()
  }

  return (
    <LeaveConfirmation preventLeaving={isFormDirty && !isSuccess}>
      <ManageSARContext.Provider value={{ formApi }}>
        <DeleteConfirmation />
        <Page
          face={PageFace.SECONDARY}
          title={
            <Tooltip id="sar_title" type="dark" event="hover" content={title}>
              <s.TooltipBody>{title}</s.TooltipBody>
            </Tooltip>
          }
          subTitle="SAR"
          isPending={isFetching}
          footer={
            <Subscribe
              children={({ isSubmitting, isDirty, isValid }) => (
                <Footer
                  onSubmit={handleSubmit}
                  isSarEditForm={id > 0}
                  onDelete={startDeletion}
                  disabled={!isDirty && isValid}
                  onCancel={comeBackToDetailsOrReturnUrl}
                  isSubmitting={isSubmitting || isFormValidating}
                />
              )}
            />
          }
        >
          {!!sar && <Form />}
        </Page>
      </ManageSARContext.Provider>
    </LeaveConfirmation>
  )
}

export default ManageSARPage
