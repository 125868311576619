import React from 'react'

import { UseNavigateResult } from '@tanstack/react-router'
import { AdverseMedia } from 'commons/types/DTO/bank/companyDetails/adverseMedia'
import SC from 'styled-components'
import { CheckMark } from 'ui/components'
import { TableButton } from 'ui/components/Buttons'
import { DateTime } from 'ui/components/DateTime'
import { Link } from 'ui/components/Link'
import { TableColumn } from 'ui/components/Table'

import { RiskLevel, RiskLevelType } from './components/RiskLevel'

type Opts = {
  rootUrl: string
}

const NameRow = SC.span`
  display: flex;
  align-items: center;
`

export const columns = (opts: Opts, navigate: UseNavigateResult<string>) => (): TableColumn<AdverseMedia>[] =>
  [
    {
      title: 'Search Term',
      align: 'left',
      render: (item) => (
        <NameRow>
          <Link to={`${opts.rootUrl}/${item.id}`}>{item.searchTerm}</Link>
        </NameRow>
      )
    },
    {
      title: 'Needs Review',
      align: 'center',
      sortable: false,
      render: ({ needsReview }) => {
        if (needsReview) {
          return <CheckMark value rounded label="" />
        }

        return null
      }
    },
    {
      title: 'Type',
      key: 'type',
      align: 'center',
      sortable: false
    },
    {
      title: 'Risk Level',
      align: 'center',
      sortable: false,
      render: ({ riskLevel }) => <RiskLevel riskLevel={riskLevel as RiskLevelType} />
    },
    {
      title: 'Last Scan Date',
      align: 'center',
      render: ({ lastScanDate }) => <DateTime date={lastScanDate} withTime />
    },
    {
      title: 'Last Modified Date',
      align: 'center',
      render: ({ lastModifiedDate }) => <DateTime date={lastModifiedDate} withTime />
    },
    {
      title: 'Action',
      align: 'center',
      sortable: false,
      render: (item) => {
        const needsReview = item.needsReview

        return (
          <TableButton
            face={needsReview ? 'danger' : 'neutral'}
            onClick={() => navigate({ to: `${opts.rootUrl}/${item.id}` })}
          >
            {needsReview ? 'VIEW & RESOLVE' : 'VIEW'}
          </TableButton>
        )
      }
    }
  ]
