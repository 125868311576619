import React from 'react'

import { CheckBoxGroup, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const Mortgage = () => {
  const { formApi } = useSARFormContext()
  return (
    <formApi.Field
      name="transactions_info.mortgage"
      children={(field) => (
        <FF
          field={field}
          label="41. Mortgage Fraud"
          input={(p) => (
            <CheckBoxGroup
              {...p}
              data-qa={'41. Mortgage Fraud'}
              direction={'vertical'}
              data={[
                { label: 'a. Application fraud', value: 'a' },
                { label: 'b. Appraisal fraud', value: 'b' },
                { label: 'c. Foreclosure/Short sale fraud', value: 'c' },
                { label: 'd. Loan Modification fraud', value: 'd' },
                { label: 'e. Origination fraud', value: 'e' },
                { label: 'z. Other', value: 'z' }
              ]}
            />
          )}
        />
      )}
    />
  )
}
