import React, { FC, memo, useMemo } from 'react'

import { useNavigate } from '@tanstack/react-router'
import { AdverseMedia } from 'commons/types/DTO/bank/alerts/adverseMedia'
import { TableDataQa } from 'commons/types/enums'
import { Layer } from 'ui/components/Layer'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { DataSourceItem, Table } from 'ui/components/Table'

import { AdverseMediaTableProps } from './AdverseMediaTableProps'
import { columns } from './columns'
import { Filter } from './components'
import { useComponentsEssence } from './hooks/useComponentsEssence'

export const AdverseMediaTable: FC<AdverseMediaTableProps> = memo(({ webPage, onAlertFilterChange }) => {
  const { loading, tableState, changePagination, onClear } = useComponentsEssence(onAlertFilterChange, webPage)
  const navigate = useNavigate()
  const memoizedColumns = useMemo(() => columns(navigate), [navigate])

  const mappedAM: DataSourceItem<AdverseMedia>[] = useMemo(
    () =>
      webPage.adverseMedia.map((el) => ({
        item: el
      })),
    [webPage.adverseMedia]
  )

  return (
    <PageSection face={PageSectionFace.PRIMARY} title="Adverse Media" anchor="adverse-media">
      <Layer rounded shadowed stretch>
        <Filter
          isExpanded={tableState.expanded}
          onToggle={tableState.onFilterToggle}
          onClear={onClear}
          form={tableState.filterForm}
          webPage={webPage}
        />
        <Table<AdverseMedia>
          dataQa={TableDataQa.BP_ALERTS_ADVERSE_MEDIA}
          columns={memoizedColumns}
          dataSource={mappedAM}
          loading={loading}
          pagination={{
            page: tableState.page,
            pageSize: tableState.size,
            onChange: changePagination,
            total: webPage.adverseMediaTotalCount
          }}
        />
      </Layer>
    </PageSection>
  )
})
