import React, { FC, memo, useCallback, useEffect, useState } from 'react'

import { Form, useFormData } from 'brief-form'
import { dictionary } from 'commons/types/dictionaries'
import { CheckBox, DatePicker, RadioGroup, RadioGroupOrientation, Select, Validators } from 'ui/components'
import { IconButton } from 'ui/components/Buttons'
import { IconName } from 'ui/components/Icon'
import { CurrencyInput, EINInput, SSNInput, SecuredInput, TextInput, WithCheckboxInput } from 'ui/components/InputV2'
import { useConfirmation } from 'ui/hooks'
import { Col, FormRow } from 'ui/themes/globalStyles'
import { CifSubject } from '~bank-bsa-reporting/hooks'
import { CTRPersonsInfo } from '~bank-bsa-reporting/types'

import { CheckBoxWrapper, Inner, Item, TinColumn, VisibilityBox } from '../../../styles'
import { PersonInvolvedInformationFormProps } from './PersonInvolvedInformationForm.types'
import { AccountNumbers } from './components'

const EMPTY_OPTION = { label: 'Not selected', value: '' }

const isIssuingStateRequiredCountry = (country: string) => {
  if (['US', 'AS', 'GU', 'MH', 'FM', 'CA', 'MP', 'PW', 'PR', 'VI', 'MX'].indexOf(country) !== -1) {
    return true
  }
  return false
}

const determineIssuingStateData = (country: string) => {
  switch (country) {
    case 'US':
      return dictionary.usStates
    case 'AS':
      return dictionary.asStates
    case 'GU':
      return dictionary.guStates
    case 'MH':
      return dictionary.mhStates
    case 'FM':
      return dictionary.fmStates
    case 'CA':
      return dictionary.caStates
    case 'MP':
      return dictionary.mpStates
    case 'PW':
      return dictionary.pwStates
    case 'PR':
      return dictionary.prStates
    case 'VI':
      return dictionary.viStates
    case 'MX':
      return dictionary.mxStates
  }
  return []
}

export const PersonInvolvedInformationForm: FC<PersonInvolvedInformationFormProps> = memo((props) => {
  const { value, onChange, onDelete, errors, index, total, validateFunctionRefs, markFormAsDirty } = props

  if (!('idFormOther' in value)) {
    value.idFormOther = ''
  }
  const { isDirty, config, validate, Field } = useFormData<CTRPersonsInfo>({
    initialValue: value,
    initialErrors: errors,
    alwaysSyncWithInitialValueAndErrors: true
  })
  const [tinChanged, setTinChanged] = useState(false)

  const { item: cifSubject } = CifSubject.useCifSubjectItem(config.value.tin || '', tinChanged)
  const [checked, setChecked] = useState(false)

  const originalChangeHandler = config.onChange
  const onCheckedChange = useCallback(
    (value: boolean) => {
      setChecked(value)
      const newValue = {
        ...config.value,
        tin: '',
        tinType: ''
      }
      if (config.errors['tinType']) {
        delete config.errors['tinType']
      }
      originalChangeHandler(newValue, config.errors)
      onChange(index, newValue, config.errors)
    },
    [config.value, config.errors, index, originalChangeHandler, onChange]
  )

  useEffect(() => {
    if (isDirty) {
      markFormAsDirty()
    }
  }, [isDirty, markFormAsDirty])

  useEffect(() => {
    validateFunctionRefs.current.personsInfo[index] = { validate, registeredFields: config.registeredFields.current }
  }, [validate, validateFunctionRefs, config.registeredFields, index])

  useEffect(() => {
    if (cifSubject && tinChanged) {
      const newValue: CTRPersonsInfo = {
        ...config.value,
        isEntity: !!cifSubject.entity_name && !cifSubject.last_name,
        lastName: cifSubject.last_name,
        entityName: cifSubject.entity_name,
        firstName: cifSubject.first_name,
        middleName: cifSubject.middle_name,
        suffix: cifSubject.suffix,
        gender: cifSubject.gender,
        alternateName: cifSubject.alternate_name,
        businessType: cifSubject.business_type,
        naicsCode: cifSubject.naics_code,
        address: cifSubject.address,
        city: cifSubject.city,
        state: cifSubject.state,
        postalCode: cifSubject.postal_code,
        country: cifSubject.country,
        tinType: cifSubject.tin_type || '',
        birthdate: cifSubject.birthdate,
        phone: cifSubject.phone,
        phoneExt: cifSubject.phone_ext,
        email: cifSubject.email,
        idForm: cifSubject.id_form,
        idFormOther: cifSubject.id_form_other,
        idNumber: cifSubject.id_number,
        idCountry: cifSubject.id_country,
        idState: cifSubject.id_state
      }
      originalChangeHandler(newValue, config.errors)
      onChange(index, newValue, config.errors)
      setTinChanged(false)
    }
  }, [cifSubject, onChange, originalChangeHandler, config.value, config.errors, index, setTinChanged, tinChanged])

  if (checked) {
    config.value.tin = ''
    config.value.tinType = ''
  }

  config.onChange = useCallback(
    (v: CTRPersonsInfo, e) => {
      if (
        v.isEntity &&
        (v.type === dictionary.ctrPersonTypesValues.CTR_PERSON_TYPE_A ||
          v.type === dictionary.ctrPersonTypesValues.CTR_PERSON_TYPE_B)
      ) {
        e['type'] = `An entity cannot has checked "a" or "b" in Item "2.".
         Uncheck the "If entity" checkbox or check either "c" or "d" items.`
      } else {
        if (e['type']?.startsWith('An entity cannot has checked')) {
          delete e['type']
        }
      }

      if (
        v.idForm &&
        v.idForm !== dictionary.ctrIdentificationTypesValues.CTR_ID_TYPE_OTHER &&
        v.idForm !== dictionary.ctrIdentificationTypesValues.CTR_ID_TYPE_UNKNOWN &&
        !v.idNumber
      ) {
        e['idNumber'] = '"Number" in Item "20. Form of identification used to verify identity" required'
      } else {
        delete e['idNumber']
      }

      if (!!v.tin && !v.tinType && !checked) {
        e['tinType'] = '"16. TIN type" is required, when "15. TIN" is filled.'
      } else {
        delete e['tinType']
      }

      if (
        ((v.tinType === 'EIN' || v.tinType === 'SSN-ITIN') && v.tin.length < 9) ||
        (v.tinType === 'Foreign' && v.tin.length === 0)
      ) {
        e['tin'] = 'Field is required'
      } else {
        delete e['tin']
      }

      if ((v.tinType === 'EIN' || v.tinType === 'SSN-ITIN') && v.tin.length > 9) {
        v.tin = v.tin.slice(0, 9)
      }

      // when user select country, then select state, then select another country,
      // we do not want to store incorrect value of state
      if (config.value.idCountry && config.value.idCountry !== v.idCountry) {
        v.idState = null
        delete e['idState']
      }
      // remove stored error if field not required
      if (v.idForm !== dictionary.ctrIdentificationTypesValues.CTR_ID_TYPE_DRIVER_LICENSE) {
        delete e['idState']
      }

      if (v.tin !== config.value.tin) {
        setTinChanged(true)
      }

      originalChangeHandler(v, e)
      onChange(index, v, e)
    },
    [onChange, originalChangeHandler, index, setTinChanged, checked, config.value.tin, config.value.idCountry]
  )

  const { open: startDeleting, Confirmation } = useConfirmation({
    message: `Are you sure you want to remove this item?`,
    onConfirm: () => {
      if (onDelete) {
        onDelete(index)
      }
    },
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const item15Input = () => {
    if (config.value.tinType === 'EIN') {
      return EINInput
    }
    if (config.value.tinType === 'SSN-ITIN') {
      return SSNInput
    }

    return SecuredInput
  }

  return (
    <Item
      rounded
      shadowed
      collapsible={false}
      title={`Edit Part I Person Involved in Transaction(s) ${+index + 1} of ${total}`}
      data-qa={`Edit Part I Person Involved in Transaction(s) ${+index + 1}`}
      actions={
        !!onDelete && (
          <IconButton face="negative" icon={IconName.DELETE} onClick={startDeleting}>
            Remove
          </IconButton>
        )
      }
    >
      <Confirmation />
      <Form config={config}>
        <Inner>
          <FormRow>
            <Col>
              <Field
                required
                name="type"
                label="2."
                input={RadioGroup}
                validator={Validators.required.field}
                inputProps={{
                  data: dictionary.ctrPersonTypes,
                  orientation: RadioGroupOrientation.VERTICAL,
                  'data-qa': '2.'
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name="multipleTransactions"
                label="1. Type of filing"
                input={CheckBox}
                inputProps={{
                  checkBoxLabel: 'Multiple transactions'
                }}
              />
            </Col>
            <Col>
              <Field
                name="isEntity"
                label="Check"
                input={CheckBox}
                inputProps={{
                  checkBoxLabel: 'If entity'
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name={config.value.isEntity ? 'entityName' : 'lastName'}
                label="4. Individual's last name or entity's legal name"
                input={WithCheckboxInput}
                inputProps={{
                  input: TextInput,
                  maxLength: 150,
                  'data-qa': `4. Individual's last name or entity's legal name`
                }}
              />
            </Col>
          </FormRow>
          {!config.value.isEntity && (
            <>
              <FormRow>
                <Col>
                  <Field
                    name="firstName"
                    label="5. First name"
                    input={WithCheckboxInput}
                    inputProps={{
                      input: TextInput,
                      maxLength: 35,
                      'data-qa': '5. First name'
                    }}
                  />
                </Col>
              </FormRow>
              <FormRow>
                <Col>
                  <Field
                    name="middleName"
                    label="6. Middle name"
                    input={TextInput}
                    inputProps={{
                      maxLength: 35
                    }}
                  />
                </Col>
              </FormRow>
              <FormRow>
                <Col>
                  <Field
                    name="suffix"
                    label="Suffix"
                    input={TextInput}
                    inputProps={{
                      maxLength: 35
                    }}
                  />
                </Col>
              </FormRow>
              <FormRow>
                <Col>
                  <Field
                    name="gender"
                    label="Gender"
                    input={Select}
                    inputProps={{
                      data: dictionary.gender,
                      emptyOption: EMPTY_OPTION
                    }}
                  />
                </Col>
              </FormRow>
            </>
          )}
          <FormRow>
            <Col>
              <Field
                name="alternateName"
                label="8. Alternate name"
                input={TextInput}
                inputProps={{
                  maxLength: 150
                }}
              />
            </Col>
            <Col>
              <Field
                name="businessType"
                label="9. Occupation or type of business"
                input={TextInput}
                inputProps={{
                  maxLength: 30
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name="naicsCode"
                label="9a. NAICS Code"
                input={TextInput}
                inputProps={{
                  maxLength: 6
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name="address"
                label="10. Address"
                input={WithCheckboxInput}
                inputProps={{
                  input: TextInput,
                  maxLength: 100
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name="city"
                label="11. City"
                input={WithCheckboxInput}
                inputProps={{
                  input: TextInput,
                  maxLength: 50
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name="state"
                label="12. State (abbreviation)"
                input={WithCheckboxInput}
                inputProps={{
                  input: TextInput,
                  maxLength: 2,
                  toUpper: true
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name="postalCode"
                label="13. ZIP/Postal Code"
                input={WithCheckboxInput}
                inputProps={{
                  input: TextInput,
                  maxLength: 9
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name="country"
                label="14. Country (abbreviation)"
                input={WithCheckboxInput}
                inputProps={{
                  input: TextInput,
                  maxLength: 2,
                  toUpper: true
                }}
              />
            </Col>
          </FormRow>

          <FormRow>
            <Col>
              <Field
                name="tin"
                label="15. TIN"
                required={config.value.tinType?.length > 0}
                validator={
                  config.value.tinType === 'EIN' || config.value.tinType === 'SSN-ITIN'
                    ? Validators.string.length(9)
                    : config.value.tinType?.length > 0
                    ? Validators.required.field
                    : undefined
                }
                input={item15Input()}
                inputProps={{
                  disabled: checked,
                  maxLength: 25
                }}
              />
            </Col>
            <TinColumn>
              <Field
                name="tinType"
                label="16. TIN type"
                input={Select}
                inputProps={{
                  data: [{ label: '-', value: '' }].concat(dictionary.ctrTINTypes),
                  disabled: checked
                }}
              />
              <CheckBoxWrapper value={checked} onChange={onCheckedChange} checkBoxLabel={'Unknown'} />
            </TinColumn>
          </FormRow>
          {!config.value.isEntity && (
            <FormRow>
              <Col>
                <Field name="birthdate" label="17. Date of Birth" input={DatePicker} inputProps={{}} />
              </Col>
            </FormRow>
          )}
          <FormRow>
            <Col>
              <Field
                name="phone"
                label="18. Contact phone number"
                input={TextInput}
                inputProps={{
                  maxLength: 16
                }}
              />
            </Col>
            <Col>
              <Field
                name="phoneExt"
                label="Ext."
                input={TextInput}
                inputProps={{
                  maxLength: 6
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name="email"
                label="19. E-mail address"
                input={TextInput}
                inputProps={{
                  maxLength: 517
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name="idForm"
                label="20. Form of identification used to verify identity"
                input={RadioGroup}
                inputProps={{
                  data: dictionary.ctrIdentificationTypes,
                  orientation: RadioGroupOrientation.VERTICAL
                }}
              />
            </Col>
          </FormRow>
          <VisibilityBox $visible={config.value.idForm === dictionary.ctrIdentificationTypesLabels.CTR_ID_TYPE_OTHER}>
            <FormRow>
              <Col>
                <Field
                  required={config.value.idForm === dictionary.ctrIdentificationTypesLabels.CTR_ID_TYPE_OTHER}
                  validator={(v) =>
                    !!v || config.value.idForm !== dictionary.ctrIdentificationTypesLabels.CTR_ID_TYPE_OTHER
                      ? undefined
                      : 'Specify Other in Item "20. Form of identification used to verify identity"'
                  }
                  name="idFormOther"
                  label="Other form of identification"
                  input={TextInput}
                  inputProps={{
                    maxLength: 50
                  }}
                />
              </Col>
            </FormRow>
          </VisibilityBox>
          <FormRow>
            <Col>
              <Field
                name="idNumber"
                label="Number"
                required={
                  config.value.idForm !== '' &&
                  config.value.idForm !== dictionary.ctrIdentificationTypesLabels.CTR_ID_TYPE_OTHER
                }
                input={TextInput}
                validator={() =>
                  config.value.idForm !== '' &&
                  config.value.idForm !== dictionary.ctrIdentificationTypesLabels.CTR_ID_TYPE_OTHER &&
                  Validators.required.field(config.value.idNumber)
                }
                inputProps={{
                  maxLength: 24
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                required={config.value.idForm !== ''}
                validator={(v) =>
                  (config.value.idForm === '' && v?.toString().length === 1) ||
                  ((!v || v?.toString().length !== 2) && config.value.idForm !== '')
                    ? '"Country (abbreviation)" in Item 20 should contain 2 characters abbreviation'
                    : undefined
                }
                name="idCountry"
                label="Country (abbreviation)	"
                input={Select}
                inputProps={{
                  searchable: true,
                  data: dictionary.countries
                }}
              />
            </Col>
            <VisibilityBox $visible={isIssuingStateRequiredCountry(config.value.idCountry)}>
              <Col>
                <Field
                  required={
                    config.value.idForm !== '' &&
                    config.value.idForm === dictionary.ctrIdentificationTypesLabels.CTR_ID_TYPE_DRIVER_LICENSE &&
                    isIssuingStateRequiredCountry(config.value.idCountry)
                  }
                  name="idState"
                  label="Issuing State"
                  input={Select}
                  validator={(v) =>
                    config.value.idForm !== '' &&
                    config.value.idForm === dictionary.ctrIdentificationTypesLabels.CTR_ID_TYPE_DRIVER_LICENSE &&
                    isIssuingStateRequiredCountry(config.value.idCountry) &&
                    Validators.required.fieldWithCustomMessage(
                      'Specify Issuing State in Item "20. Form of identification used to verify identity"'
                    )(v)
                  }
                  inputProps={{
                    data: determineIssuingStateData(config.value.idCountry),
                    emptyOption: EMPTY_OPTION
                  }}
                />
              </Col>
            </VisibilityBox>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name="cashIn"
                label="21. Cash in amount for individual or entity listed in Item 4"
                input={CurrencyInput}
                inputProps={{
                  allowDecimal: false
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field name="accountNumbersIn" label="Account number (s)" input={AccountNumbers} inputProps={{}} />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field
                name="cashOut"
                label="22. Cash out amount for individual or entity listed in Item 4"
                input={CurrencyInput}
                inputProps={{}}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col>
              <Field name="accountNumbersOut" label="Account number (s)" input={AccountNumbers} inputProps={{}} />
            </Col>
          </FormRow>
        </Inner>
      </Form>
    </Item>
  )
})
