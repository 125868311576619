/* eslint-disable camelcase */
import { BatchInfo } from './batchInfo'
import { BsaCtrInstitutionContactInfo } from './bsaCTRInstitutionContactInfo'
import { BsaCtrPersonInfo } from './bsaCTRPersonInfo'
import { BsaCtrTransactionInfo } from './bsaCTRTransactionInfo'
import { BsaCtrTransactionLocationInfo } from './bsaCTRTransactionLocationInfo'
import { BsaTransaction } from './bsaTransaction'

export type BsaCtr = {
  attention?: string
  batches_info?: BatchInfo[]
  created_at?: string
  custom?: boolean
  deleted_at?: string
  filed_date?: string
  filing_name?: string
  filing_type?: string
  fincen_warnings?: string
  id?: number
  institution_contact_info?: BsaCtrInstitutionContactInfo
  name_desc?: string
  notes?: string
  organization_id?: number
  persons_info?: BsaCtrPersonInfo[]
  prior_report_bsa_id?: string
  status?: string
  trans_date?: string
  transaction_info?: BsaCtrTransactionInfo
  transaction_locations_info?: BsaCtrTransactionLocationInfo[]
  transactions?: BsaTransaction[]
  updated_at?: string
}
