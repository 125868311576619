import React, { memo, useCallback } from 'react'

import { OtherActivity } from 'commons/types/DTO/bank/quarterlyReport/bankAccount/OtherActivity'
import { formatCurrencyCent } from 'commons/utils'
import { isString } from 'lodash'

import { Box, Columns, Label, Labels, TV, Table, TableWrap, Td, Th, Title, WrappedLayer } from './styles'

export const OtherBankActivityTable = memo(
  ({ currentQuarter, changesPriorQuarter, difference, priorQuarter }: OtherActivity) => {
    const PQ = useCallback((v: number | string) => {
      if (isString(v)) {
        v = Number(v)
      }
      if (v === -1) {
        return 'N/A'
      }

      return v >= 0 ? <TV>{v.toFixed(2)}%</TV> : <TV $negative>({(-v).toFixed(2)})%</TV>
    }, [])

    return (
      <WrappedLayer rounded shadowed>
        <Box>
          <Title>OTHER BANKING ACTIVITY</Title>
          <Columns>
            <Labels>
              <Label>Current Quarter</Label>
              {priorQuarter && <Label>Prior Quarter</Label>}
              {difference && <Label>Difference</Label>}
              {changesPriorQuarter && (
                <Label>
                  <b>% Change</b>
                </Label>
              )}
            </Labels>
            <TableWrap>
              <Table>
                <thead>
                  <tr>
                    <Th rowSpan={2}>Total</Th>
                    <Th rowSpan={2}>Cash Withdrawals</Th>
                    <Th rowSpan={2}>Checks Debits</Th>
                    <Th rowSpan={2}>ACH Debits</Th>
                    <Th $bottomBorder colSpan={2}>
                      Debit Cards
                    </Th>
                    <Th $bottomBorder colSpan={2}>
                      Transfer
                    </Th>
                    <Th $bottomBorder colSpan={2}>
                      Wires
                    </Th>
                    <Th $bottomBorder colSpan={2}>
                      Sweeps
                    </Th>
                    <Th rowSpan={2}>Bank Fees</Th>
                    <Th rowSpan={2}>Money Orders Purchased</Th>
                    <Th rowSpan={2}>Cashiers Checks Purchased</Th>
                    <Th $bottomBorder colSpan={2}>
                      CB&T Credit Card
                    </Th>
                  </tr>
                  <tr>
                    <Th>Credits</Th>
                    <Th>Debits</Th>
                    <Th>Credits</Th>
                    <Th>Debits</Th>
                    <Th>Incoming</Th>
                    <Th>Outgoing</Th>
                    <Th>Incoming</Th>
                    <Th>Outgoing</Th>
                    <Th>Charges</Th>
                    <Th>Payments</Th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <Td>{formatCurrencyCent(currentQuarter.total, false)}</Td>
                    <Td>{formatCurrencyCent(currentQuarter.cashWithdrawals, false)}</Td>
                    <Td>{formatCurrencyCent(currentQuarter.checksDebits, false)}</Td>
                    <Td>{formatCurrencyCent(currentQuarter.aCHDebits, false)}</Td>
                    <Td>{formatCurrencyCent(currentQuarter.debitCardsCredits, false)}</Td>
                    <Td>{formatCurrencyCent(currentQuarter.debitCardsDebits, false)}</Td>
                    <Td>{formatCurrencyCent(currentQuarter.transferCredits, false)}</Td>
                    <Td>{formatCurrencyCent(currentQuarter.transferDebits, false)}</Td>
                    <Td>{formatCurrencyCent(currentQuarter.wiresIncoming, false)}</Td>
                    <Td>{formatCurrencyCent(currentQuarter.wiresOutgoing, false)}</Td>
                    <Td>{formatCurrencyCent(currentQuarter.sweepsIncoming, false)}</Td>
                    <Td>{formatCurrencyCent(currentQuarter.wiresOutgoing, false)}</Td>
                    <Td>{formatCurrencyCent(currentQuarter.bankFees, false)}</Td>
                    <Td>{formatCurrencyCent(currentQuarter.moneyOrdersPurchased, false)}</Td>
                    <Td>{formatCurrencyCent(currentQuarter.cashiersChecksPurchased, false)}</Td>
                    <Td>{formatCurrencyCent(currentQuarter.cBTCreditCardCharges, false)}</Td>
                    <Td>{formatCurrencyCent(currentQuarter.cBTCreditCardPayments, false)}</Td>
                  </tr>
                  {priorQuarter && (
                    <tr>
                      <Td>{formatCurrencyCent(priorQuarter.total, false)}</Td>
                      <Td>{formatCurrencyCent(priorQuarter.cashWithdrawals, false)}</Td>
                      <Td>{formatCurrencyCent(priorQuarter.checksDebits, false)}</Td>
                      <Td>{formatCurrencyCent(priorQuarter.aCHDebits, false)}</Td>
                      <Td>{formatCurrencyCent(priorQuarter.debitCardsCredits, false)}</Td>
                      <Td>{formatCurrencyCent(priorQuarter.debitCardsDebits, false)}</Td>
                      <Td>{formatCurrencyCent(priorQuarter.transferCredits, false)}</Td>
                      <Td>{formatCurrencyCent(priorQuarter.transferDebits, false)}</Td>
                      <Td>{formatCurrencyCent(priorQuarter.wiresIncoming, false)}</Td>
                      <Td>{formatCurrencyCent(priorQuarter.wiresOutgoing, false)}</Td>
                      <Td>{formatCurrencyCent(priorQuarter.sweepsIncoming, false)}</Td>
                      <Td>{formatCurrencyCent(priorQuarter.wiresOutgoing, false)}</Td>
                      <Td>{formatCurrencyCent(priorQuarter.bankFees, false)}</Td>
                      <Td>{formatCurrencyCent(priorQuarter.moneyOrdersPurchased, false)}</Td>
                      <Td>{formatCurrencyCent(priorQuarter.cashiersChecksPurchased, false)}</Td>
                      <Td>{formatCurrencyCent(priorQuarter.cBTCreditCardCharges, false)}</Td>
                      <Td>{formatCurrencyCent(priorQuarter.cBTCreditCardPayments, false)}</Td>
                    </tr>
                  )}
                  {difference && (
                    <tr>
                      <Td>{formatCurrencyCent(difference.total, false)}</Td>
                      <Td>{formatCurrencyCent(difference.cashWithdrawals, false)}</Td>
                      <Td>{formatCurrencyCent(difference.checksDebits, false)}</Td>
                      <Td>{formatCurrencyCent(difference.aCHDebits, false)}</Td>
                      <Td>{formatCurrencyCent(difference.debitCardsCredits, false)}</Td>
                      <Td>{formatCurrencyCent(difference.debitCardsDebits, false)}</Td>
                      <Td>{formatCurrencyCent(difference.transferCredits, false)}</Td>
                      <Td>{formatCurrencyCent(difference.transferDebits, false)}</Td>
                      <Td>{formatCurrencyCent(difference.wiresIncoming, false)}</Td>
                      <Td>{formatCurrencyCent(difference.wiresOutgoing, false)}</Td>
                      <Td>{formatCurrencyCent(difference.sweepsIncoming, false)}</Td>
                      <Td>{formatCurrencyCent(difference.wiresOutgoing, false)}</Td>
                      <Td>{formatCurrencyCent(difference.bankFees, false)}</Td>
                      <Td>{formatCurrencyCent(difference.moneyOrdersPurchased, false)}</Td>
                      <Td>{formatCurrencyCent(difference.cashiersChecksPurchased, false)}</Td>
                      <Td>{formatCurrencyCent(difference.cBTCreditCardCharges, false)}</Td>
                      <Td>{formatCurrencyCent(difference.cBTCreditCardPayments, false)}</Td>
                    </tr>
                  )}
                  {changesPriorQuarter && (
                    <tr>
                      <Td $hl={changesPriorQuarter.total.alerted}>{PQ(changesPriorQuarter.total.percent)}</Td>
                      <Td $hl={changesPriorQuarter.cashWithdrawals.alerted}>
                        {PQ(changesPriorQuarter.cashWithdrawals.percent)}
                      </Td>
                      <Td $hl={changesPriorQuarter.checksDebits.alerted}>
                        {PQ(changesPriorQuarter.checksDebits.percent)}
                      </Td>
                      <Td $hl={changesPriorQuarter.achDebits.alerted}>{PQ(changesPriorQuarter.achDebits.percent)}</Td>
                      <Td $hl={changesPriorQuarter.debitCardsCredits.alerted}>
                        {PQ(changesPriorQuarter.debitCardsCredits.percent)}
                      </Td>
                      <Td $hl={changesPriorQuarter.debitCardsDebits.alerted}>
                        {PQ(changesPriorQuarter.debitCardsDebits.percent)}
                      </Td>
                      <Td $hl={changesPriorQuarter.transferCredits.alerted}>
                        {PQ(changesPriorQuarter.transferCredits.percent)}
                      </Td>
                      <Td $hl={changesPriorQuarter.transferDebits.alerted}>
                        {PQ(changesPriorQuarter.transferDebits.percent)}
                      </Td>
                      <Td $hl={changesPriorQuarter.wiresIncoming.alerted}>
                        {PQ(changesPriorQuarter.wiresIncoming.percent)}
                      </Td>
                      <Td $hl={changesPriorQuarter.wiresOutgoing.alerted}>
                        {PQ(changesPriorQuarter.wiresOutgoing.percent)}
                      </Td>
                      <Td $hl={changesPriorQuarter.sweepsIncoming.alerted}>
                        {PQ(changesPriorQuarter.sweepsIncoming.percent)}
                      </Td>
                      <Td $hl={changesPriorQuarter.sweepsOutgoing.alerted}>
                        {PQ(changesPriorQuarter.sweepsOutgoing.percent)}
                      </Td>
                      <Td $hl={changesPriorQuarter.bankFees.alerted}>{PQ(changesPriorQuarter.bankFees.percent)}</Td>
                      <Td $hl={changesPriorQuarter.moneyOrdersPurchased.alerted}>
                        {PQ(changesPriorQuarter.moneyOrdersPurchased.percent)}
                      </Td>
                      <Td $hl={changesPriorQuarter.cashiersChecksPurchased.alerted}>
                        {PQ(changesPriorQuarter.cashiersChecksPurchased.percent)}
                      </Td>
                      <Td $hl={changesPriorQuarter.cbtCreditCardCharges.alerted}>
                        {PQ(changesPriorQuarter.cbtCreditCardCharges.percent)}
                      </Td>
                      <Td $hl={changesPriorQuarter.cbtCreditCardPayments.alerted}>
                        {PQ(changesPriorQuarter.cbtCreditCardPayments.percent)}
                      </Td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </TableWrap>
          </Columns>
        </Box>
      </WrappedLayer>
    )
  }
)
