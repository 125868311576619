import { Request } from 'commons/utils/request/request'
import { ReadHook, useStateManager } from 'state-manager'

import { SARListForm, SARListWebPage } from './types'

const getWebPage = (form: SARListForm) => Request.post(Request.urls.bank.sarBatchListWebPage, form)

export const useGetWebPage: ReadHook<SARListWebPage, SARListForm> = (opts) =>
  useStateManager().use(['SAR Batch List', opts], () => getWebPage(opts), {
    keepPreviousData: true
  })
