import React, { CSSProperties, FC, ReactNode, useContext, useEffect, useRef } from 'react'

import { LEAVE_CONFIRMATION_MESSAGE } from 'commons/constants/common'
import { ButtonGroup } from 'ui/components/ButtonGroup'

import { useOutsideClick, useTabNavigationBound } from '../../hooks'
import { Button } from '../Buttons'
import { IconName } from '../Icon'
import { LeaveConfirmationContext } from '../LeaveConfirmation/LeaveConfirmationContext'
import { Portal } from './../Portal'
import { DialogStack } from './DialogStack'
import { DialogFace } from './dialogFace'
import { DialogFooterActions } from './dialogFooterActions'
import { DialogOverlay } from './dialogOverlay'
import { DialogUtils } from './dialogUtils'

import {
  ActionsWrapper,
  CustomCloseButtonWrapper,
  CustomHeader,
  DialogBackdrop,
  DialogContent,
  DialogWindow,
  Footer,
  Header,
  StickyFooter,
  WrappedButton
} from './styles'

interface Props {
  id: string
  children: ReactNode
  onClose?: () => void
  title?: string | ReactNode
  subTitle?: string | ReactNode
  customHeader?: ReactNode
  stickyFooter?: ReactNode
  face?: DialogFace
  headerStyle?: CSSProperties
  headerActions?: ReactNode | ReactNode[]
  overlay?: DialogOverlay
  /**
   * @deprecated Stayed for backward compatibility and should be removed soon.
   * Use LeaveConfirmation component
   */
  displayCloseConfirmation?: boolean
  fullScreen?: boolean
  footerActions?: DialogFooterActions[]
  customFooterActions?: ReactNode | ReactNode[]
  onSubmit?: () => void
  onDelete?: () => void
  /** @deprecated use isSubmitting that use loading buttons for blocking double submit */
  submitDisabled?: boolean
  isSubmitting?: boolean
  box?: string | HTMLElement
  closeOnOutSideClick?: boolean
  height?: string
  'data-qa'?: string
  isDialogAsRoute?: boolean
}

const CloseButton = ({ onClick, isFacePositive }: any) => (
  <WrappedButton face={isFacePositive ? 'positive' : 'neutral'} icon={IconName.CLEAR} onClick={onClick} />
)

export const Dialog: FC<Props> = ({
  id,
  onClose,
  title,
  subTitle,
  face = DialogFace.DEFAULT,
  displayCloseConfirmation,
  headerStyle,
  customHeader,
  stickyFooter,
  overlay = DialogOverlay.DARK,
  headerActions,
  footerActions,
  customFooterActions,
  children,
  fullScreen,
  submitDisabled,
  isSubmitting = false,
  onSubmit,
  onDelete,
  box,
  height = null,
  closeOnOutSideClick = true,
  'data-qa': dataQa = 'dialog',
  isDialogAsRoute = false
}) => {
  const dialogRef = useRef(null)
  const dialogContentRef = useRef(null)
  const { showLeaveConfirmation } = useContext(LeaveConfirmationContext)

  const stack = DialogStack.getInstance()
  useEffect(() => {
    stack.push(dialogRef.current)
    return () => {
      stack.pop()
    }
  }, [stack])

  const closeDialog = () => {
    if (showLeaveConfirmation && !isDialogAsRoute) {
      if (onClose && showLeaveConfirmation()) {
        onClose()
      }
    } else if (displayCloseConfirmation) {
      if (onClose && confirm(LEAVE_CONFIRMATION_MESSAGE)) {
        onClose()
      }
    } else if (onClose) {
      onClose()
    }
  }

  const onEscPress = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && !DialogUtils.isFileDialogOpened()) {
      e.preventDefault()
      closeDialog()
    }
  }

  if (closeOnOutSideClick) {
    useOutsideClick(dialogRef, () => {
      closeDialog()
    })
  }

  useTabNavigationBound(dialogRef)

  // TODO: maybe implement some hook like "useEscPress(action)" or "useKeyPress(key, action)"
  useEffect(() => {
    document.addEventListener('keyup', onEscPress, false)

    const scrollTop = document.documentElement.scrollTop
    const scrollLeft = document.documentElement.scrollLeft

    window.onscroll = function () {
      window.scrollTo(scrollLeft, scrollTop)
    }

    return () => {
      document.removeEventListener('keyup', onEscPress, false)
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      window.onscroll = function () {}
    }
  })

  const renderTitle = () => {
    if (title) {
      if (typeof title !== 'string') {
        return title
      }
      if (subTitle) {
        return (
          <h5>
            <strong>{title}</strong>
          </h5>
        )
      }
      return (
        <h2>
          <strong>{title}</strong>
        </h2>
      )
    }

    return null
  }

  const renderSubTitle = () => {
    if (subTitle) {
      return typeof subTitle === 'string' ? (
        <h2>
          <strong>{subTitle}</strong>
        </h2>
      ) : (
        subTitle
      )
    }

    return null
  }

  const renderHeader = () =>
    customHeader ? (
      <CustomHeader>
        {customHeader}
        <CustomCloseButtonWrapper>
          {onClose && <CloseButton onClick={closeDialog} isFacePositive />}
        </CustomCloseButtonWrapper>
      </CustomHeader>
    ) : (
      <Header face={face} style={headerStyle} $fullScreen={fullScreen}>
        <div>
          {renderTitle()}
          {renderSubTitle()}
        </div>
        <ActionsWrapper>
          {headerActions}
          {onClose && <CloseButton onClick={closeDialog} />}
        </ActionsWrapper>
      </Header>
    )

  const renderFooter = () => {
    if (stickyFooter) {
      return <StickyFooter>{stickyFooter}</StickyFooter>
    } else if (footerActions || customFooterActions) {
      return (
        <Footer>
          <ButtonGroup margin="small">
            {footerActions?.map((action: DialogFooterActions) => renderFooterButtons(action))}
            {customFooterActions}
          </ButtonGroup>
        </Footer>
      )
    }

    return null
  }

  const renderFooterButtons = (action: DialogFooterActions) => {
    switch (action) {
      case DialogFooterActions.ADD:
        return onSubmit ? (
          <Button
            key="add"
            face="positive"
            onClick={onSubmit}
            loading={isSubmitting}
            disabled={submitDisabled}
            data-qa="dialog-add"
          >
            Add
          </Button>
        ) : null
      case DialogFooterActions.SAVE:
        return onSubmit ? (
          <Button
            key="save"
            face="positive"
            onClick={onSubmit}
            loading={isSubmitting}
            disabled={submitDisabled}
            data-qa="dialog-save"
          >
            Save
          </Button>
        ) : null
      case DialogFooterActions.SEND:
        return onSubmit ? (
          <Button
            key="send"
            face="positive"
            onClick={onSubmit}
            loading={isSubmitting}
            disabled={submitDisabled}
            data-qa="dialog-send"
          >
            Send
          </Button>
        ) : null
      case DialogFooterActions.SUBMIT:
        return onSubmit ? (
          <Button
            key="submit"
            face="positive"
            onClick={onSubmit}
            loading={isSubmitting}
            disabled={submitDisabled}
            data-testid={'dialog-submit'}
            data-qa="dialog-submit"
          >
            Submit
          </Button>
        ) : null
      case DialogFooterActions.CANCEL:
        return (
          <Button key="cancel" face="neutral" onClick={closeDialog} data-qa="dialog-cancel">
            Cancel
          </Button>
        )
      case DialogFooterActions.DELETE:
        return onDelete ? (
          <Button key="delete" face="negative" loading={isSubmitting} onClick={onDelete} data-qa="dialog-delete">
            Delete
          </Button>
        ) : null
    }
  }

  const renderContent = () => (
    <DialogContent className="dialog__content" ref={dialogContentRef} data-qa={`${dataQa} content`}>
      {children}
    </DialogContent>
  )

  const renderDialog = () => (
    <DialogWindow
      ref={dialogRef}
      overlay={overlay}
      withFooter={!stickyFooter}
      aria-modal="true"
      role="dialog"
      data-qa={dataQa}
      $fullScreen={fullScreen}
      $height={height}
    >
      {renderHeader()}
      {renderContent()}
      {renderFooter()}
    </DialogWindow>
  )

  return (
    <Portal id={id} box={box}>
      <DialogBackdrop overlay={overlay}>{renderDialog()}</DialogBackdrop>
    </Portal>
  )
}
