import React, { FC, useState } from 'react'
import { useQueryClient } from 'react-query'

import { useUploadBatchFile } from 'commons/hooks/bank/bsa/useUploadBatchFile'
import SC from 'styled-components'
import { Spinner, SpinnerSize } from 'ui/components/Spinner'

import { Toast } from '../Toast'
import { UploadButton } from '../UploadButton'

const Uploading = SC.div`
  display: inline-flex;
  align-items: center;
  color: #b8b8b8;
  font-size: 14px;
  line-height: 1.5;
`

const UploadWrapper = SC.div`
  min-width: 90px;
`

const SmallSpinner = SC(Spinner)`
  width: 0.9em;
  height: 0.9em;
  border-width: 2px;
  display: inline-block;
  margin-right: 5px;
`

interface Props {
  batchId: number
  isAcknowledgments: boolean
  isCTR: boolean
}

export const UploadBatchPdfButton: FC<Props> = ({ batchId, isAcknowledgments, isCTR }) => {
  const [isUploading, setIsUploading] = useState(false)
  const queryClient = useQueryClient()
  const uploadFileMutation = useUploadBatchFile(queryClient, isCTR)

  const uploadFile = (file: File) => {
    const formData = new FormData()
    formData.append('batch_id', batchId.toString())
    formData.append('acknowledgments', isAcknowledgments.toString())
    formData.append('pdf', file)

    setIsUploading(true)
    uploadFileMutation
      .mutateAsync(formData, {
        onError: (error) => {
          Toast.errorSmall(error.message)
        }
      })
      .finally(() => setIsUploading(false))
  }

  const onChangeFileInput = (e: any) => {
    const file = e.target.files[0]
    uploadFile(file)
    e.target.value = null
  }

  const getFileInputId = () => (isAcknowledgments ? `batch-${batchId}-acknowledgment-pdf` : `batch-${batchId}-pdf`)

  return isUploading ? (
    <Uploading>
      <SmallSpinner size={SpinnerSize.S} />
      <span>uploading...</span>
    </Uploading>
  ) : (
    <UploadWrapper>
      <UploadButton id={getFileInputId()} acceptFiles=".pdf" onChange={onChangeFileInput} />
    </UploadWrapper>
  )
}
