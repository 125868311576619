import React, { memo } from 'react'

import { useRouterState } from '@tanstack/react-router'
import { BsaSar } from 'commons/types/DTO/bank/bsa'
import { bsaCTRStatuses } from 'commons/types/dictionaries'
import { Icon, IconSize } from 'ui/components'
import { Button } from 'ui/components/Buttons'
import { DateTime, formatDateTime } from 'ui/components/DateTime'
import { Info, InfoSet, InfoSetDirection } from 'ui/components/Info'
import { IconName, Link } from 'ui/components/Link'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { Col, Col2x, Row } from 'ui/themes/globalStyles'

import * as SC from './styles'

interface Props {
  item: BsaSar
  companyDetails: any
  openEditPeriodDialog: () => void
}

export const GeneralInformation = memo(({ item, companyDetails, openEditPeriodDialog }: Props) => {
  const state = useRouterState()
  const returnUrl = encodeURIComponent(state.location.pathname)
  const start = formatDateTime(item?.trans_start_date)
  const end = formatDateTime(item?.trans_end_date)

  return (
    <PageSection
      face={PageSectionFace.SECONDARY}
      title="General Information"
      actions={
        <Link to="/bsa/sars/$id/edit" params={{ id: item.id }} search={{ returnUrl }}>
          <Button face="positive">Edit</Button>
        </Link>
      }
      anchor="general"
    >
      <SC.WrappedHr />
      <InfoSet direction={InfoSetDirection.COLUMN}>
        <Row>
          <Col2x>
            <Info label="Filing Name">{item.filing_name}</Info>
          </Col2x>
        </Row>
        <Row>
          <Col2x>
            <Info label="1. Type of filing">
              {[
                item.filing_type.includes('filing_type_initial') ? 'Initial report' : null,
                item.filing_type.includes('filing_type_correct_amend') ? 'Correct/amend prior report' : null,
                item.filing_type.includes('filing_type_continuing') ? 'Continuing activity report' : null,
                item.filing_type.includes('filing_type_joint') ? 'Joint report' : null
              ]
                .filter(Boolean)
                .join(', ')}
            </Info>
          </Col2x>
        </Row>
        <Row>
          <Col>
            <Info label="Prior report BSA Identifier">{item.prior_report_bsa_id}</Info>
          </Col>
        </Row>
        <Row>
          <Col2x>
            <Info label="2. Filing Institution Note to FinCEN">{item.notes_to_fincen}</Info>
          </Col2x>
        </Row>
        <Row>
          <Col>
            <Info label="Company">
              <SC.WrappedLink to="/relationships/$companyId" params={{ companyId: companyDetails?.id }}>
                {companyDetails?.name}
              </SC.WrappedLink>
            </Info>
          </Col>
        </Row>
        <Row>
          <Col>
            <Info label="Status">{bsaCTRStatuses.find((i) => i.value === item.status)?.label}</Info>
            <Info label="Created Date">
              <DateTime date={item.created_at || null} withTime />
            </Info>
          </Col>
          <Col>
            <Info label="Period">
              <SC.PeriodEditRow>
                {`${start} - ${end}`}
                <Icon name={IconName.EDIT} size={IconSize.XS} onClick={openEditPeriodDialog} />
              </SC.PeriodEditRow>
            </Info>
            <Info label="Last Modified Date">
              <DateTime date={item.updated_at || null} withTime />
            </Info>
          </Col>
        </Row>
        <Row>
          <Info label="Notes">{item.notes}</Info>
        </Row>
      </InfoSet>
    </PageSection>
  )
})
