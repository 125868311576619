import { Request } from 'commons/utils/request'
import { handlerServerError } from 'errors'
import { WriteHook, useMutationManager } from 'state-manager'

import { ContactForm } from '../types/contact'

type UseSaveContactParams = {
  onSuccess?: (payload: ContactForm, result: ContactForm) => Promise<void>
  onError?: (error: Error) => Promise<void>
}

const saveContact = (contact: ContactForm) => {
  const fn = contact.id ? Request.put : Request.post
  return fn(Request.urls.bank.contact, contact)
}

const useSaveContactHook: WriteHook<ContactForm, ContactForm> = (opts) =>
  useMutationManager<ContactForm, ContactForm>(saveContact, opts)

export const useSaveContact = ({ onSuccess, onError }: UseSaveContactParams) => {
  const { routine: saveContact, ...rest } = useSaveContactHook({
    onSuccess: async (payloadContact, resultContact) => {
      if (onSuccess) {
        onSuccess(payloadContact, resultContact)
      }
    },
    onError: (error) => {
      if (onError) {
        onError(error)
      }
      handlerServerError(error)
    }
  })

  return {
    saveContact,
    ...rest
  }
}
