import { IconName } from './iconName'

/**
 * Add ?raw at the end of file path, coz webpack5 in storybook use that notation for loading raw svg as module
 */

const mapping = {
  [IconName.ADD]: require('./assets/add.svg?raw'),
  [IconName.ADD_GREEN]: require('./assets/add-green.svg?raw'),
  [IconName.APPLICATION]: require('./assets/application.svg?raw'),
  [IconName.APPLICATIONS]: require('./assets/applications.svg?raw'),
  [IconName.AUDIT]: require('./assets/audit.svg?raw'),
  [IconName.ARROW_NEXT]: require('./assets/arrow-next.svg?raw'),
  [IconName.ARROW_RIGHT]: require('./assets/arrow-right.svg?raw'),
  [IconName.ARROW_PREVIOUS]: require('./assets/arrow-previous.svg?raw'),
  [IconName.ARROW_SMALL]: require('./assets/arrow-small.svg?raw'),
  [IconName.ARROW_RIGHT_FILLED]: require('./assets/arrow-right-filled.svg?raw'),
  [IconName.ARROW_LEFT_FILLED]: require('./assets/arrow-left-filled.svg?raw'),
  [IconName.BADGE]: require('./assets/badge.svg?raw'),
  [IconName.CALENDAR_DOTTED]: require('./assets/calendar-dotted.svg?raw'),
  [IconName.CAN]: require('./assets/can.svg?raw'),
  [IconName.CANCEL_WHITE_ROUND]: require('./assets/cancel-white-round.svg?raw'),
  [IconName.CHECK_WHITE]: require('./assets/check-white.svg?raw'),
  [IconName.CHECK]: require('./assets/check.svg?raw'),
  [IconName.CHECK_GREY]: require('./assets/check-grey.svg?raw'),
  [IconName.CHECK_ROUND]: require('./assets/check-round.svg?raw'),
  [IconName.CLEAR]: require('./assets/clear.svg?raw'),
  [IconName.CLEAR_THIN]: require('./assets/clear-thin.svg?raw'),
  [IconName.CROSS_SMALL]: require('./assets/cross-small.svg?raw'),
  [IconName.COMMENT]: require('./assets/comment.svg?raw'),
  [IconName.DASHED_ARROW_DOWN_GREEN]: require('./assets/dahsed-arrow-down-green.svg?raw'),
  [IconName.DASHED_ARROW_DOWN_GRAY]: require('./assets/dahsed-arrow-down-gray.svg?raw'),
  [IconName.DASHED_ARROW_UP_GRAY]: require('./assets/dahsed-arrow-up-gray.svg?raw'),
  [IconName.DASHED_ARROW_UP_GREEN]: require('./assets/dahsed-arrow-up-green.svg?raw'),
  [IconName.DELETE]: require('./assets/delete.svg?raw'),
  [IconName.DIALOG]: require('./assets/dialog.svg?raw'),
  [IconName.DIALOG_FILLED]: require('./assets/dialog-filled.svg?raw'),
  [IconName.DIALOG_GREEN]: require('./assets/dialog-green.svg?raw'),
  [IconName.DELETE_RED_CROSS]: require('./assets/delete-red-cross.svg?raw'),
  [IconName.DOCUMENT]: require('./assets/document.svg?raw'),
  [IconName.DOTH_HORIZONTAL]: require('./assets/dots-horizontal.svg?raw'),
  [IconName.DOWN]: require('./assets/down.svg?raw'),
  [IconName.UP]: require('./assets/up.svg?raw'),
  [IconName.DOWNLOAD]: require('./assets/download.svg?raw'),
  [IconName.EDIT]: require('./assets/edit.svg?raw'),
  [IconName.EXPAND_LESS]: require('./assets/expand-less.svg?raw'),
  [IconName.EXPAND_MORE]: require('./assets/expand-more.svg?raw'),
  [IconName.ENVELOPE]: require('./assets/envelope.svg?raw'),
  [IconName.ERROR_RED]: require('./assets/error-red.svg?raw'),
  [IconName.ERROR]: require('./assets/error.svg?raw'),
  [IconName.EYE]: require('./assets/eye.svg?raw'),
  [IconName.EYE_CROSSED]: require('./assets/eye-crossed.svg?raw'),
  [IconName.EXCLAMATION]: require('./assets/exclamation.svg?raw'),
  [IconName.EXCLAMATION_WHITE]: require('./assets/exclamation-white.svg?raw'),
  [IconName.FINANCIALS]: require('./assets/financials.svg?raw'),
  [IconName.FOLDER]: require('./assets/folder.svg?raw'),
  [IconName.INFO]: require('./assets/info.svg?raw'),
  [IconName.INFO_BLUE]: require('./assets/info-blue.svg?raw'),
  [IconName.INFO_GREEN]: require('./assets/info-green.svg?raw'),
  [IconName.INFO_WHITE]: require('./assets/info-white.svg?raw'),
  [IconName.LOCK]: require('./assets/lock.svg?raw'),
  [IconName.LOGOUT]: require('./assets/logout.svg?raw'),
  [IconName.LINK]: require('./assets/link.svg?raw'),
  [IconName.LINK_GREEN]: require('./assets/link-green.svg?raw'),
  [IconName.MENU]: require('./assets/menu.svg?raw'),
  [IconName.MENU2]: require('./assets/menu2.svg?raw'),
  [IconName.MENU_MINIMIZED]: require('./assets/menu-minimized.svg?raw'),
  [IconName.MINUS]: require('./assets/minus.svg?raw'),
  [IconName.MINUS_FILLED]: require('./assets/minus-filled.svg?raw'),
  [IconName.PHONE]: require('./assets/phone.svg?raw'),
  [IconName.PLUS]: require('./assets/plus.svg?raw'),
  [IconName.PLUS_FILLED]: require('./assets/plus-filled.svg?raw'),
  [IconName.SETTINGS_STROKE]: require('./assets/settings-stroke.svg?raw'),
  [IconName.SEARCH]: require('./assets/search.svg?raw'),
  [IconName.HISTORY]: require('./assets/history.svg?raw'),
  [IconName.HISTORY_GRAY]: require('./assets/history-gray.svg?raw'),
  [IconName.HELP]: require('./assets/help.svg?raw'),
  [IconName.NEXT_CLOUD]: require('./assets/nextcloud.svg?raw'),
  [IconName.CALENDAR]: require('./assets/calendar.svg?raw'),
  [IconName.GROUP]: require('./assets/group.svg?raw'),
  [IconName.BELL]: require('./assets/bell.svg?raw'),
  [IconName.BELL2]: require('./assets/bell2.svg?raw'),
  [IconName.CLOCK]: require('./assets/clock.svg?raw'),
  [IconName.CLOCK_ALARM]: require('./assets/clock-alarm.svg?raw'),
  [IconName.EXCHANGE]: require('./assets/exchange.svg?raw'),
  [IconName.UPLOAD]: require('./assets/upload.svg?raw'),
  [IconName.USER]: require('./assets/user.svg?raw'),
  [IconName.USERS]: require('./assets/users.svg?raw'),
  [IconName.BSA]: require('./assets/bsa.svg?raw'),
  [IconName.ADVERSE_MEDIA]: require('./assets/adverse-media.svg?raw'),
  [IconName.SETTINGS]: require('./assets/settings.svg?raw'),
  [IconName.SHARED]: require('./assets/shared.svg?raw'),
  [IconName.REPEAT]: require('./assets/repeat.svg?raw'),
  [IconName.REMIND]: require('./assets/remind.svg?raw'),
  [IconName.LOCATION_MARK]: require('./assets/location-mark.svg?raw'),
  [IconName.LEFT_ARROW]: require('./assets/left-arrow.svg?raw'),
  [IconName.RIGHT_ARROW]: require('./assets/right-arrow.svg?raw'),
  [IconName.EXCLAMATION_ORANGE]: require('./assets/exclamation-orange.svg?raw'),
  [IconName.CHECK_GREEN]: require('./assets/check-green.svg?raw'),
  [IconName.PREV]: require('./assets/prev.svg?raw'),
  [IconName.NEXT]: require('./assets/next.svg?raw'),
  [IconName.SPINNER]: require('./assets/spinner.svg?raw'),
  [IconName.SHIELD]: require('./assets/shield.svg?raw'),
  [IconName.STAR]: require('./assets/star.svg?raw'),
  [IconName.COMPANIES]: require('./assets/companies.svg?raw'),
  [IconName.TASKS]: require('./assets/tasks.svg?raw'),
  [IconName.EXIT]: require('./assets/exit.svg?raw'),
  [IconName.ZEN_DESK]: require('./assets/zendesk.svg?raw'),
  [IconName.WATCH]: require('./assets/watch.svg?raw'),
  [IconName.WATCH_GREEN]: require('./assets/watch-green.svg?raw'),
  [IconName.UNION]: require('./assets/union.svg?raw'),
  [IconName.REPORTS]: require('./assets/reports.svg?raw'),

  [IconName.MAIN_BAR_LOGO]: require('./assets/main-bar-logo.svg?raw'),
  [IconName.FILES]: require('./assets/files.svg?raw'),

  [IconName.MAIN_MENU_ALERTS]: require('./assets/main-menu-alers.svg?raw'),
  [IconName.MAIN_MENU_RELATIONSHIPS]: require('./assets/main-menu-relationships.svg?raw'),
  [IconName.MAIN_MENU_APPLICATION]: require('./assets/main-menu-applications.svg?raw'),
  [IconName.MAIN_MENU_BSA]: require('./assets/main-menu-bsa.svg?raw'),
  [IconName.MAIN_MENU_CONTACTS]: require('./assets/main-menu-contacts.svg?raw'),
  [IconName.MAIN_MENU_DOCUMENTS]: require('./assets/main-menu-documents.svg?raw'),
  [IconName.MAIN_MENU_CONNECTIONS]: require('./assets/main-menu-connections.svg?raw'),
  [IconName.MAIN_MENU_CLIENT_USERS]: require('./assets/main-menu-client-users.svg?raw'),
  [IconName.MAIN_MENU_ADVERSE_MEDIA]: require('./assets/main-menu-adverse-media.svg?raw'),
  [IconName.MAIN_MENU_INTERNAL_TRANSFERS]: require('./assets/main-menu-internal-transfers.svg?raw'),
  [IconName.MAIN_MENU_REPORTS]: require('./assets/main-menu-reports.svg?raw'),
  [IconName.MAIN_MENU_USERS]: require('./assets/main-menu-users.svg?raw'),
  [IconName.MAIN_MENU_ACTIVITY_AUDIT]: require('./assets/main-menu-activity-audit.svg?raw')
}

export const getIconFile = (name: IconName) => mapping[name]
