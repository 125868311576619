import { ApiReadResponse } from 'commons/types'
import {
  BsaSar,
  BsaSarFilingType,
  BsaSarServer,
  BsaSarServerCyber,
  BsaSarServerFraud,
  BsaSarServerGaming,
  BsaSarServerIdentification,
  BsaSarServerInsurance,
  BsaSarServerLaundering,
  BsaSarServerMortgage,
  BsaSarServerOtherActivities,
  BsaSarServerSecurities,
  BsaSarServerStructuring,
  BsaSarServerTerroristFinancing
} from 'commons/types/DTO/bank/bsa'
import { Request } from 'commons/utils/request'
import { ServerOptions } from 'ui/components/QueryTable'

import { emptySAR } from '../constants'

export const getList = (opts: ServerOptions): Promise<ApiReadResponse<BsaSarServer>> =>
  Request.post(Request.urls.bank.sarList, opts)

const mapServerToClient = (sar: BsaSarServer): BsaSar => {
  const {
    cyber,
    gaming,
    insurance,
    mortgage,
    securities,
    fraud,
    structuring,
    terrorist_financing,
    laundering,
    identification,
    other_activities,
    ...transactionsInfo
  } = sar.transactions_info
  const { filing_type_initial, filing_type_continuing, filing_type_correct_amend, filing_type_joint } = sar

  const mappedFilingType = Object.keys(
    Object.fromEntries(
      Object.entries({
        filing_type_initial,
        filing_type_continuing,
        filing_type_correct_amend,
        filing_type_joint
      }).filter(([, value]) => value === true)
    )
  )

  const mappedCyber = Object.keys(Object.fromEntries(Object.entries(cyber).filter(([, value]) => value === true)))

  const mappedGaming = Object.keys(Object.fromEntries(Object.entries(gaming).filter(([, value]) => value === true)))

  const mappedInsurance = Object.keys(
    Object.fromEntries(Object.entries(insurance).filter(([, value]) => value === true))
  )

  const mappedMortgage = Object.keys(Object.fromEntries(Object.entries(mortgage).filter(([, value]) => value === true)))

  const mappedSecurities = Object.keys(
    Object.fromEntries(Object.entries(securities).filter(([, value]) => value === true))
  )

  const mappedFraud = Object.keys(Object.fromEntries(Object.entries(fraud).filter(([, value]) => value === true)))
  const mappedStructuring = Object.keys(
    Object.fromEntries(Object.entries(structuring).filter(([, value]) => value === true))
  )
  const mappedTerroristFinancing = Object.keys(
    Object.fromEntries(Object.entries(terrorist_financing).filter(([, value]) => value === true))
  )
  const mappedLaundering = Object.keys(
    Object.fromEntries(Object.entries(laundering).filter(([, value]) => value === true))
  )
  const mappedIdentification = Object.keys(
    Object.fromEntries(Object.entries(identification).filter(([, value]) => value === true))
  )
  const mappedOtherActivities = Object.keys(
    Object.fromEntries(Object.entries(other_activities).filter(([, value]) => value === true))
  )

  const mappedTransactionInfo = {
    ...transactionsInfo,
    ...{
      fraud: mappedFraud,
      structuring: mappedStructuring,
      terrorist_financing: mappedTerroristFinancing,
      laundering: mappedLaundering,
      identification: mappedIdentification,
      other_activities: mappedOtherActivities,
      cyber: mappedCyber,
      gaming: mappedGaming,
      insurance: mappedInsurance,
      mortgage: mappedMortgage,
      securities: mappedSecurities
    }
  }

  return {
    ...sar,
    ...{ transactions_info: mappedTransactionInfo },
    ...{ filing_type: mappedFilingType }
  }
}

const mapClientToServer = (sar: BsaSar): BsaSarServer => {
  const {
    cyber,
    gaming,
    insurance,
    mortgage,
    securities,
    fraud,
    structuring,
    terrorist_financing,
    laundering,
    identification,
    other_activities,
    ...transactionsInfo
  } = sar.transactions_info

  const { filing_type } = sar

  const mappedFilingType = new BsaSarFilingType()
  filing_type.forEach((value) => (mappedFilingType[value] = true))

  const mappedCyber = new BsaSarServerCyber()
  cyber.forEach((value) => (mappedCyber[value] = true))

  const mappedGaming = new BsaSarServerGaming()
  gaming.forEach((value) => (mappedGaming[value] = true))

  const mappedInsurance = new BsaSarServerInsurance()
  insurance.forEach((value) => (mappedInsurance[value] = true))

  const mappedMortgage = new BsaSarServerMortgage()
  mortgage.forEach((value) => (mappedMortgage[value] = true))

  const mappedSecurities = new BsaSarServerSecurities()
  securities.forEach((value) => (mappedSecurities[value] = true))

  const mappedFraud = new BsaSarServerFraud()
  fraud.forEach((value) => (mappedFraud[value] = true))

  const mappedStructuring = new BsaSarServerStructuring()
  structuring.forEach((value) => (mappedStructuring[value] = true))

  const mappedTerroristFinancing = new BsaSarServerTerroristFinancing()
  terrorist_financing.forEach((value) => (mappedTerroristFinancing[value] = true))

  const mappedLaundering = new BsaSarServerLaundering()
  laundering.forEach((value) => (mappedLaundering[value] = true))

  const mappedIdentification = new BsaSarServerIdentification()
  identification.forEach((value) => (mappedIdentification[value] = true))

  const mappedOtherActivities = new BsaSarServerOtherActivities()
  other_activities.forEach((value) => (mappedOtherActivities[value] = true))

  const mappedTransactionInfo = {
    ...transactionsInfo,
    ...{
      cyber: mappedCyber,
      gaming: mappedGaming,
      insurance: mappedInsurance,
      mortgage: mappedMortgage,
      securities: mappedSecurities,
      fraud: mappedFraud,
      structuring: mappedStructuring,
      terrorist_financing: mappedTerroristFinancing,
      laundering: mappedLaundering,
      identification: mappedIdentification,
      other_activities: mappedOtherActivities
    }
  }

  return {
    ...sar,
    ...{ transactions_info: mappedTransactionInfo },
    ...mappedFilingType
  }
}

export const getItem = async (id: number): Promise<BsaSar> => {
  if (id === 0) {
    return emptySAR
  }

  const serverOprions: ServerOptions = { _options: { filters: [{ field: 'id', type: 'eq', value: id }] } }

  const promise = getList(serverOprions)
  const data = await promise
  if (data?.records.length) {
    return mapServerToClient(data.records[0])
  }

  return null
}

export const remove = (opts: ServerOptions) => Request.delete(Request.urls.bank.sar, opts)

export const save = (opts: ServerOptions) => {
  const saveFunc = opts.id ? Request.put : Request.post
  const mappedOpts = mapClientToServer(opts as BsaSar)
  return saveFunc(Request.urls.bank.sar, mappedOpts)
}

export const transactionsZIP = (opts: ServerOptions) => Request.post(Request.urls.bank.bsaSarTransactionsZip, opts)

export const companyList = (opts: ServerOptions) => Request.post(Request.urls.bank.companyList, opts)

export const editPeriod = (opts: ServerOptions) => Request.put(Request.urls.bank.editSarPeriod, opts)

export const generateXML = (opts: ServerOptions) => Request.post(Request.urls.bank.sarFormBatch, opts)

export const approveSAR = (opts: { id: number }) => Request.patch(Request.urls.bank.bsaSarApprove, opts)
