import React from 'react'

import { CheckBoxGroup, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

export const Laundering = () => {
  const { formApi } = useSARFormContext()
  return (
    <formApi.Field
      name="transactions_info.laundering"
      children={(field) => (
        <FF
          field={field}
          label="36. Money Laundering"
          input={(p) => (
            <CheckBoxGroup
              {...p}
              data-qa={'36. Money Laundering'}
              direction={'vertical'}
              data={[
                { label: 'a. Exchange small bills for large bills or vice versa', value: 'a' },
                { label: 'b. Funnel account', value: 'b' },
                { label: 'c. Suspicion concerning the physical condition of funds', value: 'c' },
                { label: 'd. Suspicion concerning the source of funds', value: 'd' },
                { label: 'e. Suspicious designation of beneficiaries, assignees or joint owners', value: 'e' },
                { label: 'f. Suspicious EFT/wire transfers', value: 'f' },
                { label: 'g. Suspicious exchange of currencies', value: 'g' },
                { label: 'h. Suspicious receipt of government payments/benefits', value: 'h' },
                { label: 'i. Suspicious use of multiple accounts', value: 'i' },
                { label: 'j. Suspicious use of noncash monetary instruments', value: 'j' },
                { label: 'k. Suspicious use of third-party transactors (straw-man)', value: 'k' },
                { label: 'l. Trade-Based Money Laundering/Black Market Peso Exchange', value: 'l' },
                { label: 'm. Transaction out of pattern for customer(s)', value: 'm' },
                { label: 'z. Other', value: 'z' }
              ]}
            />
          )}
        />
      )}
    />
  )
}
