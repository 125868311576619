import styled, { css } from 'styled-components'

const TextBadge = styled.div<{ $isNumber: boolean }>`
  font: var(--theme-typography-bodyM-regular);
  text-transform: uppercase;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 11px;
  letter-spacing: var(--theme-letterSpacing-s);
  text-wrap: nowrap;
  overflow: hidden;
  ${(p) =>
    p.$isNumber
      ? css`
          width: 22px;
          padding: 0;
        `
      : ``}
`

export const TextBadgeDefault = styled(TextBadge)`
  background-color: var(--theme-colors-global-grey-grey500);
  color: var(--theme-colors-global-general-main);
`

export const TextBadgeBlack = styled(TextBadge)`
  background-color: var(--theme-colors-global-general-secondary);
  color: var(--theme-colors-global-general-main);
`

export const TextBadgeWhite = styled(TextBadge)`
  background-color: var(--theme-colors-global-general-main);
  color: var(--theme-colors-global-grey-grey700);
`

export const TextBadgePositive = styled(TextBadge)`
  background-color: var(--theme-colors-theme-positive-main);
  color: var(--theme-colors-global-general-main);
`

export const TextBadgeDisabled = styled(TextBadge)`
  background-color: var(--theme-colors-global-grey-grey300);
  color: var(--theme-colors-global-grey-grey500);
`

export const TextBadgeWarning = styled(TextBadge)`
  background-color: var(--theme-colors-theme-warning-main);
  color: var(--theme-colors-global-general-secondary);
`

export const TextBadgeWarningSecondary = styled(TextBadge)`
  background-color: var(--theme-colors-theme-warning-secondary);
  color: var(--theme-colors-global-general-main);
`

export const TextBadgeError = styled(TextBadge)`
  background-color: var(--theme-colors-theme-negative-main);
  color: var(--theme-colors-global-general-main);
`
