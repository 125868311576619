import styled from 'styled-components'
import { CheckBox } from 'ui/components/CheckBox'
import { Panel } from 'ui/components/Panel'
import { Col } from 'ui/themes/globalStyles'

export const Inner = styled.div`
  padding: 30px 35px;
`

export const TinColumn = styled(Col)`
  width: 160px;
  position: relative;
`

export const Item = styled(Panel)`
  margin-bottom: 30px;
`

export const CheckBoxWrapper = styled(CheckBox)`
  position: absolute;
  left: 100%;
  top: 34px;
  margin-left: 20px;
`

export const VisibilityBox = styled.span<{ $visible: boolean }>`
  display: ${(p) => (p.$visible ? 'inline' : 'none')};
`
