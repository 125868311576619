import React, { FC, memo } from 'react'

import { useRouterState } from '@tanstack/react-router'
import { bsaCTRStatuses, filingTypes } from 'commons/types/dictionaries'
import { Button } from 'ui/components/Buttons'
import { DateTime } from 'ui/components/DateTime'
import { Info, InfoSet, InfoSetDirection } from 'ui/components/Info'
import { Link } from 'ui/components/Link'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { CTRDetailsResponse } from '~bank-bsa-reporting/types'

import { s } from './styles'

type Props = {
  data: CTRDetailsResponse
}

export const GeneralInformation: FC<Props> = memo(({ data }) => {
  const {
    createdAt,
    updatedAt,
    id,
    generalInfo: { notes, status, priorReportBsaId, filingType, filingName, nameDesc }
  } = data
  const state = useRouterState()
  const returnUrl = encodeURIComponent(state.location.pathname)
  const search = state.location.search

  return (
    <PageSection
      face={PageSectionFace.SECONDARY}
      title="General Information"
      actions={
        <Link to="/bsa/ctrs/$id/edit" params={{ id }} search={{ ...search, returnUrl }}>
          <Button face="positive">Edit</Button>
        </Link>
      }
      anchor="general"
    >
      <s.WrappedHr />
      <InfoSet direction={InfoSetDirection.COLUMN}>
        <s.Row>
          <Info label="Filing Name">{filingName}</Info>
          <Info label="CTR Name in the List">{nameDesc}</Info>
        </s.Row>
        <s.Row>
          <Info label="1. Type of filing">{filingTypes.find((i) => i.value === filingType)?.label}</Info>
          <Info label="Prior report BSA Identifier">{priorReportBsaId}</Info>
        </s.Row>
        <s.Row>
          <Info label="Status">{bsaCTRStatuses.find((i) => i.value === status)?.label}</Info>
        </s.Row>
        <s.Row>
          <Info label="Created Date">
            <DateTime date={createdAt || null} withTime />
          </Info>
          <Info label="Last Modified Date">
            <DateTime date={updatedAt || null} withTime />
          </Info>
        </s.Row>
        <Info label="Notes">{notes}</Info>
      </InfoSet>
    </PageSection>
  )
})
