import React, { FC, memo } from 'react'

import { LabelBadgeProps } from '../props'

import * as SC from './styles'

export const LabelBadgeV2: FC<LabelBadgeProps> = memo((props) => {
  const { children } = props

  return <SC.LabelBadge>{children}</SC.LabelBadge>
})
