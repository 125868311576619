import React, { FC, memo } from 'react'

import { COMPANIES_PATH } from 'commons/constants/routes'
import { Button } from 'ui/components/Buttons'
import { Hr } from 'ui/components/Hr'
import { Info, InfoSet, InfoSetDirection } from 'ui/components/Info'
import { Link } from 'ui/components/Link'
import { PageSection, PageSectionFace } from 'ui/components/Page'

import { GeneralInformationProps } from './GeneralInformationProps'
import { AccountInformation, AddressInformation, RelationShipInformation } from './components'

export const GeneralInformation: FC<GeneralInformationProps> = memo((props) => {
  const { onSetCompanyOwners, webPageData } = props

  const {
    id,
    name,
    legalName,
    dba,
    isHolding,
    subsidiaries,
    customerStatus,
    applicationID,
    relationshipOwners,
    availableOwners,
    phone,
    website,
    tags,
    description,
    primaryAccountOpeningDate,
    companyName,
    entityType,
    stateOfIncorporation,
    ein,
    eINLabel,
    enableInviteUsersToCP,
    numberOfLicenses,
    physicalAddress,
    mailingAddress
  } = webPageData

  const holding = webPageData.parentHoldingID
    ? { id: webPageData.parentHoldingID, name: webPageData.parentHoldingName || '' }
    : undefined

  return (
    <PageSection
      face={PageSectionFace.SECONDARY}
      title="Company Information"
      actions={
        <Link to={`${COMPANIES_PATH}/edit/${id}`}>
          <Button face="positive">Edit</Button>
        </Link>
      }
      id="general"
    >
      <Hr />
      <RelationShipInformation
        id={id}
        name={name}
        legalName={legalName}
        dba={dba}
        isHolding={isHolding}
        holding={holding}
        holding_group_companies={subsidiaries}
        customerStatus={customerStatus}
        applicationId={applicationID || 0}
        assignedBankUsers={relationshipOwners || []}
        availableOwners={availableOwners || []}
        onSetCompanyOwners={onSetCompanyOwners}
        phone={phone}
        website={website}
        tags={tags}
      />
      <Hr />
      <InfoSet direction={InfoSetDirection.ROW} legend="Description">
        <Info>{description}</Info>
      </InfoSet>
      <Hr />
      <AccountInformation
        businessType={companyName}
        entityType={entityType}
        stateFounded={stateOfIncorporation}
        dateFounded={primaryAccountOpeningDate}
        licenseCount={numberOfLicenses}
        ein={ein}
        eINLabel={eINLabel}
        enableInviteUsersToCP={enableInviteUsersToCP}
      />
      <Hr />
      <AddressInformation physicalAddress={physicalAddress} mailingAddress={mailingAddress} />
    </PageSection>
  )
})
