import { Company, CompanyManageFormValue } from 'commons/types/DTO/bank'
import { CompanyListWebPageRequest } from 'commons/types/DTO/bank/companyList/CompanyListWebPageRequest'
import { CompanyListWebPageResponse } from 'commons/types/DTO/bank/companyList/CompanyListWebPageResponse'
import { ServerOptions } from 'commons/types/api'
import { Request } from 'commons/utils/request/request'

export const getCompanyList = (options: ServerOptions) => Request.post(Request.urls.bank.companyList, options)

export const getCompanyListWebPage = (opts?: CompanyListWebPageRequest): Promise<CompanyListWebPageResponse> =>
  Request.post(Request.urls.bank.companyListWebpages, opts)

export const setCompanyOwners = (opts: { companyId: number; ids: string[] }) =>
  Request.put<Company>(Request.urls.bank.companyUsersAssignment, {
    companyId: opts.companyId,
    bankUserIds: opts.ids
  })

export const deleteCompany = (id: number) => Request.delete(Request.urls.bank.company, { id })

export const saveCompany = (company: CompanyManageFormValue) => {
  const fn = company.id ? Request.put : Request.post
  return fn(Request.urls.bank.company, company)
}
