import React, { FC, useCallback } from 'react'

import { useRouter } from '@tanstack/react-router'
import { Form, useFormData } from 'brief-form'
import { ApplicationStatus } from 'commons/types/enums'
import { Button } from 'ui/components/Buttons'
import { Dialog, DialogFace } from 'ui/components/Dialog'
import { TextareaInput } from 'ui/components/InputV2'
import { Toast } from 'ui/components/Toast'
import { FormRow } from 'ui/themes/globalStyles'
import { useRevertApplication } from '~bank-onboarding/hooks/useRevertApplication'
import { useRevertToDocumentRequest } from '~bank-onboarding/hooks/useRevertToDocumentRequest'

import { RevertToDialogProps } from './RevertToDialogProps'

import { Content } from './styles'

export const RevertToDialog: FC<RevertToDialogProps> = ({ application, onCloseModal, status }) => {
  const router = useRouter()
  const { revertApplicationToRFI } = useRevertApplication()
  const { routine: revertToDocumentRequest } = useRevertToDocumentRequest({
    onSuccess: async () => {
      Toast.success('Application was reverted')
      router.invalidate()
    },
    onError: async (error) => {
      Toast.error(error?.message || 'An error has occurred while reverting')
    }
  })

  const { config, Field } = useFormData({
    initialValue: {
      notes: ''
    }
  })

  const onRevert = useCallback(async () => {
    await revertToDocumentRequest({
      id: application.applicationID,
      notes: config.value.notes
    })
  }, [config.value, application, revertToDocumentRequest])

  const onRevertToRFI = useCallback(async () => {
    try {
      await revertApplicationToRFI(application.applicationID, config.value.notes)
      Toast.success('Application was reverted')
      router.invalidate()
    } catch (error) {
      Toast.error(error?.message || 'An error has occurred while reverting')
    }
  }, [application.applicationID, config.value.notes, revertApplicationToRFI, router])

  const onRevertApplication = status === ApplicationStatus.RFI_PENDING ? onRevertToRFI : onRevert

  return (
    <Dialog
      id="revert-to-dialog"
      onClose={onCloseModal}
      face={DialogFace.DEFAULT}
      title="Are you sure you want to revert the Application?"
      customFooterActions={
        <>
          <Button onClick={onRevertApplication} face="negative">
            Revert
          </Button>
          <Button face="neutral" onClick={onCloseModal}>
            Cancel
          </Button>
        </>
      }
    >
      <Content>
        <Form config={config}>
          <FormRow>
            <Field name="notes" label="Notes" input={TextareaInput} inputProps={{}} />
          </FormRow>
        </Form>
      </Content>
    </Dialog>
  )
}
