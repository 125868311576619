import { LicenseManageRequest } from 'commons/types/DTO/bank'

export const transformEditLicenseFormValues = (value: Partial<LicenseManageRequest>): Partial<LicenseManageRequest> => {
  const newValue = { ...value }
  newValue.cityTax = +value.cityTax
  newValue.mjRetailTax = +value.mjRetailTax
  newValue.specialTax = +value.specialTax
  newValue.countyTax = +value.countyTax
  newValue.stateTax = +value.stateTax

  return newValue
}
