import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { getRouteApi } from '@tanstack/react-router'
import { BSA_CTR_PATH } from 'commons/constants/routes'
import { Logger } from 'commons/utils/logger'
import isBoolean from 'lodash/isBoolean'
import isEqual from 'lodash/isEqual'
import { LeaveConfirmation } from 'ui/components'
import { Page, PageFace } from 'ui/components/Page'
import { useConfirmation } from 'ui/hooks'
import { CTR } from '~bank-bsa-reporting/hooks'
import { useManageCTR } from '~bank-bsa-reporting/hooks/ctr/useManageCTR'
import { CTREditRequest, CTRPersonsInfo } from '~bank-bsa-reporting/types'

import { FormErrors } from './ManageCTR.types'
import { Footer, Form } from './components'
import { getRefFromMetaErrors, parseErrorMeta } from './workWithMetaErrors'

const routeApi = getRouteApi('/bsa/ctrs/$id/edit')

const ManageCTR = memo(() => {
  const [errorMeta, setErrorMeta] = useState({})
  const [errorsReceived, setErrorsReceived] = useState(false)
  const getErrorMeta = (e: { string: [] }) => setErrorMeta(e)

  const search = routeApi.useSearch()
  const params = routeApi.useParams()
  const navigate = routeApi.useNavigate()

  const { data, isFetching } = useManageCTR({ id: +(params.id || 0) })

  const deleteMutation = CTR.useDelete()
  const saveMutation = CTR.useSave(getErrorMeta)
  const validateMutation = CTR.useValidate(getErrorMeta)

  const [isFormDirty, setIsFormDirty] = useState(false)
  const [isLeaveConfirmationNeeded, setIsLeaveConfirmationNeeded] = useState(true)

  const validateFunctionRefs = useRef({
    general: null,
    contactInformation: null,
    transactionLocationsInfo: (data?.form.transactionLocationsInfo || []).map(() => null),
    personsInfo: (data?.form.personsInfo || []).map(() => null),
    transactionsInfo: null
  })

  const [formValue, setFormValue] = useState<CTREditRequest>(data?.form)

  const [formErrors, setFormErrors] = useState<FormErrors>({
    general: [],
    contactInformation: {},
    transactionLocations: (data?.form.transactionLocationsInfo || []).map(() => ({})),
    personsInfo: [],
    transactionsInfo: {},
    institutionLocationsInfo: {}
  })

  const markFormAsDirty = useCallback(() => {
    setIsFormDirty(true)
  }, [setIsFormDirty])

  const onFormChange = useCallback(
    (v: Partial<CTREditRequest>, e: FormErrors) => {
      if (!isEqual({ ...formValue, ...v }, formValue)) {
        setFormValue({ ...formValue, ...v })
      }

      // We remove it because we get errors from the backend
      delete e.transactionsInfo.cashInTotal
      delete e.transactionsInfo.cashOutTotal

      // clear backend errors, because they block submitting
      setErrorMeta({})

      setFormErrors({ ...formErrors, ...e })
    },
    [formValue, formErrors]
  )

  useEffect(() => {
    if (!isEqual(data?.form, formValue)) {
      onFormChange(data?.form, formErrors)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.form])

  const deleteCTR = () => {
    setIsLeaveConfirmationNeeded(false)
    deleteMutation.mutateAsync([data?.form.id || 0]).then(() => {
      navigate({ to: BSA_CTR_PATH })
    })
  }

  const { open: startDeleting, Confirmation } = useConfirmation({
    message: `Are you sure you want to delete ${data?.form.generalInfo.nameDesc} CTR?`,
    onConfirm: deleteCTR,
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const onCancel = useCallback(() => {
    if (search.returnUrl) {
      navigate({ to: decodeURIComponent(search.returnUrl) })
    } else {
      navigate({ to: BSA_CTR_PATH })
    }
    setFormValue(null)
  }, [search.returnUrl, navigate])

  const validateBlock = useCallback((name: string, array?: boolean) => {
    const scroll = (errors: any, index?: number) => {
      if (Object.keys(errors).length) {
        const fieldName = Object.keys(errors)[0]

        const ref =
          typeof index !== 'undefined'
            ? validateFunctionRefs.current[name][index]?.registeredFields[fieldName]?.ref
            : validateFunctionRefs.current[name]?.registeredFields[fieldName]?.ref

        if (ref) {
          ref.current.scrollIntoView()
        }
      }
    }

    if (validateFunctionRefs.current[name]?.validate !== null) {
      if (array) {
        const e = validateFunctionRefs?.current[name]
          ?.map((item: any, index: number) => {
            const e = item.validate({ updateFields: true })
            scroll(e.errors, index)
            if (!e.valid) {
              Logger.getInstance().info('ctr validation 1', e.errors)
            }
            return !e.valid
          })
          .filter(Boolean)

        return !e?.length
      } else {
        const errors = validateFunctionRefs.current[name]?.validate({ updateFields: true })
        errors && scroll(errors.errors)
        if (!errors?.valid) {
          Logger.getInstance().info('ctr validation 2', errors?.errors)
        }
        return errors ? errors.valid : true
      }
    }

    return true
  }, [])

  const onSubmit = useCallback(
    (first?: boolean) => {
      if (!validateBlock('general')) {
        return
      }
      if (!validateBlock('contactInformation')) {
        return
      }
      if (!validateBlock('transactionLocationsInfo', true)) {
        return
      }
      if (!validateBlock('personsInfo', true)) {
        return
      }
      if (!validateBlock('transactionsInfo')) {
        return
      }

      !isBoolean(first) && setIsLeaveConfirmationNeeded(false)

      let personsInfo: CTRPersonsInfo[] = []

      if (formValue?.personsInfo) {
        personsInfo = formValue.personsInfo.map((item) => ({
          ...item,
          idForm: item.idForm === '' ? '' : item.idForm,
          idNumber: !item.idNumber ? '' : item.idNumber,
          cashIn: +item.cashIn,
          cashOut: +item.cashOut
        }))
      }
      if (formValue?.institutionContactInfo?.ein) {
        formValue.institutionContactInfo.ein = formValue.institutionContactInfo.ein.replace('-', '')
      }
      if (formValue?.transactionLocationsInfo) {
        formValue.transactionLocationsInfo.map((item) => {
          item.ein = item?.ein?.replace('-', '')
        })
      }

      if (formValue?.generalInfo?.priorReportBsaId) {
        formValue.generalInfo.priorReportBsaId = formValue.generalInfo.priorReportBsaId.toString()
      }

      const ctr: CTREditRequest = { ...formValue, personsInfo }

      if (isBoolean(first) && first) {
        validateMutation.mutateAsync(ctr)
      } else {
        saveMutation.mutateAsync(ctr)
      }
    },
    [saveMutation, validateMutation, formValue, validateBlock]
  )

  useEffect(() => {
    if (formValue && !errorsReceived && !isFetching) {
      onSubmit(true)
      setErrorsReceived(true)
    }
  }, [formValue, errorsReceived, isFetching, onSubmit])

  const parsedErrors = useMemo(() => parseErrorMeta(errorMeta), [errorMeta])

  useEffect(() => {
    const ref = getRefFromMetaErrors(validateFunctionRefs, parsedErrors)
    if (ref) {
      ref.current.scrollIntoView()
    }
  }, [validateFunctionRefs, parsedErrors])

  if (!formValue) {
    return null
  }

  return (
    <Page
      face={PageFace.SECONDARY}
      title={formValue.generalInfo.nameDesc}
      subTitle="CTR"
      isPending={isFetching}
      footer={
        <Footer
          onSubmit={onSubmit}
          onDelete={formValue.id ? startDeleting : undefined}
          onCancel={onCancel}
          isSubmitting={saveMutation.isLoading}
        />
      }
    >
      <Confirmation />
      <LeaveConfirmation preventLeaving={isLeaveConfirmationNeeded && isFormDirty}>
        <Form
          errors={{
            ...formErrors,
            general: parsedErrors.general,
            transactionsInfo: parsedErrors.transactionInfo,
            personsInfo: parsedErrors.personsInfo,
            contactInformation: parsedErrors.institutionContactInfo,
            transactionLocations: parsedErrors.transactionLocationsInfo.concat(parsedErrors.institutionLocationsInfo)
          }}
          onChange={onFormChange}
          validateFunctionRefs={validateFunctionRefs}
          markFormAsDirty={markFormAsDirty}
          data={formValue}
        />
      </LeaveConfirmation>
    </Page>
  )
})

export default ManageCTR
