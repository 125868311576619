import React, { FC, memo } from 'react'

import { useSubMenuHighlighter } from 'ui/hooks'

import { useComponentGuts } from '../../hooks'
import { MenuItem } from '../MenuItem'
import { InnerMenuProps } from './InnerMenuProps'

export const InnerMenu: FC<InnerMenuProps> = memo((props) => {
  const { items, active = true } = props
  const { scrollTo } = useComponentGuts(items)
  const { activeSubMenu } = useSubMenuHighlighter(
    items?.filter((i) => !i.disabled).map((i) => i.hash),
    active
  )

  return (
    <div>
      {items?.map(({ label, hash, disabled }, index) => (
        <MenuItem
          key={index}
          isActive={hash === activeSubMenu}
          isDisabled={disabled}
          to={location.pathname}
          hash={hash}
          onClick={() => scrollTo(hash)}
        >
          {label}
        </MenuItem>
      ))}
    </div>
  )
})
