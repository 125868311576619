import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'
import * as z from 'zod'
import { getCtrBatchesList } from '~bank-bsa-reporting/service/ctrBatches'
import { CTRBatchesRequest } from '~bank-bsa-reporting/types/CTRBatches/CTRBatchesRequest'

const sch = z.object({
  batchId: z.coerce.number().int().optional(),
  bsaTrackingId: z.string().optional(),
  date: z.string().optional(),
  page: z.number().int().positive().default(1),
  size: z.number().int().positive().default(10),
  status: z.string().optional()
}) as z.ZodType<CTRBatchesRequest>

export const CTRBatchesRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: `ctr-batch-list`,
    validateSearch: sch,
    loader: ({ location }) => getCtrBatchesList(location.search as CTRBatchesRequest),
    component: lazyRouteComponent(() => import('./components/List/List')),
    pendingMinMs: 1
  })
