import React, { FC, memo, useContext, useMemo } from 'react'

import { SALESDATA_PATH } from 'commons/constants/routes'
import { SalesSourceItem, SalesSourceItemInfo } from 'commons/types/DTO/bank/reportWebPage'
import { TableDataQa } from 'commons/types/enums'
import { formatCurrencyCent } from 'commons/utils'
import { IconName, IconSize, Link, Table, Tooltip } from 'ui/components'
import { ButtonGroup } from 'ui/components/ButtonGroup'
import { Button, TableButton } from 'ui/components/Buttons'
import { LicensesTitle } from 'ui/temp/LicensesTitle'
import { ResourcePath } from 'ui/temp/ResourcePath'

import { MRContext } from '../../../context'
import { Accordeon } from '../../Accordeon'

import * as SC from './styles'

export const SalesSourcesTable: FC<{
  salesSourceItem: SalesSourceItem
  monthlyAnalyticsId: number
  bankAccountId: number
}> = memo(({ salesSourceItem, monthlyAnalyticsId }) => {
  const context = useContext(MRContext)

  const mainLicense = useMemo(
    () => salesSourceItem.licenses.find((el) => el.isMain === true),
    [salesSourceItem.licenses]
  )

  const hasDangerWarning = useMemo(
    () => salesSourceItem.sources.find((el) => el.dangerAlertNotes && el.dangerAlertNotes.length > 0) !== undefined,
    [salesSourceItem.sources]
  )

  return (
    <Accordeon
      hasWarning={hasDangerWarning}
      title={
        <>
          Sales Data Sources for License{salesSourceItem.licenses.length > 1 ? 's' : ''}&nbsp;
          <LicensesTitle licenses={salesSourceItem.licenses} withUrl />
        </>
      }
      action={
        <ButtonGroup margin="small">
          {salesSourceItem.enableOverride === false && salesSourceItem.overrideButtonTooltip !== undefined && (
            <Tooltip id="edit" type="dark" content={salesSourceItem.overrideButtonTooltip}>
              <Button face="neutral" disabled>
                Add Manual Override
              </Button>
            </Tooltip>
          )}
          {salesSourceItem.enableOverride === false && salesSourceItem.overrideButtonTooltip === undefined && <></>}
          {salesSourceItem.enableOverride && (
            <Link
              to="/salesdata/bank-source-override/$mrId/$licenseId"
              params={{ mrId: monthlyAnalyticsId, licenseId: mainLicense.licenseId }}
            >
              <Button face="neutral">Add Manual Override</Button>
            </Link>
          )}
          {salesSourceItem.enableSendTask && (
            <Button
              disabled={context.isSourcesReloading}
              face="neutral"
              onClick={(e) => {
                e.stopPropagation()
                context.sendTask(mainLicense.licenseId)
              }}
            >
              Send Task
            </Button>
          )}
        </ButtonGroup>
      }
      content={
        <>
          <Table
            loading={context.isSourcesReloading}
            dataQa={TableDataQa.BP_MONTHLY_ANALYTICS_BANK_ACCOUNT_SALES_SOURCE}
            columns={[
              {
                title: 'Source',
                sortable: false,
                align: 'left',
                width: '200px',
                render: (item: SalesSourceItemInfo) => (
                  <>
                    <ResourcePath
                      finishedPath={`${SALESDATA_PATH}/${item.sourceId}`}
                      name={item.sourceType}
                      id={item.sourceId}
                    />
                    {item.isPrimary && <SC.PrimaryIcon name={IconName.STAR} />}
                  </>
                )
              },
              {
                title: 'Total Sales',
                sortable: false,
                align: 'left',
                width: '200px',
                render: ({ totalSales }) => formatCurrencyCent(totalSales)
              },
              {
                title: 'Status',
                key: 'status',
                sortable: false,
                align: 'left',
                width: '200px'
              },
              {
                title: '',
                sortable: false,
                align: 'left',
                render: (source: SalesSourceItemInfo) => (
                  <>
                    {source.dangerAlertNotes !== undefined && (
                      <SC.Alert>
                        <SC.DangerAlertIcon name={IconName.ERROR} size={IconSize.S} /> {source.dangerAlertNotes}
                      </SC.Alert>
                    )}
                    {source.warningAlertNotes !== undefined && (
                      <SC.Alert>
                        <SC.WarningAlertIcon name={IconName.INFO} size={IconSize.S} /> {source.warningAlertNotes}
                      </SC.Alert>
                    )}
                  </>
                )
              },
              {
                title: '',
                sortable: false,
                align: 'right',
                width: '200px',
                render: (source: SalesSourceItemInfo) => (
                  <ButtonGroup margin="small">
                    {source.approvalNeeded && (
                      <Link to="/salesdata/$sourceId" params={{ sourceId: source.sourceId }}>
                        <TableButton face="danger">View & Approve</TableButton>
                      </Link>
                    )}
                    {source.enableEdit === false && source.editButtonTooltip !== undefined && (
                      <Tooltip id="edit" type="dark" content={source.editButtonTooltip}>
                        <TableButton face="neutral" disabled>
                          Edit
                        </TableButton>
                      </Tooltip>
                    )}
                    {source.enableEdit === false && source.editButtonTooltip === undefined && <></>}
                    {source.enableEdit && (
                      <Link to="/salesdata/$sourceId/edit" params={{ sourceId: source.sourceId }}>
                        <TableButton face="brand">Edit</TableButton>
                      </Link>
                    )}
                    {source.enableDelete && (
                      <TableButton face="danger" onClick={() => context.deleteBankSource(source.sourceId)}>
                        Delete
                      </TableButton>
                    )}
                    {source.enableCancelTask && (
                      <TableButton
                        disabled={context.isSourcesReloading}
                        face="neutral"
                        onClick={() => context.cancelTask(mainLicense.licenseId)}
                      >
                        Cancel Task
                      </TableButton>
                    )}
                  </ButtonGroup>
                )
              }
            ]}
            dataSource={salesSourceItem.sources.map((source) => ({ item: source }))}
          />
        </>
      }
    />
  )
})
