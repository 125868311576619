import React, { FC, memo } from 'react'

import { useRouter } from '@tanstack/react-router'
import { handlerServerError } from 'errors'
import { Button } from 'ui/components/Buttons'
import { Toast } from 'ui/components/Toast'
import { useConfirmation } from 'ui/hooks'
import { useAddApplicationRelatedRecords } from '~bank-onboarding/hooks/useAddApplicationRelatedRecords'
import { useSendApplicationDocumentRequest } from '~bank-onboarding/hooks/useSendApplicationDocumentRequest'

import { SendDocumentRequestProps } from './SendDocumentRequestProps'

export const SendDocumentRequest: FC<SendDocumentRequestProps> = memo(({ application }) => {
  const router = useRouter()

  const { addApplicationRelated } = useAddApplicationRelatedRecords()

  const { routine: sendDocumentRequest, isLoading } = useSendApplicationDocumentRequest({
    onSuccess: async () => {
      Toast.success('Application has been saved')
      router.invalidate()
    },
    onError: async (error) => {
      handlerServerError(error)
    }
  })

  const onSendDocumentRequest = async () => {
    await addApplicationRelated(application.applicationID)
    await sendDocumentRequest({ id: application.applicationID })
  }

  const { Confirmation: SendDocumentRequestConfirmation, open: openSendDocumentRequestConfirmation } = useConfirmation({
    message: <span>Are you sure want to Send Document Request?</span>,
    onConfirm: () => onSendDocumentRequest(),
    confirmationButtonText: 'Send'
  })

  return (
    <>
      <SendDocumentRequestConfirmation />
      <Button face="positive" onClick={openSendDocumentRequestConfirmation} loading={isLoading}>
        Send Document Request
      </Button>
    </>
  )
})
