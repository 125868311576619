import styled from 'styled-components'

import { Layer } from '../../../Layer'
import { Link } from '../../../Link'

export const Item = styled(Layer)`
  padding: 23px 40px;
  display: inline-block;
  background-color: #f5f5f5;
  min-width: 308px !important;
  margin-right: 30px;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: #fff;
    box-shadow: 0 7px 21px 0 rgba(0, 0, 0, 0.11);
  }
`

export const ListLink = styled(Link)`
  font-size: 13px;
  font-style: italic;
  display: inline-block !important;
  margin-top: 6px;
`

export const FirstRow = styled.div`
  font-size: 17px;
`

export const NotDue = styled.span`
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
  font-size: 13px;
`

export const PastDue = styled.span`
  color: ${(p) => p.theme.uiKit.colors.theme.negative.main};
  font-size: 13px;
`
