import React from 'react'

import { Applications } from 'commons/types/DTO/bank/applications/list/Applications'
import {
  DateTime,
  Icon,
  IconName,
  Link,
  MenuItemBehavior,
  TableActions,
  TableColumn,
  Tooltip,
  UsPhoneLabel
} from 'ui/components'

import { EmailLink, IdWithNotes, Phone } from './styles'

export const columns =
  (
    onDeleteApplication: (companyName: string, id: number) => void,
    showGetLinkModal: (applicationId: number) => void,
    showDeleteBtn: boolean
  ) =>
  (): TableColumn<Applications>[] =>
    [
      {
        title: 'ID',
        align: 'left',
        width: '100px',
        render: ({ id }) => (
          <IdWithNotes>
            <Link to={id.link}>{id.text}</Link>
            {id.internalBankNotes && (
              <Tooltip id={id.text} type="dark" event="hover" content={<>{id.internalBankNotes}</>}>
                <Icon name={IconName.DIALOG} />
              </Tooltip>
            )}
          </IdWithNotes>
        )
      },
      {
        title: 'Company Name',
        align: 'left',
        render: ({ companyName }) => (
          <Link to={companyName.link} data-qa={`Company Name ${companyName.text}`}>
            {companyName.text}
          </Link>
        )
      },
      {
        align: 'left',
        title: 'Status',
        render: ({ status }) => <>{status.text}</>
      },
      {
        title: 'Contact Name',
        align: 'left',
        render: ({ contactName }) => <>{contactName.text}</>
      },
      {
        title: 'Email',
        align: 'left',
        render: ({ contacts }) => <EmailLink href={contacts.email.link}>{contacts.email.text}</EmailLink>
      },
      {
        title: 'Phone',
        align: 'left',
        render: ({ contacts }) => (
          <>
            {contacts.phone && (
              <Phone>
                <UsPhoneLabel value={contacts.phone} />
              </Phone>
            )}
          </>
        )
      },
      {
        title: 'Current Owner',
        align: 'left',
        render: (item) => <>{item.currentOwner?.fullname}</>
      },
      {
        title: 'Last Modified',
        align: 'left',
        sortable: true,
        key: 'lastModified',
        render: ({ lastModified }) => !!lastModified && <DateTime date={lastModified.text} withTime />
      },
      {
        title: 'Created By',
        align: 'left',
        render: (item) => <>{item.createdBy?.fullname}</>
      },
      {
        title: 'Action(s)',
        align: 'right',
        render: (item) => {
          const items: any[] = [
            {
              title: item.actions.viewApplication.text,
              url: item.actions.viewApplication.link,
              textFace: 'grey',
              key: 0
            },
            item.actions.getLinkID && {
              behavior: MenuItemBehavior.BUTTON,
              title: 'Get link',
              textFace: 'grey',
              onClick: () => showGetLinkModal(item.actions.getLinkID),
              key: 2
            },
            item.actions.deleteApplication &&
              showDeleteBtn && {
                behavior: MenuItemBehavior.BUTTON,
                title: 'Delete',
                iconName: 'delete',
                textFace: 'grey',
                key: 3,
                onClick: () => {
                  onDeleteApplication(item.actions.deleteApplication.text || 'this', item.actions.deleteApplication.id)
                }
              }
          ].filter(Boolean)

          return <TableActions menuContainerId="applicationsListContainer" items={items} />
        }
      }
    ]
