import styled from 'styled-components'
import { Layer } from 'ui/components'
import { CSS } from 'ui/helpers'

export const WrappedLayer = styled(Layer)`
  margin-bottom: 20px;
  min-width: 0;
  max-width: 480px;
  border-top: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey900};
`

export const Table = styled.table`
  border-collapse: collapse;
  min-width: 480px;
  tr {
    border-top: 2px solid ${(p) => p.theme.uiKit.colors.global.grey.grey100};
  }
  td:first-child {
    text-align: left;
    background: ${(p) => p.theme.uiKit.colors.global.grey.grey100};
  }
  td:last-child {
    text-align: center;
    min-width: 217px;
  }
`

export const Title = styled.h5`
  padding: 10px 20px;
  font-weight: 600;
`

export const Td = styled.td<{ $alerted?: boolean }>`
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyM.regular)};
  padding: 9px 16px;
  text-align: center;
  border-right: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey100};
  background: ${(p) => (p.$alerted ? p.theme.uiKit.colors.theme.negative.main : 'transparent')};

  &:last-child {
    border-right: none;
  }
`
