import { BSAStatus } from '../types'

export const statusLabel = {
  [BSAStatus.NEW]: 'NEW',
  [BSAStatus.APPROVED]: 'APPROVED',
  [BSAStatus.SUBMITTED]: 'SUBMITTED',
  [BSAStatus.WARNINGS]: 'WARNINGS',
  [BSAStatus.ACKNOWLEDGED]: 'ACKNOWLEDGED',
  [BSAStatus.CANCELLED]: 'CANCELLED',
  [BSAStatus.CANCELED]: 'CANCELED'
}
