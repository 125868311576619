import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'
import { NotFoundError } from 'commons/errors'

import { getCompany } from '../../service/Manage/getCompany'

export const createCompanyRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: `create`,
    loader: async () => getCompany(null),
    component: lazyRouteComponent(() => import('./ManageCompany'))
  })

export const editCompanyRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: `edit/$companyId`,
    loader: async ({ params }) => {
      if ('companyId' in params) return await getCompany(Number(params?.companyId))
      else throw new NotFoundError()
    },
    component: lazyRouteComponent(() => import('./ManageCompany')),
    gcTime: 0
  })
