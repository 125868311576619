import styled from 'styled-components'
import { CheckBox } from 'ui/components'
import { TextButton } from 'ui/components/Buttons'

const WrappedCheckBox = styled(CheckBox)`
  margin: 0;

  & > span {
    margin: 0;
  }
`

const TableButton = styled(TextButton)`
  padding: 0 10px;
`

const LongFilename = styled.span`
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 1920px) {
    max-width: calc((100vw / 10) - 20px);
  }

  @media (max-width: 1650px) {
    max-width: calc((100vw / 10) - 60px);
  }

  @media (max-width: 1470px) {
    max-width: 70px;
  }
`

export const s = { WrappedCheckBox, TableButton, LongFilename }
