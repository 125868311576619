import React, { FC, memo } from 'react'

import { ctrPersonTypes, usAndTerritories, usStates } from 'commons/types/dictionaries'
import { formatCurrency } from 'commons/utils'
import { CheckMark } from 'ui/components/CheckMark'
import { formatDateTime } from 'ui/components/DateTime'
import { Info } from 'ui/components/Info'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { Panel, PanelFace } from 'ui/components/Panel'
import { RadioGroup, RadioGroupOrientation } from 'ui/components/RadioGroup'
import { TextMask } from 'ui/components/TextMask'
import { CTRDetailsResponse } from '~bank-bsa-reporting/types'

import { s } from './styles'

type Props = {
  data: CTRDetailsResponse
}

export const PersonsInvolvedInformation: FC<Props> = memo(({ data }) => {
  const { personsInfo } = data

  return (
    <PageSection face={PageSectionFace.THIRD} title="Step 3. Person(s) Involved Information" anchor="step3">
      {personsInfo.map((info, index) => {
        const {
          type,
          multipleTransactions,
          isEntity,
          showFirstName,
          showMiddleName,
          showLastName,
          showEntityName,
          showSuffix,
          showGender,
          alternateName,
          businessType,
          naicsCode,
          address,
          city,
          country,
          state,
          postalCode,
          tin,
          tinType,
          birthdate,
          phone,
          phoneExt,
          email,
          idForm,
          idFormOther,
          idNumber,
          idCountry,
          idState,
          cashIn,
          cashOut,
          accountNumbersIn,
          accountNumbersOut
        } = info

        return (
          <Panel
            rounded
            shadowed
            key={index}
            collapsible={false}
            title={`Part I Person Involved in Transaction(s) ${index + 1} of ${personsInfo.length}`}
            data-qa={`Part I Person Involved in Transaction(s) ${index + 1}`}
            face={PanelFace.PRIMARY}
          >
            <s.InnerPanel>
              {!!type && (
                <s.PersonGroup>
                  <Info label="2.">
                    <RadioGroup
                      value={type}
                      onChange={() => undefined}
                      orientation={RadioGroupOrientation.VERTICAL}
                      data={ctrPersonTypes}
                    />
                  </Info>
                </s.PersonGroup>
              )}
              <s.Row>
                <Info label="1. Type of filing">
                  <s.CheckOrRadio>
                    <CheckMark value={multipleTransactions || false} label="Multiple transactions" rounded={false} />
                  </s.CheckOrRadio>
                </Info>
                <Info label="Check">
                  <s.CheckOrRadio>
                    <CheckMark value={isEntity || false} label="If entity" rounded={false} />
                  </s.CheckOrRadio>
                </Info>
              </s.Row>
              <s.Row>
                <Info label="4. Individual's last name or entity's legal name">{showEntityName ?? showLastName}</Info>
              </s.Row>
              {!isEntity && (
                <>
                  <s.Row>
                    <Info label="5. First name">{showFirstName}</Info>
                  </s.Row>
                  <s.Row>
                    <Info label="6. Middle name">{showMiddleName}</Info>
                  </s.Row>
                  <s.Row>
                    <Info label="Suffix">{showSuffix}</Info>
                  </s.Row>
                  <s.Row>
                    <Info label="Gender">{showGender}</Info>
                  </s.Row>
                </>
              )}
              <s.Row>
                <Info label="8. Alternate name">{alternateName}</Info>
              </s.Row>
              <s.Row>
                <Info label="9. Occupation or type of business">{businessType}</Info>
                <Info label="9a. NAICS Code">{naicsCode}</Info>
              </s.Row>
              <s.Row>
                <Info label="10-14. Address">
                  {address || '-'}
                  <div>{[city, country === 'US' ? state : null, postalCode].filter(Boolean).join(', ') || '-'}</div>
                  <div>{country}</div>
                </Info>
              </s.Row>
              <s.Row>
                <Info label="15. TIN">
                  {tin ? (
                    <TextMask
                      text={tin}
                      valueType={tinType === 'EIN' ? 'EIN' : tinType === 'SSN-ITIN' ? 'SSN' : undefined}
                    />
                  ) : (
                    '-'
                  )}
                </Info>
                <Info label="16. TIN type">{tinType}</Info>
              </s.Row>
              {!isEntity && (
                <s.Row>
                  <Info label="17. Date of Birth">{birthdate ? formatDateTime(birthdate) : '-'}</Info>
                </s.Row>
              )}
              <s.Row>
                <Info label="18. Contact phone number">{phone}</Info>
                <Info label="Ext">{phoneExt}</Info>
              </s.Row>
              <s.Row>
                <Info label="19. E-mail address">{email}</Info>
              </s.Row>
              <s.Row>
                <Info label="20. Form of identification used to verify identity">
                  <s.FlexBox>
                    <CheckMark label={idForm || ''} value={!!idForm} rounded={true} />
                    {idForm === 'Other' && ` - ${idFormOther}`}
                  </s.FlexBox>
                </Info>
                <Info label="Number">{idNumber || '-'}</Info>
              </s.Row>
              <s.Row>
                <Info label="Country">{usAndTerritories.find((c) => c.value === idCountry)?.label || idCountry}</Info>
                {idCountry === 'US' && (
                  <Info label="Issuing state">{usStates.find((s) => s.value === idState)?.label}</Info>
                )}
              </s.Row>
              <s.Row>
                <Info label="21. Cash in amount for individual or entity listed in Item 4">
                  {formatCurrency(cashIn || 0)}
                </Info>
                <Info label="Account Number(s)">
                  {accountNumbersIn.length > 0
                    ? accountNumbersIn.map((number) =>
                        number ? <TextMask text={number} key={number} /> : <div>---</div>
                      )
                    : ''}
                </Info>
              </s.Row>
              <s.Row>
                <Info label="22. Cash out amount for individual or entity listed in Item 4">
                  {formatCurrency(cashOut || 0)}
                </Info>
                <Info label="Account Number(s)">
                  {accountNumbersOut.length > 0
                    ? accountNumbersOut.map((number) =>
                        number ? <TextMask text={number} key={number} /> : <div>---</div>
                      )
                    : ''}
                </Info>
              </s.Row>
            </s.InnerPanel>
          </Panel>
        )
      })}
    </PageSection>
  )
})
