import styled from 'styled-components'
import { Panel } from 'ui/components'

const Inner = styled.div`
  padding: 30px 35px;
`

const Item = styled(Panel)`
  margin-bottom: 30px;
`

const VisibilityBox = styled.span<{ $visible: boolean }>`
  display: ${(p) => (p.$visible ? 'inline' : 'none')};
`

export const s = { Inner, Item, VisibilityBox }
