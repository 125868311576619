import React, { FC, memo, useCallback } from 'react'

import { useRouter } from '@tanstack/react-router'
import { Button } from 'ui/components/Buttons'
import { Toast } from 'ui/components/Toast'
import { useConfirmation } from 'ui/hooks'
import { useSubmitApproveDocuments } from '~bank-onboarding/hooks/useSubmitApproveDocuments'

import { ApproveAllDocumentsProps } from './ApproveAllDocumentsProps'

export const ApproveAllDocuments: FC<ApproveAllDocumentsProps> = memo(({ application }) => {
  const router = useRouter()

  const { routine: submitApproveDocuments, isLoading } = useSubmitApproveDocuments({
    onSuccess: async () => {
      Toast.success('All Documents has been approved')
      router.invalidate()
    },
    onError: async () => {
      Toast.error('A trouble was occurred while approving')
    }
  })

  const onApproveAllDocs = useCallback(async () => {
    await submitApproveDocuments({ id: application.applicationID })
  }, [application, submitApproveDocuments])

  const { Confirmation: ApproveAllDocsConfirmation, open: openApproveAllDocsConfirmation } = useConfirmation({
    message: <span>Are you sure want to approve all Client Documents?</span>,
    onConfirm: () => onApproveAllDocs(),
    confirmationButtonText: 'Approve'
  })

  return (
    <>
      <ApproveAllDocsConfirmation />
      <Button face="positive" onClick={openApproveAllDocsConfirmation} loading={isLoading}>
        Approve All Documents
      </Button>
    </>
  )
})
