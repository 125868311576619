import { Request } from 'commons/utils/request/request'

export type UploadFileRequest = {
  file: File
  reportMonthlyId?: number
}

export const uploadFile = ({ file, reportMonthlyId }: UploadFileRequest) => {
  const form = new FormData()
  form.append('file', file)
  reportMonthlyId && form.append('reportMonthlyId', reportMonthlyId.toString())
  return Request.post(Request.urls.bank.reportFileUpload, form)
}
