import React, { FC, memo, useCallback } from 'react'

import {
  CTRGeneralInfo,
  CTRInstitutionContactInfo,
  CTRPersonsInfo,
  CTRTransactionInfo,
  CTRTransactionLocationsInfo
} from '~bank-bsa-reporting/types'

import { FormProps } from './Form.types'
import {
  AmountAndTypeOfTransactions,
  FilingInstitutionContactInformationForm,
  PersonsInvolvedInformationList,
  TransactionLocationsInformationList
} from './components'

import { s } from './styles'

export const Form: FC<FormProps> = memo((props) => {
  const { errors, onChange, validateFunctionRefs, markFormAsDirty, data } = props

  const onGeneralInformationFormChange = useCallback(
    (v: CTRGeneralInfo, e) => {
      onChange({ generalInfo: v }, { ...errors, general: e })
    },
    [onChange, errors]
  )

  const onFilingInstitutionContactInformationFormChange = useCallback(
    (v: CTRInstitutionContactInfo, e) => {
      onChange({ institutionContactInfo: v }, { ...errors, contactInformation: e })
    },
    [onChange, errors]
  )

  const onTransactionLocationsInformationFormChange = useCallback(
    (v: CTRTransactionLocationsInfo[], e: { [key: string]: string }[]) => {
      onChange({ transactionLocationsInfo: v }, { ...errors, transactionLocations: e })
    },
    [onChange, errors]
  )

  const onPersonsInformationFormChange = useCallback(
    (v: CTRPersonsInfo[], e: { [key: string]: string }[]) => {
      onChange({ personsInfo: v }, { ...errors, personsInfo: e })
    },
    [onChange, errors]
  )

  const onTransactionsInfoFormChange = useCallback(
    (v: CTRTransactionInfo, e) => {
      const totalIn = [
        v.cashInA,
        v.cashInB,
        v.cashInC,
        v.cashInD,
        v.cashInE,
        v.cashInF,
        v.cashInG,
        v.cashInH,
        v.cashInI,
        v.cashInZ
      ].reduce((p, c) => +(p || 0) + +(c || 0), 0)

      const totalOut = [
        v.cashOutA,
        v.cashOutB,
        v.cashOutC,
        v.cashOutD,
        v.cashOutE,
        v.cashOutF,
        v.cashOutG,
        v.cashOutH,
        v.cashOutI,
        v.cashOutJ,
        v.cashOutZ
      ].reduce((p, c) => +(p || 0) + +(c || 0), 0)

      onChange(
        {
          transactionInfo: {
            ...v,
            cashInTotal: totalIn,
            cashOutTotal: totalOut
          }
        },
        { ...errors, transactionsInfo: e }
      )
    },
    [errors, onChange]
  )

  return (
    <div>
      <s.WrappedGeneralInformationForm
        data={data}
        errors={errors.general}
        onChange={onGeneralInformationFormChange}
        validateFunctionRefs={validateFunctionRefs}
        markFormAsDirty={markFormAsDirty}
      />
      <FilingInstitutionContactInformationForm
        data={data}
        errors={errors.contactInformation}
        onChange={onFilingInstitutionContactInformationFormChange}
        validateFunctionRefs={validateFunctionRefs}
        markFormAsDirty={markFormAsDirty}
      />
      <TransactionLocationsInformationList
        data={data}
        errors={errors.transactionLocations}
        onChange={onTransactionLocationsInformationFormChange}
        validateFunctionRefs={validateFunctionRefs}
        markFormAsDirty={markFormAsDirty}
      />
      <PersonsInvolvedInformationList
        data={data}
        errors={errors.personsInfo}
        onChange={onPersonsInformationFormChange}
        validateFunctionRefs={validateFunctionRefs}
        markFormAsDirty={markFormAsDirty}
      />
      <AmountAndTypeOfTransactions
        data={data}
        errors={errors.transactionsInfo}
        onChange={onTransactionsInfoFormChange}
        validateFunctionRefs={validateFunctionRefs}
        markFormAsDirty={markFormAsDirty}
      />
    </div>
  )
})
