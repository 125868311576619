export type BsaCIFSubject = {
  address?: string
  alternate_name?: string
  attentions?: string
  birthdate?: string
  branch_id?: number
  business_type?: string
  cif?: string
  city?: string
  common_carrier?: boolean
  country?: string
  created_at?: string
  email?: string
  entity_name?: string
  first_name?: string
  gender?: string
  id?: number
  id_country?: string
  id_form?: string
  id_form_other?: string
  id_number?: string
  id_state?: string
  last_name?: string
  middle_name?: string
  naics_code?: string
  organization_id?: number
  phone?: string
  phone_ext?: string
  postal_code?: string
  state?: string
  suffix?: string
  tin?: string
  tin_type?: string
  updated_at?: string
}
