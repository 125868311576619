import { useCallback, useMemo, useState } from 'react'
import { useStateManager } from 'react-query-state-manager'

import { useBankPortalContext } from 'bank-portal-context'
import { BeforeFormChangeHandler, useFormData } from 'brief-form'
import { ContactListWebPageRequest, ContactListWebPageResponseItem } from 'commons/types/contracts/api/bank/contactList'
import { handlerServerError } from 'errors'
import { Keys } from 'state-manager'
import { Toast, useTableState } from 'ui/components'
import { useConfirmation } from 'ui/hooks'

import { useDeleteContact, useGetContactListWebPage } from '../../../hooks'

export const useContactList = () => {
  const {
    layout: { assignedCompaniesIDs }
  } = useBankPortalContext()

  const sm = useStateManager()

  const defaultFilter: ContactListWebPageRequest = useMemo(
    () => ({
      company_id: assignedCompaniesIDs.length > 0 ? 'my' : 'all',
      relationships_type: assignedCompaniesIDs.length > 0 ? 'my' : 'all',
      page: 1,
      size: 10,
      searchQuery: '',
      type: 'all'
    }),
    []
  )

  const tableState = useTableState({
    filter: {}
  })

  const onBeforeChange: BeforeFormChangeHandler<ContactListWebPageRequest> = useCallback(
    ({ value, errors, oldValue }) => {
      const filterChanged =
        value.relationships_type !== oldValue.relationships_type ||
        value.company_id !== oldValue.company_id ||
        value.type !== oldValue.type ||
        value.searchQuery !== oldValue.searchQuery

      if (filterChanged) value.page = 1

      return {
        value,
        errors
      }
    },
    []
  )

  const filterForm = useFormData<ContactListWebPageRequest>({
    initialValue: defaultFilter,
    onBeforeChange
  })

  const onSearch = useCallback(
    (term: string) => {
      const newFilter: ContactListWebPageRequest = { ...filterForm.config.value, searchQuery: term, page: 1, size: 10 }
      filterForm.set({ value: newFilter })
      sm.invalidate(Keys.Contact.List(newFilter))
    },
    [filterForm.config]
  )

  const changePagination = useCallback(
    async (page: number, size: number) => {
      const newFilter: ContactListWebPageRequest = {
        ...filterForm.config.value,
        page: page,
        size: size
      }
      filterForm.set({ value: newFilter })
      sm.invalidate(Keys.Contact.List(newFilter))
    },
    [filterForm.config]
  )

  const { data, isFetching } = useGetContactListWebPage({
    ...filterForm.config.value,
    relationships_type:
      typeof filterForm.config.value?.company_id === 'string' ? filterForm.config.value?.company_id : undefined,
    company_id:
      typeof filterForm.config.value?.company_id === 'number' ? filterForm.config.value?.company_id : undefined
  })

  const [contactForDelete, setContactForDelete] = useState<ContactListWebPageResponseItem | undefined>(undefined)
  const { routine: deleteContact } = useDeleteContact({
    onSuccess: async () => {
      sm.invalidate(Keys.Contact.List())
      Toast.successSmall('Contact was deleted.')
    },
    onError: (error) => handlerServerError(error)
  })

  const { open, Confirmation: DeleteConfirmation } = useConfirmation({
    message: `Are you sure want to delete ${contactForDelete?.contactInfo} contact?`,
    onConfirm: () => {
      if (contactForDelete) {
        deleteContact(contactForDelete.id)
      }
    },
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const startContactDelete = useCallback(
    (item: ContactListWebPageResponseItem) => {
      setContactForDelete(item)
      open()
    },
    [setContactForDelete, open]
  )

  return {
    webPageData: data,
    tableState,
    isFetching,
    filterForm,
    onSearch,
    changePagination,
    DeleteConfirmation,
    startContactDelete
  }
}
