import styled from 'styled-components'
import { Icon } from 'ui/components'

export const FieldWithIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
`

export const IconWrapper = styled.div`
  outline: none;
  margin-top: 25px;
  cursor: help;
`

export const InfoIcon = styled(Icon)`
  cursor: help;
`
