/* eslint-disable camelcase */

export type BsaCtrInstitutionContactInfo = {
  address?: string
  city?: string
  contact_office?: string
  country?: string
  dba?: string
  ein?: string
  federal_regulator?: string
  id_number?: string
  id_type?: string
  legal_name?: string
  filed_date?: string
  filer_name?: string
  tin?: string
  tin_type?: string
  internal_control_number?: string
  phone?: string
  phone_ext?: string
  state?: string
  type?: string
  type_other?: string
  zip_code?: string
  le_contact_agency?: string
  le_contact_name?: string
  le_contact_phone?: string
  le_contact_phone_ext?: string
  le_contact_date?: string
}
