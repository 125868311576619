import React, { memo } from 'react'

import { ExternalLicense } from 'commons/types/DTO/bank'
import { TableDataQa } from 'commons/types/enums'
import { Request } from 'commons/utils/request'
import { Dialog, DialogFace } from 'ui/components/Dialog'
import { QueryTable } from 'ui/components/QueryTable'

import { columns } from './columns'
import { dataSourceSelector } from './dataSourceSelector'

interface Props {
  entities: { companies: number[] | null; contacts: number[] | null; externalLicenses: number[] | null }
  onClose: () => void
  isDialogShown: boolean
}

export const RelatedEntitiesDialog = memo((props: Props) => {
  const {
    entities: { companies, contacts, externalLicenses },
    onClose,
    isDialogShown
  } = props

  return isDialogShown ? (
    <Dialog
      id="search-term-related-entities"
      onClose={onClose}
      face={DialogFace.PRIMARY}
      title="Related Entities"
      headerStyle={{ width: 800 }}
    >
      <QueryTable
        name="Search Term Related Entities"
        dataQa={TableDataQa.BP_ADVERSE_MEDIA_SEARCH_TERM_RELATED_ENTITIES}
        dataSources={[
          {
            key: 'companies',
            url: Request.urls.bank.companyList,
            options: () => ({
              _options: {
                filters: [
                  {
                    field: 'id',
                    type: 'in',
                    value: companies
                  }
                ]
              }
            })
          },
          {
            key: 'contacts',
            url: Request.urls.bank.contactList,
            options: () => ({
              _options: {
                filters: [
                  {
                    field: 'id',
                    type: 'in',
                    value: contacts
                  }
                ]
              }
            })
          },
          {
            key: 'externalLicenses',
            url: Request.urls.bank.externalLicenseList,
            options: () => ({
              _options: {
                filters: [
                  {
                    field: 'id',
                    type: 'in',
                    value: externalLicenses
                  }
                ]
              }
            })
          },
          {
            key: 'licenses',
            url: Request.urls.bank.licenseList,
            // TODO: fix any
            // eslint-disable-next-line
            options: (data: any) => {
              const externalLicenseList = data?.externalLicenses?.records

              if (!externalLicenseList) {
                return {}
              }

              const licenseIdsSet = new Set()
              externalLicenseList.forEach((externalLicense: ExternalLicense) => {
                if (externalLicense.license_id) {
                  licenseIdsSet.add(externalLicense.license_id)
                }
              })

              return {
                _options: {
                  filters: [
                    {
                      field: 'id',
                      type: 'in',
                      value: [...licenseIdsSet]
                    }
                  ]
                }
              }
            }
          }
        ]}
        // TODO: declare types
        columns={columns as any}
        dataSourceSelector={dataSourceSelector}
      />
    </Dialog>
  ) : null
})
