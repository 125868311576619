import React, { FC, memo } from 'react'

import { DotBadgeProps } from '../props'

import * as SC from './styles'

export const DotBadge: FC<DotBadgeProps> = memo((props) => {
  const { color } = props

  switch (color) {
    case 'default':
      return <SC.DotBadgeDefault>&nbsp;</SC.DotBadgeDefault>
    case 'positive':
      return <SC.DotBadgePositive>&nbsp;</SC.DotBadgePositive>
    case 'warning':
      return <SC.DotBadgeWarning>&nbsp;</SC.DotBadgeWarning>
    case 'error':
      return <SC.DotBadgeError>&nbsp;</SC.DotBadgeError>
    default:
      return <></>
  }
})
