export class MRWebPagePayload {
  monthlyAnalyticsId = 0
  uploadedFilesLimit = 0
  uploadedFilesOffset = 0
  uploadedFilesSortBy = ''
  uploadedFilesSortDesc = true
  historyOffset? = 0
  historyLimit? = 10

  constructor(monthlyAnalyticsId?: number) {
    this.monthlyAnalyticsId = monthlyAnalyticsId || 0
  }
}
