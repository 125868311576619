import styled from 'styled-components'
import { Hr } from 'ui/components/Hr'
import { Icon } from 'ui/components/Icon'
import { CSS } from 'ui/helpers'

export const WrappedHr = styled(Hr)`
  margin-top: 0;
  margin-bottom: 21px;
`

export const WarningBox = styled.div`
  background: ${(p) => p.theme.uiKit.colors.theme.negative.transparent.opacity12};
  padding: 12px;
  border-radius: 8px;
  margin: 20px 0;
`

export const WarningIcon = styled(Icon)`
  margin-right: 10px;
`

export const WarningTitle = styled.b`
  color: ${(p) => p.theme.uiKit.colors.theme.negative.main};
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyM.button)};
  display: flex;
  align-items: center;
`

export const WarningTitleNonCapitalized = styled.span`
  text-transform: none;
`

export const WarningList = styled.ol`
  color: ${(p) => p.theme.uiKit.colors.theme.negative.main};
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyM.regular)};
  margin: 14px 0 0 0;
  padding: 0 0 0 22px;
`
