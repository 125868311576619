import React, { FC, memo, useMemo } from 'react'

import { Link, useNavigate } from '@tanstack/react-router'
import { TableDataQa } from 'commons/types/enums'
import { HeaderButton } from 'ui/components/Buttons'
import { Layer } from 'ui/components/Layer'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { DataSourceItem, Table } from 'ui/components/Table'
import { DocumentsPaths } from '~bank-documents/constants'
import { useDeletePeriodConfirmation } from '~bank-documents/hooks/useDeletePeriodConfirmation'
import { DocumentDetailsPeriodItem } from '~bank-documents/types/documentDetails'

import { DocumentPeriodsProps } from './DocumentPeriods.types'
import { columns } from './columns'

export const DocumentPeriods: FC<DocumentPeriodsProps> = memo(({ documentPeriods, documentId, isLastDelivery }) => {
  const { DeletePeriodConfirmation, showDeletePeriodConfirmation } = useDeletePeriodConfirmation()

  const navigate = useNavigate()

  const tableColumns = useMemo(
    () => columns({ documentId, onDeletePeriod: showDeletePeriodConfirmation, navigate }),
    [documentId, showDeletePeriodConfirmation, navigate]
  )

  const dataSource: DataSourceItem<DocumentDetailsPeriodItem>[] = useMemo(
    () => documentPeriods?.map((item) => ({ item })),
    [documentPeriods]
  )

  return (
    <PageSection
      face={PageSectionFace.SECONDARY}
      title="Document Periods"
      actions={
        <Link
          to={DocumentsPaths.NEW_PERIOD}
          params={{ documentId }}
          search={isLastDelivery ? { isLastDelivery } : null}
          resetScroll={false}
        >
          <HeaderButton>Add New</HeaderButton>
        </Link>
      }
    >
      <DeletePeriodConfirmation />
      <Layer rounded shadowed>
        <Table<DocumentDetailsPeriodItem>
          dataQa={TableDataQa.BP_DOCUMENT_DOCUMENTS_PERIODS}
          columns={tableColumns}
          dataSource={dataSource}
        />
      </Layer>
    </PageSection>
  )
})
