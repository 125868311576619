import React, { memo } from 'react'

import { BsaSar } from 'commons/types/DTO/bank/bsa'
import { formatCurrency } from 'commons/utils'
import { IconSize } from 'ui/components/Icon'
import { Info } from 'ui/components/Info'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { Panel, PanelFace } from 'ui/components/Panel'
import { TextMask } from 'ui/components/TextMask'
import { Col, Row } from 'ui/themes/globalStyles'

import { CashInOut } from './CashInOut'

import * as SC from './styles'

interface Props {
  item: BsaSar
}

export const TransactionsInformation = memo(({ item }: Props) => {
  const items = item.transactions_info?.by_account || []
  return (
    <PageSection face={PageSectionFace.THIRD} title="Transactions Information" anchor="transactions-info">
      {items.map((info: any, index) => (
        <Panel
          rounded
          shadowed
          key={index}
          collapsible={false}
          title={`Account ${index + 1} of ${items?.length}`}
          face={PanelFace.PRIMARY}
        >
          <SC.InnerPanel>
            <Row>
              <Col>
                <Info label="Bank Account Number">
                  <TextMask isBankAccount text={info.account_number || '-'} iconSize={IconSize.XS} />
                </Info>
              </Col>
              <Col>
                <Info label="Total Bank Fees">{formatCurrency(info.total_bank_fees || 0)}</Info>
              </Col>
            </Row>
            <Row>
              <Col>
                <CashInOut
                  rows={[
                    {
                      label: 'Cash In ',
                      value: formatCurrency(info.cash_in || 0)
                    },
                    {
                      label: 'Checks In ',
                      value: formatCurrency(info.check_in || 0)
                    },
                    {
                      label: 'ACH In ',
                      value: formatCurrency(info.ach_in || 0)
                    },
                    {
                      label: 'Transfer In ',
                      value: formatCurrency(info.transfer_in || 0)
                    },
                    {
                      label: 'Wires In ',
                      value: formatCurrency(info.wires_in || 0)
                    },
                    {
                      label: 'Misc Credits ',
                      value: formatCurrency(info.misc_in || 0)
                    },
                    { label: '', value: '' },
                    { label: '', value: '' },
                    { label: '', value: '' },
                    {
                      label: <b>TOTAL CREDITS</b>,
                      value: <b>{formatCurrency(info.total_in || 0)}</b>
                    }
                  ]}
                />
              </Col>
              <Col>
                <CashInOut
                  rows={[
                    {
                      label: 'Cash Out ',
                      value: formatCurrency(info.cash_out || 0)
                    },
                    {
                      label: 'Checks Out ',
                      value: formatCurrency(info.check_out || 0)
                    },
                    {
                      label: 'ACH Out ',
                      value: formatCurrency(info.ach_out || 0)
                    },
                    {
                      label: 'Transfer Out ',
                      value: formatCurrency(info.transfer_out || 0)
                    },
                    {
                      label: 'Wires Out ',
                      value: formatCurrency(info.wires_out || 0)
                    },
                    {
                      label: 'Debit Card Out ',
                      value: formatCurrency(info.debit_card_out || 0)
                    },
                    {
                      label: 'Returned Checks Out ',
                      value: formatCurrency(info.returned_checks_out || 0)
                    },
                    {
                      label: 'Withdrawals ',
                      value: formatCurrency(info.withdrawals_out || 0)
                    },
                    {
                      label: 'Misc Debits ',
                      value: formatCurrency(info.misc_out || 0)
                    },
                    {
                      label: <b>TOTAL DEBITS</b>,
                      value: <b>{formatCurrency(info.total_out || 0)}</b>
                    }
                  ]}
                />
              </Col>
            </Row>
          </SC.InnerPanel>
        </Panel>
      ))}
      <SC.Totals>
        <Row>
          <Info label="Current SAR Amount">{formatCurrency(item.transactions_info.total.current)}</Info>
        </Row>
        <Row>
          <Info label="Current SAR Cumulative Amount">
            {formatCurrency(item.transactions_info.total.current_cumulative)}
          </Info>
        </Row>
        <Row>
          <Info label="Previous SAR Cumulative Amount">
            {formatCurrency(item.transactions_info.total.previous_cumulative)}
          </Info>
        </Row>
      </SC.Totals>
    </PageSection>
  )
})
