import React, { FC, memo, useCallback } from 'react'

import { fakeLinkDownload } from 'commons/utils'
import { Request } from 'commons/utils/request'

import { DownloadProps } from './Download.types'

export const Download: FC<DownloadProps> = memo((properties) => {
  const { children, baseUrl, parametersForm, direct, name, mimeType, method = 'post', handleError } = properties

  const downloadFile = useCallback(() => {
    const request = method === 'put' ? Request.put : Request.post

    request(baseUrl, parametersForm, {
      responseType: direct ? 'arraybuffer' : 'json',
      transformResponse: (data, headers) => {
        const disposition = headers?.['content-disposition']
        let filename: string | undefined
        if (disposition) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          const matches = filenameRegex.exec(disposition)
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
          }
        }
        return {
          data,
          filename
        }
      }
    })
      .then((response: { data: any; filename: string | undefined }) => {
        const fileData = response?.data
        let url

        if (direct) {
          url = window.URL.createObjectURL(new Blob([fileData], { type: mimeType || 'image/jpg' }))
        } else {
          url = fileData?.link
        }

        fakeLinkDownload(url, response.filename || name)
      })
      .catch((error) => {
        const json =
          error.response && JSON.parse(String.fromCharCode.apply(null, new Uint8Array(error.response.data) as any))

        const message = error.message || 'Cannot download file'

        handleError(json && json.message ? json.message : message)
      })
  }, [method, baseUrl, parametersForm, direct, name, mimeType, handleError])

  return (
    <span onClick={downloadFile} data-qa={`${name}-link`}>
      {children}
    </span>
  )
})
