import React, { FC, memo, useEffect, useRef } from 'react'

import { COMPANIES_PATH } from 'commons/constants/routes'
import { HeaderButton } from 'ui/components/Buttons'
import { Page, PageFace } from 'ui/components/Page'

import { CompanyDetailsPageProps } from './CompanyDetailsPageProps'
import { AffiliatedCompaniesTable } from './components/AffiliatedCompaniesTable'
import { CompanyAdverseMediaCaseTable } from './components/CompanyAdverseMediaCaseTable'
import { CompanyAnnualReviewTable } from './components/CompanyAnnualReviewTable'
import { CompanyBankAccountsTable } from './components/CompanyBankAccountsTable'
import { CompanyContactsTable } from './components/CompanyContactsTable'
import { CompanyCustomersTable } from './components/CompanyCustomersTable/CompanyCustomersTable'
import { CompanyDocumentsTable } from './components/CompanyDocumentsTable'
import { CompanyHistoryTable as CompanyHistory } from './components/CompanyHistory'
import { CompanyLicensesTable } from './components/CompanyLicensesTable'
import { CompanyQRsTable } from './components/CompanyQRsTable'
import { CompanyVendorsTable } from './components/CompanyVendorsTable/CompanyVendorsTable'
import { GeneralInformation } from './components/GeneralInformation'

export const CompanyDetailsPage: FC<CompanyDetailsPageProps> = memo((props) => {
  const { webPageData, onSetCompanyOwners, companyId, onOpenClientPortalInviteDialog } = props

  // TO-DO: move it to provider for all App
  const lastHash = useRef('')

  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1)
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document.getElementById(lastHash.current)?.scrollIntoView({ behavior: 'smooth', block: 'end' })
        lastHash.current = ''
      }, 0)
    }
  }, [])
  //.end

  return (
    <Page
      title="Company Profile"
      face={PageFace.SECONDARY}
      subTitle={webPageData?.name || '...'}
      actions={
        webPageData.enableInviteUsersToCP ? (
          <HeaderButton size="middle" overColoredPad={true} onClick={onOpenClientPortalInviteDialog}>
            Client Portal Invite
          </HeaderButton>
        ) : (
          <HeaderButton disabled size="middle" overColoredPad={true} title="Not available for consumer accounts">
            Client Portal Invite
          </HeaderButton>
        )
      }
    >
      <GeneralInformation webPageData={webPageData} onSetCompanyOwners={onSetCompanyOwners} />
      <CompanyDocumentsTable
        companyId={companyId || 0}
        webPage={webPageData}
        documentEditCancelReturnUrl={encodeURIComponent(`${COMPANIES_PATH}/${companyId}#documents`)}
      />
      <CompanyContactsTable
        companyId={companyId || 0}
        initialContacts={webPageData.contacts}
        initialContactsTotalCount={webPageData.contactsTotalCount}
        contactsTypeValues={webPageData.contactsTypeValues}
      />
      <CompanyQRsTable companyId={companyId || 0} quarterlyAnalytics={webPageData.quarterlyAnalytics} />
      <AffiliatedCompaniesTable
        companyId={companyId || 0}
        initialAffiliatedCompanies={webPageData.affiliatedCompanies}
        initialAffiliatedCompaniesTotalCount={webPageData.affiliatedCompaniesTotalCount}
      />
      <CompanyLicensesTable
        companyId={companyId || 0}
        initialLicenses={webPageData.licenses}
        initialLicensesTotalCount={webPageData.licensesTotalCount}
        isAPIConnectionColumnEnabled={webPageData.isAPIConnectionColumnEnabled}
      />
      <CompanyCustomersTable
        companyId={companyId || 0}
        initialCustomers={webPageData.customers}
        initialCustomersTotalCount={webPageData.customersTotalCount}
      />
      <CompanyBankAccountsTable
        companyId={companyId || 0}
        initialBankAccounts={webPageData.bankAccounts}
        initialBankAccountsTotalCount={webPageData.bankAccountsTotalCount}
      />
      <CompanyVendorsTable
        companyId={companyId || 0}
        initialVendors={webPageData.vendors}
        initialVendorsTotalCount={webPageData.vendorsTotalCount}
      />
      {webPageData.annualReviews && (
        <CompanyAnnualReviewTable
          companyId={companyId || 0}
          companyName={webPageData.name}
          initialAnnualReviews={webPageData.annualReviews}
          initialAnnualReviewsTotalCount={webPageData.annualReviewsTotalCount}
        />
      )}
      {webPageData.adverseMedia && (
        <CompanyAdverseMediaCaseTable
          companyId={companyId || 0}
          initialAdverseMedia={webPageData.adverseMedia}
          initialAdverseMediaTotalCount={webPageData.adverseMediaTotalCount}
        />
      )}
      <CompanyHistory
        companyId={companyId || 0}
        initialCompanyHistory={webPageData.history}
        initialCompanyHistoryTotalCount={webPageData.historyTotalCount}
      />
    </Page>
  )
})
