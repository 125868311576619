export enum IconName {
  ADD = 'add',
  ADD_GREEN = 'add-green',
  ADVERSE_MEDIA = 'adverse-media',
  APPLICATION = 'application',
  APPLICATIONS = 'applications',
  AUDIT = 'audit',
  ARROW_NEXT = 'arrow-next',
  ARROW_RIGHT = 'arrow-right',
  ARROW_PREVIOUS = 'arrow-previous',
  ARROW_SMALL = 'arrow-small',
  ARROW_RIGHT_FILLED = 'ARROW_RIGHT_FILLED',
  ARROW_LEFT_FILLED = 'ARROW_LEFT_FILLED',
  BADGE = 'badge',
  BELL = 'bell',
  BELL2 = 'bell2',
  BSA = 'bsa',
  CALENDAR = 'calendar',
  CALENDAR_DOTTED = 'calendar-dotted',
  // eslint-disable-next-line max-len
  CAN = 'can', // TODO: looks @deprecated, do not use (has trick with fill color, different size layout that not supported by icon button)
  CANCEL_WHITE_ROUND = 'cancel-white-round',
  CHECK = 'check',
  CHECK_GREEN = 'check-green',
  CHECK_GREY = 'check-grey',
  CHECK_WHITE = 'check-white',
  CHECK_ROUND = 'check-round',
  CLEAR = 'clear',
  CLEAR_THIN = 'clear-thin',
  CLOCK = 'clock',
  CLOCK_ALARM = 'clock-alarm',
  CROSS_SMALL = 'cross-small',
  CP_ACTIVE = 'cp-active',
  CP_DEFAULT = 'cp-default',
  COMMENT = 'comment',
  COMPANIES = 'companies',
  DASHED_ARROW_DOWN_GRAY = 'dashed-arrow-down-gray',
  DASHED_ARROW_UP_GRAY = 'dashed-arrow-up-gray',
  DASHED_ARROW_DOWN_GREEN = 'dashed-arrow-down-green',
  DASHED_ARROW_UP_GREEN = 'dashed-arrow-up-green',
  DELETE = 'delete',
  DELETE_RED_CROSS = 'delete-red-cross',
  DIALOG = 'dialog',
  DIALOG_FILLED = 'dialog-filled',
  DIALOG_GREEN = 'dialog-green',
  DOCUMENT = 'document',
  DOTH_HORIZONTAL = 'dots-horizontal',
  DOWN = 'down',
  UP = 'up',
  DOWNLOAD = 'download',
  EDIT = 'edit',
  ENVELOPE = 'envelope',
  ERROR_RED = 'error-red', // looks @deprecated, use Error
  ERROR = 'error',
  EXCHANGE = 'exchange',
  EXCLAMATION = 'exclamation',
  EXCLAMATION_ORANGE = 'exclamation-orange',
  EXCLAMATION_WHITE = 'exclamation-white',
  EYE = 'eye',
  EYE_CROSSED = 'eye-crossed',
  EXPAND_LESS = 'expand-less',
  EXPAND_MORE = 'expand-more',
  FINANCIALS = 'financials',
  FOLDER = 'folder',
  GROUP = 'group',
  HELP = 'help',
  HISTORY = 'history',
  HISTORY_GRAY = 'history-gray',
  INFO = 'info',
  INFO_BLUE = 'info-blue',
  INFO_GREEN = 'info-green',
  INFO_WHITE = 'info-white',
  LEFT_ARROW = 'left-arrow',
  LINK = 'link',
  LINK_GREEN = 'link-green',
  LOCATION_MARK = 'location-mark',
  LOCK = 'lock',
  LOGOUT = 'logout',
  MENU = 'menu',
  MENU2 = 'menu2',
  MENU_MINIMIZED = 'menu-minimized',
  MINUS = 'minus',
  MINUS_FILLED = 'minus-filled',
  NEXT = 'next',
  NEXT_CLOUD = 'nextcloud',
  PHONE = 'phone',
  PLUS = 'plus',
  PLUS_FILLED = 'plus-filled',
  PREV = 'prev',
  REMIND = 'remind',
  REPEAT = 'repeat',
  REPORTS = 'reports',
  REPORTS_ACTIVE = 'reports-active',
  RIGHT_ARROW = 'right-arrow',
  SETTINGS = 'settings',
  SETTINGS_STROKE = 'settings-stroke',
  SEARCH = 'search',
  SHARED = 'shared',
  SHIELD = 'shield',
  SPINNER = 'spinner',
  STAR = 'star',
  TASKS = 'tasks',
  UPLOAD = 'upload',
  USER = 'user',
  USERS = 'users',
  EXIT = 'exit',
  ZEN_DESK = 'zendesk',
  WATCH = 'watch',
  WATCH_GREEN = 'watch-green',
  UNION = 'Union',
  ADVERSE_MEDIA_DEFAULT = 'adverse-media-default',
  ADVERSE_MEDIA_ACTIVE = 'adverse-media-active',

  AUDIT_DEFAULT = 'audit-default',
  AUDIT_ACTIVE = 'audit-active',
  BSA_DEFAULT = 'bsa-default',
  BSA_ACTIVE = 'bsa-active',
  DOCUMENTS_DEFAULT = 'documents-default',
  DOCUMENTS_ACTIVE = 'documents-active',
  INTERNAL_DEFAULT = 'internal-default',
  INTERNAL_ACTIVE = 'internal-active',
  RELATIONSHIPS_DEFAULT = 'relationships-default',
  RELATIONSHIPS_ACTIVE = 'relationships-active',
  REPORT_DEFAULT = 'reports-default',
  REPORT_ACTIVE = 'reports-active',
  USERS_DEFAULT = 'users-default',
  USERS_ACTIVE = 'users-active',
  APPLICATION_DEFAULT = 'application-default',
  APPLICATION_ACTIVE = 'application-active',
  CONTACTS_DEFAULT = 'contacts-default',
  CONTACTS_ACTIVE = 'contacts-active',
  CONNECTIONS_DEFAULT = 'connections-default',
  CONNECTIONS_ACTIVE = 'connections-active',
  MAIN_BAR_LOGO = 'main-bar-logo',
  FILES = 'files',

  /* main menu icons */
  MAIN_MENU_ALERTS = 'main-menu-alerts',
  MAIN_MENU_RELATIONSHIPS = 'MAIN_MENU_RELATIONSHIPS',
  MAIN_MENU_APPLICATION = 'MAIN_MENU_APPLICATION',
  MAIN_MENU_BSA = 'MAIN_MENU_BSA',
  MAIN_MENU_CONTACTS = 'MAIN_MENU_CONTACTS',
  MAIN_MENU_DOCUMENTS = 'MAIN_MENU_DOCUMENTS',
  MAIN_MENU_CONNECTIONS = 'MAIN_MENU_CONNECTIONS',
  MAIN_MENU_CLIENT_USERS = 'MAIN_MENU_CLIENT_USERS',
  MAIN_MENU_ADVERSE_MEDIA = 'MAIN_MENU_ADVERSE_MEDIA',
  MAIN_MENU_INTERNAL_TRANSFERS = 'MAIN_MENU_INTERNAL_TRANSFERS',
  MAIN_MENU_REPORTS = 'MAIN_MENU_REPORTS',
  MAIN_MENU_USERS = 'MAIN_MENU_USERS',
  MAIN_MENU_ACTIVITY_AUDIT = 'MAIN_MENU_ACTIVITY_AUDIT'
  /* end of main menu icons */
}
