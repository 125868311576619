import { useState } from 'react'

import { FormApi, ReactFormApi, useForm } from '@tanstack/react-form'
import { getRouteApi } from '@tanstack/react-router'
import { BsaSar } from 'commons/types/DTO/bank/bsa'
import { handleFormSubmissionError } from 'ui/components/Form'

import { emptySAR } from '../../../../constants'
import { useSaveSAR } from '../../../../hooks/sar'

const routeApi = getRouteApi('/bsa/sars/$id/edit')

export const useSARForm = (sar: BsaSar) => {
  const { returnUrl } = routeApi.useSearch()
  const navigate = routeApi.useNavigate()
  const [isSuccess, setIsSuccess] = useState(false)

  const comeBackToSarOrReturnUrl = () => {
    if (returnUrl) {
      return navigate({ to: decodeURIComponent(returnUrl) })
    } else {
      if (sar.id) {
        return navigate({ to: '/bsa/sars/$id', params: { id: sar.id } })
      } else {
        return navigate({ to: '/bsa/sars' })
      }
    }
  }

  const { routine } = useSaveSAR({
    onSuccess: async () => {
      setIsSuccess(true)
      setNotDirty()
      // Calling `form.reset()` doesn't update state immediately.
      // So we schedule route change on the next render when state is updated.
      setTimeout(() => {
        comeBackToSarOrReturnUrl()
      }, 0)
    },
    onError: (error) => {
      handleFormSubmissionError<BsaSar>(error, formApi)
    }
  })

  const formApi = useForm<BsaSar>({
    defaultValues: sar ? sar : emptySAR,
    onSubmit: async ({ value }) => {
      return await routine({
        ...value,
        prior_report_bsa_id: value.prior_report_bsa_id?.toString() || null
      })
    },
    validators: {
      onSubmit: ({ value }: { value: BsaSar }) => {
        const ti = value.transactions_info
        if (
          !(
            ti.structuring.length ||
            ti.terrorist_financing.length ||
            ti.fraud.length ||
            ti.laundering.length ||
            ti.identification.length ||
            ti.other_activities.length ||
            ti.gaming.length ||
            ti.cyber.length ||
            ti.insurance.length ||
            ti.mortgage.length ||
            ti.securities.length
          )
        ) {
          const errorMessage = 'Item 32-42. At least one type of suspicious activity must be selected'
          return {
            fields: {
              'transactions_info.structuring': errorMessage,
              'transactions_info.terrorist_financing': errorMessage,
              'transactions_info.fraud': errorMessage,
              'transactions_info.laundering': errorMessage,
              'transactions_info.identification': errorMessage,
              'transactions_info.other_activities': errorMessage,
              'transactions_info.gaming': errorMessage,
              'transactions_info.cyber': errorMessage,
              'transactions_info.insurance': errorMessage,
              'transactions_info.mortgage': errorMessage,
              'transactions_info.securities': errorMessage
            }
          }
        }

        return undefined
      },
      onSubmitAsync: async ({
        value,
        formApi
      }: {
        value: BsaSar
        formApi: FormApi<BsaSar, undefined> & ReactFormApi<BsaSar, undefined>
      }) => {
        const otherFieldsErrors = await formApi.validateAllFields('change')
        const isAllOtherFieldsValid = otherFieldsErrors.length === 0

        if (
          isAllOtherFieldsValid &&
          !(
            value.transactions_info.amount_unknown ||
            value.transactions_info.no_amount_involved ||
            value.transactions_info.current_amount
          )
        ) {
          const element = document.querySelector(`[data-qa="Item29"]`)
          element?.scrollIntoView({ block: 'center', behavior: 'smooth' })
        } else return undefined
      }
    }
  })

  const { useStore } = formApi
  const isDirty = useStore((state) => state.isDirty && state.isValid)
  const isFormValidating = useStore((state) => state.isFormValidating)

  const setNotDirty = () => {
    formApi.reset()
  }

  return {
    formApi,
    isFormValidating,
    setNotDirty,
    isFormDirty: isDirty,
    isSuccess
  }
}
