import React, { FC, memo } from 'react'

import { documentSubjectLabelByType } from 'commons/types/enums'
import { CheckBox } from 'ui/components/CheckBox'
import { FF, Validators } from 'ui/components/Form'
import { Info } from 'ui/components/Info'
import { IntegerInput, TextInput, TextareaInput } from 'ui/components/InputV2'
import { Select, SelectListName, getOptionsByListName } from 'ui/components/Select'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'
import { DocumentPeriodStatusChip } from '~bank-documents/components/DocumentPeriodStatusChip'

import { ManageDocumentPageFormProps } from './EditDocumentForm.types'
import { InfoBlockForExistingDocument } from './components/InfoBlockForExistingDocument'
import { SelectsForNewDocument } from './components/SelectsForNewDocument/SelectsForNewDocument'

export const EditDocumentForm: FC<ManageDocumentPageFormProps> = memo((props) => {
  const { isNew, form, appearanceInfo, presetFields } = props
  const isFormFrequencyIsOneTime = form.useStore((s) => s.values.frequency) === 'one-time'
  const formSubjectType = form.useStore((s) => s.values.subjectType)

  return (
    <>
      <FormRow>
        <Col>
          <form.Field
            name="name"
            validators={{
              onSubmitAsync: ({ value }) => Validators.required.field(value)
            }}
            children={(nameField) => (
              <FF
                label="Document Name"
                field={nameField}
                required={true}
                input={(props) => <TextInput data-qa="Document Name" {...props} />}
              />
            )}
          />
        </Col>
        <Col>
          <form.Field
            name="internal"
            children={(internalField) => (
              <FF
                label="Internal"
                field={internalField}
                input={(props) => <CheckBox data-qa="Internal" {...props} />}
              />
            )}
          />
        </Col>
      </FormRow>

      <FormRow>
        <Col>
          {!isNew && (
            <Info label="Subject Type">{documentSubjectLabelByType[appearanceInfo?.overviewSubjectType]}</Info>
          )}
          {isNew && (
            <form.Field
              name="subjectType"
              validators={{
                onSubmitAsync: ({ value }) => Validators.required.field(value)
              }}
              children={(subjectTypeField) => (
                <FF
                  label="Subject Type"
                  field={subjectTypeField}
                  required={true}
                  input={(props) => (
                    <Select
                      data={getOptionsByListName(SelectListName.SUBJECT)}
                      data-qa="Subject Type"
                      options-data-qa="Subject Type Item"
                      {...props}
                    />
                  )}
                />
              )}
            />
          )}
        </Col>
        <Col>
          {isNew && (
            <SelectsForNewDocument
              formSubjectType={formSubjectType}
              form={form}
              fieldsForDisabled={presetFields.fieldsForDisabled}
              presetSubjectName={presetFields.subjectName}
            />
          )}
          {!isNew && <InfoBlockForExistingDocument appearanceInfo={appearanceInfo} />}
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <form.Field
            name="frequency"
            validators={{
              onSubmitAsync: ({ value }) => Validators.required.stringWithPossibleZeroLength(value)
            }}
            children={(frequencyField) => (
              <FF
                label="Frequency"
                field={frequencyField}
                required={true}
                input={(props) => (
                  <Select
                    data={getOptionsByListName(SelectListName.FREQUENCY)}
                    data-qa="Frequency"
                    options-data-qa="Frequency option"
                    {...props}
                  />
                )}
              />
            )}
          />
        </Col>
        <Col>
          {isFormFrequencyIsOneTime && !isNew && (
            <Info label="Status">
              <DocumentPeriodStatusChip name={appearanceInfo.overviewStatus} />
            </Info>
          )}
          {!isFormFrequencyIsOneTime && (
            <form.Field
              name="startDateType"
              children={(startDateTypeField) => (
                <FF
                  label="Start Date Type"
                  field={startDateTypeField}
                  required={true}
                  input={(props) => (
                    <Select
                      data={getOptionsByListName(SelectListName.START_DATE_TYPE)}
                      data-qa="Start Date Type"
                      options-data-qa="Start Date Type options"
                      {...props}
                    />
                  )}
                />
              )}
            />
          )}
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <form.Field
            name="expirationDelayDays"
            children={(expirationDelayDaysField) => (
              <FF
                label="Expiration Delay Days"
                field={expirationDelayDaysField}
                input={(props) => <IntegerInput {...props} />}
              />
            )}
          />
        </Col>
        <Col>
          {!isFormFrequencyIsOneTime && (
            <form.Field
              name="dueDateType"
              children={(dueStartDateField) => (
                <FF
                  label="Due Date Type"
                  field={dueStartDateField}
                  input={(props) => (
                    <Select
                      data={getOptionsByListName(SelectListName.DUE_START_DATE)}
                      data-qa="Due Date Type"
                      options-data-qa="Due Date Type options"
                      {...props}
                    />
                  )}
                />
              )}
            />
          )}
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <form.Field
            name="optional"
            children={(optionalField) => (
              <FF
                label="Optional"
                field={optionalField}
                input={(props) => <CheckBox data-qa="Optional" {...props} />}
              />
            )}
          />
        </Col>
        <Col>
          {!isNew && !isFormFrequencyIsOneTime && (
            <form.Field
              name="disablePeriodsCreation"
              children={(disablePeriodsCreationField) => (
                <FF
                  label="Disable Periods Creation"
                  field={disablePeriodsCreationField}
                  input={(props) => <CheckBox data-qa="Optional" {...props} />}
                />
              )}
            />
          )}
        </Col>
      </FormRow>
      {!isNew && isFormFrequencyIsOneTime && (
        <FormRow>
          <Col2x>
            <form.Field
              name="notes"
              children={(notesField) => (
                <FF label="Notes" field={notesField} input={(props) => <TextareaInput data-qa="Notes" {...props} />} />
              )}
            />
          </Col2x>
        </FormRow>
      )}
    </>
  )
})
