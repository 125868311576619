/* eslint-disable camelcase */

export class BsaSarFilingType {
  filing_type_continuing = false
  filing_type_correct_amend = false
  filing_type_initial = false
  filing_type_joint = false
}

export class BsaSarServerCyber {
  a = false
  b = false
  z = false
}

export class BsaSarServerGaming {
  a = false
  b = false
  c = false
  d = false
  z = false
}

export class BsaSarServerInsurance {
  a = false
  b = false
  c = false
  d = false
  e = false
  f = false
  z = false
}

export class BsaSarServerMortgage {
  a = false
  b = false
  c = false
  d = false
  e = false
  z = false
}

export class BsaSarServerSecurities {
  a = false
  b = false
  c = false
  d = false
  e = false
  z = false
}

export class BsaSarServerFraud {
  a = false
  b = false
  c = false
  d = false
  e = false
  f = false
  g = false
  h = false
  i = false
  j = false
  k = false
  l = false
  m = false
  z = false
}

export class BsaSarServerStructuring {
  a = false
  b = false
  c = false
  d = false
  e = false
  z = false
}

export class BsaSarServerTerroristFinancing {
  a = false
  z = false
}

export class BsaSarServerLaundering {
  a = false
  b = false
  c = false
  d = false
  e = false
  f = false
  g = false
  h = false
  i = false
  j = false
  k = false
  l = false
  m = false
  z = false
}

export class BsaSarServerIdentification {
  a = false
  b = false
  c = false
  d = false
  e = false
  f = false
  z = false
}

export class BsaSarServerOtherActivities {
  a = false
  b = false
  c = false
  d = false
  e = false
  f = false
  g = false
  h = false
  i = false
  j = false
  k = false
  l = false
  m = false
  n = false
  o = false
  p = false
  q = false
  r = false
  s = false
  z = false
}

export type BsaSarServer = {
  id: number
  attention: string
  filing_name: string
  filing_type_continuing: boolean
  filing_type_correct_amend: boolean
  filing_type_initial: boolean
  filing_type_joint: boolean
  prior_report_bsa_id: string
  notes_to_fincen: string
  fincen_warnings: string
  company_id: number
  created_at: string
  custom: boolean
  deleted_at: string
  filed_date: string
  institution_activities_info: [
    {
      address: string
      city: string
      country: string
      federal_regulator: string
      id_number: string
      id_type: string
      institution_activity_branches_info: [
        {
          address: string
          city: string
          country: string
          id_number: string
          role_in_transaction: string
          state: string
          zip_code: string
        }
      ]
      internal_control_number: string
      legal_name: string
      role_in_transaction: string
      state: string
      tin: string
      tin_type: string
      type: string
      type_other: string
      zip_code: string
      dba: string
      loss_to_fin_institution: string
      no_branch_involved: boolean
    }
  ]
  institution_contact_info: {
    address: string
    city: string
    contact_office: string
    country: string
    federal_regulator: string
    filer_name: string
    id_number: string
    id_type: string
    internal_control_number: string
    phone: string
    phone_ext: string
    state: string
    tin: string
    tin_type: string
    type: string
    type_other: string
    zip_code: string
    dba: string
    le_contact_agency: string
    le_contact_name: string
    le_contact_phone: string
    le_contact_phone_ext: string
    le_contact_date: string
  }
  narrative: string
  notes: string
  status: string
  subjects_info: [
    {
      address_info: [
        {
          address: string
          city: string
          country: string
          postal_code: string
          state: string
        }
      ]
      alternate_names: [
        {
          value: string
        }
      ]
      birthdate: string
      business_type: string
      corroborative_statement_to_filer: string
      emails: [
        {
          value: string
        }
      ]
      entity_name: string
      first_name: string
      gender: string
      id_info: [
        {
          id_country: string
          id_form: string
          id_form_other: string
          id_number: string
          id_state: string
        }
      ]
      no_known_accounts_involved: boolean
      institution_info: {
        tin: string
        account_numbers: [
          {
            closed: boolean
            number: string
          }
        ]
      }[]
      is_entity: boolean
      last_name: string
      middle_name: string
      naics_code: string
      phone_numbers: [
        {
          phone: string
          phone_type: string
          phone_ext: string
        }
      ]
      relationships_info: [
        {
          accountant: boolean
          action_date: string
          agent: boolean
          appraiser: boolean
          attorney: boolean
          borrower: boolean
          customer: boolean
          director: boolean
          employee: boolean
          no_relationship: boolean
          officer: boolean
          other: boolean
          other_desc: string
          owner: boolean
          relationship_status: string
          tin: string
        }
      ]
      role_in_activity: string
      tin: string
      tin_type: string
      websites: [
        {
          value: string
        }
      ]
      is_all_info_unavailable: boolean
      suffix: string
      dba: string
    }
  ]
  trans_end_date: string
  trans_start_date: string
  transactions_info: {
    by_account: [
      {
        account_number: string
        ach_in: number
        ach_out: number
        cash_in: number
        cash_out: number
        check_in: number
        check_out: number
        debit_card_out: number
        returned_checks_out: number
        total: number
        total_bank_fees: number
        total_in: number
        total_out: number
        transfer_in: number
        transfer_out: number
        wires_in: number
        wires_out: number
        withdrawals_out: number
        misc_in: number
        misc_out: number
      }
    ]
    current_amount: number
    amount_unknown: boolean
    no_amount_involved: boolean
    current_cumulative_amount: number
    ip_addresses: {
      date: string
      ip: string
      time: string
    }[]
    cyber_event_indicators: {
      date: string
      time: string
      type: string
      type_other: string
      value: string
    }[]
    commodity_types: {
      value: string
    }[]
    product_instrument_desc: {
      value: string
    }[]
    markets_traded: {
      value: string
    }[]
    cusip_numbers: {
      value: string
    }[]
    cyber: BsaSarServerCyber
    cyber_other: string
    fraud: BsaSarServerFraud
    fraud_other: string
    gaming: BsaSarServerGaming
    gaming_other: string
    identification: BsaSarServerIdentification
    identification_other: string
    insurance: BsaSarServerInsurance
    insurance_other: string
    laundering: BsaSarServerLaundering
    laundering_other: string
    mortgage: BsaSarServerMortgage
    mortgage_other: string
    other_activities: BsaSarServerOtherActivities
    other_activities_other: string
    payment_mechanisms: {
      a: boolean
      b: boolean
      c: boolean
      d: boolean
      e: boolean
      f: boolean
      g: boolean
      h: boolean
      i: boolean
      z: boolean
    }
    payment_mechanisms_other: string
    product_types: {
      a: boolean
      b: boolean
      c: boolean
      d: boolean
      e: boolean
      f: boolean
      g: boolean
      h: boolean
      i: boolean
      j: boolean
      k: boolean
      l: boolean
      m: boolean
      n: boolean
      o: boolean
      p: boolean
      q: boolean
      r: boolean
      s: boolean
      t: boolean
      z: boolean
    }
    product_types_other: string
    securities: BsaSarServerSecurities
    securities_other: string
    structuring: BsaSarServerStructuring
    structuring_other: string
    terrorist_financing: BsaSarServerTerroristFinancing
    terrorist_financing_other: string
    total: {
      current: number
      current_cumulative: number
      previous_cumulative: number
    }
  }
  updated_at: string
}

export type BsaSar = {
  id: number
  attention: string
  filing_name: string
  filing_type: string[]
  prior_report_bsa_id: string
  notes_to_fincen: string
  fincen_warnings: string
  company_id: number
  created_at: string
  custom: boolean
  deleted_at: string
  filed_date: string
  institution_activities_info: [
    {
      address: string
      city: string
      country: string
      federal_regulator: string
      id_number: string
      id_type: string
      institution_activity_branches_info: [
        {
          address: string
          city: string
          country: string
          id_number: string
          role_in_transaction: string
          state: string
          zip_code: string
        }
      ]
      internal_control_number: string
      legal_name: string
      role_in_transaction: string
      state: string
      tin: string
      tin_type: string
      type: string
      type_other: string
      zip_code: string
      dba: string
      loss_to_fin_institution: string
      no_branch_involved: boolean
    }
  ]
  institution_contact_info: {
    address: string
    city: string
    contact_office: string
    country: string
    federal_regulator: string
    filer_name: string
    id_number: string
    id_type: string
    internal_control_number: string
    phone: string
    phone_ext: string
    state: string
    tin: string
    tin_type: string
    type: string
    type_other: string
    zip_code: string
    dba: string
    le_contact_agency: string
    le_contact_name: string
    le_contact_phone: string
    le_contact_phone_ext: string
    le_contact_date: string
  }
  narrative: string
  notes: string
  status: string
  subjects_info: [
    {
      address_info: [
        {
          address: string
          city: string
          country: string
          postal_code: string
          state: string
        }
      ]
      alternate_names: [
        {
          value: string
        }
      ]
      birthdate: string
      business_type: string
      corroborative_statement_to_filer: string
      emails: [
        {
          value: string
        }
      ]
      entity_name: string
      first_name: string
      gender: string
      id_info: [
        {
          id_country: string
          id_form: string
          id_form_other: string
          id_number: string
          id_state: string
        }
      ]
      no_known_accounts_involved: boolean
      institution_info: {
        tin: string
        account_numbers: [
          {
            closed: boolean
            number: string
          }
        ]
      }[]
      is_entity: boolean
      last_name: string
      middle_name: string
      naics_code: string
      phone_numbers: [
        {
          phone: string
          phone_type: string
          phone_ext: string
        }
      ]
      relationships_info: [
        {
          accountant: boolean
          action_date: string
          agent: boolean
          appraiser: boolean
          attorney: boolean
          borrower: boolean
          customer: boolean
          director: boolean
          employee: boolean
          no_relationship: boolean
          officer: boolean
          other: boolean
          other_desc: string
          owner: boolean
          relationship_status: string
          tin: string
        }
      ]
      role_in_activity: string
      tin: string
      tin_type: string
      websites: [
        {
          value: string
        }
      ]
      is_all_info_unavailable: boolean
      suffix: string
      dba: string
    }
  ]
  trans_end_date: string
  trans_start_date: string
  transactions_info: {
    by_account: [
      {
        account_number: string
        ach_in: number
        ach_out: number
        cash_in: number
        cash_out: number
        check_in: number
        check_out: number
        debit_card_out: number
        returned_checks_out: number
        total: number
        total_bank_fees: number
        total_in: number
        total_out: number
        transfer_in: number
        transfer_out: number
        wires_in: number
        wires_out: number
        withdrawals_out: number
        misc_in: number
        misc_out: number
      }
    ]
    current_amount: number
    amount_unknown: boolean
    no_amount_involved: boolean
    current_cumulative_amount: number
    ip_addresses: {
      date: string
      ip: string
      time: string
    }[]
    cyber_event_indicators: {
      date: string
      time: string
      type: string
      type_other: string
      value: string
    }[]
    commodity_types: {
      value: string
    }[]
    product_instrument_desc: {
      value: string
    }[]
    markets_traded: {
      value: string
    }[]
    cusip_numbers: {
      value: string
    }[]
    cyber: string[]
    cyber_other: string
    fraud: string[]
    fraud_other: string
    gaming: string[]
    gaming_other: string
    identification: string[]
    identification_other: string
    insurance: string[]
    insurance_other: string
    laundering: string[]
    laundering_other: string
    mortgage: string[]
    mortgage_other: string
    other_activities: string[]
    other_activities_other: string
    payment_mechanisms: {
      a: boolean
      b: boolean
      c: boolean
      d: boolean
      e: boolean
      f: boolean
      g: boolean
      h: boolean
      i: boolean
      z: boolean
    }
    payment_mechanisms_other: string
    product_types: {
      a: boolean
      b: boolean
      c: boolean
      d: boolean
      e: boolean
      f: boolean
      g: boolean
      h: boolean
      i: boolean
      j: boolean
      k: boolean
      l: boolean
      m: boolean
      n: boolean
      o: boolean
      p: boolean
      q: boolean
      r: boolean
      s: boolean
      t: boolean
      z: boolean
    }
    product_types_other: string
    securities: string[]
    securities_other: string
    structuring: string[]
    structuring_other: string
    terrorist_financing: string[]
    terrorist_financing_other: string
    total: {
      current: number
      current_cumulative: number
      previous_cumulative: number
    }
  }
  updated_at: string
}
