import { Request } from 'commons/utils/request'
import { WriteHook, useMutationManager } from 'state-manager'

type DisconnectContactProp = {
  companyId: number
  contactId: number
}

const request = (opts: DisconnectContactProp) => Request.delete(Request.urls.bank.contactCompanyConnection, opts)

export const useDisconnectContactOnWebpage: WriteHook<DisconnectContactProp, void> = (opts) =>
  useMutationManager(request, opts)
