import { createContext } from 'react'

import { FormStatusMessages } from '../../enums'
import { FormsValidation, OnboardingFormContextValue } from '../../types/state'
import { onboardingFormInitValue } from './onboardingFormInitValue'

export const OnboardingFormContext = createContext<OnboardingFormContextValue>({
  handlers: {
    // ui
    handleSetPrevStep: () => undefined,
    handleSetNextStep: () => undefined,

    // submit/errors
    handleSubmitForm: () => undefined,
    handleManualSaveForm: () => undefined,
    handleSaveForm: () => undefined,
    handleCancelSaveNotesDialog: () => undefined,
    handleConfirmNotesSave: () => undefined,
    //
    handleAddCompany: () => undefined,
    handleRemoveCompany: () => undefined,
    handleCompanyPhysicalAddressCopyToMailing: () => undefined,
    // license
    handleAddLicense: () => undefined,
    handleRemoveLicense: () => undefined,

    // contacts
    handleAddContact: () => undefined,
    handleRemoveContact: () => undefined,
    handleContactCopy: () => undefined,
    // atms
    handleAddATM: () => undefined,
    handleRemoveATM: () => undefined,

    handleNotesChange: () => undefined,
    handleNotesSave: () => undefined,

    handleCompanyInformationChange: () => undefined,
    handleCompanyAccountDetailsChange: () => undefined,
    handleCompanyPhysicalAddressChange: () => undefined,
    handleCompanyMailingAddressChange: () => undefined,
    // licenses
    handleLicenseDetailsChange: () => undefined,
    handleLicenseAddressInformationChange: () => undefined,
    // contacts
    handleContactDetailsChange: () => undefined,
    handleRoleInformationChange: () => undefined,
    handleContactAdditionalInformationChange: () => undefined,
    //
    handleQuestionnaireChange: () => undefined,
    handleProfessionalPartnershipChange: () => undefined,
    // atms
    handleATMGeneralInfoChange: () => undefined,
    handleATMServicesChange: () => undefined,
    //
    handleAvailableServicesChange: () => undefined,
    handleAnticipatedMonthlyActivityChange: () => undefined,
    handleAnticipatedMonthlyActivityNAChange: () => undefined,

    handleMerchantQuestionnaireChange: () => undefined,

    // docs, step2
    handleAddFiles: () => undefined,
    handleRemoveFile: () => undefined,
    handleRequestAcknowledge: () => undefined,

    // docs isolated, step2
    handleAddFilesIsolated: () => undefined,
    handleRemoveFileIsolated: () => undefined
  },
  state: onboardingFormInitValue,
  ui: {
    ErrorMessagesDialog: () => null,
    formStatusMessage: FormStatusMessages.ISNT_DIRTY,
    isOnboardingFormDetailsEnable: false,
    isOnboardingFormEnable: false,
    isDocumentsEnable: false,
    isOnboardingDocumentsEnable: false,
    isDocumentsLoading: false,
    isDocumentDeleting: false,
    isDocumentsLoadingIsolated: false,
    isDocumentDeletingIsolated: false,
    isBP: false,
    isCP: false,
    isIsolatedApplicationFromEntities: false,
    isSubmitRFIFormEnabled: false,
    contactsList: [],
    buttonsProps: {
      // ?
      pageLabel: '',
      isShowPrev: false,
      isShowNext: false,
      isShowSubmit: true
    }
  },
  validation: {
    validateFunctionRefs: { current: { formsValidation: {} as FormsValidation } }
  },
  isWebpabgeFetchLock: false
})
