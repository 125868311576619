/* eslint-disable import/no-unused-modules */
import { OnboardingWebPageResponse } from 'commons/types/DTO/commons'
import { Application } from 'commons/types/DTO/commons/application'
import { OnboardingRFIFormSavePayload } from 'commons/types/DTO/commons/webpagesOnboardinForm'
import { PortalName } from 'commons/types/enums'
import { Request } from 'commons/utils/request/request'

export const sendAgreement = (id: number) =>
  Request.put<Application>(Request.urls.client.applicationDocumentRequestAcknowledge, { application_id: id })

export const applicationTokenValidate = (token: string) =>
  Request.post<{ password_reset_key: string | undefined }>(Request.urls.client.authApplicationTokenValidate, { token })

export const saveRFIApplication = (props: { application: OnboardingRFIFormSavePayload; p: PortalName }) => {
  const saveApplicationUrls = {
    [PortalName.CP]: Request.urls.client.applicationSave,
    [PortalName.BP]: Request.urls.bank.applicationSave
  }
  return Request.put<OnboardingRFIFormSavePayload>(saveApplicationUrls[props.p], props.application)
}

// eslint-disable-next-line max-len
// https://swagger.dev.helioscompliance.com/?urls.primaryName=Staging%20Client%20API#/applications/postWebpagesOnboarding
export const getOnboardingWebPage = (id: number, p: PortalName) => {
  const onboardingWebPageUrls = {
    [PortalName.CP]: Request.urls.client.applicationWebPage,
    [PortalName.BP]: Request.urls.bank.applicationWebPage
  }
  return Request.post<OnboardingWebPageResponse>(onboardingWebPageUrls[p], { appId: id })
}

export type SaveApplicationNotesParams = { id: number; clientNotes: string }
// https://swagger.dev.helioscompliance.com/?urls.primaryName=Staging%20Client%20API#/applications/putApplicationnotes
export const saveApplicationNotes = ({ id, clientNotes }: SaveApplicationNotesParams) =>
  Request.put<unknown>(Request.urls.client.applicationSaveClientNotes, { id, clientNotes })

// eslint-disable-next-line max-len
// https://swagger.dev.helioscompliance.com/?urls.primaryName=Staging%20Client%20API#/applications/putApplicationsubmitrfiform
export const submitRFIApplication = (props: { id: number; p: PortalName }) => {
  const submitRFIApplicationUrls = {
    [PortalName.CP]: Request.urls.client.applicationSubmitRFI,
    [PortalName.BP]: Request.urls.bank.applicationSubmitRFI
  }
  return Request.put<unknown>(submitRFIApplicationUrls[props.p], { id: props.id })
}

// eslint-disable-next-line max-len
// https://swagger.dev.helioscompliance.com/?urls.primaryName=Staging%20Client%20API#/applications/putApplicationsubmitdocumentrequest
export const submitOnboardingDocuments = (application: OnboardingRFIFormSavePayload) =>
  Request.put<unknown>(Request.urls.client.applicationDocumentsSubmit, application)
