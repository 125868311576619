import { ReactNode } from 'react'

export enum TableColumnAlign {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right'
}

export type TableColumn<T extends { [key: string]: any }> = {
  title: React.ReactNode
  align?: TableColumnAlign | 'center' | 'left' | 'right'
  sortable?: boolean
  width?: string
  color?: string
  dataQa?: (value: string) => string
} & (
  | {
      key: keyof T
      render?: (record: T) => ReactNode
    }
  | {
      key?: keyof T
      render: (record: T) => ReactNode
    }
)
