/* eslint-disable camelcase */

export type BsaDDASubject = {
  CIF?: string
  DdaAccountNumber?: string
  Exempt?: boolean
  ID?: number
  Joint?: boolean
  Name?: string
  OrganizationID?: number
  TIN?: string
  created_at?: string
  updated_at?: string
}
