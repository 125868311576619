import { Request } from 'commons/utils/request'
import { ServerOptions } from 'ui/components/QueryTable'
import { CTREditRequest } from '~bank-bsa-reporting/types'

export const get = (opts: ServerOptions) => Request.post(Request.urls.bank.bsaCtr, opts)

export const remove = (opts: ServerOptions) => Request.delete(Request.urls.bank.bsaCtr, opts)

export const save = (item: CTREditRequest) => {
  const saveFunc = item.id ? Request.put : Request.post
  return saveFunc(Request.urls.bank.bsaCtr, item)
}

export const validate = (opts: ServerOptions) => Request.post(Request.urls.bank.bsaCtrValidate, opts)

export const generateXML = (opts: ServerOptions) => Request.post(Request.urls.bank.bsaCtrFormBatch, opts)

export const generateCTRs = (opts: ServerOptions) => Request.post(Request.urls.bank.bsaCtrForm, opts)

export const approveCTR = (opts: { id: number }) => Request.patch(Request.urls.bank.bsaCtrApprove, opts)

export const cancelCTR = (opts: { id: number }) => Request.patch(Request.urls.bank.bsaCtrCancel, opts)
