import { useEffect } from 'react'
import { useStateManager } from 'react-query-state-manager'

import { getRouteApi, notFound } from '@tanstack/react-router'
import { useCancelInternalTransfer } from 'commons/hooks/bank/internalTransfer'
import { handlerServerError } from 'errors'
import { Toast } from 'ui/components'
import { TablePagination, useFilterFormState } from 'ui/components/Table'
import { useConfirmation } from 'ui/hooks'

import { hooks } from '../hooks'
import { InternalTransfersDetailsWebpagePayload } from '../types'

const emptyFilter: InternalTransfersDetailsWebpagePayload = {
  id: 0,
  historyPage: 1,
  historySize: 10
}

export const useDetails = () => {
  const sm = useStateManager()

  const id = getRouteApi('/internal-transfers/$internalTransferId').useParams({
    select: (s) => Number(s.internalTransferId)
  })

  const filter = useFilterFormState({ initialValue: { ...emptyFilter, id } })
  const {
    value: { historyPage, historySize }
  } = filter

  const { data, isFetching, isFetched } = hooks.useDetailsWebpage(filter.value, {
    onSuccess: (response) => {
      if (historyPage === null && typeof response.form.historyPage === 'number') {
        filter.set({ newValue: response.form, reset: true })
      }
    },
    onError: (error) => Toast.errorSmall(error.message)
  })

  useEffect(() => {
    if ((isFetched && !data) || id === 0 || isNaN(id)) {
      throw notFound()
    }
  }, [id, isFetched, data])

  const { routine } = useCancelInternalTransfer({
    onSuccess: async (id) => {
      sm.invalidate(hooks.DETAILS_WEBPAGE_KEY_ROOT)
      Toast.successSmall(`IT-${id} was canceled.`)
    },
    onError: (error) => handlerServerError(error)
  })

  const { open: cancelInternalTransfer, Confirmation: CancelConfirmation } = useConfirmation({
    message: `Are you sure you want to cancel IT-${id}?`,
    onConfirm: () => routine(id),
    confirmationButtonText: 'Yes',
    isConfirmationNegative: true
  })

  const pagination: TablePagination = {
    page: historyPage,
    pageSize: historySize,
    total: data?.historyTotalCount || 0,
    onChange: (historyPage, historySize) => {
      filter.set({ newValue: { historyPage, historySize } })
    }
  }

  return {
    CancelConfirmation,
    isFetching,
    data,
    cancelInternalTransfer,
    pagination
  }
}
