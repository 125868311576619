import { SalesDataSourceWebPage } from './SalesDataSourceWebPage'

export class SalesDataClientSourceRejectPayload {
  internalNotes = ''
  rejectionReason = ''
  salesDataSourceId = 0

  constructor(webPage: SalesDataSourceWebPage) {
    this.salesDataSourceId = webPage.salesDataSourceId
    this.rejectionReason = webPage.infoRejectionReason
    this.internalNotes = webPage.infoInternalNotes
  }
}
