import React from 'react'

import { QuarterlyAnalytics } from 'commons/types/DTO/bank/companyDetails/quarterlyAnalytics'
import { DateTime, Link } from 'ui/components'
import { IconName } from 'ui/components/Icon'
import { QuarterlyReportStatusLabel } from 'ui/components/Labels'
import { TableActions } from 'ui/components/QueryTable'
import { TableColumn } from 'ui/components/Table'

export const columns = (onDelete: (item: any) => void) => (): TableColumn<QuarterlyAnalytics>[] =>
  [
    {
      title: 'Period',
      align: 'center',
      sortable: false,
      render: (item) => (
        <Link to="/qr/$id" params={{ id: item.id }}>
          {item.period}
        </Link>
      )
    },
    {
      title: 'Status',
      align: 'center',
      sortable: false,
      render: ({ status }) => <QuarterlyReportStatusLabel name={status} />
    },
    {
      title: 'Last Modified Date',
      sortable: false,
      render: ({ lastModifiedDate }) => <DateTime date={lastModifiedDate} withTime />
    },
    {
      title: 'Actions',
      sortable: false,
      width: '100px',
      render: (item) => (
        <TableActions
          items={(
            [
              {
                key: 3,
                title: 'Delete',
                behavior: 'button',
                onClick: () => onDelete(item),
                iconName: IconName.DELETE
              }
            ] as any
          ).filter(Boolean)}
        />
      )
    }
  ]
