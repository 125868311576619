import React, { FC, memo, useCallback, useEffect } from 'react'

import { BeforeFormChangeHandler, Form, FormChangedHandler, useFormData } from 'brief-form'
import { bsaCTRStatuses, filingTypes } from 'commons/types/dictionaries'
import { RadioGroup, Select, Validators } from 'ui/components'
import { IntegerInput, TextInput, TextareaInput } from 'ui/components/InputV2'
import { Panel } from 'ui/components/Panel'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'
import { CTRGeneralInfo } from '~bank-bsa-reporting/types'

import { GeneralInformationFormProps } from './GeneralInformationForm.types'

import { s } from './styles'

export const GeneralInformationForm: FC<GeneralInformationFormProps> = memo((props) => {
  const { data, onChange, errors, validateFunctionRefs, markFormAsDirty, ...rest } = props
  const { generalInfo } = data

  const onBeforeChange: BeforeFormChangeHandler<CTRGeneralInfo> = useCallback(({ value, errors: e }) => {
    const errors = { ...e }

    if (value?.filingType === 'amend' && !value?.priorReportBsaId) {
      errors['priorReportBsaId'] = '"Prior report BSA Identifier" is required for "Correct/amend" type of filing.'
    } else {
      if (errors['priorReportBsaId']?.startsWith('"Prior report BSA Identifier"')) {
        delete errors['priorReportBsaId']
      }
    }

    return { value, errors }
  }, [])

  const onFormChanged: FormChangedHandler<CTRGeneralInfo> = useCallback(
    (value, errors) => onChange(value, errors),
    [onChange]
  )

  const { isDirty, config, validate, Field, set } = useFormData<CTRGeneralInfo>({
    onBeforeChange,
    onFormChanged,
    alwaysSyncWithInitialValueAndErrors: true,
    initialValue: generalInfo,
    initialErrors: errors
  })

  useEffect(() => {
    if (isDirty) {
      markFormAsDirty()
    }
  }, [isDirty, markFormAsDirty])

  useEffect(() => {
    set({ ...config.value, errors })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors])

  useEffect(() => {
    validateFunctionRefs.current.general = { validate, registeredFields: config.registeredFields.current }
  }, [validate, validateFunctionRefs, config.registeredFields])

  return (
    <Panel rounded shadowed collapsible={false} title="Edit General information" anchor="general" {...rest}>
      <Form config={config}>
        <s.Inner>
          <FormRow>
            <Col>
              <Field
                required
                name="filingName"
                label="Filing Name"
                input={TextInput}
                validator={Validators.required.field}
                inputProps={{ 'data-qa': 'Filing Name', maxLength: 500 }}
              />
            </Col>
            <Col>
              <Field
                name="nameDesc"
                label="CTR Name in the List"
                input={TextInput}
                inputProps={{ 'data-qa': 'CTR Name in the List', maxLength: 500 }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Field
              required
              name="filingType"
              label="1. Type of filing"
              input={RadioGroup}
              validator={Validators.required.field}
              inputProps={{
                name: 'filing_type',
                data: filingTypes,
                'data-qa': '1. Type of filing'
              }}
            />
          </FormRow>
          <FormRow>
            <Col>
              <Field
                required={generalInfo.filingType === 'amend'}
                name="priorReportBsaId"
                label="Prior report BSA Identifier"
                input={IntegerInput}
                validator={generalInfo.filingType === 'amend' ? Validators.required.field : undefined}
                inputProps={{
                  maxLength: 14
                }}
              />
            </Col>
            <Col>
              <Field
                required
                name="status"
                label="Status"
                input={Select}
                validator={Validators.required.field}
                inputProps={{
                  disabled: true,
                  data: bsaCTRStatuses,
                  'data-qa': 'Status'
                }}
              />
            </Col>
          </FormRow>
          <FormRow>
            <Col2x>
              <Field
                name="notes"
                label="Notes"
                input={TextareaInput}
                inputProps={{
                  'data-qa': 'Notes',
                  maxLength: 5000
                }}
              />
            </Col2x>
          </FormRow>
        </s.Inner>
      </Form>
    </Panel>
  )
})
