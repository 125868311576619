import React from 'react'

import { ConnectionHistory } from 'commons/types/DTO/bank/connections'
import { TableColumn } from 'ui/components/Table'

import * as SC from './styles'

export const columns: TableColumn<ConnectionHistory>[] = [
  {
    title: 'User',
    align: 'left',
    width: '100px',
    render: (item) => (
      <>
        {item?.userType && <SC.UserType>{item?.userType}</SC.UserType>}
        {item?.userFullname && <SC.UserName>{item?.userFullname}</SC.UserName>}
      </>
    )
  },
  {
    title: 'Date',
    key: 'date',
    width: '70px'
  },
  {
    title: 'Action',
    key: 'action',
    width: '60px'
  },
  {
    title: 'Details',
    align: 'right',
    // TODO: how render details, this doesn't work
    render: (item: any) => (
      <SC.HistoryChangesWrapper>
        {item &&
          item.length > 0 &&
          item.map((change, change_index) => (
            <SC.ChangeSection key={change_index}>
              <div>
                <strong>From:</strong> {change.from}. <strong>To:</strong> {change.to}
              </div>
              <div>
                <strong>Subject:</strong> {change.subject}. <strong>Type:</strong> {change.type}
              </div>
            </SC.ChangeSection>
          ))}
      </SC.HistoryChangesWrapper>
    )
  }
]
