import { isArray } from 'lodash'

import { MappedErrors } from './ManageCTR.types'

export const parseErrorMeta = (errors: any) => {
  const mappedErrors: MappedErrors = {
    general: {},
    personsInfo: [],
    transactionInfo: {},
    transactionLocationsInfo: [],
    institutionContactInfo: [],
    institutionLocationsInfo: []
  }
  try {
    Object.keys(errors).map((key) => {
      const regex = key.indexOf('[') !== -1 ? /(.*)\[([0-9])\]\.(.*)/ : /(.*)\.(.*)/
      let parseKey = key.match(regex)

      if (!parseKey) {
        parseKey = ['', 'general', key]
      }
      const group = parseKey[1] as keyof MappedErrors
      const index = parseKey[2]
      const field = parseKey[3]

      if (!field) {
        mappedErrors[group] = {
          ...mappedErrors[group],
          [index]: errors[key][0]
        }
      } else {
        if (!mappedErrors[group][index]) {
          const intIndex = Number(index)
          for (let i = 0; i <= intIndex; i++) {
            if (!mappedErrors[group][i]) {
              mappedErrors[group].push([])
            }
          }
        }
        mappedErrors[group][index] = {
          ...mappedErrors[group][index],
          [field]: errors[key][0]
        }
      }
    })

    return mappedErrors
  } catch (ex: unknown) {
    return mappedErrors
  }
}

export const getRefFromMetaErrors = (
  validateFunctionRefs: React.MutableRefObject<any>,
  errors: MappedErrors
): any | undefined => {
  for (const key in errors) {
    if (!validateFunctionRefs.current[key]) {
      continue
    }
    const element = errors[key as keyof MappedErrors]

    if (isArray(element) && element.length > 0) {
      for (let i = 0; i < element.length; i++) {
        if (element[i] && element[i].length) {
          const fieldWithError = element[i][0]
          const fieldName = Object.keys(fieldWithError)[0]

          if (!fieldName) {
            return undefined
          }

          return validateFunctionRefs.current[key][i].registeredFields[fieldName]?.ref
        }
      }
    }

    if (!isArray(element) && Object.keys(element).length > 0) {
      const fieldName = Object.keys(element)[0]

      if (!fieldName) {
        return undefined
      }

      return validateFunctionRefs.current[key].registeredFields[fieldName]?.ref
    }
  }

  return undefined
}
