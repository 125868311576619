import { ContactRelatedCompanies } from '../../../../types'

export interface ContactCompaniesTableProps {
  onAdd: () => any
  onEdit: (companyId: number) => any
  onDetach: (companyId: number, contactId: number) => any

  relatedCompanies: ContactRelatedCompanies[]
  contactId: number
}
