import { Reducer, useCallback, useEffect, useMemo, useReducer, useRef } from 'react'

import { useRouter } from '@tanstack/react-router'
import { ApplicationStatus, PortalName } from 'commons/types/enums'

import { OnboardingFormAction } from '../../types/actions'
import {
  OnboardingFormConfirmations,
  OnboardingFormContextValue,
  OnboardingFormProps,
  OnboardingFormState
} from '../../types/state'
import * as actions from './actions'
import { getAllContactFromForms } from './helpers/getAllContactFromForms'
import { getFormStatusMessage } from './helpers/getFormStatusMessage'
import { getPageLabel } from './helpers/getPageLabel'
import { onboardingFormInitValue } from './onboardingFormInitValue'
import { formReducer } from './onboardingFormReducer'
import { useOnboardingFormAddRemoveItems } from './useOnboardingFormAddRemoveItems'
import { useOnboardingFormChangeSaveHandlers } from './useOnboardingFormChangeSaveHandlers'
import { useOnboardingFormErrors } from './useOnboardingFormErrors'
import { useStep2Documents } from './useStep2Documents'
import { useSubmit } from './useSubmit'

export const useOnboardingForm = (
  props: OnboardingFormProps
): { Confirmations: OnboardingFormConfirmations; state: OnboardingFormContextValue } => {
  const router = useRouter()
  const { portalName, applicationId, applicationData } = props

  const isWebpabgeFetchLock = useRef<boolean>(false)

  const [formState, formDispatch] = useReducer<Reducer<OnboardingFormState, OnboardingFormAction>>(
    formReducer,
    onboardingFormInitValue
  )
  const { isAutosaveUpdating, isDirty, hasChanges } = formState

  // webPage mock
  useEffect(() => {
    if (applicationData) {
      isWebpabgeFetchLock.current = true
      formDispatch(actions.setInitApplication(applicationData))
    }
  }, [applicationData])

  const invalidateWebpageData = useCallback(async () => {
    isWebpabgeFetchLock.current = false

    router.invalidate()
  }, [router])

  const formCompanyList = formState.formValue.companiesFormValues
  const { isFormSubmitting, isShowClientNotesDialog } = formState

  const {
    confirmations: { CompanyDelete, LicenseDelete, ContactDelete, AtmDelete },
    handlers: {
      handleAddCompany,
      handleRemoveCompany,
      handleAddLicense,
      handleRemoveLicense,
      handleAddContact,
      handleRemoveContact,
      handleContactCopy,
      handleAddATM,
      handleRemoveATM
    }
  } = useOnboardingFormAddRemoveItems({
    formDispatch,
    companiesFormValues: formCompanyList
  })

  // autosave
  const {
    handlers: {
      handleSaveRFI,
      handleNotesChange,
      handleNotesSave,
      handleCompanyInformationChange,
      handleCompanyAccountDetailsChange,
      handleCompanyPhysicalAddressChange,
      handleCompanyPhysicalAddressCopyToMailing,
      handleCompanyMailingAddressChange,
      handleLicenseDetailsChange,
      handleLicenseAddressInformationChange,
      handleContactDetailsChange,
      handleRoleInformationChange,
      handleContactAdditionalInformationChange,
      handleQuestionnaireChange,
      handleProfessionalPartnershipChange,
      handleATMGeneralInfoChange,
      handleATMServicesChange,
      handleAvailableServicesChange,
      handleAnticipatedMonthlyActivityChange,
      handleAnticipatedMonthlyActivityNAChange,
      handleMerchantQuestionnaireChange,
      handleSaveForm,
      handleManualSaveForm
    }
  } = useOnboardingFormChangeSaveHandlers({
    applicationId,
    isDirty,
    isFormSubmitting,
    formDispatch,
    formState,
    portalName
  })

  const { handleSubmitForm, handleCancelSaveNotesDialog, handleConfirmNotesSave, validateFunctionRefs } = useSubmit({
    applicationId,
    currentStep: formState.currentStep,
    handleNotesSave,
    isFormSubmitting,
    isAutosaveUpdating,
    isShowClientNotesDialog,
    formDispatch,
    invalidateWebpageData,
    formValue: formState.formValue,
    portalName,
    handleSaveRFI
  })

  const {
    confirmations: { ErrorMessagesDialog }
  } = useOnboardingFormErrors({
    lastErrorCount: formState.lastErrorCount,
    generalErrors: formState.generalErrors,
    formDispatch
  })

  // docs, step 2
  // docs
  const {
    ui: { isDocumentsLoading, isDocumentDeleting, isDocumentsLoadingIsolated, isDocumentDeletingIsolated },
    handlers: {
      handleAddFiles,
      handleRemoveFile,
      handleRequestAcknowledge,
      handleAddFilesIsolated,
      handleRemoveFileIsolated
    }
  } = useStep2Documents({ invalidateWebpageData })

  // ui
  const handleSetPrevStep = useCallback(() => formDispatch(actions.setPrevStep()), [])
  const handleSetNextStep = useCallback(() => formDispatch(actions.setNextStep()), [])

  const formStatusMessage = useMemo(
    () =>
      getFormStatusMessage({
        isDirty,
        hasChanges,
        isAutosaveUpdating
      }),
    [isDirty, hasChanges, isAutosaveUpdating]
  )

  const { step } = formState

  const isOnboardingFormEnable = step === 1 && formState.currentStep === 1 && formState.isCurrentStepEditEnable
  const isOnboardingFormDetailsEnable =
    (step === 1 && formState.currentStep === 1 && !formState.isCurrentStepEditEnable) ||
    (step === 1 && formState.currentStep > 1)

  const isDocumentsEnable = step === 2
  const isOnboardingDocumentsEnable = step === 3
  const isCanSetNextStep = step < formState.currentStep

  const isFirstStepEditDisabled =
    step === formState.currentStep && formState.currentStep === 1 && !formState.isCurrentStepEditEnable

  const pageLabel = useMemo(() => getPageLabel(step), [step])

  const companiesFormValues = formState.formValue.companiesFormValues
  const contactsList = useMemo(() => getAllContactFromForms({ companiesFormValues }), [companiesFormValues])

  return {
    Confirmations: { CompanyDelete, LicenseDelete, ContactDelete, AtmDelete },
    state: {
      handlers: {
        // ui
        handleSetPrevStep,
        handleSetNextStep,
        handleCancelSaveNotesDialog,
        handleConfirmNotesSave,
        // submit, errors
        handleSubmitForm,

        //save form without submit
        handleSaveForm,

        // submit form on BP
        handleManualSaveForm,

        // rest
        handleNotesChange,
        handleNotesSave,

        handleCompanyInformationChange,
        handleCompanyAccountDetailsChange,
        handleCompanyPhysicalAddressChange,
        handleCompanyPhysicalAddressCopyToMailing,
        handleCompanyMailingAddressChange,
        // Licenses
        handleLicenseDetailsChange,
        handleLicenseAddressInformationChange,
        // Contacts
        handleContactDetailsChange,
        handleRoleInformationChange,
        handleContactAdditionalInformationChange,
        handleQuestionnaireChange,
        handleProfessionalPartnershipChange,
        handleATMGeneralInfoChange,
        handleATMServicesChange,
        handleAvailableServicesChange,
        handleAnticipatedMonthlyActivityChange,
        handleAnticipatedMonthlyActivityNAChange,
        handleMerchantQuestionnaireChange,
        // add/remove handles
        // company
        handleAddCompany,
        handleRemoveCompany,

        // licenses
        handleAddLicense,
        handleRemoveLicense,

        // contacts
        handleAddContact,
        handleRemoveContact,
        handleContactCopy,
        // atms
        handleAddATM,
        handleRemoveATM,

        // docs, step2
        handleAddFiles,
        handleRemoveFile,
        handleRequestAcknowledge,

        // docs isolated, step2
        handleAddFilesIsolated,
        handleRemoveFileIsolated
      },
      state: formState,
      ui: {
        ErrorMessagesDialog,
        formStatusMessage,
        isOnboardingFormDetailsEnable,
        isOnboardingFormEnable,
        isDocumentsEnable,
        isOnboardingDocumentsEnable,
        isDocumentsLoading,
        isDocumentDeleting,
        isDocumentsLoadingIsolated,
        isDocumentDeletingIsolated,
        isBP: portalName === PortalName.BP,
        isCP: portalName === PortalName.CP,
        isIsolatedApplicationFromEntities: formState.isIsolatedApplicationFromEntities,
        isSubmitRFIFormEnabled: formState.isSubmitRFIFormEnabled,
        contactsList,
        buttonsProps: {
          // ?
          pageLabel,
          isShowPrev: formState.currentStep > 1 && step > 1,
          isShowNext: isCanSetNextStep,
          isShowSubmit:
            !isCanSetNextStep &&
            !isFirstStepEditDisabled &&
            formState.applicationStatus !== ('Document Request Submitted' as unknown as ApplicationStatus) &&
            formState.applicationStatus !== ('Document Request Review' as unknown as ApplicationStatus) && // TODO:
            formState.applicationStatus !== ('Pending Customer Review' as unknown as ApplicationStatus) // TODO:
        }
      },
      validation: {
        validateFunctionRefs
      },
      isWebpabgeFetchLock: isWebpabgeFetchLock.current
    }
  }
}
