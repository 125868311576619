import React, { Suspense } from 'react'

import { Outlet, useRouterState } from '@tanstack/react-router'
import { BPLayout } from 'ui/components/BPLayout'
import { Spinner, SpinnerSize } from 'ui/components/Spinner'

import { CTRSideBar, CtrBatchSideBar, SARSideBar, SarBatchSideBar } from './components'

export const BankBsaWrapper = () => {
  const pathname = useRouterState({ select: (s) => s.location.pathname })

  let sideBar: undefined | JSX.Element = undefined

  // cancelling sideMenu for create/edit forms
  if (!pathname.includes('edit')) {
    if (pathname.indexOf('/bsa/ctrs/') === 0) {
      sideBar = <CTRSideBar />
    } else if (pathname.indexOf('/bsa/sars/') === 0) {
      sideBar = <SARSideBar />
    } else if (pathname !== '/bsa/ctr-batch-list/' && pathname.indexOf('/bsa/ctr-batch-list/') === 0) {
      sideBar = <CtrBatchSideBar />
    } else if (pathname !== '/bsa/sar-batch-list/' && pathname.indexOf('/bsa/sar-batch-list/') === 0) {
      sideBar = <SarBatchSideBar />
    }
  }

  return (
    <BPLayout sidebar={sideBar}>
      <Suspense fallback={<Spinner centered size={SpinnerSize.M} />}>
        <Outlet />
      </Suspense>
    </BPLayout>
  )
}
