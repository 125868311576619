import React, { FC, memo } from 'react'

import { useForm } from '@tanstack/react-form'
import { getRouteApi } from '@tanstack/react-router'
import { omitBy } from 'lodash'
import { DatePicker, FF, Select } from 'ui/components'
import { IntegerInput, TextInput } from 'ui/components/InputV2'
import { FilterPanel } from 'ui/components/Table'
import { ChoiceOption } from 'ui/types'
import { BSAPaths } from '~bank-bsa-reporting/constants'
import { CTRBatchesRequest } from '~bank-bsa-reporting/types/CTRBatches/CTRBatchesRequest'

import { s } from './styles'

const routeApi = getRouteApi(BSAPaths.BSA_CTR_BATCHES_LIST)

export const Filter: FC = memo(() => {
  const navigate = routeApi.useNavigate()

  const statusOptions = routeApi.useLoaderData<ChoiceOption[]>({
    select: (s) => s.statusOptions
  })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { page: _, size, ...searchParams } = routeApi.useSearch<CTRBatchesRequest>({ select: (s) => s })

  const form = useForm({
    defaultValues: searchParams,
    validators: {
      onChangeAsync: ({ value }) => {
        navigate({
          search: {
            page: 1,
            size,
            ...omitBy(value, (v) => !v)
          },
          resetScroll: false
        })
      }
    }
  })

  const reset = () => {
    form.reset()
    navigate({ search: (search) => ({ page: search.page, size: search.size }), resetScroll: false })
  }

  return (
    <FilterPanel isDirty={form.useStore((s) => s.isDirty)} onReset={reset}>
      <s.FormWrapper>
        <s.Row>
          <s.LeftWrapper>
            <form.Field
              name="batchId"
              children={(idField) => (
                <FF field={idField} label="Batch ID" input={(inputProps) => <IntegerInput {...inputProps} />} />
              )}
            />
            <form.Field
              name="bsaTrackingId"
              children={(trackingField) => (
                <FF
                  field={trackingField}
                  label="BSA Tracking ID"
                  input={(inputProps) => <TextInput {...inputProps} />}
                />
              )}
            />
          </s.LeftWrapper>
          <s.RightWrapper>
            <form.Field
              name="date"
              children={(dateField) => (
                <FF label="Date" field={dateField} input={(inputProps) => <DatePicker {...inputProps} />} />
              )}
            />
            <form.Field
              name="status"
              children={(statusField) => (
                <FF
                  label="Status"
                  field={statusField}
                  input={(inputProps) => <Select data={statusOptions} {...inputProps} />}
                />
              )}
            />
          </s.RightWrapper>
        </s.Row>
      </s.FormWrapper>
    </FilterPanel>
  )
})
