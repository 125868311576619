import { ApplicationStatus, BankNames } from 'commons/types/enums'

import { DocumentStatuses } from './documentStatuses'

export type Notes = {
  clientNotes: string
}

export type CompanyInformation = {
  companyID?: number
  // packages/onboarding-form/src/components/OnboardingForm/helpers/__tests__/mappersResponse.test.ts
  companyName?: string // not used, should be removed
  entityUID: string // hidden https://helioscompliance.atlassian.net/browse/MP-8528
  legalName: string
  DBA: string
  phone: string
  website: string
  description: string
}

export type AccountDetails = {
  businessType: string
  entityType: string
  stateOfIncorporation: string
  EIN: string
  preLicenseMRBInfo: string
}

export type CompanyPhysicalAddress = {
  street: string
  city: string
  state: string
  country: string
  postalCode: string
}

export type CompanyMailingAddress = {
  mailingCity: string
  mailingCountry: string
  mailingPostalCode: string
  mailingState: string
  mailingStreet: string
}

export type LicenseDetails = {
  licenseID: number
  entityUID: string // hidden https://helioscompliance.atlassian.net/browse/MP-8528
  name: string
  type: string
  subtype: string
  POSType: string
  POSTypeOther: string
  licenseNumber: string
  issueDate: string
  expirationDate: string
  phone: string
  licenseMetrcAPIKey: string
}

export type LicenseAddressInformation = {
  street: string
  city: string
  postalCode: string
  state: string
}

//** Contacts */
export type ContactDetails = {
  contactID?: number // TODO: what is it?
  entityUID: string // hidden https://helioscompliance.atlassian.net/browse/MP-8528
  contactMetadataID?: number
  bio: string
  email: string
  firstName: string
  homePhone: string
  lastName: string
  mobile: string
  phone: string
  otherMJRelatedBusiness: string
  title: string
}

export type RoleInformation = {
  accountSigner: number
  conductor?: number
  debtholder: number
  owner: number
}

export type ContactAdditionalInformation = {
  birthdate: string
  city: string
  country: string
  debtholderDebtAmount: number | null
  debtholderMaturityDate: string | null
  debtholderPaymentAmount: number | null
  debtholderPaymentFrequency: string
  EINSSN: string
  ownerInvestmentAmount: number | null
  ownerOwnershipPercent: string | null
  street: string
  postalCode: string
  state: string
  mailingStreet?: string
  mailingCity?: string
  mailingState?: string
  mailingCountry?: string
  mailingPostalCode?: string
  USCitizen: number
}

export type ProfessionalPartnership = {
  proPartCurrentlyBanked: number
  proPartPrevClosureReason?: string
  proPartCurrentServicesCPAs: boolean
  proPartCurrentServicesLaw: boolean
  proPartCurrentServicesLogistic?: boolean
  proPartCurrentServicesLogisticDesc?: string
  proPartCurrentServicesOther: boolean
  proPartCurrentServicesOtherDesc: string
  proPartLitigation: string
  proPartStateEnforcements: string
  proPartInternetGambling?: number
  proPartPayrollSoftware: string
}

export type Questionnaire = {
  aWholesaleProducts?: number
  aWholesaleProductsDesc?: string
  bTypeOfProducts?: string
  cTypeOfAncillaryProducts?: string
  dHowManyLocations?: string
  eOnlineOrdersForPickup?: number
  fMinorSellingEnsure?: string
  gPaymentMethods?: string
  hTransferToOtherStates?: number
  hTransferToOtherStatesDesc?: string
  iAreYouAffiliated?: number
  iAreYouAffiliatedDesc?: string
  growerCustomersLocation?: string
  statesLicensed: string
  whereIsYourSupplier?: string
  whoDoYourSupplyGrower?: string
  whoSuppliesYou?: string
  statesLicensedLabel: string
  contactsForeignConnections?: number
}

export type ATMGeneralInfo = {
  ATMCount: string
  ATMHave: number
  ATMOwn?: number
  ATMReplenishingThirdParty?: number
  ATMReplenishingCompany?: number
}

export type ATMServices = {
  ATMAddress: string
  ATMAvgAmountReplenished: number
  ATMFundsFromBank: number
  ATMFundsFromBankFalseDesc: string
  ATMMaxAmount: number
  ATMReplenishFrequency: string
}

export type AvailableServices = {
  availServiceACHOrigination: boolean
  availServiceBillPay: boolean
  availServiceCheckingOperating: boolean
  availServiceCheckingPayroll: boolean
  availServiceDebitCard: boolean
  availServiceEscrow: boolean
  availServiceOnlineMobileBanking: boolean
  availServiceRemoteDepositCapture: boolean
  availServiceSavings: boolean
  availServiceWire?: boolean
  availServiceWireDomestic: boolean
  availServiceWireForeign: boolean
  availServiceChecking?: boolean
  availServiceMobileDeposit?: boolean
  availServiceRollingCoinChangeOrder?: boolean
  availServiceCreditCard?: boolean
  availServiceWireDomesticOnly?: boolean
  availServicePersonalChecking?: boolean
  availServicePersonalSavings?: boolean
  availServiceATMSettlement?: boolean
  availServiceHowManyAccountsIsOpening: string
}

export type AnticipatedMonthlyActivity = {
  anticipatedActivityACHDeposits?: boolean
  anticipatedActivityACHDepositsAmount?: number
  anticipatedActivityACHDepositsFrequency: string
  anticipatedActivityACHOriginating?: boolean
  anticipatedActivityACHOriginatingAmount?: number
  anticipatedActivityACHOriginatingFrequency: string

  anticipatedActivityATMWithdrawals?: boolean
  anticipatedActivityATMWithdrawalsAmount?: number
  anticipatedActivityATMWithdrawalsFrequency: string

  anticipatedActivityCashDeposits?: boolean
  anticipatedActivityCashDepositsAmount?: number
  anticipatedActivityCashDepositsFrequency: string
  anticipatedActivityCashWithdrawals?: boolean
  anticipatedActivityCashWithdrawalsAmount?: number
  anticipatedActivityCashWithdrawalsFrequency: string
  anticipatedActivityCheckWithdrawals?: boolean
  anticipatedActivityCheckWithdrawalsAmount?: number
  anticipatedActivityCheckWithdrawalsFrequency: string
  anticipatedActivityRemoteDeposits?: boolean
  anticipatedActivityRemoteDepositsAmount?: number
  anticipatedActivityRemoteDepositsFrequency: string
  anticipatedActivityWiresDomestic?: boolean
  anticipatedActivityWiresDomesticAmount?: number
  anticipatedActivityWiresDomesticFrequency: string
  anticipatedActivityWiresDomesticOnly?: boolean
  anticipatedActivityWiresDomesticOnlyAmount?: number
  anticipatedActivityWiresDomesticOnlyFrequency?: string

  anticipatedActivityWiresInternational?: boolean
  anticipatedActivityWiresInternationalAmount?: number
  anticipatedActivityWiresInternationalCountries: string
  anticipatedActivityWiresInternationalFrequency: string

  anticipatedActivityCheckDeposits?: boolean // no in Application
  anticipatedActivityCheckDepositsAmount?: number // no in Application
  anticipatedActivityCheckDepositsFrequency?: string // no in Application

  anticipatedActivityATMSettlement?: boolean
  anticipatedActivityATMSettlementAmount?: number
  anticipatedActivityATMSettlementFrequency?: string
}

export type AnticipatedMonthlyActivityNA = {
  activityDepositsCashNA: boolean
  activityDepositsChecksNA: boolean
  activityDepositsOtherNA: boolean
  activityWithdrawalsCashNA: boolean
  //
  activityDepositsChecksItems?: number
  activityDepositsChecksAmount?: number
  activityDepositsCashItems?: number
  activityDepositsCashAmount?: number
  activityDepositsOtherItems?: number
  activityDepositsOtherAmount?: number
  activityWithdrawalsCashItems?: number
  activityWithdrawalsCashAmount?: number
}

export type MerchantQuestionnaire = {
  legacyCash: number
  merchantProcessing: number
  merchantProcessingDesc: string
  primaryMethodToFund?: boolean
  cryptocurrencyTransactions?: number
  realTimePaymentServices?: number
}

export type FilesIsolated = {
  fileCreatedAt: string
  fileName: string
  fileId: number
}

export type DocumentsIsolated = {
  id: number
  internal: boolean
  name: string
  notes: string
  optional: boolean
  status: DocumentStatuses
  updatedAt: string
  entityUid: string
  files: FilesIsolated[]
}

export type DocumentsUploadingDoc = {
  id: number
  name: string
  optional: boolean
  subjectType: string
  // TODO: type it
  documentPeriod: {
    files: []
    id: number
    notes: string
    status: DocumentStatuses
  }
}

export type ApplicationClientDocumentsUploading = {
  companyUid: string
  companyName: string
  documents: DocumentsIsolated[]
}

export type DocumentsUploading = {
  id: number
  name: string
  uploading: DocumentsUploadingDoc[]
}

export type BusinessTypesDescription = {
  description: string
  title: string
}

export type FilterValues = {
  label: string
  value: string
}

export type WebPageLicenseItem = LicenseDetails & LicenseAddressInformation
export type WebPageContactItem = ContactDetails & RoleInformation & ContactAdditionalInformation
type WebPageATMItem = ATMServices

// eslint-disable-next-line import/no-unused-modules
export type WebPageCompanyItem = CompanyInformation &
  AccountDetails &
  CompanyPhysicalAddress &
  CompanyMailingAddress & {
    licenses: WebPageLicenseItem[]
    contacts: WebPageContactItem[]
    questionnaire: Questionnaire &
      ProfessionalPartnership &
      ATMGeneralInfo &
      MerchantQuestionnaire &
      AvailableServices &
      AnticipatedMonthlyActivity &
      AnticipatedMonthlyActivityNA & {
        ATMs: WebPageATMItem[]
      }
  }

export type OnboardingWebPageResponse = {
  // use it if isIsolatedApplicationFromEntities= true
  applicationClientDocumentsUploading: ApplicationClientDocumentsUploading[]
  applicationStatus: ApplicationStatus
  applicationName: string
  bankName: BankNames
  bankNotes: string
  bankContactOwnershipPercents: number
  clientNotes: string
  currentStep: number
  contactPersonalAddressLabel: string
  // for Shore
  conditionPercentOwnerSsn?: number
  availableSteps: number[]
  companies: WebPageCompanyItem[]
  documentsUploading: DocumentsUploading[]
  isAnticipatedMonthlyActivityNAFormEnabled: boolean
  isAvailServiceOnlineBankingDescEnable: boolean
  isBusinessTypeConsumerAccountOptionEnabled: boolean
  isContactBioFieldEnabled: boolean
  isContactConductorRoleFieldEnabled: boolean
  isContactHomePhoneFieldEnabled: boolean
  isContactMobileFieldEnabled: boolean
  isContactOtherMjRelatedBusinessFieldEnabled: boolean
  isContactOwnerFieldRequired: boolean
  isContactOwnerWithPercentFieldRequired: boolean
  isLicenseMetrcAPIKeyFieldEnabled: boolean
  isIsolatedApplicationFromEntities: boolean
  isPageEditEnable: boolean
  isQuestionnaireATMAdditionalHintEnabled: boolean
  isQuestionnaireAnticipatedActivityAchOriginatingFieldRequired: boolean
  isQuestionnaireAnticipatedActivityAdditionalAgreementTextEnabled: boolean
  isQuestionnaireAvailableServicesBusinessSavingsFieldEnabled: boolean
  isQuestionnaireAvailableServicesDescFieldEnabled: boolean
  isAvailServiceHowManyAccountsIsOpeningFieldEnabled: boolean
  isQuestionnaireProPartCurrentServicesOtherDescConditionsEnabled: boolean
  isQuestionnaireProPartCurrentServicesOtherDescFieldEnabled: boolean
  isAvailServiceHowManyAccountsIsOpeningEnable: boolean
  isProPartInternetGamblingEnable: boolean
  isContactsForeignConnectionsEnable: boolean
  licenseTypesValues: FilterValues[]
  licenseSubTypesValues: FilterValues[]
  licensePOSTypesValues: FilterValues[]
  businessTypesValues: FilterValues[]
  entityTypesValues: FilterValues[]
  shouldCustomerAcknowledgementDialogBeShown: boolean
  showOwnerBirthdayAndSsn: boolean
  showOwnerPhysicalAddress: boolean
  showOwnerMailingAddress: boolean
  showAccountSignerMailingAddress: boolean
  showOwnerSsn: boolean
  showOwnerBirthday: boolean
  isCryptocurrencyTransactionsFieldEnabled: boolean
  isRealTimePaymentServicesFieldEnabled: boolean
  businessTypesDescription: BusinessTypesDescription[]
  documentSign: {
    title: string
    description: string
  }
  isSubmitRFIFormEnabled: boolean
  updatedAt: string
}
type SaveRFIFormPayloadCompanyItem = CompanyInformation &
  AccountDetails &
  CompanyPhysicalAddress &
  CompanyMailingAddress & {
    licenses: WebPageLicenseItem[]
    contacts: WebPageContactItem[]
    questionnaire: Questionnaire &
      ProfessionalPartnership &
      ATMGeneralInfo &
      MerchantQuestionnaire &
      AvailableServices &
      AnticipatedMonthlyActivity &
      AnticipatedMonthlyActivityNA & {
        ATMs: WebPageATMItem[]
      }
  }

export type OnboardingRFIFormSavePayload = {
  id: number
  rfiInfo: {
    relationships: SaveRFIFormPayloadCompanyItem[]
  }
  updatedAt: string
}
