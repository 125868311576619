import { FormInputProps } from 'brief-form'
import { ChoiceValue } from 'ui/types'

import { Item } from './Item'

export type MultiSelectAutoSuggestOpts = {
  options?: Item[] // data
  valueTitle: string // placeholder
  placeholder?: string
  type?: string // obsolete
  equalCondition?: (optionValue: ChoiceValue, el: ChoiceValue) => any // obsolete

  // Options describing fetching items from server (limits, filters etc).
  serverOptions?: {
    // API url to fetch items from.
    url: string

    // Mapping function to transform server entity to select option element.
    mapper: (item: any) => Item

    // Optional auto suggest length limit.
    limit?: number

    // Optional filters.
    filters?: {
      field: string
      type: string
      value: ChoiceValue
    }[]

    // Entity field to compare with search term.
    searchFields?: string[]

    // Method to fetch data.
    method?: 'post' | 'get'
  }

  apiAxios?: any // obsolete

  'data-qa-input'?: string
  'data-qa'?: string
}

export interface MultiSelectAutoSuggestProps extends FormInputProps<ChoiceValue[], MultiSelectAutoSuggestOpts> {}
