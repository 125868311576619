import React, { FC, memo } from 'react'

import { mergeArrayToString } from 'commons/utils/mergeArrayToString'
import { Page, PageFace } from 'ui/components/Page'

import { ContactCompanies } from '../../../../containers/ContactCompanies'
import { ContactDetailsPageProps } from './ContactDetailsPageProps'
import { ContactDocuments, ContactHistory, Information } from './components'

export const ContactDetailsPage: FC<ContactDetailsPageProps> = memo((props) => {
  const { data, invalidate, historyTableConfig, documentsTableConfig } = props
  const { history, historyTotalCount } = data
  const subTitle = data ? mergeArrayToString(' ', [data.firstName, data.lastName]) : '...'

  return (
    <Page title="Contact" subTitle={subTitle} face={PageFace.SECONDARY}>
      <Information data={data} />
      <ContactCompanies data={data} invalidate={invalidate} />
      <ContactDocuments
        data={data}
        invalidate={invalidate}
        config={documentsTableConfig}
        contactName={`${data.firstName} ${data.lastName}`}
      />
      <ContactHistory
        initialCompanyHistory={history}
        initialCompanyHistoryTotalCount={historyTotalCount}
        config={historyTableConfig}
      />
    </Page>
  )
})
