import React from 'react'

import { BSAIdentifierInput, FF, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const PriorReportBSAId = () => {
  const { formApi } = useSARFormContext()
  const filingType = formApi.useStore((s) => s.values.filing_type)

  return (
    <formApi.Field
      name="prior_report_bsa_id"
      validators={{
        onChange: ({ value, fieldApi }) => {
          const { filing_type } = fieldApi.form.state.values
          return filing_type.includes('filing_type_correct_amend') || filing_type.includes('filing_type_continuing')
            ? Validators.required.field(value)
            : undefined
        }
      }}
      children={(field) => (
        <FF
          required={!filingType.includes('filing_type_initial')}
          label="Prior report BSA Identifier"
          field={field}
          input={(p) => (
            <BSAIdentifierInput {...p} maxLength={14} disabled={filingType.includes('filing_type_initial')} />
          )}
        />
      )}
    />
  )
}
