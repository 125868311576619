import { useMutation, useQueryClient } from 'react-query'

import { getRouteApi } from '@tanstack/react-router'
import { BSACTRStatus } from 'commons/types/enums'
import { Toast } from 'ui/components/Toast'
import { CTREditRequest } from '~bank-bsa-reporting/types'

import * as Service from '../../service'

const routeApi = getRouteApi('/bsa/ctrs/$id/edit')

export const useSave = (callbackOnError?: (params: { string: [] }) => void, noUpdate?: boolean) => {
  const queryClient = useQueryClient()
  const navigate = routeApi.useNavigate()

  return useMutation((item: CTREditRequest) => Service.CTR.save(item), {
    onError: ({ message, meta, httpCode }: { message: string; meta?: { string: [] }; httpCode?: number }, item) => {
      let errorMessage = message
      if (item.generalInfo.status === BSACTRStatus.APPROVED && httpCode === 400) {
        errorMessage = 'The CTR contains errors and can’t be approved.'
      }
      Toast.errorSmall(errorMessage)
      callbackOnError && meta && callbackOnError(meta)
    },
    onSuccess: async (data: any, variables: CTREditRequest) => {
      const id = data?.id ?? variables.id

      if (!noUpdate) {
        Toast.successSmall(`The CTR was updated successfully`)
        navigate({ to: '/bsa/ctrs/$id', params: { id: id.toString() } })
        await queryClient.invalidateQueries(['ctrs', id])
      }
    }
  })
}
