import React, { useCallback, useState } from 'react'

import { useRouter } from '@tanstack/react-router'
import { Button } from 'ui/components/Buttons'
import { TextareaInput } from 'ui/components/InputV2'
import { LeaveConfirmation } from 'ui/components/LeaveConfirmation'
import { Toast } from 'ui/components/Toast'

import { useAddNotes } from '../../../../hooks/useAddNotes'
import { ContactWithTheApplicantProps } from './ContactWithTheApplicantProps'

import * as SC from './styles'

export const ContactWithTheApplicant = ({ application }: ContactWithTheApplicantProps) => {
  const {
    notesForApplicant,
    notesFromApplicant,
    notesBankInternal,
    enableNotesForApplicantModification,
    enableInternalNotesModification,
    applicationID
  } = application

  const router = useRouter()
  const { addNotes } = useAddNotes()
  const [notesForTheApplicant, setNotesForTheApplicant] = useState<string>(notesForApplicant)
  const [internalNotes, setInternalNotes] = useState<string>(notesBankInternal)

  const isNotesSaved = notesForTheApplicant === notesForApplicant
  const isInternalNotesSaved = internalNotes === notesBankInternal

  const onChangeNotesForTheApplicant = useCallback(
    (value) => {
      setNotesForTheApplicant(value)
    },
    [setNotesForTheApplicant]
  )

  const onChangeInternalNotes = useCallback(
    (value) => {
      setInternalNotes(value)
    },
    [setInternalNotes]
  )

  const onSaveNotes = useCallback(
    async (saveInternal: boolean) => {
      const body = { id: applicationID }

      if (saveInternal) {
        body['internalBankNotes'] = internalNotes
      } else {
        body['bankNotes'] = notesForTheApplicant
      }
      try {
        await addNotes(body)
        Toast.success(saveInternal ? 'Internal Bank Notes has been saved.' : 'Bank Notes has been saved.')
        router.invalidate()
      } catch (error) {
        Toast.error(saveInternal ? 'Cannot save internal bank notes.' : 'Cannot save bank notes.')
      }
    },
    [addNotes, applicationID, internalNotes, notesForTheApplicant, router]
  )

  return (
    <LeaveConfirmation preventLeaving={!isInternalNotesSaved || !isNotesSaved}>
      <section id="contact">
        <SC.Contact>
          <SC.Header>Contact with the Applicant</SC.Header>
          <SC.Label>Applicant&apos;s notes</SC.Label>
          <SC.ClientNotes>{notesFromApplicant || 'No notes.'}</SC.ClientNotes>
          <SC.CustomHr />
          <SC.Label>Notes for the Applicant</SC.Label>
          <SC.NotesWrapper>
            {!enableNotesForApplicantModification && (
              <SC.ClientNotes>{notesForTheApplicant || 'No Notes'}</SC.ClientNotes>
            )}
            {enableNotesForApplicantModification && (
              <TextareaInput
                value={notesForTheApplicant}
                onChange={onChangeNotesForTheApplicant}
                disabled={!enableNotesForApplicantModification}
              />
            )}
          </SC.NotesWrapper>

          {enableNotesForApplicantModification && (
            <Button
              face="positive"
              disabled={isNotesSaved || !enableNotesForApplicantModification}
              onClick={() => onSaveNotes(false)}
            >
              Save
            </Button>
          )}
          <SC.CustomHr $marginTop={true} />
          <SC.Label>Internal Notes (only visible to Bank)</SC.Label>
          <SC.NotesWrapper>
            {!enableInternalNotesModification && <SC.ClientNotes>{internalNotes || 'No Notes'}</SC.ClientNotes>}
            {enableInternalNotesModification && (
              <TextareaInput value={internalNotes} onChange={onChangeInternalNotes} />
            )}
          </SC.NotesWrapper>
          {enableInternalNotesModification && (
            <Button face="positive" disabled={isInternalNotesSaved} onClick={() => onSaveNotes(true)}>
              Save
            </Button>
          )}
        </SC.Contact>
      </section>
    </LeaveConfirmation>
  )
}
