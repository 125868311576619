import styled from 'styled-components'
import { Icon, Layer } from 'ui/components'
import { CSS } from 'ui/helpers'

export const WrappedLayer = styled(Layer)`
  overflow-x: auto;
  display: block;
  margin-bottom: 20px;
  width: 100%;
`

export const Title = styled.h5`
  padding: 10px 20px;
  font-weight: 600;
`

export const SubTitle = styled.span`
  font-size: 0.78em;
`

export const Columns = styled.div`
  display: flex;
`

export const Labels = styled.div`
  background: ${(p) => p.theme.uiKit.colors.global.grey.grey900};
  color: ${(p) => p.theme.uiKit.colors.global.general.main};
  border-right: 1px solid ${(p) => p.theme.uiKit.colors.global.general.main};
  padding: 72px 0 0 0;
`

export const Label = styled.div`
  height: 34px;
  padding: 9px 13px;
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyM.semiBold)};
  white-space: nowrap;
`

export const Table = styled.table`
  border-collapse: collapse;
`

export const Th = styled.th<{ $bottomBorder?: boolean; $forceRightBorder?: boolean }>`
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyM.semiBold)};
  color: ${(p) => p.theme.uiKit.colors.global.general.main};
  background: ${(p) => p.theme.uiKit.colors.global.grey.grey900};
  padding: 8px 30px;
  border-right: 1px solid ${(p) => p.theme.uiKit.colors.global.general.main};
  ${(p) => (p.$bottomBorder ? 'border-bottom: 1px solid #fff' : '')};

  &:last-child {
    ${(p) => (!p.$forceRightBorder ? `border-right: none;` : ``)};
  }
`

export const Th2 = styled.th`
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyS.semiBold)};
  color: ${(p) => p.theme.uiKit.colors.global.grey.grey700};
  background: ${(p) => p.theme.uiKit.colors.global.grey.grey300};
  padding: 10px 40px;
  border-right: 1px solid ${(p) => p.theme.uiKit.colors.global.general.main};

  &:last-child {
    border-right: none;
  }
`

export const Td = styled.td<{ $alerted?: boolean }>`
  ${(p) => CSS.populateFont(p.theme.uiKit.typography.bodyM.regular)};
  padding: 9px 16px;
  text-align: center;
  border-right: 1px solid ${(p) => p.theme.uiKit.colors.global.grey.grey100};
  background: ${(p) => (p.$alerted ? p.theme.uiKit.colors.theme.negative.main : 'transparent')};

  &:last-child {
    border-right: none;
  }
`

export const Alert = styled.span`
  display: flex;
  align-items: center;
`

export const WarningAlertIcon = styled(Icon)`
  margin-right: 6px;
  svg {
    fill: ${(p) => p.theme.uiKit.colors.theme.warning.main};
  }
`

export const DangerAlertIcon = styled(Icon)`
  margin-right: 6px;
  svg {
    fill: ${(p) => p.theme.uiKit.colors.theme.negative.main};
  }
`

export const PrimaryIcon = styled(Icon)`
  vertical-align: inherit;
  svg {
    width: 22px;
    height: 22px;
    color: ${(p) => p.theme.uiKit.colors.theme.brand.primary};
  }
`
