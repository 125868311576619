export class SalesDataSourceWebPagePayload {
  salesDataSourceId = 0
  uploadedFilesLimit = 0
  uploadedFilesOffset = 0
  uploadedFilesSortBy = ''
  uploadedFilesSortDesc = true
  historyOffset = 0
  historyLimit = 10

  constructor(salesDataSourceId?: number) {
    this.salesDataSourceId = salesDataSourceId || 0
  }
}
