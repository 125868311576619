import React, { FC, memo, useCallback, useContext, useMemo, useState } from 'react'

import { getRouteApi } from '@tanstack/react-router'
import { TableDataQa } from 'commons/types/enums'
import { DataSourceItem, Table } from 'ui/components'
import { BSAPaths } from '~bank-bsa-reporting/constants'
import { CTRBatch } from '~bank-bsa-reporting/types/CTRBatches/CTRBatch'
import { CTRBatchesResponse } from '~bank-bsa-reporting/types/CTRBatches/CTRBatchesResponse'

import { CTRBatchesChosenItemsContext } from '../../context/CTRBatchesChosenItemsContext'
import { useCTRBatchDeleteConfirmation } from '../../hooks/useCTRBatchDeleteConfirmation'
import { columns } from './columns'
import { BatchView } from './components/BatchView'

const routeApi = getRouteApi(BSAPaths.BSA_CTR_BATCHES_LIST)

export const BSABatchesTable: FC = memo(() => {
  const navigate = routeApi.useNavigate()

  const { chosenItemsIdList, onToggleChooseItem, chooseSomeItems, resetChosenItemsList } =
    useContext(CTRBatchesChosenItemsContext)

  const items = routeApi.useLoaderData<CTRBatchesResponse['items']>({ select: (s) => s?.items })
  const total = routeApi.useLoaderData<CTRBatchesResponse['total']>({ select: (s) => s?.total })
  const { page, size } = routeApi.useSearch({ select: (s) => ({ page: s.page, size: s.size }) })
  const isFetching = routeApi.useMatch({ select: (s) => Boolean(s.isFetching) })

  const { DeleteConfirmation, openDeleteConfirmation } = useCTRBatchDeleteConfirmation()

  const [modalItem, setModalItem] = useState<CTRBatch | null>(null)

  const mappedItems: DataSourceItem<CTRBatch>[] = useMemo(() => items.map((item) => ({ item })), [items])

  const isAllItemsChosen: boolean = useMemo(() => {
    let f = true
    for (const i of items) {
      const { id } = i
      if (!chosenItemsIdList.includes(id)) {
        f = false
        break
      }
    }
    return f
  }, [chosenItemsIdList, items])

  const toggleAllItemsChoosen = useCallback(() => {
    if (isAllItemsChosen) {
      resetChosenItemsList()
    } else {
      chooseSomeItems(items)
    }
  }, [isAllItemsChosen, items, chooseSomeItems, resetChosenItemsList])

  return (
    <>
      {modalItem && <BatchView item={modalItem} onClose={() => setModalItem(undefined)} />}
      <DeleteConfirmation />
      <Table
        loading={isFetching}
        dataQa={TableDataQa.BP_BATCH_LIST}
        dataSource={mappedItems}
        columns={columns({
          onShowBatch: setModalItem,
          openDeleteConfirmation,
          chosenItemsIdList,
          isAllItemsChosen,
          onToggleChooseItem,
          toggleAllItemsChoosen
        })}
        pagination={{
          page,
          pageSize: size,
          onChange: (page, size) => navigate({ search: { page, size }, resetScroll: false }),
          total: total
        }}
      />
    </>
  )
})
