import React, { memo } from 'react'

import { Link as RouteLink } from '@tanstack/react-router'
import { TableDataQa } from 'commons/types/enums'
import { IconName, IconSize, Page, PageFace, PanelFace, Spinner, SpinnerSize } from 'ui/components'
import { Table } from 'ui/components/Table'

import { SARsInfo } from '../components/SARsInfo'
import { columns } from './columns'
import { FilterForm, Footer } from './components'
import { SARListItem } from './types'
import { useData } from './useData'

import * as SC from './styles'

const SARBatchList = memo(() => {
  const {
    isFetching,
    data,
    tableState,
    sarItemForInfoView,
    chosenSarItems,
    DeleteConfirmation,
    setSarItemForInfoView,
    startDeleting,
    handleSarItemSelect,
    handleDeleteFromTableMenu,
    handleClear,
    handlePagination
  } = useData()

  if (!data) {
    return <Spinner centered size={SpinnerSize.M} />
  }

  return (
    <Page
      face={PageFace.PRIMARY}
      title={
        <SC.Title>
          <RouteLink to="/bsa">BSA Reporting</RouteLink>
          <SC.Arrow name={IconName.ARROW_SMALL} size={IconSize.XS} />
          SAR Batch List
        </SC.Title>
      }
    >
      <DeleteConfirmation />
      <SARsInfo item={sarItemForInfoView} onClose={() => setSarItemForInfoView(undefined)} />
      <SC.TablePanel rounded shadowed collapsible={false} face={PanelFace.SECONDARY}>
        <FilterForm form={tableState.filterForm} optionStatus={data.statusOptions} onClear={handleClear} />
        <Table<SARListItem>
          dataQa={TableDataQa.BP_BATCH_SAR_LIST}
          columns={columns(chosenSarItems, data, setSarItemForInfoView, handleSarItemSelect, handleDeleteFromTableMenu)}
          dataSource={data.items.map((el) => ({
            item: el
          }))}
          loading={isFetching}
          pagination={{
            page: tableState.page,
            pageSize: tableState.size,
            onChange: handlePagination,
            total: data.total
          }}
        />
      </SC.TablePanel>

      <Footer onDeleteBatches={startDeleting} hasChosenItems={!!chosenSarItems.length} />
    </Page>
  )
})

export default SARBatchList
