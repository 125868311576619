import { useCallback, useMemo, useState } from 'react'
import { ReadHook, useStateManager } from 'react-query-state-manager'

import { notFound } from '@tanstack/react-router'
import { Request } from 'commons/utils/request'
import { isEqual } from 'lodash'
import { TableSortingChangeHandler, TableSortingOrder } from 'ui/components'

import { ContactDetailsRequest, ContactDetailsResponse } from '../types'

const CONTACT_DETAILS_WEBPAGE_KEY = 'get-contact'

const request = (opts: ContactDetailsRequest) =>
  Request.post<ContactDetailsResponse, ContactDetailsRequest>(Request.urls.bank.contactDetailsWebPage, opts)

const useGetContact: ReadHook<ContactDetailsResponse, ContactDetailsRequest> = (opts, callbacks) =>
  useStateManager().use([CONTACT_DETAILS_WEBPAGE_KEY, opts], () => request(opts), {
    keepPreviousData: true,
    onSuccess: callbacks.onSuccess,
    onError: callbacks.onError
  })

const defaultFilter = (id: number): ContactDetailsRequest => ({
  docFilter: {
    alerts: '',
    frequency: 'all',
    internal: null,
    page: 1,
    size: 10,
    sortBy: '',
    sortDesc: true
  },
  historyPage: 1,
  historySize: 10,
  id
})

export const useGetContactDetails = (id: number) => {
  const [filter, setFilter] = useState<ContactDetailsRequest>(defaultFilter(id))
  const [updateCaller, setUpdateCaller] = useState<'' | 'documents' | 'history'>('')

  const resetPending = useCallback(() => setUpdateCaller(''), [])

  const { isFetching, data, isFetched, invalidate } = useGetContact(filter, {
    onSuccess: resetPending,
    onError: resetPending
  })

  if ((isFetched && !data) || !id) {
    throw notFound()
  }

  const onDocumentsFilterChange = useCallback(
    (docFilter: ContactDetailsRequest['docFilter']) => {
      if (!isEqual(docFilter, filter.docFilter)) {
        setFilter({ ...filter, docFilter })
        setUpdateCaller('documents')
      }
    },
    [filter]
  )

  const onOrderChange: TableSortingChangeHandler = useCallback(
    (value) => {
      setFilter({
        ...filter,
        docFilter: { ...filter.docFilter, sortBy: value.field, sortDesc: value.direction === 'DESC' }
      })
      setUpdateCaller('documents')
    },
    [filter]
  )

  const order: TableSortingOrder = useMemo(
    () => ({
      field: filter.docFilter?.sortBy ?? '',
      direction: filter.docFilter?.sortDesc ? 'DESC' : 'ASC'
    }),
    [filter.docFilter]
  )

  const onHistoryFilterChange = useCallback(
    (page: number, size: number) => {
      if (page !== filter.historyPage || size !== filter.historySize) {
        setFilter({ ...filter, historyPage: page, historySize: size })
        setUpdateCaller('history')
      }
    },
    [filter]
  )

  const historyTableConfig = useMemo(
    () => ({
      isFetching: updateCaller === 'history',
      filter: {
        page: filter.historyPage,
        size: filter.historySize
      },
      onFilterChange: onHistoryFilterChange
    }),
    [filter, onHistoryFilterChange, updateCaller]
  )

  const documentsTableConfig = useMemo(
    () => ({
      onOrderChange,
      isFetching: updateCaller === 'documents',
      onFilterChange: onDocumentsFilterChange,
      order: [order]
    }),
    [onOrderChange, updateCaller, onDocumentsFilterChange, order]
  )

  return {
    isFetched,
    isFetching,
    data,
    invalidate,
    historyTableConfig,
    documentsTableConfig
  }
}
