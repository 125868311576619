import React from 'react'

import { useForm } from '@tanstack/react-form'
import { CreateApplicationRequest } from 'commons/types/DTO/bank/applications/manage/CreateApplicationRequest'
import { Keys, useStateManager } from 'state-manager'
import { FF } from 'ui/components'
import { Dialog, DialogFace, DialogFooterActions } from 'ui/components/Dialog'
import { Validators } from 'ui/components/Form/validators'
import { EmailInput, TextInput, TextareaInput, USPhoneInput } from 'ui/components/InputV2'
import { LeaveConfirmation } from 'ui/components/LeaveConfirmation'
import { Toast } from 'ui/components/Toast'
import { Col, Col2x, FormRow } from 'ui/themes/globalStyles'

import { useCreateApplication } from '../../../../hooks/useCreateApplication'
import CreateApplicationDialogProps from './CreateApplicationDialogProps'

import * as SC from './styles'

export const CreateApplicationDialog = ({ onClose }: CreateApplicationDialogProps) => {
  const sm = useStateManager()

  const { routine: createApplication } = useCreateApplication({
    onSuccess: async () => {
      Toast.success('Application has been saved')
      await sm.invalidate(Keys.BankApplication.List)
      onClose()
    },
    onError: () => {
      Toast.error('A trouble was occurred while saved')
    }
  })

  const form = useForm<CreateApplicationRequest>({
    defaultValues: {
      companyName: '',
      contactFirstName: '',
      contactLastName: '',
      email: '',
      phone: '',
      bankNotes: '',
      internalBankNotes: ''
    },
    onSubmit: async ({ value }) => {
      createApplication(value)
    }
  })

  return (
    <LeaveConfirmation preventLeaving={form.useStore((s) => s.isDirty && !s.isSubmitting && !s.isSubmitted)}>
      <Dialog
        id="create-application"
        onClose={onClose}
        face={DialogFace.PRIMARY}
        title="New Application"
        onSubmit={form.handleSubmit}
        footerActions={[DialogFooterActions.ADD, DialogFooterActions.CANCEL]}
        isSubmitting={form.useStore((s) => s.isSubmitting)}
      >
        <SC.ModalContent>
          <FormRow>
            <Col>
              <form.Field
                name="companyName"
                validators={{
                  onSubmit: ({ value }) => Validators.required.string(value)
                }}
                children={(companyNameField) => (
                  <FF
                    label="Company Name"
                    field={companyNameField}
                    required
                    input={(inputProps) => <TextInput autoComplete="off" data-qa="Company Name" {...inputProps} />}
                  />
                )}
              />
            </Col>
          </FormRow>

          <FormRow>
            <Col>
              <form.Field
                name="contactFirstName"
                children={(contactFirstNameField) => (
                  <FF
                    label="First Name"
                    field={contactFirstNameField}
                    input={(inputProps) => <TextInput data-qa="First Name" {...inputProps} />}
                  />
                )}
              />
            </Col>
            <Col>
              <form.Field
                name="contactLastName"
                validators={{
                  onSubmit: ({ value }) => Validators.required.string(value)
                }}
                children={(contactLastNameField) => (
                  <FF
                    label="Last Name"
                    field={contactLastNameField}
                    required
                    input={(inputProps) => <TextInput data-qa="Last Name" {...inputProps} />}
                  />
                )}
              />
            </Col>
          </FormRow>

          <FormRow>
            <Col>
              <form.Field
                name="email"
                validators={{
                  onChange: ({ value }) => Validators.email.requiredAndValid(value)
                }}
                children={(emailField) => (
                  <FF
                    label="Email"
                    field={emailField}
                    required
                    input={(inputProps) => <EmailInput data-qa="Email" {...inputProps} />}
                  />
                )}
              />
            </Col>
            <Col>
              <form.Field
                name="phone"
                children={(phoneField) => (
                  <FF
                    label="Phone"
                    field={phoneField}
                    input={(inputProps) => <USPhoneInput data-qa="Phone" {...inputProps} />}
                  />
                )}
              />
            </Col>
          </FormRow>

          <FormRow>
            <Col2x>
              <form.Field
                name="bankNotes"
                children={(bankNotesField) => (
                  <FF
                    label="Notes for the Applicant (visible for client)"
                    field={bankNotesField}
                    input={(inputProps) => <TextareaInput data-qa="Notes for the Applicant" {...inputProps} />}
                  />
                )}
              />
            </Col2x>
          </FormRow>

          <FormRow>
            <Col2x>
              <form.Field
                name="internalBankNotes"
                children={(internalBankNotesField) => (
                  <FF
                    label="Internal Notes (only visible to Bank)"
                    field={internalBankNotesField}
                    input={(inputProps) => <TextareaInput data-qa="Internal Notes" {...inputProps} />}
                  />
                )}
              />
            </Col2x>
          </FormRow>
        </SC.ModalContent>
      </Dialog>
    </LeaveConfirmation>
  )
}
