import React, { FC, memo, useEffect, useState } from 'react'

import { IconName } from '../Icon'
import { AccordionProps } from './props'

import * as SC from './styles'

export const Accordion: FC<AccordionProps> = memo((props) => {
  const { title, actions, expanded = false, children } = props

  const [isExpanded, setIsExpanded] = useState(expanded)

  useEffect(() => {
    setIsExpanded(expanded)
  }, [expanded])

  return (
    <SC.Box>
      <SC.TitleBox onClick={() => setIsExpanded(!isExpanded)}>
        <SC.TitleArrow name={isExpanded ? IconName.EXPAND_LESS : IconName.EXPAND_MORE} />
        <SC.TitleText>{title}</SC.TitleText>
        <SC.TitleActions>{actions}</SC.TitleActions>
      </SC.TitleBox>
      <SC.ContentBox $expanded={isExpanded}>{children}</SC.ContentBox>
    </SC.Box>
  )
})
