import React from 'react'

import { Vendor } from 'commons/types/DTO/bank/companyDetails/vendor'
import { IconName, IconSize } from 'ui/components/Icon'
import { TableColumn } from 'ui/components/Table'
import { Tooltip } from 'ui/components/Tooltip'

import * as SC from './styles'

export const columns: TableColumn<Vendor>[] = [
  {
    title: 'Name',
    render: ({ name }) => (
      <SC.VendorName>
        {name}
        <Tooltip id={`shared${name}`} type="dark" content="Shared" event="hover">
          <SC.WrappedIcon name={IconName.SHARED} size={IconSize.XS} />
        </Tooltip>
      </SC.VendorName>
    )
  }
]
