const frequencyLabelMap: { [key: string]: string } = {
  annual: 'Annual',
  'one-time': 'One-time',
  'semi-annual': 'Semi-annual',
  every_2_years: 'Every 2 Years',
  every_5_years: 'Every 5 Years',
  every_18_months: 'Every 18 Months',
  monthly: 'Monthly',
  quarterly: 'Quarterly'
}

export const getFrequencyLabel = (name: string) => {
  if (name === '') {
    return 'Manual Period'
  }
  return frequencyLabelMap[name] || name
}
