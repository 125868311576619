// docs/jZJPtP_id__b305038803e440c0aacf03c1cf8c1fd6.pdf -> jZJPtP_id__b305038803e440c0aacf03c1cf8c1fd6.pdf
// jZJPtP_id__b305038803e440c0aacf03c1cf8c1fd6.pdf -> b305038803e440c0aacf03c1cf8c1fd6.pdf
// invoice_BVkdzY_pngwing.com  20230328t230909.229.png -> pngwing.com  20230328t230909.229.png
export const extractFilenameFromPath = (filepath: string): string => {
  const filename = filepath.replace(/^.*[\\/]/, '')

  try {
    let filenameWoID = filename
    if (filename.indexOf('_id__') !== -1) {
      filenameWoID = filename.split('_id__')[1]
    } else if (filename.indexOf('invoice_') !== -1) {
      filenameWoID = filename.split(/invoice_[a-zA-Z0-9]+_/)[1]
    }
    return filenameWoID
  } catch {
    return filename
  }
}
