import { AnyRoute, createRoute, lazyRouteComponent } from '@tanstack/react-router'
import { NotFoundError } from 'commons/errors'
import * as z from 'zod'
import { getDocumentDetails } from '~bank-documents/service/documentDetails'

const sch = z.object({
  historyPage: z.number().int().positive().default(1),
  historySize: z.number().int().positive().default(10)
})

export const documentDetailsRoute = <T extends AnyRoute>(indexRoute: T) =>
  createRoute({
    getParentRoute: () => indexRoute,
    path: '$documentId',
    // for future versions on tanstack-router
    // params: {
    //   parse: (params) => ({
    //     documentId: z.number().int().parse(Number(params?.documentId))
    //   }),
    //   stringify: ({ documentId }) => ({ documentId: `${documentId}` })
    // },
    validateSearch: (search: Record<string, unknown>) => sch.parse(search),
    loaderDeps: (all) => ({ ...all.search }),
    beforeLoad: ({ params, search, ...all }) => {
      if ('documentId' in params) return { ...all, flatDeps: { id: Number(params.documentId), ...search } }
      else throw new NotFoundError()
    },
    loader: async ({ context: { flatDeps } }) =>
      await getDocumentDetails({
        id: flatDeps.id,
        historyPage: flatDeps.historyPage,
        historySize: flatDeps.historySize
      }),
    shouldReload: (p) => p.location.href !== p.route.path,
    component: lazyRouteComponent(() => import('./DocumentDetails'))
  })
