import { QueryClient, useMutation } from 'react-query'

import { handlerServerError } from 'errors'
import { Toast } from 'ui/components'

import * as Service from '../../service'

export const useDelete = (client?: QueryClient) =>
  useMutation((ids: number[]) => Promise.all(ids.map((id) => Service.Batch.remove({ id }))), {
    onError: (error) => handlerServerError(error),
    onSuccess: async () => {
      Toast.successSmall('Batch(es) was(were) successfully deleted.')
      if (client) await client.invalidateQueries(['tables', 'CTR Batch List'])
    }
  })
