/* eslint-disable camelcase */
import { BsaCtrTransactionForeignInfo } from './bsaCTRTransactionForeignInfo'

export type BsaCtrTransactionInfo = {
  cash_in_a?: number
  cash_in_b?: number
  cash_in_c?: number
  cash_in_d?: number
  cash_in_e?: number
  cash_in_f?: number
  cash_in_g?: number
  cash_in_h?: number
  cash_in_i?: number
  cash_in_total?: number
  cash_in_z?: number
  cash_in_z_type?: string
  cash_out_a?: number
  cash_out_b?: number
  cash_out_c?: number
  cash_out_d?: number
  cash_out_e?: number
  cash_out_f?: number
  cash_out_g?: number
  cash_out_h?: number
  cash_out_i?: number
  cash_out_j?: number
  cash_out_total?: number
  cash_out_z?: number
  cash_out_z_type?: string
  foreign_in?: BsaCtrTransactionForeignInfo[]
  foreign_out?: BsaCtrTransactionForeignInfo[]
  source_aggregated?: boolean
  source_armored?: boolean
  source_atm?: boolean
  source_mail?: boolean
  source_night?: boolean
  source_shared_branching?: boolean
  transaction_date?: string
}
