/* eslint-disable camelcase */
import { BsaSettingFilesParsingCIFConfig } from './bsaSettingFilesParsingCIFConfig'
import { BsaSettingFilesParsingTellerJournalConfig } from './bsaSettingFilesParsingTellerJournalConfig'
import { BsaSettingFilesParsingTransactionsConfig } from './bsaSettingFilesParsingTransactionsConfig'
import { BsaSettingFilesParsingDDAConfig } from './bsaSettingsFilesParsingDDAConfig'

export type BsaSettingFilesParsingConfig = {
  cif?: BsaSettingFilesParsingCIFConfig
  dda?: BsaSettingFilesParsingDDAConfig
  teller_journal?: BsaSettingFilesParsingTellerJournalConfig
  transactions?: BsaSettingFilesParsingTransactionsConfig
}
