import React from 'react'

import { CheckBoxGroup, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

export const Structuring = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      validators={{
        onChangeListenTo: [
          'transactions_info.terrorist_financing',
          'transactions_info.fraud',
          'transactions_info.laundering',
          'transactions_info.identification',
          'transactions_info.other_activities',
          'transactions_info.gaming',
          'transactions_info.cyber',
          'transactions_info.insurance',
          'transactions_info.mortgage',
          'transactions_info.securities'
        ],
        onChange: () => {
          let meta = formApi.getFieldMeta('transactions_info.structuring')
          formApi.setFieldMeta('transactions_info.structuring', { ...meta, errors: [], errorMap: {} })

          meta = formApi.getFieldMeta('transactions_info.terrorist_financing')
          formApi.setFieldMeta('transactions_info.terrorist_financing', { ...meta, errors: [], errorMap: {} })

          meta = formApi.getFieldMeta('transactions_info.fraud')
          formApi.setFieldMeta('transactions_info.fraud', { ...meta, errors: [], errorMap: {} })

          meta = formApi.getFieldMeta('transactions_info.laundering')
          formApi.setFieldMeta('transactions_info.laundering', { ...meta, errors: [], errorMap: {} })

          meta = formApi.getFieldMeta('transactions_info.identification')
          formApi.setFieldMeta('transactions_info.identification', { ...meta, errors: [], errorMap: {} })

          meta = formApi.getFieldMeta('transactions_info.other_activities')
          formApi.setFieldMeta('transactions_info.other_activities', { ...meta, errors: [], errorMap: {} })

          meta = formApi.getFieldMeta('transactions_info.gaming')
          formApi.setFieldMeta('transactions_info.gaming', { ...meta, errors: [], errorMap: {} })

          meta = formApi.getFieldMeta('transactions_info.cyber')
          formApi.setFieldMeta('transactions_info.cyber', { ...meta, errors: [], errorMap: {} })

          meta = formApi.getFieldMeta('transactions_info.insurance')
          formApi.setFieldMeta('transactions_info.insurance', { ...meta, errors: [], errorMap: {} })

          meta = formApi.getFieldMeta('transactions_info.mortgage')
          formApi.setFieldMeta('transactions_info.mortgage', { ...meta, errors: [], errorMap: {} })

          meta = formApi.getFieldMeta('transactions_info.securities')
          formApi.setFieldMeta('transactions_info.securities', { ...meta, errors: [], errorMap: {} })
        }
      }}
      name="transactions_info.structuring"
      children={(field) => (
        <FF
          field={field}
          label="32. Structuring"
          input={(p) => (
            <CheckBoxGroup
              {...p}
              data-qa={'32. Structuring'}
              direction={'vertical'}
              data={[
                { label: 'a. Alters or cancels transaction to avoid BSA record keeping requirement', value: 'a' },
                { label: 'b. Alters or cancels transaction to avoid CTR requirement', value: 'b' },
                { label: 'c. Suspicious inquiry by customer', value: 'c' },
                { label: 'd. Transaction(s) below BSA record keeping threshold', value: 'd' },
                { label: 'e. Transaction(s) below CTR threshold', value: 'e' },
                { label: 'z. Other', value: 'z' }
              ]}
            />
          )}
        />
      )}
    />
  )
}
