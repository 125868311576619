import React, { FC, KeyboardEvent, memo } from 'react'

import { ButtonGroup } from 'ui/components/ButtonGroup'
import { IconButton, OutlineButton } from 'ui/components/Buttons'
import { DateTime } from 'ui/components/DateTime'
import { IconName } from 'ui/components/Icon'

import CheckboxContainer from '../CheckboxContainer'
import { PublicationProps } from './PublicationProps'

import * as SC from './styles'

export const Publication: FC<PublicationProps> = memo(
  ({ value, reviewAction, onSelectPublication, isPublicationSelected, onOpenPublication, onOpenNotes, disabled }) => {
    const { needs_review: needsReview, notes, id } = value
    const isExternalPublication = value.content_type === 'url'
    const hasNotes = notes ? notes.length > 0 : false

    const openPublication = () => {
      onOpenPublication(value)
    }

    const onPressPublication = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        openPublication()
      }
    }

    const onClickNotes = () => {
      onOpenNotes(value)
    }

    const onReviewActionClick = () => {
      reviewAction(value)
    }

    const handleSelectPublication = () => {
      onSelectPublication(id)
    }

    const RewiewedPublicationWrapper = needsReview ? SC.PublicationWrapper : SC.PublicationRewiewedWrapper

    return (
      <RewiewedPublicationWrapper>
        <SC.WrappedCheckBoxContainer>
          <CheckboxContainer />
        </SC.WrappedCheckBoxContainer>
        <SC.Header>
          {!!value.publication_date && (
            <SC.DateTimeWrapper>
              Publication Date <DateTime date={value.publication_date} />
            </SC.DateTimeWrapper>
          )}
          <ButtonGroup margin="big">
            {hasNotes ? (
              <IconButton face="positive" icon={IconName.DIALOG_FILLED} onClick={onClickNotes} disabled={disabled}>
                Notes
              </IconButton>
            ) : (
              <IconButton face="positive" icon={IconName.DIALOG} onClick={onClickNotes} disabled={disabled}>
                Notes
              </IconButton>
            )}
            {needsReview ? (
              <OutlineButton onClick={onReviewActionClick} face="positive" disabled={disabled}>
                Review
              </OutlineButton>
            ) : (
              <OutlineButton icon={IconName.CHECK} face="neutral" onClick={onReviewActionClick} disabled={disabled}>
                Reviewed
              </OutlineButton>
            )}

            {needsReview && (
              <SC.WrappedCheckBox
                checkBoxLabel=""
                value={isPublicationSelected}
                onChange={handleSelectPublication}
                disabled={disabled}
              />
            )}
          </ButtonGroup>
        </SC.Header>
        {isExternalPublication ? (
          <>
            <SC.TitleLink onClick={disabled ? undefined : openPublication}>
              <SC.WrappedIcon name={IconName.LINK_GREEN} /> {value.title}
            </SC.TitleLink>
            {value.snippet && <SC.PublicationPreview>{value.snippet}</SC.PublicationPreview>}
          </>
        ) : (
          <>
            <SC.Title
              tabIndex={0}
              onClick={disabled ? undefined : openPublication}
              onKeyPress={disabled ? undefined : onPressPublication}
            >
              {value.title}
            </SC.Title>
            {value.snippet && <SC.PublicationPreview>{value.snippet}</SC.PublicationPreview>}
            {value.rationale && (
              <SC.PublicationPreview>
                <b>Rationale: </b>
                {value.rationale}
              </SC.PublicationPreview>
            )}
          </>
        )}
      </RewiewedPublicationWrapper>
    )
  }
)
