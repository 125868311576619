import styled from 'styled-components'
import { Icon } from 'ui/components/Icon'

const AddIcon = styled(Icon)`
  margin-top: 7px;
  margin-left: 9px;
  cursor: pointer;
`

const DeleteIcon = styled(Icon)`
  margin-top: 7px;
  margin-left: 9px;
  cursor: pointer;
  fill: ${(p) => p.theme.uiKit.colors.global.grey.grey500};
`

const Inputs = styled.div`
  display: flex;
  width: 500px;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  & > div {
    width: 320px;
  }
`
export const s = { AddIcon, DeleteIcon, Inputs }
