/* eslint-disable camelcase */

export type BsaCtrTransactionLocationInfo = {
  address?: string
  cash_in?: number
  cash_out?: number
  city?: string
  country?: string
  dba?: string
  ein?: string
  federal_regulator?: string
  id_number?: string
  id_type?: string
  legal_name?: string
  state?: string
  type?: string
  type_other?: string
  zip_code?: string
}
