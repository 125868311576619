/* eslint-disable camelcase */

export type BsaSettingFilesParsingCIFConfigColumnIndexes = {
  attentions?: number
  birthdate?: number
  branch?: number
  business_type?: number
  cif?: number
  email_1?: number
  email_2?: number
  entity_type?: number
  gender?: number
  id_form?: number
  id_form_other?: number
  id_number?: number
  id_state?: number
  multi_name_address_1?: number
  multi_name_address_2?: number
  multi_name_address_3?: number
  multi_name_physical?: number
  naics?: number
  name?: number
  phone?: number
  postal_code?: number
  tin?: number
  tin_type?: number
}
