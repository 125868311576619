import { ContactMetadata } from '../../../../types/contact'

export type ContactMetadataManageDialogProps = {
  isOpen: boolean
  onClose: () => void
  onSubmit: (metadata: ContactMetadata, isCreating: boolean) => void
  metadata?: ContactMetadata
  companyId?: number
  contactId: number
  contactName: string
  isSuccess: boolean
  isFetching: boolean
}
