import React, { FC, memo, useEffect } from 'react'

import { useRouterState } from '@tanstack/react-router'

import { LeaveConfirmationContext } from './LeaveConfirmationContext'
import { LeaveConfirmationProps } from './LeaveConfirmationProps'
import { useLeaveConfirmation } from './hooks/useLeaveConfirmation'

export const LeaveConfirmation: FC<LeaveConfirmationProps> = memo((props: LeaveConfirmationProps) => {
  const { preventLeaving, children, onLeave, ignoreUrlHashChanging } = props
  const { LeaveConfirmation, showLeaveConfirmation } = useLeaveConfirmation(preventLeaving)
  const location = useRouterState({ select: (s) => s.location })

  useEffect(() => {
    if (!ignoreUrlHashChanging && onLeave) {
      onLeave()
    }
  }, [location, onLeave])

  return (
    <LeaveConfirmationContext.Provider value={{ showLeaveConfirmation }}>
      <LeaveConfirmation />
      {children}
    </LeaveConfirmationContext.Provider>
  )
})
