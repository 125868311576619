import React, { FC, memo, useEffect, useState } from 'react'
import { useStateManager } from 'react-query-state-manager'

import { getRouteApi, notFound } from '@tanstack/react-router'
import { CompanyDetailsWebPage } from 'commons/types/DTO/bank'
import { Keys } from 'state-manager'
import { Spinner, SpinnerSize } from 'ui/components/Spinner'

import { ClientPortalInviteDialog } from './components/ClientPortalInviteDialog'
import { CompanyDetailsPage } from './components/CompanyDetailsPage'
import { useClientPortalInvite, useGetCompanyDetailsWebPage, useSetCompanyOwners } from './hooks'

import { SpinnerBox } from './styles'

const routeApi = getRouteApi('/relationships/$companyId')

const CompanyDetails: FC = memo(() => {
  const companyId = routeApi.useParams({ select: (s) => Number(s.companyId) })
  const sm = useStateManager()

  const { dialogVisible, openDialog, closeDialog, sendInvite, isLoading: isSendingInvite } = useClientPortalInvite()
  const [webPageData, setWebPageData] = useState<CompanyDetailsWebPage | undefined>(undefined)
  const { data, isFetched } = useGetCompanyDetailsWebPage(companyId)
  const { onSetCompanyOwners } = useSetCompanyOwners(() => sm.invalidate(Keys.CompanyDetails.WebPage(companyId)))

  useEffect(() => {
    setWebPageData(undefined)
    window.scrollTo(0, 0)
  }, [companyId])

  useEffect(() => {
    if (isFetched) {
      setWebPageData(data)
    }
  }, [isFetched, data])

  useEffect(() => {
    if (isFetched && !data) {
      throw notFound()
    }
  })

  if (!webPageData) {
    return (
      <SpinnerBox>
        <Spinner centered size={SpinnerSize.M} />
      </SpinnerBox>
    )
  }

  return (
    <>
      <ClientPortalInviteDialog
        onClose={closeDialog}
        visible={dialogVisible}
        isPending={isSendingInvite}
        companyName={webPageData?.name || ''}
        companyId={webPageData?.id || 0}
        onSubmit={sendInvite}
      />
      <CompanyDetailsPage
        webPageData={webPageData}
        onSetCompanyOwners={onSetCompanyOwners}
        onOpenClientPortalInviteDialog={openDialog}
        companyId={companyId}
      />
    </>
  )
})

export default CompanyDetails
