import styled from 'styled-components'
import { Hr } from 'ui/components/Hr'

const Inner = styled.div`
  padding: 30px 35px;
`

const ColumnTitle = styled.h4`
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
`

const FieldStub = styled.div`
  width: 1px;
  height: 97px;
`

const WrappedHr = styled(Hr)`
  margin-left: -35px;
  margin-right: -35px;
  margin-bottom: 15px;
`

const CheckBoxWrapper = styled.div<{ withLabel?: boolean }>`
  height: ${(p) => (p.withLabel ? 55 : 35)}px;
`

export const s = { Inner, ColumnTitle, FieldStub, WrappedHr, CheckBoxWrapper }
