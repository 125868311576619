import React, { memo } from 'react'

import { CheckBox } from 'ui/components/CheckBox'
import { formatDateTime } from 'ui/components/DateTime'
import { Info, InfoSet, InfoSetDirection } from 'ui/components/Info'
import { PageSection, PageSectionFace } from 'ui/components/Page'
import { Col, Col2x, Row } from 'ui/themes/globalStyles'

import * as SC from './styles'

interface Props {
  // item: Types.DTO.BsaSar;
  item: any
}

export const SuspiciousActivityInformation = memo(({ item }: Props) => {
  const start = formatDateTime(item?.trans_start_date)
  const end = formatDateTime(item?.trans_end_date)

  return (
    <PageSection face={PageSectionFace.THIRD} title="Step 4. Suspicious Activity Information" anchor="step4">
      <InfoSet direction={InfoSetDirection.COLUMN}>
        <Row>
          <Col>
            <Info label="29. Amount involved in this report">
              {item.transactions_info?.amount_unknown && 'Amount Unknown'}
              {item.transactions_info?.no_amount_involved && 'No Amount Involved'}
              {!item.transactions_info?.amount_unknown &&
                !item.transactions_info?.no_amount_involved &&
                item.transactions_info?.current_amount}
            </Info>
          </Col>
          <Col>
            <Info label="30. Date or date range of suspicious activity for this report">{`${start} - ${end}`}</Info>
          </Col>
        </Row>
        <Row>
          <Info label="31. Cumulative amount">{item.transactions_info?.current_cumulative_amount}</Info>
        </Row>
        <Row>
          <Info label="32. Structuring">
            <CheckBox
              value={item.transactions_info?.structuring?.a || false}
              checkBoxLabel="a. Alters or cancels transaction to avoid BSA record keeping requirement"
            />
            <CheckBox
              value={item.transactions_info?.structuring?.b || false}
              checkBoxLabel="b. Alters or cancels transaction to avoid CTR requirement"
            />
            <CheckBox
              value={item.transactions_info?.structuring?.c || false}
              checkBoxLabel="c. Suspicious inquiry by customer"
            />
            <CheckBox
              value={item.transactions_info?.structuring?.d || false}
              checkBoxLabel="d. Transaction(s) below BSA record keeping threshold"
            />
            <CheckBox
              value={item.transactions_info?.structuring?.e || false}
              checkBoxLabel="e. Transaction(s) below CTR threshold"
            />
            <CheckBox value={item.transactions_info?.structuring?.z || false} checkBoxLabel="z. Other" />
            <SC.Label>{item.transactions_info?.structuring_other}</SC.Label>
          </Info>
        </Row>
        <Row>
          <Info label="33. Terrorist Financing">
            <CheckBox
              value={item.transactions_info?.terrorist_financing?.a || false}
              checkBoxLabel="a. Known or suspected terrorist/terrorist organization"
            />
            <CheckBox value={item.transactions_info?.terrorist_financing?.z || false} checkBoxLabel="z. Other" />
            <SC.Label>{item.transactions_info?.terrorist_financing_other}</SC.Label>
          </Info>
        </Row>
        <Row>
          <Col2x>
            <Info label="34. Fraud">
              <SC.CheckBoxes>
                <CheckBox value={item.transactions_info?.fraud?.a || false} checkBoxLabel="a. ACH" />
                <CheckBox value={item.transactions_info?.fraud?.h || false} checkBoxLabel="h. Mail" />
              </SC.CheckBoxes>
              <SC.CheckBoxes>
                <CheckBox value={item.transactions_info?.fraud?.b || false} checkBoxLabel="b. Advance fee" />
                <CheckBox value={item.transactions_info?.fraud?.i || false} checkBoxLabel="i. Mass-marketing" />
              </SC.CheckBoxes>
              <SC.CheckBoxes>
                <CheckBox value={item.transactions_info?.fraud?.c || false} checkBoxLabel="c. Business loan" />
                <CheckBox value={item.transactions_info?.fraud?.j || false} checkBoxLabel="j. Ponzi scheme" />
              </SC.CheckBoxes>
              <SC.CheckBoxes>
                <CheckBox value={item.transactions_info?.fraud?.d || false} checkBoxLabel="d. Check" />
                <CheckBox value={item.transactions_info?.fraud?.k || false} checkBoxLabel="k. Pyramid scheme" />
              </SC.CheckBoxes>
              <SC.CheckBoxes>
                <CheckBox value={item.transactions_info?.fraud?.e || false} checkBoxLabel="e. Consumer loan" />
                <CheckBox value={item.transactions_info?.fraud?.l || false} checkBoxLabel="l. Securities fraud" />
              </SC.CheckBoxes>
              <SC.CheckBoxes>
                <CheckBox value={item.transactions_info?.fraud?.f || false} checkBoxLabel="f. Credit/Debit card" />
                <CheckBox value={item.transactions_info?.fraud?.m || false} checkBoxLabel="m. Wire" />
              </SC.CheckBoxes>
              <SC.CheckBoxes>
                <CheckBox
                  value={item.transactions_info?.fraud?.g || false}
                  checkBoxLabel="g. Healthcare/Public or private health insurance"
                />
                <CheckBox value={item.transactions_info?.fraud?.z || false} checkBoxLabel="z. Other" />
              </SC.CheckBoxes>
              <SC.CheckBoxes>
                <div />
                <SC.Label>{item.transactions_info?.fraud_other}</SC.Label>
              </SC.CheckBoxes>
            </Info>
          </Col2x>
        </Row>
        <Row>
          <Info label="36. Money Laundering">
            <CheckBox
              value={item.transactions_info?.laundering?.a || false}
              checkBoxLabel="a. Exchange small bills for large bills or vice versa"
            />
            <CheckBox value={item.transactions_info?.laundering?.b || false} checkBoxLabel="b. Funnel account" />
            <CheckBox
              value={item.transactions_info?.laundering?.c || false}
              checkBoxLabel="c. Suspicion concerning the physical condition of funds"
            />
            <CheckBox
              value={item.transactions_info?.laundering?.d || false}
              checkBoxLabel="d. Suspicion concerning the source of funds"
            />
            <CheckBox
              value={item.transactions_info?.laundering?.e || false}
              checkBoxLabel="e. Suspicious designation of beneficiaries, assignees or joint owners"
            />
            <CheckBox
              value={item.transactions_info?.laundering?.f || false}
              checkBoxLabel="f. Suspicious EFT/wire transfers"
            />
            <CheckBox
              value={item.transactions_info?.laundering?.g || false}
              checkBoxLabel="g. Suspicious exchange of currencies"
            />
            <CheckBox
              value={item.transactions_info?.laundering?.h || false}
              checkBoxLabel="h. Suspicious receipt of government payments/benefits"
            />
            <CheckBox
              value={item.transactions_info?.laundering?.i || false}
              checkBoxLabel="i. Suspicious use of multiple accounts"
            />
            <CheckBox
              value={item.transactions_info?.laundering?.j || false}
              checkBoxLabel="j. Suspicious use of noncash monetary instruments"
            />
            <CheckBox
              value={item.transactions_info?.laundering?.k || false}
              checkBoxLabel="k. Suspicious use of third-party transactors (straw-man)"
            />
            <CheckBox
              value={item.transactions_info?.laundering?.l || false}
              checkBoxLabel="l. Trade-Based Money Laundering/Black Market Peso Exchange"
            />
            <CheckBox
              value={item.transactions_info?.laundering?.m || false}
              checkBoxLabel="m. Transaction out of pattern for customer(s)"
            />
            <CheckBox value={item.transactions_info?.laundering?.z || false} checkBoxLabel="z. Other" />
            {item.transactions_info?.laundering?.z && (
              <SC.Label>{item.transactions_info?.laundering_other || '-'}</SC.Label>
            )}
          </Info>
        </Row>
        <Row>
          <Info label="37. Identification/Documentation">
            <CheckBox
              value={item.transactions_info?.identification?.a || false}
              checkBoxLabel="a. Changes spelling or arrangement of name"
            />
            <CheckBox
              value={item.transactions_info?.identification?.b || false}
              checkBoxLabel="b. Multiple individuals with same or similar identities"
            />
            <CheckBox
              value={item.transactions_info?.identification?.c || false}
              checkBoxLabel="c. Provided questionable or false documentation"
            />
            <CheckBox
              value={item.transactions_info?.identification?.d || false}
              checkBoxLabel="d. Provided questionable or false identification"
            />
            <CheckBox
              value={item.transactions_info?.identification?.e || false}
              checkBoxLabel="e. Refused or avoided request for documentation"
            />
            <CheckBox
              value={item.transactions_info?.identification?.f || false}
              checkBoxLabel="f. Single individual with multiple identities"
            />
            <CheckBox value={item.transactions_info?.identification?.z || false} checkBoxLabel="z. Other" />
            {item.transactions_info?.identification?.z && (
              <SC.Label>{item.transactions_info?.identification_other || '-'}</SC.Label>
            )}
          </Info>
        </Row>
        <Row>
          <Info label="38. Other Suspicious Activities">
            <CheckBox
              value={item.transactions_info?.other_activities?.a || false}
              checkBoxLabel="a. Account takeover"
            />
            <CheckBox
              value={item.transactions_info?.other_activities?.b || false}
              checkBoxLabel="b. Bribery or gratuity"
            />
            <CheckBox
              value={item.transactions_info?.other_activities?.c || false}
              checkBoxLabel="c. Counterfeit instruments"
            />
            <CheckBox
              value={item.transactions_info?.other_activities?.d || false}
              checkBoxLabel="d. Elder financial exploitation"
            />
            <CheckBox
              value={item.transactions_info?.other_activities?.e || false}
              checkBoxLabel="e. Embezzlement/theft/disappearance of funds"
            />
            <CheckBox value={item.transactions_info?.other_activities?.f || false} checkBoxLabel="f. Forgeries" />
            <CheckBox value={item.transactions_info?.other_activities?.g || false} checkBoxLabel="g. Human smuggling" />
            <CheckBox
              value={item.transactions_info?.other_activities?.h || false}
              checkBoxLabel="h. Human trafficking"
            />
            <CheckBox value={item.transactions_info?.other_activities?.i || false} checkBoxLabel="i. Identity theft" />
            <CheckBox
              value={item.transactions_info?.other_activities?.j || false}
              checkBoxLabel="j. Little or no concern for product performance penalties, fees, or tax consequences"
            />
            <CheckBox
              value={item.transactions_info?.other_activities?.k || false}
              checkBoxLabel="k. Misuse of position or self-dealing"
            />
            <CheckBox
              value={item.transactions_info?.other_activities?.l || false}
              checkBoxLabel="l. Suspected public/private corruption (domestic)"
            />
            <CheckBox
              value={item.transactions_info?.other_activities?.m || false}
              checkBoxLabel="m. Suspected public/private corruption (foreign)"
            />
            <CheckBox
              value={item.transactions_info?.other_activities?.n || false}
              checkBoxLabel="n. Suspicious use of informal value transfer system"
            />
            <CheckBox
              value={item.transactions_info?.other_activities?.o || false}
              checkBoxLabel="o. Suspicious use of multiple transaction locations"
            />
            <CheckBox
              value={item.transactions_info?.other_activities?.p || false}
              checkBoxLabel="p. Transaction with no apparent economic, business, or lawful purpose"
            />
            <CheckBox
              value={item.transactions_info?.other_activities?.q || false}
              checkBoxLabel="q. Transaction(s) involving foreign high risk jurisdiction"
            />
            <CheckBox
              value={item.transactions_info?.other_activities?.r || false}
              checkBoxLabel="r. Two or more individuals working together"
            />
            <CheckBox
              value={item.transactions_info?.other_activities?.s || false}
              checkBoxLabel="s. Unlicensed or unregistered MSB"
            />
            <CheckBox value={item.transactions_info?.other_activities?.z || false} checkBoxLabel="z. Other" />
            {item.transactions_info?.other_activities?.z && (
              <SC.Label>{item.transactions_info?.other_activities_other || '-'}</SC.Label>
            )}
          </Info>
        </Row>
        <Row>
          <Info label="45. Were any of the following product type(s) involved in the suspicious activity?">
            <SC.CheckBoxes>
              <CheckBox value={item.transactions_info?.product_types?.a || false} checkBoxLabel="a. Bonds/Notes" />
              <CheckBox value={item.transactions_info?.product_types?.k || false} checkBoxLabel="k. Home equity loan" />
            </SC.CheckBoxes>
            <SC.CheckBoxes>
              <CheckBox
                value={item.transactions_info?.product_types?.b || false}
                checkBoxLabel="b. Commercial mortgage"
              />
              <CheckBox
                value={item.transactions_info?.product_types?.l || false}
                checkBoxLabel="l. Insurance/Annuity products"
              />
            </SC.CheckBoxes>
            <SC.CheckBoxes>
              <CheckBox value={item.transactions_info?.product_types?.c || false} checkBoxLabel="c. Commercial paper" />
              <CheckBox
                value={item.transactions_info?.product_types?.m || false}
                checkBoxLabel="m. Microcap securities"
              />
            </SC.CheckBoxes>
            <SC.CheckBoxes>
              <CheckBox value={item.transactions_info?.product_types?.d || false} checkBoxLabel="d. Credit card" />
              <CheckBox value={item.transactions_info?.product_types?.n || false} checkBoxLabel="n. Mutual fund" />
            </SC.CheckBoxes>
            <SC.CheckBoxes>
              <CheckBox value={item.transactions_info?.product_types?.e || false} checkBoxLabel="e. Debit card" />
              <CheckBox
                value={item.transactions_info?.product_types?.o || false}
                checkBoxLabel="o. Options on securities"
              />
            </SC.CheckBoxes>
            <SC.CheckBoxes>
              <CheckBox value={item.transactions_info?.product_types?.f || false} checkBoxLabel="f. Deposit account" />
              <CheckBox value={item.transactions_info?.product_types?.p || false} checkBoxLabel="p. Prepaid access" />
            </SC.CheckBoxes>
            <SC.CheckBoxes>
              <CheckBox
                value={item.transactions_info?.product_types?.g || false}
                checkBoxLabel="g. Forex transactions"
              />
              <CheckBox
                value={item.transactions_info?.product_types?.q || false}
                checkBoxLabel="q. Residential mortgage"
              />
            </SC.CheckBoxes>
            <SC.CheckBoxes>
              <CheckBox
                value={item.transactions_info?.product_types?.h || false}
                checkBoxLabel="h. Futures/Options on futures"
              />
              <CheckBox
                value={item.transactions_info?.product_types?.r || false}
                checkBoxLabel="r. Security futures products"
              />
            </SC.CheckBoxes>
            <SC.CheckBoxes>
              <CheckBox value={item.transactions_info?.product_types?.i || false} checkBoxLabel="i. Hedge fund" />
              <CheckBox value={item.transactions_info?.product_types?.s || false} checkBoxLabel="s. Stocks" />
            </SC.CheckBoxes>
            <SC.CheckBoxes>
              <CheckBox
                value={item.transactions_info?.product_types?.j || false}
                checkBoxLabel="j. Home equity line of credit"
              />
              <CheckBox
                value={item.transactions_info?.product_types?.t || false}
                checkBoxLabel="t. Swap, hybrid, or other derivatives"
              />
            </SC.CheckBoxes>
            <SC.CheckBoxes>
              <CheckBox value={item.transactions_info?.product_types?.z || false} checkBoxLabel="z. Other" />
            </SC.CheckBoxes>
            <SC.CheckBoxes>
              <SC.Label>{item.transactions_info?.product_types_other}</SC.Label>
            </SC.CheckBoxes>
          </Info>
        </Row>
        <Row>
          <Info
            label="46. Were any of the following instrument type(s)/payment mechanism(s) involved in the suspicious
            activity?"
          >
            <SC.CheckBoxes>
              <CheckBox
                value={item.transactions_info?.payment_mechanisms?.a || false}
                checkBoxLabel="a. Bank/Cashier's check"
              />
              <CheckBox
                value={item.transactions_info?.payment_mechanisms?.f || false}
                checkBoxLabel="f. Money orders"
              />
            </SC.CheckBoxes>
            <SC.CheckBoxes>
              <CheckBox
                value={item.transactions_info?.payment_mechanisms?.b || false}
                checkBoxLabel="b. Foreign currency"
              />
              <CheckBox
                value={item.transactions_info?.payment_mechanisms?.g || false}
                checkBoxLabel="g. Personal/Business check"
              />
            </SC.CheckBoxes>
            <SC.CheckBoxes>
              <CheckBox
                value={item.transactions_info?.payment_mechanisms?.c || false}
                checkBoxLabel="c. Funds transfer"
              />
              <CheckBox
                value={item.transactions_info?.payment_mechanisms?.h || false}
                checkBoxLabel="h. Travelers checks"
              />
            </SC.CheckBoxes>
            <SC.CheckBoxes>
              <CheckBox
                value={item.transactions_info?.payment_mechanisms?.d || false}
                checkBoxLabel="d. Gaming instruments"
              />
              <CheckBox
                value={item.transactions_info?.payment_mechanisms?.i || false}
                checkBoxLabel="i. U.S. Currency"
              />
            </SC.CheckBoxes>
            <SC.CheckBoxes>
              <CheckBox
                value={item.transactions_info?.payment_mechanisms?.e || false}
                checkBoxLabel="e. Government payment"
              />
              <CheckBox value={item.transactions_info?.payment_mechanisms?.z || false} checkBoxLabel="z. Other" />
            </SC.CheckBoxes>
            <SC.CheckBoxes>
              <div />
              <SC.Label>{item.transactions_info?.payment_mechanisms_other}</SC.Label>
            </SC.CheckBoxes>
          </Info>
        </Row>
        {item.transactions_info?.ip_addresses?.length > 0 && (
          <>
            <Row>
              <Col2x>
                <h5>43. IP Address</h5>
                <br />
              </Col2x>
            </Row>
            {item.transactions_info?.ip_addresses.map((ip_address) => (
              <>
                <Row>
                  <Col>
                    <Info label="IP">{ip_address.ip}</Info>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Info label="Date">{ip_address.date}</Info>
                  </Col>
                  <Col>
                    <Info label="Timestamp (UTC)">{ip_address.time}</Info>
                  </Col>
                </Row>
              </>
            ))}
          </>
        )}
        {item.transactions_info?.cyber_event_indicators?.length > 0 && (
          <>
            <Row>
              <Col2x>
                <h5>44. Cyber Event Indicators</h5>
                <br />
              </Col2x>
            </Row>
            {item.transactions_info?.cyber_event_indicators.map((item) => (
              <>
                <Row>
                  <Col>
                    <Info label="Event Type">
                      {item.type === 'Other' && item.type_other}
                      {item.type !== 'Other' && item.type}
                    </Info>
                  </Col>
                  <Col>
                    <Info label="Event Value">{item.value}</Info>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Info label="Date">{item.date}</Info>
                  </Col>
                  <Col>
                    <Info label="Timestamp (UTC)">{item.time}</Info>
                  </Col>
                </Row>
              </>
            ))}
          </>
        )}
        {item.transactions_info?.commodity_types?.length > 0 && (
          <>
            <Row>
              <Col2x>
                <h5>47. Commodity type</h5>
                <br />
              </Col2x>
            </Row>
            {item.transactions_info?.commodity_types.map((item) => (
              <>
                <Row>
                  <Col>
                    <Info label="Value">{item.value}</Info>
                  </Col>
                </Row>
              </>
            ))}
          </>
        )}
        {item.transactions_info?.product_instrument_desc?.length > 0 && (
          <>
            <Row>
              <Col2x>
                <h5>48. Product/Instrument description</h5>
                <br />
              </Col2x>
            </Row>
            {item.transactions_info?.product_instrument_desc.map((item) => (
              <>
                <Row>
                  <Col>
                    <Info label="Value">{item.value}</Info>
                  </Col>
                </Row>
              </>
            ))}
          </>
        )}
        {item.transactions_info?.markets_traded?.length > 0 && (
          <>
            <Row>
              <Col2x>
                <h5>49. Market where traded</h5>
                <br />
              </Col2x>
            </Row>
            {item.transactions_info?.markets_traded.map((item) => (
              <>
                <Row>
                  <Col>
                    <Info label="Value">{item.value}</Info>
                  </Col>
                </Row>
              </>
            ))}
          </>
        )}
        {item.transactions_info?.cusip_numbers?.length > 0 && (
          <>
            <Row>
              <Col2x>
                <h5>50. CUSIP® number</h5>
                <br />
              </Col2x>
            </Row>
            {item.transactions_info?.cusip_numbers.map((item) => (
              <>
                <Row>
                  <Col>
                    <Info label="Value">{item.value}</Info>
                  </Col>
                </Row>
              </>
            ))}
          </>
        )}
      </InfoSet>
    </PageSection>
  )
})
