import React from 'react'

import { CurrencyInput, FF, Validators } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

type MiscOutProps = {
  index: number
}

export const MiscOut = ({ index }: MiscOutProps) => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name={`transactions_info.by_account[${index}].misc_out` as 'transactions_info.by_account[0].misc_out'}
      validators={{ onChange: ({ value }) => Validators.required.notEmpty(value) }}
      children={(field) => <FF required field={field} label="Misc Debits" input={(p) => <CurrencyInput {...p} />} />}
    />
  )
}
