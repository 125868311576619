import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'

import { getRouteApi, useNavigate } from '@tanstack/react-router'
import { useBankPortalContext } from 'bank-portal-context'

import { DocumentListFilters } from '../../components/DocumentListTable/types'
import { TableState } from '../../components/DocumentListTable/useTableState/types'
import parseParamsToFilters, { FlatDocumentFilterParams } from './parseParamsToFilters'

type FilterChangeHandler = (value: DocumentListFilters) => void

// http://localhost:3000/documents?page=2&size=10&sortBy=name&sortDesc=false&onlyMyCompanies=false&search=

type UseDocumentListHookReturn = {
  alerts: string
  search: string
  initFilters: DocumentListFilters
  externalTableState: Partial<TableState>
  setSearch: Dispatch<SetStateAction<string>>
  onFilterChange: FilterChangeHandler
}

type UseDocumentListHook = () => UseDocumentListHookReturn

const documentsRouteApi = getRouteApi('/documents')

export const useDocumentList: UseDocumentListHook = () => {
  const routeSearch = documentsRouteApi.useSearch()
  const navigate = useNavigate({ from: '/documents' })

  const {
    layout: { assignedCompaniesIDs }
  } = useBankPortalContext()

  const query = useMemo(
    () => routeSearch as FlatDocumentFilterParams,
    // startup stuff
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const [search, setSearch] = useState<string>(routeSearch?.search || '')

  const initFilters = useMemo(
    () => {
      return parseParamsToFilters({ search, query: { ...query, page: 1 }, assignedCompaniesIDs })
    },

    /* 
      intentionally remove alerts and assignedCompaniesIDs from deps, 
      because they values needs only at startup of the component
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search]
  )

  const externalTableState = useMemo(
    () => ({
      ...(assignedCompaniesIDs.length ? { expanded: true } : {})
    }),
    // startup stuff
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleFilterChange: FilterChangeHandler = useCallback(
    (filterValue) => {
      // deleting undefined/null values from filterValue object
      const values = Object.keys(filterValue.filter)
        .filter((v) => filterValue.filter[v] !== undefined && filterValue.filter[v] !== null)
        .reduce(
          (s, a) => ({
            ...s,
            [a]: filterValue.filter[a]
          }),
          {}
        )

      if (filterValue.search) {
        values['search'] = filterValue.search
        values['page'] = 1
      }

      navigate({ from: '/documents', to: '/documents', search: values, resetScroll: false, replace: true })
    },
    [navigate]
  )

  return {
    search,
    initFilters,
    externalTableState,
    setSearch,
    alerts: query.alerts,
    onFilterChange: handleFilterChange
  }
}
