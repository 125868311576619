/* eslint-disable max-len */
import React, { memo, useCallback, useEffect, useState } from 'react'

import { Link, getRouteApi } from '@tanstack/react-router'
import { BSA_CTR_BATCH_PATH, BSA_MODULE_PATH } from 'commons/constants/routes'
import { Button } from 'ui/components/Buttons'
import { IconName, IconSize } from 'ui/components/Icon'
import { Page, PageFace } from 'ui/components/Page'
import { PanelFace } from 'ui/components/Panel'
import { useConfirmation } from 'ui/hooks'
import { CTR } from '~bank-bsa-reporting/hooks'
import { useGetCTRList } from '~bank-bsa-reporting/hooks/ctr'
import { CTRItem, ChosenItems } from '~bank-bsa-reporting/types'

import { Warnings } from '../components'
import { CTRTable, DownloadXMLDialog, Footer } from './components'
import { Batch, IWarnings } from './types'

import { s } from './styles'

const routeApi = getRouteApi('/bsa/ctrs/')

const List = memo(() => {
  const [generatedBatch, setGeneratedBatch] = useState<null | Batch>(null)
  const [isGeneratingXML, setIsGeneratingXML] = useState(false)
  const [chosenItems, setChosenItems] = useState<ChosenItems[]>([])
  const [itemToDelete, setItemToDelete] = useState<CTRItem | null>(null)
  const [warnings, setWarnings] = useState<IWarnings>({
    warnings: null,
    warningsType: null
  })
  const [loading, setLoading] = useState(false)

  const search = routeApi.useSearch()
  const navigate = routeApi.useNavigate()

  const deleteMutation = CTR.useDelete()
  const ctrMutation = CTR.useCTRsGeneration()
  const xmlMutation = CTR.useXMLGeneration(setGeneratedBatch)

  const { data, tableConfig } = useGetCTRList()

  useEffect(() => {
    const transDate = search.trans_date
    const splitTransDate = transDate ? transDate.split('/') : []
    let date = ''
    if (splitTransDate.length) {
      date = `${splitTransDate[2]}-${splitTransDate[0]}-${splitTransDate[1]}`
    }

    if (date) {
      setChosenItems([])
      setLoading(true)
      tableConfig.onFilterChange({ ...data?.form, transactionDate: date })
    }
  }, [])

  const onItemSelect = (item?: ChosenItems) => {
    if (!item) {
      if (chosenItems.length < (data.items?.length || 0)) {
        setChosenItems((data.items || []).map((i) => ({ id: i.id, name: i.name })))
      } else {
        setChosenItems([])
      }
    } else {
      const index = chosenItems.findIndex((i) => i.id === item.id)
      if (index === -1) {
        setChosenItems(chosenItems.concat(item))
      } else {
        const copy = [...chosenItems]
        copy.splice(index, 1)
        setChosenItems(copy)
      }
    }
  }

  const { open: startDeleting, Confirmation: DeleteConfirmation } = useConfirmation({
    message: (
      <span>
        Are you sure you want to delete{' '}
        <i>{itemToDelete || chosenItems.length === 1 ? (itemToDelete || chosenItems[0]).name : 'these'}</i> CTR(s)?
      </span>
    ),
    onConfirm: () =>
      deleteMutation.mutateAsync(itemToDelete ? [itemToDelete.id] : chosenItems.map((i) => i.id)).then(() => {
        setChosenItems([])
        setItemToDelete(null)
      }),
    confirmationButtonText: 'Delete',
    isConfirmationNegative: true
  })

  const onDeleteFromTableMenu = useCallback(
    (item: CTRItem) => {
      setItemToDelete(item)
      startDeleting()
    },
    [setItemToDelete, startDeleting]
  )

  const { open: startGenerateXML, Confirmation: GenerateXMLConfirmation } = useConfirmation({
    message: (
      <span>
        Are you sure you want to generate Batch from <i>{chosenItems.length === 1 ? chosenItems[0].name : 'these'}</i>{' '}
        CTR(s)?
      </span>
    ),
    onConfirm: () => {
      setIsGeneratingXML(true)
      xmlMutation
        .mutateAsync(chosenItems.map((i) => i.id))
        .then(() => {
          setChosenItems([])
        })
        .finally(() => {
          setIsGeneratingXML(false)
        })
    },
    confirmationButtonText: 'Generate'
  })

  const onCloseXMLDialog = () => {
    if (isGeneratingXML) {
      return
    }

    setGeneratedBatch(null)
  }

  const onGoToNewCTR = useCallback(() => {
    navigate({ to: '/bsa/ctrs/$id/edit', params: { id: '0' } })
  }, [navigate])

  const { open: startGenerateCTRs, Confirmation: GenerateCTRsConfirmation } = useConfirmation({
    message: <span>Are you sure? It may create duplicates for the selected date.</span>,
    onConfirm: () => ctrMutation.mutate(`${data.form.transactionDate}T00:00:00.000Z` || ''),
    confirmationButtonText: 'Generate'
  })

  const onShowWarnings = useCallback(
    (data: string, warningsType) =>
      setWarnings({
        warningsType: warningsType,
        warnings: data
      }),
    [setWarnings]
  )

  useEffect(() => {
    if (generatedBatch) {
      setGeneratedBatch(null)
      setTimeout(() => {
        navigate({
          to: BSA_CTR_BATCH_PATH,
          search: { batchId: Number(generatedBatch.id) }
        })
      }, 0)
    }
  }, [generatedBatch])

  const canGenerate = !!data?.form.transactionDate && !loading && !!data.items?.length
  const hasChosenItems = !!chosenItems.length

  if (!data) {
    return null
  }

  return (
    <Page
      face={PageFace.PRIMARY}
      title={
        <s.Title>
          <Link to={BSA_MODULE_PATH}>BSA Reporting</Link>
          <s.Arrow name={IconName.ARROW_SMALL} size={IconSize.XS} />
          CTRs List
        </s.Title>
      }
      actions={
        data.canAddNew && (
          <Button face="neutral" onClick={onGoToNewCTR} data-qa="New CTR">
            New CTR
          </Button>
        )
      }
    >
      <s.PageWrapper>
        <DeleteConfirmation />
        <GenerateXMLConfirmation />
        <GenerateCTRsConfirmation />
        <Warnings
          warnings={warnings}
          onClose={() =>
            setWarnings({
              warningsType: null,
              warnings: null
            })
          }
        />
        <DownloadXMLDialog batch={generatedBatch} isGenerating={isGeneratingXML} onClose={onCloseXMLDialog} />
        <s.TablePanel rounded shadowed collapsible={false} face={PanelFace.SECONDARY}>
          <CTRTable
            onItemSelect={onItemSelect}
            onDelete={onDeleteFromTableMenu}
            onGenerateCTRs={startGenerateCTRs}
            onShowWarnings={onShowWarnings}
            chosenItems={chosenItems}
            isAllItemsChosen={chosenItems.length === data.items?.length && chosenItems.length > 0}
            tableConfig={tableConfig}
            data={data}
          />
        </s.TablePanel>
        {(canGenerate || hasChosenItems) && (
          <Footer
            canGenerate={canGenerate}
            onDeleteCTRs={startDeleting}
            onXMLGenerate={startGenerateXML}
            hasChosenItems={hasChosenItems}
            date={data.form.transactionDate || ''}
            type={data.form.filingType}
            status={data.form.status}
            page={data.form.page}
            size={data.form.size}
          />
        )}
      </s.PageWrapper>
    </Page>
  )
})

export default List
