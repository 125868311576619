import { ClientUserPermissions } from 'commons/types/enums'

export const permissionsTree: any = {
  connections: {
    label: 'Connections',
    items: [
      { label: 'List', value: ClientUserPermissions.CONNECTIONS_LIST },
      { label: 'Create', value: ClientUserPermissions.CONNECTIONS_CREATE },
      { label: 'Delete', value: ClientUserPermissions.CONNECTIONS_DELETE }
    ]
  },
  invoices: {
    label: 'Invoices',
    items: [
      { label: 'List', value: ClientUserPermissions.INVOICES_LIST },
      { label: 'Create', value: ClientUserPermissions.INVOICES_CREATE }
    ]
  },
  documents: {
    label: 'Documents',
    items: [
      { label: 'List', value: ClientUserPermissions.DOCUMENT_LIST },
      { label: 'Create', value: ClientUserPermissions.DOCUMENTS_UPLOAD }
    ]
  },
  users: {
    label: 'Manage users',
    items: [
      { label: 'List', value: ClientUserPermissions.USERS_LIST },
      { label: 'Create', value: ClientUserPermissions.USERS_CREATE },
      { label: 'Delete', value: ClientUserPermissions.USERS_DELETE },
      { label: 'Set permissions', value: ClientUserPermissions.USERS_SET_PERMISSIONS }
    ]
  },
  internal_transfers: {
    label: 'Internal transfers',
    items: []
  }
}
