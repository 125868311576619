import React, { FC, memo } from 'react'

import { DepositsFromSales } from 'commons/types/DTO/bank/reportWebPage'
import { formatCurrencyCent } from 'commons/utils'

import * as SC from './styles'

export const DepositsFromSalesTable: FC<{ depositsFromSales: DepositsFromSales }> = memo(({ depositsFromSales }) => (
  <SC.WrappedLayer rounded shadowed>
    <SC.Title>Deposits From Sales</SC.Title>
    <SC.Columns>
      <SC.Labels>
        <SC.Label>Current Month</SC.Label>
      </SC.Labels>
      <SC.Table>
        <thead>
          <tr>
            <SC.Th rowSpan={2}>Total</SC.Th>
            <SC.Th $bottomBorder colSpan={3}>
              Cash
            </SC.Th>
            <SC.Th rowSpan={2}>Internal Transfers</SC.Th>
            <SC.Th rowSpan={2}>Credit / Debit Cards</SC.Th>
            <SC.Th rowSpan={2}>Checks</SC.Th>
            <SC.Th rowSpan={2}>Other</SC.Th>
          </tr>
          <tr>
            <SC.Th>Deposits</SC.Th>
            <SC.Th>Invoices</SC.Th>
            <SC.Th $forceRightBorder>ATM Load</SC.Th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <SC.Td>{formatCurrencyCent(depositsFromSales.total, false)}</SC.Td>
            <SC.Td>{formatCurrencyCent(depositsFromSales.cashDeposits, false)}</SC.Td>
            <SC.Td>{formatCurrencyCent(depositsFromSales.cashInvoices, false)}</SC.Td>
            <SC.Td>{formatCurrencyCent(depositsFromSales.cashATMLoad, false)}</SC.Td>
            <SC.Td>{formatCurrencyCent(depositsFromSales.internalTransfers, false)}</SC.Td>
            <SC.Td>{formatCurrencyCent(depositsFromSales.creditDebitCards, false)}</SC.Td>
            <SC.Td>{formatCurrencyCent(depositsFromSales.checks, false)}</SC.Td>
            <SC.Td>{formatCurrencyCent(depositsFromSales.other, false)}</SC.Td>
          </tr>
        </tbody>
      </SC.Table>
    </SC.Columns>
  </SC.WrappedLayer>
))
