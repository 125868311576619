import React from 'react'

import { Applications } from 'commons/types/DTO/bank/alerts/applications'
import { UsPhoneLabel } from 'ui/components'
import { DateTime } from 'ui/components/DateTime'
import { Icon, IconName } from 'ui/components/Icon'
import { Link } from 'ui/components/Link'
import { MenuItemBehavior, TableActions } from 'ui/components/QueryTable'
import { TableColumn } from 'ui/components/Table'
import { Tooltip } from 'ui/components/Tooltip'

import { EmailLink, IdWithNotes, Phone } from './styles'

export const columns = (showGetLinkModal: (applicationId: number) => void): TableColumn<Applications>[] => [
  {
    title: 'ID',
    align: 'left',
    render: (item) => (
      <IdWithNotes>
        <Link to={`/onboarding/applications/${item.id}`}>{`ID-${item.id}`}</Link>
        {item.internalBankNotes && (
          <Tooltip id={item.text} type="dark" event="hover" content={<>{item.internalBankNotes}</>}>
            <Icon name={IconName.DIALOG} />
          </Tooltip>
        )}
      </IdWithNotes>
    )
  },
  {
    title: 'Company Name',
    align: 'left',
    render: (item) => (
      <Link to={`/onboarding/applications/${item.id}`} data-qa={`Company Name ${item.companyName}`}>
        {item.companyName}
      </Link>
    )
  },
  {
    title: 'Status',
    align: 'left',
    key: 'statusName'
  },
  {
    title: 'Contact Name',
    align: 'left',
    key: 'contactFullName'
  },
  {
    title: 'Email',
    align: 'left',
    render: (item) => <EmailLink href={`mailto:${item.contactEmail}`}>{item.contactEmail}</EmailLink>
  },
  {
    title: 'Phone',
    align: 'left',
    render: (item) => (
      <>
        {item.contactPhone && (
          <Phone>
            <UsPhoneLabel value={item.contactPhone} />
          </Phone>
        )}
      </>
    )
  },
  {
    title: 'Last Modified',
    align: 'left',
    sortable: true,
    key: 'lastModifiedDate',
    render: (item) => !!item && <DateTime date={item.lastModifiedDate} withTime />
  },
  {
    title: 'Action(s)',
    align: 'right',
    render: (item) => {
      const items: any[] = [
        {
          title: item.mainAction,
          url: `/onboarding/applications/${item.id}`,
          textFace: 'grey',
          key: 'mainAction'
        },
        item.enableGetLink && {
          behavior: MenuItemBehavior.BUTTON,
          title: 'Get link',
          textFace: 'grey',
          key: 'enableGetLink',
          onClick: () => showGetLinkModal(item.id)
        }
      ]
      return <TableActions menuContainerId="applicationsListContainer" items={items} />
    }
  }
]
