import React from 'react'

import { UseNavigateResult } from '@tanstack/react-router'
import { AdverseMediaSearchCase } from 'commons/types/DTO/bank'
import { AdverseMedia } from 'commons/types/DTO/bank/alerts/adverseMedia'
import { Link } from 'ui/components'
import { TableButton } from 'ui/components/Buttons'
import { DateTime } from 'ui/components/DateTime'
import { RiskLevel } from 'ui/components/RiskLevel'
import { TableColumn } from 'ui/components/Table'

import { NameRow, NumBadge } from './styles'

export const columns = (navigate: UseNavigateResult<string>): TableColumn<AdverseMedia>[] => [
  {
    title: 'Search Term',
    align: 'left',
    render: ({ searchTerm, id }) => (
      <NameRow>
        <Link to="/adverse-media/$id" params={{ id }}>
          {searchTerm}
        </Link>
      </NameRow>
    )
  },
  {
    title: 'Needs review',
    align: 'center',
    render: (item) => {
      if (item.alertedPublicationsCount) {
        return <NumBadge>{item.alertedPublicationsCount > 50 ? '50+' : item.alertedPublicationsCount}</NumBadge>
      }
      return null
    }
  },
  {
    title: 'Type',
    align: 'center',
    key: 'type'
  },
  {
    title: 'Risk Level',
    align: 'center',
    render: ({ riskLevel }) => <RiskLevel riskLevel={riskLevel.toLowerCase() as AdverseMediaSearchCase['risk_level']} />
  },
  {
    title: 'Last Scan Date',
    align: 'center',
    render: ({ lastScanDate }) => <DateTime date={lastScanDate} withTime />
  },
  {
    title: 'Last Modified Date',
    align: 'center',
    render: ({ lastModifiedDate }) => <DateTime date={lastModifiedDate} withTime />
  },
  {
    title: 'Action',
    align: 'center',
    render: (item) => {
      const needsReview = item.needsReview

      return (
        <TableButton
          face={needsReview ? 'danger' : 'neutral'}
          onClick={() => navigate({ to: '/adverse-media/$id', params: { id: item.id } })}
        >
          {needsReview ? 'VIEW & RESOLVE' : 'VIEW'}
        </TableButton>
      )
    }
  }
]
