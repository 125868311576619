import React from 'react'

import { CheckBoxGroup, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../../context'

export const OtherActivities = () => {
  const { formApi } = useSARFormContext()
  return (
    <formApi.Field
      name="transactions_info.other_activities"
      children={(field) => (
        <FF
          field={field}
          label="38. Other Suspicious Activities"
          input={(p) => (
            <CheckBoxGroup
              {...p}
              data-qa={'38. Other Suspicious Activities'}
              direction={'vertical'}
              data={[
                { label: 'a. Account takeover', value: 'a' },
                { label: 'b. Bribery or gratuity', value: 'b' },
                { label: 'c. Counterfeit instruments', value: 'c' },
                { label: 'd. Elder financial exploitation', value: 'd' },
                { label: 'e. Embezzlement/theft/disappearance of funds', value: 'e' },
                { label: 'f. Forgeries', value: 'f' },
                { label: 'g. Human smuggling', value: 'g' },
                { label: 'h. Human trafficking', value: 'h' },
                { label: 'i. Identity theft', value: 'i' },
                {
                  label: 'j. Little or no concern for product performance penalties, fees, or tax consequences',
                  value: 'j'
                },
                { label: 'k. Misuse of position or self-dealing', value: 'k' },
                { label: 'l. Suspected public/private corruption (domestic)', value: 'l' },
                { label: 'm. Suspected public/private corruption (foreign)', value: 'm' },
                { label: 'n. Suspicious use of informal value transfer system', value: 'n' },
                { label: 'o. Suspicious use of multiple transaction locations', value: 'o' },
                { label: 'p. Transaction with no apparent economic, business, or lawful purpose', value: 'p' },
                { label: 'q. Transaction(s) involving foreign high risk jurisdiction', value: 'q' },
                { label: 'r. Two or more individuals working together', value: 'r' },
                { label: 's. Unlicensed or unregistered MSB', value: 's' },
                { label: 'z. Other', value: 'z' }
              ]}
            />
          )}
        />
      )}
    />
  )
}
