import React, { useEffect } from 'react'

import { FieldApi } from '@tanstack/react-form'
import { BsaSar } from 'commons/types/DTO/bank/bsa'
import { throttle } from 'lodash'
import { CurrencyInput, FF, Tooltip } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

type CurrentAmountRendererProps = {
  field: FieldApi<BsaSar, 'transactions_info.current_amount'>
}

const CurrentAmountRenderer = ({ field }: CurrentAmountRendererProps) => {
  const { formApi } = useSARFormContext()

  useEffect(
    throttle(() => {
      let currentAmount = 0
      formApi.state.values.transactions_info?.by_account.forEach((bankAccount) => {
        Object.entries(bankAccount).forEach(([key, value]) => {
          if (typeof value === 'number' && value > 0 && key !== 'total' && key !== 'total_in' && key !== 'total_out')
            currentAmount += Number(value)
        })
      })

      formApi.setFieldValue('transactions_info.current_amount', Math.ceil(currentAmount))
    }, 100),
    [formApi, formApi.state.values.transactions_info.by_account]
  )

  return (
    <FF
      field={field}
      input={(p) => (
        <Tooltip
          content="The amount is automatically calculated based on the Transactions Information section"
          id="current_amount"
          type="dark"
        >
          <CurrencyInput {...p} disabled />
        </Tooltip>
      )}
    />
  )
}

export const CurrentAmount = () => {
  const { formApi } = useSARFormContext()

  return (
    <formApi.Field
      name="transactions_info.current_amount"
      children={(field) => <CurrentAmountRenderer field={field} />}
    />
  )
}
