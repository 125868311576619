import styled, { css } from 'styled-components'

import { IconV2 } from '../Icon'

export const Box = styled.div`
  border-bottom: 1px solid var(--theme-colors-global-grey-grey300);
  &:last-child {
    border-bottom: none;
  }
`

export const TitleArrow = styled(IconV2)`
  margin: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TitleText = styled.h5`
  margin-right: 8px;
`

export const TitleActions = styled.div`
  display: flex;
  gap: 32px;
  margin-left: auto;
`

export const TitleBox = styled.div`
  display: flex;
  height: 48px;
  padding-right: 32px;
  padding-left: 8px;
  align-items: center;
  cursor: pointer;
`

export const ContentBox = styled.div<{ $expanded: boolean }>`
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 28px;
  padding-bottom: 60px;
  ${(p) =>
    !p.$expanded
      ? css`
          display: none;
        `
      : ''}
`
