import { useCallback, useEffect, useRef } from 'react'
import { useQueryClient } from 'react-query'

import { useNavigate, useParams, useSearch } from '@tanstack/react-router'
import { useFormData } from 'brief-form'
import { handlerServerError } from 'errors'
import { Toast } from 'ui/components'
import { useConfirmation } from 'ui/hooks'

import { CONTACTS_PATH } from '../../constants'
import { emptyContact, useContactItem, useDeleteContact, useSaveContact } from '../../hooks'
import { messages } from '../../messages'
import { ContactForm } from '../../types/contact'

export const useContactManage = () => {
  const oncePerformedFlag = useRef<boolean>(false)
  const { contactId } = useParams({ strict: false })

  const { data, isFetching: isContactFetching } = useContactItem(+(contactId || 0))

  const formData = useFormData<ContactForm>({ initialValue: data?.form || emptyContact })

  // edit. contact loaded, change form value
  useEffect(() => {
    if (!data?.form || oncePerformedFlag.current === true) {
      return
    }
    formData.set({ reset: true, value: data?.form })
    oncePerformedFlag.current = true
  }, [data?.form, formData])

  const client = useQueryClient()

  const navigate = useNavigate({ from: '/contacts/new' })

  const search = useSearch({ strict: false })

  const { saveContact, isLoading: isContactFormSaving } = useSaveContact({
    onSuccess: async (...[payload, responseContact]) => {
      const successMessage =
        data?.form && data?.form.id ? messages['bankContacts.updateSuccess'] : messages['bankContacts.createSuccess']
      Toast.successSmall(successMessage)

      if (!search.returnUrl) navigate({ to: '/contacts/$contactId', params: { contactId: responseContact.id } })
      // this row for flow "Company Page > Create New Contact > Back to Company Page with Opened Edit Contact dialog
      if (search.successReturnUrl && search.successReturnUrl.includes('contact/add')) {
        navigate({ to: `${search.successReturnUrl + responseContact.id}?contactName=${payload.lastName}` })
      } else {
        navigate({ to: search.returnUrl })
      }
    }
  })

  const { routine: deleteContact } = useDeleteContact({
    onSuccess: async () => {
      await client.invalidateQueries(['tables', 'contacts'])
      navigate({ to: CONTACTS_PATH })
      Toast.successSmall(messages['bankContacts.deleteSuccess'])
    },
    onError: (error) => handlerServerError(error)
  })

  const { open: openDeleteConfirmation, Confirmation: DeleteConfirmation } = useConfirmation({
    message: messages['bankContacts.deleteConfirmation']({
      contactFirstName: data?.form?.firstName,
      contactLastName: data?.form?.lastName
    }),
    onConfirm: () => data?.form && deleteContact(data?.form.id),
    confirmationButtonText: messages['bankContacts.delete'],
    isConfirmationNegative: true
  })

  const handleSubmitContactForm = useCallback(async () => {
    if (!formData.validate({ updateFields: true }).valid) {
      return
    }

    await saveContact(formData.config.value as any)
  }, [saveContact, formData])

  const returnUrlForCancel = search.cancelReturnUrl || CONTACTS_PATH

  return {
    formData,
    subTitle: data?.form && data?.form.id ? messages['bankContacts.editTitle'] : messages['bankContacts.createTitle'],
    isContactFetching,
    isContactFormSaving,
    showBio: data?.showBio,
    DeleteConfirmation,
    returnUrlForCancel,
    onDelete: openDeleteConfirmation,
    onSubmit: handleSubmitContactForm
  }
}
