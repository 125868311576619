/* eslint-disable camelcase */

export type AdverseMediaPublication = {
  id: number
  case_id?: number
  content?: string
  content_type?: string
  copyright_holder?: string
  copyright_notice?: string
  created_at?: string
  external_id?: string
  needs_review?: boolean
  notes?: string
  publication_date?: string
  snippet?: string
  rationale?: string
  title?: string
  updated_at?: string
}
