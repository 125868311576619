import styled from 'styled-components'

const DotBadge = styled.div`
  display: inline-flex;
  width: 10px;
  height: 10px;
  border-radius: 5px;
`

export const DotBadgeDefault = styled(DotBadge)`
  background-color: var(--theme-colors-global-grey-grey500);
`

export const DotBadgePositive = styled(DotBadge)`
  background-color: var(--theme-colors-theme-positive-main);
`

export const DotBadgeWarning = styled(DotBadge)`
  background-color: var(--theme-colors-theme-warning-main);
`

export const DotBadgeError = styled(DotBadge)`
  background-color: var(--theme-colors-theme-negative-main);
`
