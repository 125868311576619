/* eslint-disable camelcase */

export type BsaCtrBatch = {
  batch_id?: number
  created_at?: string
  ctr_id?: number
  ctr_name?: string
  ctr_status?: string
  deleted_at?: string
  id?: number
  seq_num?: number
  updated_at?: string
}
