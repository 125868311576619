import React from 'react'

import { FieldApi } from '@tanstack/react-form'
import { BsaSar } from 'commons/types/DTO/bank/bsa'
import { CheckBoxGroup, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

const FilingTypeRenderer = ({ field }: { field: FieldApi<BsaSar, 'filing_type'> }) => {
  const { formApi } = useSARFormContext()

  const userSelectCheckbox = (c: string, v: string[]) => {
    return v.includes(c) && !field.state.value.includes(c)
  }

  const deselectCheckbox = (c: string, v: string[]) => {
    const i = v.indexOf(c)
    if (i !== -1) {
      v.splice(i, 1)
    }
  }

  const handleChange = (v: string[]) => {
    const newValue = [...v]

    if (userSelectCheckbox('filing_type_initial', newValue)) {
      deselectCheckbox('filing_type_correct_amend', newValue)
      deselectCheckbox('filing_type_continuing', newValue)

      const bsaIdMeta = formApi.getFieldMeta('prior_report_bsa_id')
      formApi.setFieldMeta('prior_report_bsa_id', { ...bsaIdMeta, errors: [], errorMap: {} })
      formApi.setFieldValue('prior_report_bsa_id', null, { dontUpdateMeta: true })
    }

    if (
      userSelectCheckbox('filing_type_correct_amend', newValue) ||
      userSelectCheckbox('filing_type_continuing', newValue)
    ) {
      deselectCheckbox('filing_type_initial', newValue)
    }

    field.handleChange(newValue)
  }

  return (
    <FF
      label="1. Type of filing"
      field={field}
      input={(p) => (
        <CheckBoxGroup
          {...p}
          data-qa={'1. Type of filing'}
          direction="horizontal"
          data={[
            { label: 'Initial report', value: 'filing_type_initial' },
            { label: 'Correct/amend prior report', value: 'filing_type_correct_amend' },
            { label: 'Continuing activity report', value: 'filing_type_continuing' },
            { label: 'Joint report', value: 'filing_type_joint' }
          ]}
          onChange={handleChange}
        />
      )}
    />
  )
}

export const FilingType = () => {
  const { formApi } = useSARFormContext()

  return <formApi.Field name="filing_type" children={(field) => <FilingTypeRenderer field={field} />} />
}
