import styled, { css } from 'styled-components'
import { Direction } from 'ui/types'

export const CheckBoxGroupContainer = styled.div<{ $direction: Direction }>`
  display: flex;
  flex-wrap: wrap;

  ${({ $direction }) =>
    $direction === 'horizontal'
      ? css`
          flex-direction: row;
        `
      : css`
          flex-direction: column;
          gap: 15px;
        `}
`

export const Hint = styled.div<{ disabled: boolean }>`
  font: var(--theme-typography-bodyS-regular);
  color: ${(p) => (p.disabled ? p.theme.uiKit.colors.global.grey.grey700 : p.theme.uiKit.colors.global.grey.grey900)};
`
