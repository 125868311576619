import React from 'react'

import { BankAccount } from 'commons/types/DTO/bank/companyDetails/bankAccount'
import { CheckBox } from 'ui/components/CheckBox'
import { IconName, IconSize } from 'ui/components/Icon'
import { TableActions } from 'ui/components/QueryTable'
import { TableColumn } from 'ui/components/Table'
import { TextMask } from 'ui/components/TextMask'

import { BANK_ACCOUNTS_PATH } from '@orbit/bank-accounts'

import * as SC from './styles'

export const columns = (onDelete: (item: any) => void): TableColumn<BankAccount>[] => [
  {
    title: 'Account Number',
    align: 'center',
    width: '250px',
    sortable: false,
    render: ({ accountNumber }) => <TextMask isBankAccount text={accountNumber} iconSize={IconSize.XS} />
  },
  {
    title: 'Licensed',
    align: 'center',
    sortable: false,
    width: '100px',
    render: ({ isLicensed }) => (
      <SC.Licensed>
        <CheckBox value={isLicensed} checkBoxLabel="" />
      </SC.Licensed>
    )
  },
  {
    title: 'Notes',
    key: 'notes',
    sortable: false,
    align: 'left'
  },
  {
    title: 'Actions',
    sortable: false,
    width: '100px',
    render: (item: any) => (
      <TableActions
        items={(
          [
            {
              key: 1,
              title: 'Details',
              url: `${BANK_ACCOUNTS_PATH}/${item.id}?returnUrl=${window.location.pathname + window.location.hash}`
            },
            {
              key: 2,
              title: 'Edit',
              url: `${BANK_ACCOUNTS_PATH}/${item.id}/edit?returnUrl=${window.location.pathname + window.location.hash}`,
              iconName: IconName.EDIT
            },
            {
              key: 3,
              title: 'Delete',
              behavior: 'button',
              onClick: () => onDelete(item),
              iconName: IconName.DELETE
            }
          ] as any
        ).filter(Boolean)}
        dataQaAttribute={`Bank accounts-menu-dots-button-${item.id}`}
      />
    )
  }
]
