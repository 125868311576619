import React, { FC, memo, useCallback, useEffect, useMemo, useRef } from 'react'

import { IconName, IconSize } from 'ui/components/Icon'
import { SecuredInput } from 'ui/components/InputV2'
import { FormRow } from 'ui/themes/globalStyles'

import { AccountNumbersProps } from './AccountNumbers.types'

import { s } from './styles'

export const AccountNumbers: FC<AccountNumbersProps> = memo((props) => {
  const { value, onChange } = props
  const finalValue = useMemo(() => (!value?.length ? [''] : value), [value])
  const listRef = useRef(value)

  useEffect(() => {
    listRef.current = [...finalValue]
  }, [finalValue, listRef])

  const onAdd = useCallback(() => {
    onChange(listRef.current.concat(''), undefined)
  }, [listRef, onChange])

  const onDelete = useCallback(
    (index: number) => {
      const copy = [...listRef.current]
      copy.splice(index, 1)
      onChange(copy, undefined)
    },
    [listRef, onChange]
  )

  const onInputChange = useCallback(
    (index: number, v: string, e?: string) => {
      const copy = [...listRef.current]
      copy[index] = v
      onChange(copy, e)
    },
    [listRef, onChange]
  )

  return (
    <div>
      {finalValue.map((accountNumber: string, index: number) => (
        <FormRow key={index}>
          <SecuredInput value={accountNumber} onChange={onInputChange.bind(null, index)} maxLength={40} />
          {finalValue.length > 1 && (
            <s.DeleteIcon name={IconName.CAN} size={IconSize.M} onClick={() => onDelete(index)} />
          )}
          {index === finalValue.length - 1 && <s.AddIcon name={IconName.ADD_GREEN} size={IconSize.M} onClick={onAdd} />}
        </FormRow>
      ))}
    </div>
  )
})
