import React, { FC, memo } from 'react'

import { DOCUMENTS_PATH } from 'commons/constants/routes'

import { Hr } from '../Hr'
import { PageSection, PageSectionFace } from '../Page'
import { DocumentsDueProps } from './DocumentsDueProps'
import { AlertedItem } from './components'

export const DocumentsDue: FC<DocumentsDueProps> = memo((props) => {
  const { webPageData } = props

  const {
    documentsFirstNotificationCount,
    documentsPastNotificationCount,
    documentsLastNotificationCount,
    documentsPreNotificationCount,
    documentsIncompleteCount,
    documentsDaysToFirstNotificationBeforeExpiration,
    documentsDaysToLastNotificationBeforeExpiration,
    documentsDaysToPreNotificationBeforeExpiration
  } = webPageData

  return (
    <>
      <Hr />
      <PageSection title="Documents Due" face={PageSectionFace.SECONDARY} anchor="documents-due">
        <AlertedItem
          past
          count={documentsPastNotificationCount}
          daysDue={0}
          url={`${DOCUMENTS_PATH}?alerts=past-due`}
        />
        <AlertedItem
          past={false}
          count={documentsLastNotificationCount}
          daysDue={documentsDaysToLastNotificationBeforeExpiration || 3}
          url={`${DOCUMENTS_PATH}?alerts=last-alert`}
        />
        <AlertedItem
          past={false}
          count={documentsFirstNotificationCount}
          daysDue={documentsDaysToFirstNotificationBeforeExpiration || 7}
          url={`${DOCUMENTS_PATH}?alerts=first-alert`}
        />
        <AlertedItem
          past={false}
          count={documentsPreNotificationCount}
          daysDue={documentsDaysToPreNotificationBeforeExpiration || 30}
          url={`${DOCUMENTS_PATH}?alerts=initial-alert`}
        />
        <AlertedItem
          past={false}
          count={documentsIncompleteCount}
          incomplete={true}
          url={`${DOCUMENTS_PATH}?alerts=incomplete`}
        />
      </PageSection>
    </>
  )
})
