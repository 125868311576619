import React from 'react'

import { CheckBoxGroup, FF } from 'ui/components'

import { useSARFormContext } from '../../../../../context'

export const Cyber = () => {
  const { formApi } = useSARFormContext()
  return (
    <formApi.Field
      name="transactions_info.cyber"
      children={(field) => (
        <FF
          field={field}
          label="42. Cyber event"
          input={(p) => (
            <CheckBoxGroup
              {...p}
              data-qa={'42. Cyber event'}
              direction={'vertical'}
              data={[
                { label: 'a. Against Financial Institution(s)', value: 'a' },
                { label: 'b. Against Financial Institution Customer(s)', value: 'b' },
                { label: 'z. Other', value: 'z' }
              ]}
            />
          )}
        />
      )}
    />
  )
}
