import { SalesSourceItem as MRSalesSourceItem } from 'commons/types/DTO/bank/reportWebPage'
import { SalesDataSourceWebPage } from 'commons/types/DTO/bank/salesDataSourceWebPage'

export class BankSourceCreateFormValue {
  internalNotes = ''
  licenseId: number = null
  reportMonthlyId: number = null

  totalCannabisSales: number = null
  totalNonCannabisSales: number = null
  totalSalesTaxCollected: number = null

  constructor(reportMonthlyId?: number, licenseId?: number) {
    this.reportMonthlyId = reportMonthlyId || 0
    this.licenseId = licenseId || 0
  }

  fillFromMRSalesSourceItem(mRSalesSourceItem: MRSalesSourceItem) {
    this.totalCannabisSales = mRSalesSourceItem.showOverrideTotalCannabisSales || null
    this.totalNonCannabisSales = mRSalesSourceItem.showOverrideTotalNonCannabisSales
    this.totalSalesTaxCollected = mRSalesSourceItem.showOverrideTotalSalesTaxCollected

    return this
  }

  fillFromSalesDataSourceWebPage(salesDataSourceWebPage: SalesDataSourceWebPage) {
    this.internalNotes = salesDataSourceWebPage.infoInternalNotes
    this.licenseId = salesDataSourceWebPage.infoLicenses.find((el) => el.isMain).licenseId
    this.reportMonthlyId = salesDataSourceWebPage.infoReportId
    this.totalCannabisSales = salesDataSourceWebPage.totalCannabisSales
    this.totalNonCannabisSales = salesDataSourceWebPage.totalNonCannabisSales
    this.totalSalesTaxCollected = salesDataSourceWebPage.totalSalesTaxCollected

    return this
  }
}
